import { createSlice } from "@reduxjs/toolkit"
//import axios from 'axios';


const dt = new Date()
const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
const day = dt.getUTCDate();
const month = String(dt.getMonth() + 1).padStart(2, '0');
const year = dt.getFullYear();
const date = year + "-" + month + "-" + day;


let data = []
if (localStorage.getItem("agent") !== null && localStorage.getItem("agent") !== undefined) {
    data = JSON.parse(localStorage.getItem("agent"))
}



const Insider = createSlice({

    name: "todos",
    initialState: data,
    reducers: {

        update_selectedagent: (state, action) => {

            state = action.payload.data
            localStorage.setItem("agent", JSON.stringify(action.payload.data))
            return state

        },





    }
})

export const { update_selectedagent } = Insider.actions;
export default Insider.reducer;