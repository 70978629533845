import React, {useEffect} from 'react';
import Table1 from "./portaltable1"
import Table2 from "./portaltable2"
import Table3 from "./portaltable3"
import Table4 from "./portaltable4"
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Agentstatus from './agentstatus';
import Backdrop from '@mui/material/Backdrop';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Badge from "@mui/material/Badge"

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Person_ from '../images/Ellipse77.png';
import CircularProgress from '@mui/material/CircularProgress';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";


import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import swal from 'sweetalert';
import axios from "axios"

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_motor } from '../redux/motor';
import { update_travel } from '../redux/travel';
import { update_agent } from '../redux/agent';
import { Offline, Online } from "react-detect-offline";
import Network from '../images/network.svg';


interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


function Profile() {
      const [openpage, setOpenpage] = React.useState(!true);

    const dispatch = useDispatch()
    const inst = useSelector((state) => state.admin)
    const Agent = inst
    

const dt = new Date()
const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
const day = dt.getUTCDate();
const month = String(dt.getMonth() + 1).padStart(2, '0');
const year = dt.getFullYear();
const date = year + "-" + month + "-" + day;
const last2 = new Date().getFullYear().toString().slice(-2)

  

const months=["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const [chooseyear, setchooseyear] = React.useState(year);
    const [choosemonth, setchoosemonth] = React.useState(months[parseInt(month)-1]);
    const [chooseday, setchooseday] = React.useState("none");


    
 const getaccidentdata = async () => {
        await axios.post("https://cromer.sisrevolution.com/api/schools/getschools/",
            {      
             
            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_accident({
                        data: res.data
                    }))
                }
            })
    }
    
   const gethomedata = async () => {
        await axios.post("https://server.ayconsultgh.com/api/homedata/gethomepolicy/",
            {
            
                userID:Agent.IDnumber,
                type:"Admin",
                institution:"subscribe",
                year:chooseyear,
                month:choosemonth,
                day:chooseday

            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_home({
                        data: res.data
                    }))
                }
            })
    }   
    
    const getmotordata = async () => {
        await axios.post("https://server.ayconsultgh.com/api/motordata/getmotorpolicy/",
            {
              userID:Agent.IDnumber,
                type:"Admin",
                institution:"subscribe",
                year:chooseyear,
                month:choosemonth,
                day:chooseday


            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_motor({
                        data:res.data
                    }))
                }
            })
    }

    const gettraveldata = async () => {
        await axios.post("https://server.ayconsultgh.com/api/traveldata/gettravelpolicy/",
            {

                userID:Agent.IDnumber,
                type:"Admin",
                institution:"subscribe",
                year:chooseyear,
                month:choosemonth,
                day:chooseday

            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_travel({
                        data: res.data
                    }))
                    setOpenpage(!true)
                }
            })
    }
    
    const homepolicy  = useSelector((state) => state.home).filter(bug=>bug.institution==="subscription" )
    const travelpolicy  = useSelector((state) => state.travel).filter(bug=>bug.institution==="subscription" )
    const accidentpolicy  = useSelector((state) => state.accident).filter(bug=>bug.institution==="subscription" )
    const motorpolicy  = useSelector((state) => state.motor).filter(bug=>bug.institution==="subscription" )



 useEffect(() => {
     
        gettraveldata()
        getaccidentdata()
        gethomedata()
        getmotordata()

  },[choosemonth, chooseyear, chooseday, inst])

  
    
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [opendelete, setOpendelete] = React.useState(!true);
    const [openedit, setOpenedit] = React.useState(!true);

    const [marital, setmarital] = React.useState(inst.marritalstatus)
    const [gender, setgender] = React.useState(inst.gender)
    const [currency, setcurrency] = React.useState("none")
    const [vehicleusage, setvehicleusage] = React.useState("none")
    const [branch, setbranch] = React.useState("none")
    const [idtype, setidtype] = React.useState(inst.IDtype)
    const [loading, setloading] = React.useState(false)
    const [message, setmessage] = React.useState("")
    const [stated, setstated] = React.useState("")
    const [person, setperson] = React.useState(inst.image===null || inst.image === undefined? Person_ :"https://cromeradmin.3ammissionministry.com/server/routes/uploads/"+inst.image)
    const [name, setname] = React.useState(inst.name)
    const [contact, setcontact] = React.useState(inst.contact)
    const [email, setemail] = React.useState(inst.email)
    const [dob, setdob] = React.useState(inst.dob)
    const [idnumber, setidnumber] = React.useState(inst.IDnumber)
    const [address, setaddress] = React.useState(inst.address)
    const [institution, setinstitution] = React.useState(inst.institution)
    const [localid, setlocalid] = React.useState(inst.localID)
    const [nicid, setnicid] = React.useState(inst.nicID)
    const [password, setpassword] = React.useState(inst.password)

   console.log("https://cromeradmin.3ammissionministry.com/server/routes/uploads/"+inst.image)
    const [opensnack, setOpensnack] = React.useState(false);

    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };

      const summation = (data)=>{
        var sum = 0
        for(var i in data){
            console.log(data[i].charge)
             if(data[i].charge!=="" && data[i].charge!==undefined){
           sum += parseFloat(data[i].charge)
            }
           
        }

        return sum
    } 
    
    const summation_commission = (data)=>{
        var sum = 0
        for(var i in data){
            console.log(data[i].agentcommission)
             if(data[i].charge!=="" && data[i].agentcommission!==undefined){
           sum += parseFloat(data[i].agentcommission)
            }
           
        }

        return sum
    } 
    
  
    const handleChangeyear = (event) => {
        setOpenpage(true)
        setchooseyear(event.target.value);
    };
    const handleChangeday = (event) => {
        setOpenpage(true)
        setchooseday(event.target.value);
    };
    const handleChangemonth = (event) => {
        setOpenpage(true)
        setchoosemonth(event.target.value);
    };
    
  const yearcalc=()=>{
var data = []
         for(var i=0; i<12; i++){
                data=[ ...data, year-i]       
              }
    return data
    }
    yearcalc()
  

    const handleChangemarital = (event) => {
        setmarital(event.target.value);
    };
    const handleChangegender = (event) => {
        setgender(event.target.value);
    };

    const handleChangeidtype = (event) => {
        setidtype(event.target.value);
    };
    const handleChangevehicleusage = (event) => {
        setvehicleusage(event.target.value);
    };
   
     const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };


    const handleClickOpendelete = () => {
        setOpendelete(true);
    };

    const handleClosedelete = () => {
        setOpendelete(false);
    };
  
    const handleClickOpenedit = () => {
        setOpenedit(true);
    };

    const handleCloseedit = () => {
        setOpenedit(false);
    };

      const imagehandler = (e) => {
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setperson(reader.result)
            }
        }

        reader.readAsDataURL(e.target.files[0])
    }
  
    const submitting = (e) => {
        setloading(true)
        e.preventDefault()

        const file = document.getElementById("fileimage").files[0]
       
        const password1 = document.getElementById("password1").value
        const password2 = document.getElementById("password2").value
        const marritalstatus = marital
    
        
        const fd = new FormData()
          if (file) {
                   
                        fd.append("file", file)
                        fd.append("image",dob + "" + name+ ".jpg")
                    }
                    fd.append("_id",Agent._id)
                    fd.append("name",name)
                    fd.append("contact",contact)
                    fd.append("email",email)
                    fd.append("dob",dob)
                    fd.append("address",address)
                    fd.append("institution",institution)
                    fd.append("password1",password)
                    fd.append("marritalstatus",marritalstatus)
                    fd.append("gender",gender)
                    fd.append("IDtype",idtype)
                    fd.append("IDnumber",idnumber)
                    fd.append("localID",localid)
                    fd.append("nicID",nicid)
                    fd.append("type","Agent")
                    
        const buyingpolicy = async () => {
            await axios.post("https://server.ayconsultgh.com/api/agent/editagent/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }

                        }).then(res => {

                    if (res.status === 200) {
                        e.target.reset()
                        setloading(!true)
                           dispatch(update_agent({
                        data: res.data
                    }))
                        swal("", " Profile Updated ", "success");


                    } else {
                        setloading(!true)
                        swal("", " Profile Update Error ", "error");

                    }

                })

        }
if(password1 === password2){
        buyingpolicy()
}else{
                            swal("", " Passwords do not match ", "error");

}
    }

const changer =(data,e)=>{
    data(e.target.value)
}
  
   
    return (

        <div>
        {/* <Online> */}
        <div >
<Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openpage}
        
      >
        <CircularProgress color="inherit" />
      </Backdrop>

                <Dialog fullWidth maxWidth="sm" onClose={handleClosedelete} aria-labelledby="customized-dialog-title" open={opendelete}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClosedelete} style={{ color: "grey" }}>
                      <b>Agent Status</b>
                      <IconButton  style={{float:"right"}} onClick={handleClosedelete}><Close /></IconButton>
                    </DialogTitle>

                    <DialogContent >


<Agentstatus status={inst.status} type="agent"  _id={inst._id} link="https://server.ayconsultgh.com/api/agent/changestatus/"/>

                   
                    </DialogContent>

                   
                </Dialog>

      <Dialog fullWidth maxWidth="sm" onClose={handleCloseedit} aria-labelledby="customized-dialog-title" open={openedit}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseedit} style={{ color: "grey" }}>
                      <b>Edit Agent</b>
                      <IconButton  style={{float:"right"}} onClick={handleCloseedit}><Close /></IconButton>
                    </DialogTitle>

                    <DialogContent >




<form  onSubmit={submitting} >

                        <div style={{ width: "100%", textAlign:"center" }}>



                            <input

                                style={{ display: "none" }}
                                id="fileimage"
                                multiple
                                type="file"
                                onChange={(e) => imagehandler(e)}
                            />
                            <label htmlFor="fileimage">
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <CameraAltIcon style={{ cursor:"pointer", backgroundColor: "#062333", padding: 6, borderRadius: "100%", color: "white", fontSize: "26px" }} />
                                    }
                                >
                                    <Avatar style={{ width: 130, height: 130, border: "solid", borderColor: "#062333", borderWidth:"0.1px" }} alt="Travis Howard" src={person} />

                                </Badge>

                            </label>


                           
                        </div>
                        <FormControl align="left" style={{ margin: 10 }}>
                            <b>Name </b>
                            <TextField
                                required
                                id="name"
                                style={{ width: "28ch" }}
                                type="text"
                                value={name}
                                inputMode='name'
                                onChange={(e)=>changer(setname,e)}
                                placeholder="Enter Full Name"
                                size="small"

                            /> </FormControl>
                        <FormControl align="left" style={{ margin: 10 }}>
                            <b>Contact </b>
                            <TextField
                                required
                                id="contact"
                                style={{ width: "28ch" }}
                                type="tel"
                                onChange={(e)=>changer(setcontact,e)}

                                value={contact}
                                inputMode='tel'
                                placeholder="Enter Contact"
                                size="small"

                            /> </FormControl>
                        <br/>
                        <FormControl align="left" style={{ margin: 10 }}>
                            <b>Email </b>
                            <TextField

                                id="email"
                                required
                                value={email}
                                onChange={(e)=>changer(setemail,e)}

                                style={{ width: "28ch" }}
                                type="email"
                                placeholder="Enter Email"
                                size="small"

                            /> </FormControl>

                        <FormControl align="left" style={{ margin: 10 }}>
                            <b>Date of Birth </b>
                            <TextField

                                id="dob"
                                required
                                value={dob}
                                style={{ width: "28ch" }}
                                type="date"
                                onChange={(e)=>changer(setdob,e)}

                                placeholder="Enter Full Name"
                                size="small"

                            /> </FormControl>
<br/>
                        <FormControl align="left" style={{ margin: 10 }}>
                            <b>Marital Status </b>

                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={marital}
                                size="small"
                                style={{ width: "28ch" }}
                                onChange={handleChangemarital}
                            >
                                <MenuItem value="none">
                                    <em>Marital status</em>
                                </MenuItem>
                                <MenuItem value="Single">Single</MenuItem>
                                <MenuItem value="Married">Married</MenuItem>
                                <MenuItem value="Divorced">Divorced</MenuItem>
                                <MenuItem value="Complicated">Complicated</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl align="left" style={{ margin: 10 }}>
                            <b>Gender</b>

                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={gender}
                                size="small"
                                style={{ width: "27ch" }}
                                onChange={handleChangegender}
                            >
                                <MenuItem value="none">
                                    <em>Choose Gender</em>
                                </MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>

                            </Select>
                        </FormControl>
<br/>
                        <FormControl align="left" style={{ margin: 10 }}>
                            <b>ID Type</b>

                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={idtype}
                                 
                                size="small"
                                style={{ width: "28ch" }}
                                onChange={handleChangeidtype}
                            >
                                <MenuItem value="none">
                                    <em>Choose ID Type</em>
                                </MenuItem>
                                <MenuItem value="Ghana Card">Ghana Card</MenuItem>
                                <MenuItem value="Passport">Passport</MenuItem>
                                <MenuItem value="Voter ID">Voter ID</MenuItem>

                            </Select>
                        </FormControl>
                         <FormControl align="left" style={{ margin: 10 }}>
                            <b>ID Number</b>
                            <TextField

                                id="idnumber"
                                required
                                
                                value={idnumber}
                                style={{ width: "28ch" }}
                                type="text"
                                onChange={(e)=>changer(setidnumber,e)}

                                placeholder="Enter ID Number"
                                size="small"

                            /> </FormControl>       
                            <br/>
                            <FormControl align="left" style={{ margin: 10 }}>
                            <b>Local ID </b>
                            <TextField

                                id="idnumber"
                                required
                                
                                value={localid}
                                style={{ width: "28ch" }}
                                type="text"
                                onChange={(e)=>changer(setlocalid,e)}

                                placeholder="Enter ID Number"
                                size="small"

                            /> </FormControl> 
                            <FormControl align="left" style={{ margin: 10 }}>
                            <b>NIC ID </b>
                            <TextField

                                id="idnumber"
                                required
                                
                                value={localid}
                                style={{ width: "28ch" }}
                                type="text"
                                onChange={(e)=>changer(setnicid,e)}

                                placeholder="Enter ID Number"
                                size="small"

                            /> </FormControl>
                            <br/>
                        <FormControl align="left" style={{ margin: 10 }}>
                            <b>Address </b>
                            <TextField
                                required
                                value={address}
                                id="address"
                                onChange={(e)=>changer(setaddress,e)}

                                style={{ width: "28ch" }}
                                type="address"
                                placeholder="Enter Address"
                                size="small"

                            /> </FormControl>  

                            {institution === name?
                             <FormControl align="left" style={{ margin: 10 }}>
                            <b>Institution </b>
                            <TextField
                                required
                                value="Individual"
                                id="institution"
                                style={{ width: "28ch" }}
                                type="text"                                
                                onChange={(e)=>changer(setinstitution,e)}
                                placeholder="Enter Institution Name"
                                size="small"

                            /> </FormControl>
                            :
                                <FormControl align="left" style={{ margin: 10 }}>
                            <b>Institution </b>
                            <TextField
                                required
                                 
                               value={institution}
                                id="institution"
                                style={{ width: "28ch" }}
                                type="text"                                
                                onChange={(e)=>changer(setinstitution,e)}

                                placeholder="Enter Institution Name"
                                size="small"

                            /> </FormControl>
                            }
                        <br />
                        <br />

                        <Divider style={{ marginBottom: "18px" , backgroundColor:"#062333"}}>
                            <Chip label="PASSWORD SETTINGS" style={{backgroundColor:"white", color:"#062333"}}  />
                        </Divider>


                        <FormControl align="left" style={{ margin: 10 }}>
                            <b>Password </b>
                            <TextField

                                id="password1"
                                style={{ width: "28ch" }}
                                type="password"
                                placeholder="Enter Password"
                                size="small"
                                onChange={(e)=>changer(setpassword,e)}


                            /> </FormControl>

                        <FormControl align="left" style={{ margin: 10 }}>
                            <b>Confirm Password</b>
                            <TextField

                                id="password2"
                                style={{ width: "28ch" }}
                                type="password"
                                placeholder="Confirm Password"
                                size="small"

                            /> </FormControl>
                        <br />
                        <br />
                      
          <div align="center">
          {!loading?
          <input type="submit" value="SUBMIT" style={{padding:13.5, cursor:"pointer", borderRadius:5, outline:"none", border:"none", backgroundColor:"#062333", color:"white", width:"35ch", marginTop:"10px", marginBottom:"40px"}}/>
          :
          <Button size="large" disabled startIcon={<CircularProgress style={{color:"#062333", width:"20px", height:"20px"}} />}  style={{ backgroundColor:"lightgrey", color:"#062333", width:"35ch", marginTop:"10px", marginBottom:"8px"}} >Loading...</Button>
          }
          </div>
                  </form> 





                   
                    </DialogContent>

                   
                </Dialog>





        <h2 align="center" style={{color:"#062333"}}>LIST OF SCHOOLS</h2>
       <Grid container>
        <Grid xs={12} lg={6}>
        {/* {JSON.parse(localStorage.getItem("user")).admintype!=="Secondary"?
        <div>
              <Button style={{backgroundColor:"#062333", color:"white", margin:5}} onClick={handleClickOpendelete}>Agent Status</Button>
                <Button style={{backgroundColor:"#062333", color:"white", margin:5}}  onClick={handleClickOpenedit}>Edit Agent</Button>
        </div>
        :
        ""
        } */}
        </Grid>

       <br/>
       <br/>
       </Grid>
<Divider/>
       <Grid container>
           <Grid xs={12} lg={4}>

             <div style={{ borderWidth:"0.1px", padding:10,  borderRadius:"5px"}}>

      
           <FormControl align="left" style={{margin:0}}> 

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={chooseyear}
          size="small"
          style={{width:"12ch"}}
          onChange={handleChangeyear}
        >
          <MenuItem value="none">
            <em>Sort by Year</em>
          </MenuItem>
          {yearcalc().map((list,index)=>
                    <MenuItem value={list}>{list}</MenuItem>
          )
             
          }

          
        </Select>
       </FormControl>

<FormControl align="left" style={{margin:0}}> 

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={choosemonth}
          size="small"
          style={{width:"12ch"}}
          onChange={handleChangemonth}
        >
                             
        <MenuItem value="none">All</MenuItem>

          {months.map((list,index)=>
                    <MenuItem value={list}>{list}</MenuItem>
          )
             
          }

          
        </Select>
       </FormControl>

    <FormControl align="left" style={{margin:0}}> 

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={chooseday}
          size="small"
          style={{width:"12ch"}}
          onChange={handleChangeday}
        >
         <MenuItem value="none">Days</MenuItem>
         <MenuItem value="1">1</MenuItem>
         <MenuItem value="2">2</MenuItem>
         <MenuItem value="3">3</MenuItem>
         <MenuItem value="4">4</MenuItem>
         <MenuItem value="5">5</MenuItem>
         <MenuItem value="6">6</MenuItem>
         <MenuItem value="7">7</MenuItem>
         <MenuItem value="8">8</MenuItem>
         <MenuItem value="9">9</MenuItem>
         <MenuItem value="10">10</MenuItem>
         <MenuItem value="11">11</MenuItem>
         <MenuItem value="12">12</MenuItem>
         <MenuItem value="13">13</MenuItem>
         <MenuItem value="14">14</MenuItem>
         <MenuItem value="15">15</MenuItem>
         <MenuItem value="16">16</MenuItem>
         <MenuItem value="17">17</MenuItem>
         <MenuItem value="18">18</MenuItem>
         <MenuItem value="19">19</MenuItem>
         <MenuItem value="20">20</MenuItem>
         <MenuItem value="21">21</MenuItem>
         <MenuItem value="22">22</MenuItem>
         <MenuItem value="23">23</MenuItem>
         <MenuItem value="24">24</MenuItem>
         <MenuItem value="25">25</MenuItem>
         <MenuItem value="26">26</MenuItem>
         <MenuItem value="27">27</MenuItem>
         <MenuItem value="28">28</MenuItem>
         <MenuItem value="29">29</MenuItem>
         <MenuItem value="30">30</MenuItem>
         <MenuItem value="31">31</MenuItem>
        </Select>
       
       
       
       
       
       </FormControl>

       
        </div>

        
        
        </Grid>

        <Grid xs={12} lg={8}>

<Grid container style={{marginTop:10}}>

          <Grid  item  xs={6} lg={2}>   <p style={{fontWeight:"bolder", margin:5}}>Total Policies : {travelpolicy.length + homepolicy.length + motorpolicy.length + accidentpolicy.length} </p>  
</Grid>
     
          <Grid  item  xs={12} lg={4}>  <p style={{fontWeight:"bolder", margin:5}} >Total Commission : GHC {(summation_commission(travelpolicy)+summation_commission(motorpolicy)+summation_commission(homepolicy)+summation_commission(accidentpolicy)).toFixed(2)} </p>
</Grid>
          <Grid   item xs={12} lg={3}>  <p style={{fontWeight:"bolder", margin:5}} >Total Premium : GHC {(summation(travelpolicy)+summation(motorpolicy)+summation(homepolicy)+summation(accidentpolicy)).toFixed(2)} </p>
</Grid>

</Grid>

        </Grid>
       </Grid>
              <Divider/>

      <Table1/>
      
        </div>

        </div>
    );
}

export default Profile;
