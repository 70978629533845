import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Container from '@mui/material/Container';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Badge, { BadgeProps } from '@mui/material/Badge';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { capitalize, IconButton } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ListItemButton from '@mui/material/ListItemButton';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SellIcon from '@mui/icons-material/Sell';
import MenuIcon from '@mui/icons-material/Menu';
import ForumIcon from '@mui/icons-material/Forum';
import GroupIcon from '@mui/icons-material/Group';

import EqualizerIcon from '@mui/icons-material/Equalizer';
import InsightsIcon from '@mui/icons-material/Insights';
import AddTaskIcon from '@mui/icons-material/AddTask';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PersonIcon from '@mui/icons-material/Person';
import AddPersonIcon from '@mui/icons-material/AddBusiness';
import EngineeringIcon from '@mui/icons-material/Engineering';

import Collapse from '@mui/material/Collapse';
import DraftsIcon from '@mui/icons-material/Drafts';
import Logout from '@mui/icons-material/Logout';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Settings from '@mui/icons-material/Settings';
import axios from 'axios';
import Person from '../images/Ellipse77.png';
import Avatar from '@mui/material/Avatar';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_motor } from '../redux/motor';
import { update_travel } from '../redux/travel';
import { update_agent } from '../redux/agent';
import { update_selectedagent } from '../redux/selectedagent';
import { update_admin } from '../redux/admin';
import { update_chat } from '../redux/chat';
import { styled } from '@mui/material/styles';


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -25,
    top: 16,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

export default function TemporaryDrawer() {
      const [person, setperson] = React.useState(Person)
    const dispatch = useDispatch()

    const getchat = async() => {
      await axios.post("https://cromer.sisrevolution.com/api/chat/getchats/", {

      }).then(res => {
          if (res.status === 200) {
            dispatch(update_chat({
              data: res.data
          }))
          }
      })
  }

  const mainadmin = JSON.parse(localStorage.getItem("user"))
  const chatlist  = useSelector((state) => state.chat )

    const agents  = useSelector((state) => state.agent )

    const motor  = useSelector((state) => state.motor ).filter(bug=>bug.status==="Pending...")
    const travel = useSelector((state) => state.travel).filter(bug=>bug.status==="Pending...")
    const home = useSelector((state) => state.home).filter(bug=>bug.status==="Pending...")
    const accident = useSelector((state) => state.accident).filter(bug=>bug.status==="Pending...")

    const agents_ = useSelector((state) => state.selectedagent)

 var admindata=[]

 if(mainadmin.type==="admin"){
  admindata=[['Dashboard', "/", <EqualizerIcon/>],['Rooms', "/profile", <AddPersonIcon/>], ['Bookings', "/cashback", <AccountBalanceWalletIcon />],['Guests', "/getquote", <PersonIcon />], ['Bulk Email', "/registeragent",  <MailIcon/>], ['Live Chat', "/portal",  <ForumIcon/>], ['Staff', "/appusers",  <GroupIcon/>]]
 }else{
  admindata=[['Dashboard', "/", <EqualizerIcon/>],['Rooms', "/profile", <AddPersonIcon/>], ['Bookings', "/cashback", <AccountBalanceWalletIcon />],['Guests', "/getquote", <PersonIcon />], ['Bulk Email', "/registeragent",  <MailIcon/>], ['Live Chat', "/portal",  <ForumIcon/>]]
 }





const counter=()=>{
var list=0
for(var i in chatlist){
  list+=chatlist[i].chat.filter(bug=> bug.seen && bug.seen.length===0 ).length
}

return list

}
    const loader=true

  const logout=()=>{
    localStorage.clear()
    window.location.replace("https://cromeradmin.3ammissionministry.com")
  }

      const getagentdata = async () => {
         
        await axios.post("https://server.ayconsultgh.com/api/agent/getagentdata/",
            {
            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_agent({
                        data: res.data
                    }))
                }
            })
    }

   

    useEffect(()=>{

      getagentdata()
      getchat()


    },[loader])
  
    
            
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
     const [open, setOpen] = React.useState(!true);

    const handleClick = () => {
      setOpen(!open);
    };


  const list = (anchor) => {
 const setagent =(data)=>{

       dispatch(update_selectedagent({
                        data: data
                    }))

    setState({ ...state, [anchor]: false });

    }
return(
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 280 }}
      role="presentation"
    >
     <Container >
     <div align="center">
      
    <br/>

          <Avatar style={{ width: 100, height: 100, border: "solid", borderColor: "#062333", borderWidth:"0.1px" }} alt="Travis Howard" src={localStorage.getItem("user")!==null? JSON.parse(localStorage.getItem("user")).image !==null? "https://cromeradmin.3ammissionministry.com/server/routes/uploads/"+JSON.parse(localStorage.getItem("user")).image : person :person} />

    </div>
      <h4 align="center" style={{color:"#062333"}}>{localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).name:""}</h4>
      
    </Container>
    <Divider/>
      <List >
        {admindata.map((text, index) => (
          <Link to={text[1]}  style={{color:"grey", textDecoration:"none"}} onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
          <ListItem button key={text}>
            <ListItemIcon>
            {text[0]==="Live Chat"?
            <Badge badgeContent={counter()} color="error">
              {text[2]}
                </Badge>:
                text[2]
              }
          
            </ListItemIcon>
            <ListItemText primary={text[0]} />
          </ListItem>
          </Link>
        ))}
      </List>
    
      <list>
      
          <Link to="/">
           <ListItem button onClick={logout}>
            <ListItemIcon>
              <Logout/>
            </ListItemIcon>
            <ListItemText primary="Log Out" />
          </ListItem>
          </Link>
          </list>
    
    
    </Box>
)
}

  return (
    <div>
      {[<MenuIcon/>].map((anchor) => (
        <React.Fragment >
          <IconButton onClick={toggleDrawer("left", true)} style={{color:"white", marginRight:30}}>{anchor}</IconButton>
          <Drawer
            anchor="left"
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {list("left")}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
