import React from 'react';
import Grid from '@mui/material/Grid';
import '../index.css'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import AccountCircle from '@mui/icons-material/AccountCircle';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import Container from '@mui/material/Container';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Aylogo from '../images/photo_2022-06-02_02-37-50.jpg';
import Serene from '../images/serenelogo.png';
import Glicologo from '../images/glicologo.png';
import Slider from './slider';
import Login from './login_details';
import Signup from './signup_details';
import Password from '../registeragent/password';
import { Offline, Online } from "react-detect-offline";
import Network from '../images/network.svg';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams
} from "react-router-dom";


import Logo from '../images/logo.png';
import FacebookOutlined from '@mui/icons-material/FacebookOutlined';
import WhatsApp from '@mui/icons-material/WhatsApp';


export default function BurcyHome() {

    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
<div>


        <Grid container className="mainsection">

            <Grid lg={6} xs={12} md={6} className="side1" style={{color:"white", paddingTop:10}} >

              
               <br/>
               <br/>
               <br/>
              <div>
                  <img src={Aylogo} width="26%" style={{marginTop:"-50px"}} />
                  <p style={{margin:0, color:"white"}}>A Home Away From Home</p>
              </div>

                <Slider/>
                
            </Grid>
            <Grid lg={6} xs={12} md={6} className="side2">
                <Container maxWidth="lg" style={{ textAlign: 'center' }}>
                 <br/>
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/forgottenpassword" element={<Password />} />
                        </Routes>

                  
            <div align="center" style={{marginTop:"8%"}}>

            </div>
 </Container>
            </Grid>

           
        </Grid>

{/* <Offline>
    <div>
<img src={Network} className="nonetwork" />
<p align="center" >no internet connection, connect internet and reload</p>
<button onClick={()=>window.location.reload()} style={{backgroundColor:"white", border:"none", color:"blue", cursor:"pointer"}}> Reload</button>
    </div>
</Offline> */}


        </div>
    );
}
