import * as React from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

import Shield from '@mui/icons-material/Security';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import axios from "axios"

import Badge from '@mui/material/Badge';
import ListItemButton from '@mui/material/ListItemButton';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Person from '../images/Ellipse77.png';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import Close from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Image from '../images/imager.jpg';
// import Avatar from '@mui/material/Avatar';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide'

import swal from 'sweetalert';
import Box from '@mui/material/Box';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { AvatarGroup } from '@mui/material';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_agent } from '../redux/agent';
import { update_rooms } from '../redux/rooms';
import { EditOutlined } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction = "up"
    ref = { ref } {...props }
    />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation = { 6 }
    ref = { ref }
    variant = "filled" {...props }
    />;
});

function Profile() {
    const [selected, setselected] = React.useState([])

    const Mydata = useSelector((state) => state.admin)
    const rooms = useSelector((state) => state.rooms)
    const dispatch = useDispatch()
    const [loader, setloader] = React.useState(true)
    const [staff, setstaff] = React.useState([]);

    const getrooms = async() => {
        await axios.post("https://cromer.sisrevolution.com/api/staff/getstaff/", {

        }).then(res => {
            if (res.status === 200) {
                console.log(res)

                   setstaff(res.data)
               
            }
        })
    }
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const [status, setstatus] = React.useState(selected.length!==0? selected.status :"");
    const [open_login, setOpen_login] = React.useState(false);
    const [open_status, setOpen_status] = React.useState(false);
    const [open_edit, setOpen_edit] = React.useState(false);
    const [open_delete, setOpen_delete] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpen_delete(false);
        setOpen_edit(false);
        setOpen_login(false);
        setOpen_status(false);
    };

    React.useEffect(() => {
        getrooms()
    }, [loader])

    const [help, sethelp] = React.useState("")
    const [roomlist, setroomlist] = React.useState([])
    const [gender, setgender] = React.useState(Mydata.gender)
    const [marital, setmarital] = React.useState(Mydata.marritalstatus)
    const [loading, setloading] = React.useState(false)
    const [message, setmessage] = React.useState("")
    const [stated, setstated] = React.useState("")
    const [category, setcategory] = React.useState("")
    const [numofrooms, setnumofrooms] = React.useState("")
    const [price, setprice] = React.useState("")
    const [discount, setdiscount] = React.useState("")
    const [max, setmax] = React.useState("")
    const [features, setfeatures] = React.useState([])
    const [person, setperson] = React.useState(Mydata.image !== undefined || Mydata.image !== null ? "https://cromeradmin.3ammissionministry.com/server/routes/uploads/" + Mydata.image : person)

    const [opensnack, setOpensnack] = React.useState(false);

    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };


    const changeselect = (e) => {
        setselected(e)
    }

    const changing = (setting, e) => {

        setting(e.target.value)

    }

    const handleClickOpen_edit = () => {
        setOpen_edit(true);
        setcategory(selected.category)
        setnumofrooms(selected.numberofrooms)
        setprice(selected.price)
        setdiscount(selected.discount)
        setmax(selected.max)
        setfeatures(selected.features)
    };

    const handleClickOpen_login=()=>{
        setOpen_login(true);

    }

    const handleClickOpen_status=()=>{
        setOpen_status(true);

    }


    const addapartment = (e) => {
        console.log("adder")
        e.preventDefault()
      
        const fd = new FormData()
       

        fd.append("lastname", document.getElementById("lastname").value)
        fd.append("othername", document.getElementById("othername").value)
        fd.append("contact", document.getElementById("contact").value)
        fd.append("address", document.getElementById("address").value)
        fd.append("email", document.getElementById("email").value)
        fd.append("job", document.getElementById("job").value)
        console.log(fd)
        const apartment = async() => {
            await axios.post("https://cromer.sisrevolution.com/api/staff/addstaff/", fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            }).then(res => {

                if (res.status === 200) {
                    dispatch(update_rooms({
                            data: res.data
                        }))
                        setloading(!true)

                    swal("", " New Staff Added ", "success");


                } else {
                    setloading(!true)
                    swal("", "Error Adding Staff", "error");

                }

            })

        }
        apartment()


    }

    const giveaccess = () => {
        console.log("adder")
      
        const fd = new FormData()
       

        fd.append("_id", selected._id)
        fd.append("lastname", selected.lastname)
        fd.append("email", selected.email)
        console.log(fd)
        const apartment = async() => {
            await axios.post("https://cromer.sisrevolution.com/api/staff/giveaccess/", fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            }).then(res => {

                if (res.status === 200) {
                    dispatch(update_rooms({
                            data: res.data
                        }))
                        setloading(!true)

                    swal("", " Access granted", "success");


                } else {
                    setloading(!true)
                    swal("", "Error granting access", "error");

                }

            })

        }
        apartment()


    }

    const changestatus = () => {
        console.log("adder")
      
        const fd = new FormData()
       

        fd.append("_id", selected._id)
        fd.append("status", status)
        console.log(fd)
        const apartment = async() => {
            await axios.post("https://cromer.sisrevolution.com/api/staff/changestatus/", fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            }).then(res => {

                if (res.status === 200) {
                            setstaff(res.data)
                        
                        setloading(!true)

                    swal("", " Status Changed", "success");


                } else {
                    setloading(!true)
                    swal("", "Error Changing Status", "error");

                }

            })

        }
        apartment()


    }

    const addapartment_edit = (e) => {
        console.log("adder")
        e.preventDefault()



        const apartment = async() => {
            await axios.post("https://cromer.sisrevolution.com/api/staff/editstaff/", {
                _id: selected._id,
                category: category,
                rooms: numofrooms,
                price: price,
                doscount: discount,
                max: max,
                specifications: features,

            }).then(res => {

                if (res.status === 200) {
                    dispatch(update_rooms({
                        data: res.data
                    }))
                    setloading(!true)

                    swal("", " Edited Successfully", "success");


                } else {
                    // setloading(!true)
                    swal("", "Error", "error");

                }

            })

        }
        apartment()


    }

  

    const addroom_edit = () => {
        var data = [...features]
        var feature_ = document.getElementById("feature_").value
        data.push(feature_)
        setfeatures(data)
    }



    const removeroom_ = (e) => {

        const apartment = async() => {
            await axios.post("https://cromer.sisrevolution.com/api/staff/deletestaff/", {

                _id: e

            }).then(res => {

                if (res.status === 200) {
                    dispatch(update_rooms({
                        data: res.data
                    }))

                    setselected([])
                        setloading(!true)

                    swal("", " Staff Deleted ", "success");


                } else {
                    setloading(!true)
                    swal("", "Error Deleting Staff", "error");

                }

            })

        }
        apartment()
    }

    return ( <Container >

        <Snackbar open = { opensnack }
        autoHideDuration = { 6000 }
        onClose = { handleClosesnack } >
        <Alert onClose = { handleClosesnack }
        severity = { stated }
        sx = {
            { width: '100%' }
        } > { message } </Alert> </Snackbar>



        <form>
        <Dialog open = { open }
        TransitionComponent = { Transition }
        keepMounted onClose = { handleClose }
        aria-describedby = "alert-dialog-slide-description" >
        <DialogTitle > { "Add Staff" } </DialogTitle> <DialogContent >
        <DialogContentText id = "alert-dialog-slide-description">

        <TextField size = "small"
        type = "text"
        id = "lastname"
        label = "Lastname"
        style = {
            { margin: "10px" }
        }
        /> <TextField size = "small"
        type = "text"
        id = "othername"
        label = "Othername(s) "
        style = {
            { margin: "10px" }
        }
        /> <TextField size = "small"
        type = "tel"
        id = "contact"
        label = "Contact Number"
        style = {
            { margin: "10px" }
        }
        /> <TextField size = "small"
        type = "email"
        id = "email"
        label = "Email"
        style = {
            { margin: "10px" }
        }
        /> 
        <TextField size = "small"
        type = "text"
        id = "job"
        label="Job Description"
        style = {
            { margin: "10px" }
        }
        />
        <TextField size = "small"
        type = "address"
        id = "address"
        label = "Address"
        style = {
            { margin: "10px" }
        }
        /> 
       




        </DialogContentText> </DialogContent > 
        <DialogActions >
        <Button onClick = { addapartment } > Add Staff </Button> 
        <Button onClick = { handleClose } > Close </Button> 
        </DialogActions > </Dialog> 
        </form >



        <Dialog open = { open_edit }
        TransitionComponent = { Transition }
        keepMounted onClose = { handleClose }
        aria-describedby = "alert-dialog-slide-description" >
        <DialogTitle > { "Edit Room" } </DialogTitle> 
        <DialogContent >
        <DialogContentText id = "alert-dialog-slide-description" >



        <br/>
        <TextField size = "small"
        value = { category }
        onChange = {
            (e) => changing(setcategory, e)
        }
        type = "text"
        id = "apartmentname"
        label = "Apartment Name"
        style = {
            { margin: "10px" }
        }
        /> 
        <TextField size = "small"
        value = { numofrooms }
        onChange = {
            (e) => changing(setnumofrooms, e)
        }
        type = "number"
        id = "rooms_"
        label = "Number Of Rooms "
        style = {
            { margin: "10px" }
        }
        /> 
        <TextField size = "small"
        value = { price }
        onChange = {
            (e) => changing(setprice, e)
        }
        type = "number"
        id = "price"
        label = "Price"
        style = {
            { margin: "10px" }
        }
        /> 
        <TextField size = "small"
        value = { discount }
        onChange = {
            (e) => changing(setdiscount, e)
        }
        type = "number"
        id = "discount"
        label = "Discount"
        style = {
            { margin: "10px" }
        }
        /> 
        <TextField size = "small"
        value = { max }
        onChange = {
            (e) => changing(setmax, e)
        }
        type = "number"
        id = "max"
        label = "Maximum Number of persons"
        style = {
            { margin: "10px" }
        }
        />


        <Grid container spacing = { 2 } >
        <Grid item xs = { 8 } >
        <TextField type = "text"
        id = "feature_"
        label = "Room Feature "
        size = "small"
        style = {
            { margin: "10px", width: "100%" }
        }
        />   </Grid > 
        <Grid item xs = { 4 } >
        <Button variant = "outlined"
        onClick = {
            () => addroom_edit()
        }
        style = {
            { margin: "10px" }
        } > Add Feature </Button> 
        </Grid > 
        </Grid> 
        <Divider/>


        </DialogContentText> 
        </DialogContent > <DialogActions> {
            loading ? <Button disabled style = {
                { backgroundColor: "gray" }
            } > Loading... </Button> : <Button onClick = { addapartment_edit } > Submit </Button>

        } <Button onClick = { handleClose } > Close </Button> 
        </DialogActions > </Dialog>


        <Dialog open = { open_delete }
        TransitionComponent = { Transition }
        keepMounted onClose = { handleClose }
        aria-describedby = "alert-dialog-slide-description" >
        <DialogTitle > { "Delete" } </DialogTitle> 
        <DialogContent >
        <DialogContentText id = "alert-dialog-slide-description" >

        <p> Are you sure you want to delete this staff ? </p>

        </DialogContentText> 
        </DialogContent > 
        <DialogActions > {
            loading ? <Button disabled style = {
                { backgroundColor: "gray" }
            } > Loading... </Button> : <Button onClick = {
                () => removeroom_(selected._id)
            } > Yes </Button>

        } <Button onClick = { handleClose } > Close </Button> 
        </DialogActions > </Dialog>




        <Dialog open = { open_login }
        TransitionComponent = { Transition }
        keepMounted onClose = { handleClose }
        aria-describedby = "alert-dialog-slide-description" >
        <DialogTitle > { "Longin Access" } </DialogTitle> 
        <DialogContent >
        <DialogContentText id = "alert-dialog-slide-description" >

        <p> Do you want to give <b>{selected.lastname+" "+selected.othername} </b> access to login to the Cromer Administrative system? </p>

        </DialogContentText> 
        </DialogContent > 
        <DialogActions > {
            loading ? <Button disabled style = {
                { backgroundColor: "gray" }
            } > Loading... </Button> : <Button onClick = {
                () => giveaccess()
            } > Yes </Button>

        } <Button onClick = { handleClose } > Close </Button> 
        </DialogActions > </Dialog>




        <Dialog open = { open_status }
        maxWidth="sm"
        fullWidth
        TransitionComponent = { Transition }
        keepMounted onClose = { handleClose }
        aria-describedby = "alert-dialog-slide-description" >
        <DialogTitle > { "Change Status" } </DialogTitle> 
        <DialogContent >
        <DialogContentText id = "alert-dialog-slide-description" >


        <FormControl>
      {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={status}
        onChange={(e)=>setstatus(e.target.value)}
        name="radio-buttons-group"
      >
        <FormControlLabel value="Active" control={<Radio />} label="Active" />
        <FormControlLabel value="Suspend" control={<Radio />} label="Suspend" />
        <FormControlLabel value="Resign" control={<Radio />} label="Resign" />
      </RadioGroup>
    </FormControl>
        
        
        </DialogContentText> 
        </DialogContent > 
        <DialogActions > {
            loading ? <Button disabled style = {
                { backgroundColor: "gray" }
            } > Loading... </Button> : <Button onClick = {
                () => changestatus()
            } > Yes </Button>

        } <Button onClick = { handleClose } > Close </Button> 
        </DialogActions > </Dialog>





        <h2 align = "center" > < span style = {
            { color: "#062333", padding: 10, borderRadius: "40px" }
        } > Staff </span></h2>

        <Button variant = "outlined"
        startIcon = { <AddBusinessIcon/> }
        onClick = { handleClickOpen } >
        Add Staff </Button> <br/>
        <br/>
        <Grid container spacing = { 4 } >

        <Grid item xs = { 12 }
        lg = { 4 }
        align = "center" >
        <div className = 'scroller1'
        style = {
            { height: "80vh", padding: 10, borderRadius: "10px", border: "solid", borderColor: "lightgray", borderWidth: "0.05px" }
        } > {
            staff.map((list, index) =>
                <div>
                <List style = {
                    { padding: 0, margin: 0 }
                } >

                <ListItemButton onClick = {
                    () => changeselect(list)
                }
                secondaryAction = { <
                    IconButton edge = "end"
                    aria-label = "delete" >
                    <DeleteIcon/>
                    </IconButton>
                } >
                <ListItemAvatar >
                <Avatar >
                <AddBusinessIcon/>
                </Avatar> </ListItemAvatar > 
                <ListItemText primary = { list.lastname+" "+list.othername}
                secondary = {list.contact}
                /> </ListItemButton >

                </List> <Divider/>
                </div> 
            )
        }


        </div> </Grid >



        <Grid item xs = { 12 }
        lg = { 8 } >

        <div className = 'scroller1'
        style = {
            { height: "90vh", padding: 10, borderRadius: "10px", border: "solid", borderColor: "lightgray", borderWidth: "0.05px" }
        } >

        {
            selected.length !== 0 ?

            <Container >
            <h3 textAlign = "center" > { selected.category } </h3> 
            
            <Button style = {
                { marginLeft: 10 }
            }
            variant = "outlined"
            startIcon = { <Shield/> }
            onClick = {
                () => handleClickOpen_login()
            } > Login Access </Button> 
            
            <Button style = {
                { marginLeft: 10 }
            }
            variant = "outlined"
            startIcon = { < AutorenewIcon/> }
            onClick = {
                () => handleClickOpen_status()
            } > Status </Button>  
            
             <Button style = {
                { marginLeft: 10 }
            }
            variant = "outlined"
            startIcon = { < EditOutlined/> }
            onClick = {
                () => handleClickOpen_edit()
            } > Edit </Button> 
            
            
            <Button style = {
                { marginLeft: 10 }
            }
            variant = "outlined"
            color = "error"
            startIcon = { <Close/> }
            onClick = {
                () => setOpen_delete(true)
            } > DELETE </Button>


            <br/>
            <br/>

            <div style = {
                { textAlign: "left" }
            } >
            <ListItemText
            primary = {selected.lastname+" "+selected.othername}
            secondary = "Name" />
            <Divider />

            <ListItemText
            primary = {selected.status}
            secondary = "Status" />
            <Divider />


            <ListItemText
            primary = {selected.loginaccess}
            secondary = "Login Access" />
            <Divider />


            <ListItemText
            primary = { selected.contact }
            secondary = "Contact" />
            <Divider />


            <ListItemText
            primary = { selected.email }
            secondary = "Email" />
            <Divider />


            <ListItemText
            primary = { selected.address }
            secondary = "Address" />

<Divider />

            <ListItemText
            primary = { selected.job }
            secondary = "Job Description" />

           

            </div>

            </Container>

            :
                ""

        }


        </div> </Grid >
        </Grid>



        </Container>
    );
}

export default Profile;