import { createSlice } from "@reduxjs/toolkit"
//import axios from 'axios';


const dt = new Date()
const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
const day = dt.getUTCDate();
const month = String(dt.getMonth() + 1).padStart(2, '0');
const year = dt.getFullYear();
const date = year + "-" + month + "-" + day;


let data = []

const Insider = createSlice({

    name: "todos",
    initialState: data,
    reducers: {

        update_rooms: (state, action) => {

            state = action.payload.data
            return state

        },





    }
})

export const { update_rooms } = Insider.actions;
export default Insider.reducer;