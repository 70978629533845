import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_motor } from '../redux/motor';
import { update_travel } from '../redux/travel';
import { update_selectedagent } from '../redux/selectedagent';
import swal from 'sweetalert';

const options = [
    'Active',
    'Suspend',

];

function ConfirmationDialogRaw(props) {
    const { onClose, value: valueProp, open, _id, ...other } = props;
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef(null);
    const dispatch = useDispatch()


    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };



    const handleOk = () => {
        onClose(value);

        const statusmotor = async () => {

            await axios.post(props.link,
                {
                    _id: props._id,
                    status: value
                }).then(res => {
                    if (res.status === 200) {
                        console.log(res.data)
                        if (props.type === "motor") {
                            dispatch(update_motor({
                                data: res.data
                            }))
                        }
                        if (props.type === "travel") {
                            dispatch(update_travel({
                                data: res.data
                            }))
                        }
                        if (props.type === "accident") {
                            dispatch(update_accident({
                                data: res.data
                            }))
                        }
                        if (props.type === "home") {
                            dispatch(update_home({
                                data: res.data
                            }))
                        } if (props.type === "agent") {
                            dispatch(update_selectedagent({
                                data: res.data
                            }))
                               
                       
                        }

                        swal("", "Status changed to " + value, "success");


                    }
                })
        }

        statusmotor()

    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >
            <DialogTitle>Agent Status</DialogTitle>
            <DialogContent dividers>
                <RadioGroup
                    ref={radioGroupRef}
                    aria-label="Change status"
                    name="Agent Status"
                    value={value}
                    onChange={handleChange}
                >
                    {options.map((option) => (
                        <FormControlLabel
                            value={option}
                            key={option}
                            control={<Radio />}
                            label={option}
                        />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel}>
                    Cancel
                </Button>
                <Button onClick={handleOk}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
};

export default function ConfirmationDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(props.status);
    const [_id, set_id] = React.useState(props._id);

    const handleClickListItem = () => {
        setOpen(true);
        set_id(props._id);
    };

    const handleClose = (newValue) => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
        }
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <List component="div" role="group">
                <ListItem button divider disabled>
                    <ListItemText primary="Change Agent status" />
                </ListItem>
                <ListItem
                    button
                    divider
                    aria-haspopup="true"
                    aria-controls="ringtone-menu"
                    aria-label="phone ringtone"
                    onClick={handleClickListItem}
                >
                    <ListItemText primary={value} secondary="Agent Status" />
                </ListItem>


                <ConfirmationDialogRaw
                    id="ringtone-menu"
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    value={value}
                    _id={props._id}
                    link={props.link}
                    type={props.type}
                />
            </List>
            <br />
            <br />
        </Box>
    );
}







