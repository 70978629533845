import * as React from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';

import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import axios from "axios"
import swal from 'sweetalert';

import Badge from '@mui/material/Badge';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Person from '../images/Ellipse77.png';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';


import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { AvatarGroup } from '@mui/material';
import { Offline, Online } from "react-detect-offline";
import Network from '../images/network.svg';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function Profile() {

    const theme = useTheme();
    const [age, setAge] = React.useState('');

    const [buyagent, setbuyagent] = React.useState("none")
    const [policytype, setpolicytype] = React.useState("none")
    const [idtype, setidtype] = React.useState("none")
    const [gender, setgender] = React.useState("none")
    const [marital, setmarital] = React.useState("none")
    const [bodytype, setbodytype] = React.useState("none")
    const [buyextra, setbuyextra] = React.useState("none")
    const [buyback, setbuyback] = React.useState("none")
    const [loading, setloading] = React.useState(false)
    const [message, setmessage] = React.useState("")
    const [stated, setstated] = React.useState("")
    const [person, setperson] = React.useState(Person)

    const [opensnack, setOpensnack] = React.useState(false);

    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };

    const handleChangeselector = (event) => {
        setAge(event.target.value);
    };


    const handleChangebuyback = (event) => {
        setbuyback(event.target.value);
    };

    const handleChangebuyextra = (event) => {
        setbuyextra(event.target.value);
    };
    const handleChangebodytype = (event) => {
        setbodytype(event.target.value);
    };

    const handleChangeidtype = (event) => {
        setidtype(event.target.value);
    };
    const handleChangegender = (event) => {
        setgender(event.target.value);
    };
    const handleChangemarital = (event) => {
        setmarital(event.target.value);
    };

    const handleChangepolicytype = (event) => {
        setpolicytype(event.target.value);
    };

    const handleChangebuyagent = (event) => {
        setbuyagent(event.target.value);
    };


    const submitting = (e) => {
        setloading(true)
        e.preventDefault()

        const file = document.getElementById("fileimage").files[0]
        const name = document.getElementById("name").value
        const contact = document.getElementById("contact").value
        const email = document.getElementById("email").value
        const dob = document.getElementById("dob").value
        const address = document.getElementById("address").value
        const security = document.getElementById("security").value
        const password1 = document.getElementById("password1").value
        const password2 = document.getElementById("password2").value
        const idnumber = document.getElementById("idnumber").value
        const localid = document.getElementById("localid").value
        const marritalstatus = marital
        const gender_ = gender
        const idtype_ = idtype

        const fd = new FormData()
        if (file) {

            fd.append("file", file)
            fd.append("image", document.getElementById("fileimage").files[0].name)
        }

        fd.append("name", name)
        fd.append("contact", contact)
        fd.append("email", email)
        fd.append("dob", dob)
        fd.append("address", address)
        fd.append("security", security)
        fd.append("password1", password1)
        fd.append("marritalstatus", marritalstatus)
        fd.append("gender", gender)
        fd.append("localID", localid)
        fd.append("IDtype", idtype)
        fd.append("IDnumber", idnumber)

        const buyingpolicy = async () => {
            await axios.post("https://server.ayconsultgh.com/api/admin/registeradmin/", fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            }).then(res => {

                if (res.status === 200) {
                    setloading(!true)
                    swal("", " New Admin Registered", "success");

                } else if(res.status===204){
                    setloading(!true)
                   swal("", " Registration Error, please Check your security code ", "warning");
                } else {
                    setloading(!true)
                   swal("", " Error Registering Admin ", "error");
                }



            })

        }

        buyingpolicy()

    }


    const imagehandler = (e) => {
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setperson(reader.result)
            }
        }

        reader.readAsDataURL(e.target.files[0])
    }


    return (

        <div>
        <Online>
        <Container>

            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <h2 align="center"><span style={{ color: "#062333", padding: 10, borderRadius: "40px" }} >Register New AY Admin</span></h2>
            <form onSubmit={submitting}>
                <Grid container spacing={4}>

                    <Grid item xs={12} lg={4} align="center">
                        <div style={{ padding: 10, borderRadius: "10px", border: "solid", borderColor: "#062333", borderWidth: "0.05px" }}>
                            <Avatar size="large" src={person} style={{ width: "13ch", height: "13ch", border: "solid", borderWidth: "0.1px" }} />
                            <p style={{ color: "#062333" }}><b>Person Name Here</b></p>
                            <div style={{ width: "100%", textAlign: "center", marginTop: 20 }}>



                                <input

                                    style={{ display: "none", marginBottom: 5 }}
                                    id="fileimage"
                                    multiple
                                    type="file"
                                    onChange={(e) => imagehandler(e)}
                                />
                                <label htmlFor="fileimage">


                                    <span size="large" style={{ backgroundColor: "#062333", color: "white", padding: 12, cursor: "pointer", margin: 10, borderRadius: 5 }}>Upload New Image</span>



                                </label>

                                <br />
                                <br />
                            </div>

                        </div>
                    </Grid>



                    <Grid item xs={12} lg={8}>


                        <div align="center" style={{ padding: 10, borderRadius: "10px", borderColor: "#062333", borderWidth: "0.05px" }}>

                            <Divider style={{ marginBottom: "18px", backgroundColor: "#062333" }}>
                                <Chip label="PERSONAL DETAILS" style={{ backgroundColor: "white", color: "#062333" }} />
                            </Divider>


                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Name </b>
                                <TextField
                                    required
                                    id="name"
                                    style={{ width: "28ch" }}
                                    type="text"
                                    inputMode='name'
                                    placeholder="Enter Full Name"
                                    size="small"

                                /> </FormControl>
                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Contact </b>
                                <TextField
                                    required
                                    id="contact"
                                    style={{ width: "28ch" }}
                                    type="tel"
                                    inputMode='tel'
                                    placeholder="Enter Contact"
                                    size="small"

                                /> </FormControl>
                            <br />
                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Email </b>
                                <TextField

                                    id="email"
                                    style={{ width: "28ch" }}
                                    type="email"
                                    placeholder="Enter Email"
                                    size="small"

                                /> </FormControl>

                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Date of Birth </b>
                                <TextField

                                    id="dob"
                                    style={{ width: "28ch" }}
                                    type="date"
                                    placeholder="Enter "
                                    size="small"

                                /> </FormControl>

                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Marital Status </b>

                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={marital}
                                    size="small"
                                    style={{ width: "28ch" }}
                                    onChange={handleChangemarital}
                                >
                                    <MenuItem value="none">
                                        <em>Marital status</em>
                                    </MenuItem>
                                    <MenuItem value="Single">Single</MenuItem>
                                    <MenuItem value="Married">Married</MenuItem>
                                    <MenuItem value="Divorced">Divorced</MenuItem>
                                    <MenuItem value="Complicated">COmplicated</MenuItem>
                                </Select>
                            </FormControl>


                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Gender</b>

                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={gender}
                                    size="small"
                                    style={{ width: "28ch" }}
                                    onChange={handleChangegender}
                                >
                                    <MenuItem value="none">
                                        <em>Choose Gender</em>
                                    </MenuItem>
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>

                                </Select>
                            </FormControl>

                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>ID Type</b>

                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={idtype}
                                    size="small"
                                    style={{ width: "28ch" }}
                                    onChange={handleChangeidtype}
                                >
                                    <MenuItem value="none">
                                        <em>Choose ID Type</em>
                                    </MenuItem>
                                    <MenuItem value="Ghana Card">Ghana Card</MenuItem>
                                    <MenuItem value="Passport">Passport</MenuItem>
                                    <MenuItem value="Voter ID">Voter ID</MenuItem>

                                </Select>
                            </FormControl>
                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>ID Number </b>
                                <TextField

                                    id="idnumber"
                                    style={{ width: "28ch" }}
                                    type="address"
                                    placeholder="Enter ID number"
                                    size="small"

                                /> </FormControl>
                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Address </b>
                                <TextField

                                    id="address"
                                    style={{ width: "28ch" }}
                                    type="address"
                                    placeholder="Enter Address"
                                    size="small"

                                /> </FormControl> 
                                <FormControl align="left" style={{ margin: 10 }}>
                                <b>Local ID </b>
                                <TextField

                                    id="localid"
                                    style={{ width: "28ch" }}
                                    type="text"
                                    placeholder="Enter Local ID"
                                    size="small"

                                /> </FormControl>
                                 <FormControl align="left" style={{ margin: 10 }}>
                                <b>AY Security Code </b>
                                <TextField

                                    id="security"
                                    style={{ width: "28ch" }}
                                    type="password"
                                    placeholder="Enter AY Security Code"
                                    size="small"

                                /> </FormControl>

                            <br />
                            <br />

                            <Divider style={{ marginBottom: "18px", backgroundColor: "#062333" }}>
                                <Chip label="PASSWORD SETTINGS" style={{ backgroundColor: "white", color: "#062333" }} />
                            </Divider>

                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Password </b>
                                <TextField
                                    id="password1"
                                    style={{ width: "28ch" }}
                                    type="password"
                                    placeholder="Enter Password"
                                    size="small"

                                /> </FormControl>

                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Password </b>
                                <TextField

                                    id="password2"
                                    style={{ width: "28ch" }}
                                    type="password"
                                    placeholder="Confirm Password"
                                    size="small"

                                /> </FormControl>
                            <br />
                            <br />

                            <div align="center">
                                {!loading ?
                                    <input type="submit" value="SUBMIT" style={{ padding: 13.5, cursor: "pointer", borderRadius: 5, outline: "none", border: "none", backgroundColor: "#062333", color: "white", width: "35ch", marginTop: "10px", marginBottom: "40px" }} />
                                    :
                                    <Button size="large" disabled startIcon={<CircularProgress style={{ color: "#062333", width: "20px", height: "20px" }} />} style={{ backgroundColor: "lightgrey", color: "#062333", width: "35ch", marginTop: "10px", marginBottom: "8px" }} >Loading...</Button>
                                }
                            </div>

                        </div>
                        <br />
                        <br />
                        <br />
                    </Grid>

                </Grid>

            </form>

        </Container>
</Online>
        
<Offline>
    <div>
<img src={Network} className="nonetwork" />
<p align="center" >no internet connection, connect internet and reload</p>
<button onClick={()=>window.location.reload()} style={{backgroundColor:"white", border:"none", color:"blue", cursor:"pointer"}}> Reload</button>
    </div>
</Offline>

        </div>
    );
}

export default Profile;
