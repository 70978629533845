import * as React from 'react';
import Table1 from "./portaltable1"
import Table2 from "./portaltable2"
import Table3 from "./portaltable3"
import Table4 from "./portaltable4"
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';

import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Travelpolicy from './buytravel';
import Motorpolicy from './buymotor';
import Accidentpolicy from './buyaccident';
import Paymenttable from './paymenthistory';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";




function Profile() {

    const theme = useTheme();
 

    return (
        <Container maxWidth="lg" >
       <br/>
       <Paymenttable/>
        </Container>
    );
}

export default Profile;
