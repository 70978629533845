import React, {Component} from "react";
import Chart from 'react-apexcharts'

function PartialLoadingIndicatorStory(props){
 

  console.log(props)

    const state = {
      options: {
        chart: {
          id: 'apexchart-example'
        },
        xaxis: {
          categories: props.data.date_
        }
      },
      series: [{
        name: 'Number of Bookings',
        data: props.data.numofbooking
      }]
    }

    return (
      <Chart options={state.options} series={state.series} type="area" width="100%" height={320} />
    )
 
}

export default PartialLoadingIndicatorStory;