import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import Search from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import SupplyProduct from '@mui/icons-material/LocalShipping';
import SearchIcon from '@mui/icons-material/Search';
import AddSRV from '@mui/icons-material/PostAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { PDFReader } from 'react-read-pdf';
import Mpd from '../images/jj.pdf';
import Nodata from '../images/nodata.svg';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import axios from 'axios';
// import { DownloadTableExcel } from 'react-export-table-to-excel';
import Snack from './snack';


import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_motor } from '../redux/motor';
import { update_travel } from '../redux/travel';



function createData( _id, name, contact, charge, email, dob, occupation, address, worknature, startdate, enddate, currency, suminsured, beneficiary, agent, date, time, policynumber, status, policy
) {
    return {
       _id, name, contact, charge, email, dob, occupation, address, worknature, startdate, enddate, currency, suminsured, beneficiary, agent, date, time, policynumber, status, policy
    };
}

const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [

    {
        id: 'No',
        numeric: true,
        disablePadding: false,
        label: 'No.',
    },  {
        id: 'policynumber',
        numeric: true,
        disablePadding: !false,
        label: 'Policy Number',
    },
    {
        id: 'name',
       numeric: true,
        disablePadding: false,
        label: 'Customer Name',
    },
    {
        id: 'policy',
       numeric: true,
        disablePadding: false,
        label: 'Product',
    },
    {
        id: 'policytype',
        numeric: true,
        disablePadding: false,
        label: 'Cover/Risk',
    }, {
        id: 'charge',
        numeric: true,
        disablePadding: false,
        label: 'Premium',
    }, {
        id: 'suminsured',
        numeric: true,
        disablePadding: false,
        label: 'Sum Insured',
    }, {
        id: 'date',
        numeric: true,
        disablePadding: false,
        label: 'Date Paid',
    },
    {
        id: 'carbs',
        numeric: true,
        disablePadding: false,
        label: 'Platform',
    }
];





function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>

                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <b>{headCell.label}</b>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >

            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                SRV
            </Typography>



        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {

    const getaccidentdata = async () => {
        await axios.post("https://server.ayconsultgh.com/api/accidentdata/getaccidentpolicy/",
            {
               
             userID:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).IDnumber:"",
                type:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).type:"",
                institution:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).institution:"",
                year:"none",
                month:"none",
                day:"none"
            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_accident({
                        data: res.data
                    }))
                }
            })
    }

    const gethomedata = async () => {
        await axios.post("https://server.ayconsultgh.com/api/homedata/gethomepolicy/",
            {
            userID:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).IDnumber:"",
                type:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).type:"",
                institution:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).institution:"",
                year:"none",
                month:"none",
                day:"none"
            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_home({
                        data: res.data
                    }))
                }
            })
    }

    const getmotordata = async () => {
        await axios.post("https://server.ayconsultgh.com/api/motordata/getmotorpolicy/",
            {
               
              userID:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).IDnumber:"",
                type:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).type:"",
                institution:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).institution:"",
                year:"none",
                month:"none",
                day:"none"

            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_motor({
                        data: res.data
                    }))
                }
            })
    }

    const gettraveldata = async () => {
        await axios.post("https://server.ayconsultgh.com/api/traveldata/gettravelpolicy/",
            {
              
             userID:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).IDnumber:"",
                type:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).type:"",
                institution:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).institution:"",
                year:"none",
                month:"none",
                day:"none"
            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_travel({
                        data: res.data
                    }))
                }
            })
    }

    const tableRef = useRef(null)

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl_, setAnchorEl_] = React.useState(null);
    const open = Boolean(anchorEl);
    const open_ = Boolean(anchorEl_);
    const [age, setAge] = React.useState('');
    const [chosen, setchosen] = React.useState([]);

    const [openadd, setOpenadd] = React.useState(!true);
    const [openview, setOpenview] = React.useState(!true);
    const [opensrv, setOpensrv] = React.useState(!true);
    const [openedit, setOpenedit] = React.useState(!true);
    const [opendelete, setOpendelete] = React.useState(!true);
    const [search, setsearch] = React.useState("");
    const [maindata, setmaindata] = React.useState([]);
    const [selectedpolicy, setselectedpolicy] = React.useState("Motor");


    const dispatch = useDispatch()
    const policy = useSelector((state) => state[selectedpolicy.toLowerCase()])

    const data = [policy.map((list, index) => createData(list.id, list.name, list.contact, list.charge, list.email, list.dob, list.occupation, list.address, list.worknature, list.startdate, list.enddate, list.currency, list.suminsured, list.beneficiary, list.agent, list.date, list.time, list.policynumber, list.status, list.policy))]
    var rows = data[0]

    useEffect(() => {


        if (selectedpolicy === "Motor") {
            getmotordata()
        }
        if (selectedpolicy === "Accident") {
            getaccidentdata()
        }
        if (selectedpolicy === "Travel") {
            gettraveldata()
        }
        if (selectedpolicy === "Home") {
            gethomedata()
        }

    }, [policy])


    if (search !== "") {
        console.log(search)
        var listed = rows.filter(bug => bug.name.toLowerCase().includes(search.toLowerCase()) || bug.contact.toLowerCase().includes(search.toLowerCase()) || bug.email.toLowerCase().includes(search.toLowerCase()))
        rows = listed
    }



    const handleClickOpenedit = () => {
        setOpenedit(true);
    };
    const handleCloseedit = () => {
        setOpenedit(false);
    };
    const handleClickOpendelete = () => {
        setOpendelete(true);
    };
    const handleClosedelete = () => {
        setOpendelete(false);
    };

    const handleClickOpenadd = () => {
        setOpenadd(true);
    };
    const handleCloseadd = () => {
        setOpenadd(false);
    };

    const handleClickOpensrv = () => {
        setOpensrv(true);
    };
    const handleClosesrv = () => {
        setOpensrv(false);
    };

    const handleClickOpenview = (data) => {
        setchosen(data)
        setOpenview(true);
    };
    const handleCloseview = () => {
        setOpenview(false);
    };





    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    function accountmenu(data) {

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };
        return (
            <React.Fragment>
                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    <Tooltip title="Account settings">
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 2px rgba(10,10,10,0.1))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >

                    <MenuItem onClick={() => handleClickOpenview(data)}>
                        <ListItemIcon>
                            <VisibilityIcon fontSize="small" />
                        </ListItemIcon>
                        View Policy Documents
                    </MenuItem>




                </Menu>
            </React.Fragment>
        );
    }

    const handlesearch = (e) => {
        setsearch(e.target.value)
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };
    const handleChangepolicy = (event) => {
        setselectedpolicy(event.target.value);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>





            <Dialog maxWidth="lg" onClose={handleClosedelete} aria-labelledby="customized-dialog-title" open={opendelete}>

                <DialogContent dividers>

                    <p>
                        Are you sure you want to delete this ?
                    </p>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClosedelete} color="primary">
                        Yes
                    </Button> <Button autoFocus onClick={handleClosedelete} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog maxWidth="lg" onClose={handleCloseview} aria-labelledby="customized-dialog-title" open={openview}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseview} >

                    <IconButton onClick={handleCloseview} style={{ float: "right" }}>x</IconButton>
                </DialogTitle>
                <DialogContent dividers>




                    {chosen.length !== 0 ? chosen.document === "none" ? <PDFReader url={Mpd} /> : <div align="center"><img src={Nodata} width="50%" /> <br /> <h2 style={{ margin: 0, color: "#062333" }}>No data found</h2> </div> : <div align="center"><img src={Nodata} width="50%" /> <br /> <h2 style={{ margin: 0, color: "#062333" }}>No data found</h2> </div>}


                </DialogContent>
                <DialogActions>
                    <a href='#' download >
                        <Button color="primary">Download</Button>

                    </a>
                </DialogActions>
            </Dialog>


            <Dialog maxWidth="lg" onClose={handleClosesrv} aria-labelledby="customized-dialog-title" open={opensrv}>
                <DialogTitle id="customized-dialog-title" onClose={handleClosesrv} style={{ backgroundColor: "darkblue", color: "white" }}>
                    Add SRV
                </DialogTitle>

                <DialogContent dividers style={{ textAlign: "center" }}>

                    <FormControl variant="standard" style={{ margin: 20 }}>

                        <Input
                            id="input-with-icon-adornment"
                            placeholder="Quantity Received"
                            type="number"
                            className="signup"


                        />
                    </FormControl>



                </DialogContent>

                <DialogActions>
                    <Button autoFocus onClick={handleClosesrv} color="primary">
                        Submit
                    </Button>  <Button autoFocus onClick={handleClosesrv} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog maxWidth="lg" onClose={handleCloseadd} aria-labelledby="customized-dialog-title" open={openadd}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseadd} style={{ backgroundColor: "darkblue", color: "white" }}>
                    Add Supply
                </DialogTitle>

                <DialogContent dividers>

                    <FormControl variant="standard" style={{ margin: 20 }}>

                        <Input
                            id="input-with-icon-adornment"
                            placeholder="Quantity Supplied"
                            type="number"
                            className="signup"


                        />
                    </FormControl>
                    <FormControl variant="standard" style={{ margin: 20 }}>

                        <Input
                            id="input-with-icon-adornment"
                            placeholder="SRV Received"
                            type="number"
                            className="signup"


                        />
                    </FormControl>
                    <br />

                    <FormControl variant="Outline" style={{ margin: 20 }}>

                        <Input
                            id="input-with-icon-adornment"
                            placeholder="Supplied By"
                            type="text"
                            className="signup"


                        />
                    </FormControl>


                    <br />
                    <div style={{ textAlign: "center" }}>
                        <Button autoFocus color="primary">
                            Submit
                        </Button>
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button autoFocus onClick={handleCloseadd} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog maxWidth="lg" onClose={handleCloseedit} aria-labelledby="customized-dialog-title" open={openedit}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseedit} style={{ backgroundColor: "darkblue", color: "white" }}>
                    Edit Supply Details
                </DialogTitle>
                <DialogContent dividers>
                    <FormControl variant="standard" style={{ margin: 20 }}>

                        <Input
                            id="input-with-icon-adornment"
                            placeholder="School Name"
                            type="text"
                            className="signup"


                        />
                    </FormControl>
                    <FormControl variant="standard" style={{ margin: 20 }}>

                        <Input
                            id="input-with-icon-adornment"
                            placeholder="Location"
                            type="text"
                            className="signup"


                        />
                    </FormControl>
                    <br />

                    <FormControl variant="standard" style={{ margin: 20 }}>

                        <Input
                            id="input-with-icon-adornment"
                            placeholder="Contact"
                            type="tel"
                            className="signup"


                        />
                    </FormControl>

                    <FormControl variant="standard" style={{ margin: 20 }}>

                        <Input
                            id="input-with-icon-adornment"
                            placeholder="Allocation"
                            type="number"
                            className="signup"


                        />
                    </FormControl>

                    <br />
                    <div style={{ textAlign: "center" }}>
                        <Button autoFocus color="primary">
                            Submit
                        </Button>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseedit} color="primary">
                        close
                    </Button>
                </DialogActions>
            </Dialog>




            <Paper sx={{ width: '100%', mb: 2 }}>

                <Grid container style={{ paddingTop: 10 }}>
                    <Grid lg={2} xs={16} md={2} align="center">


                        <Button style={{ marginTop: "7px", backgroundColor: "#062333", color: "white" }} color="primary">Export to excell</Button>

                    </Grid>

                    <Grid lg={3} xs={6}>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            align="left"
                            value={selectedpolicy}
                            style={{ minWidth: 200, padding: 0, marginTop: 6, outline: "none" }}
                            onChange={handleChangepolicy}
                            autoWidth

                            size="small"
                        >

                            <MenuItem value="Accident">Accident Policy</MenuItem>
                            <MenuItem value="Home">Homeowner Policy</MenuItem>
                            <MenuItem value="Motor">Motor Policy</MenuItem>
                            <MenuItem value="Travel">Travel Policy</MenuItem>


                        </Select>
                    </Grid>

                    <Grid lg={3} sm={12} md={4}>
                        <SearchIcon style={{ position: "absolute", marginTop: "15px", marginLeft: "6px", color: "lightgrey" }} /><input type="search" onChange={handlesearch} placeholder="Search" style={{ padding: 11, outline: "none", marginTop: 7, width: "100%", border: "solid", borderWidth: "0.2px", borderColor: "lightgrey", paddingLeft: "40px", borderRadius: "5px" }} id="search" />
                    </Grid>

                    <Grid lg={4} sm={12} md={5}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />

                        <TableBody res={tableRef}>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover

                                        >
                                            <TableCell style={{ textAlign: "left" }}>
                                                <span>
                                                    {(index + 1 + (page * rowsPerPage)) + "."}
                                                </span>
                                            </TableCell>

                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.policynumber==="none"? "Awaiting..." : row.policynumber}
                                            </TableCell>
                                                <TableCell align="left">{row.name}</TableCell>
                                            <TableCell align="left">{selectedpolicy + " Policy"}</TableCell>
                                            <TableCell align="left">{row.policytype}</TableCell>
                                            <TableCell align="left">GHC {row.charge}</TableCell>
                                            <TableCell align="left">GHC {row.suminsured}</TableCell>
                                            <TableCell align="left">{row.date}</TableCell>
                                            <TableCell align="left">E-Platform</TableCell>

                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Box>
    );
}
