import * as React from 'react';
import Table1 from "./portaltable1"
import Table2 from "./portaltable2"
import Table3 from "./portaltable3"
import Table4 from "./portaltable4"
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';

import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Travelpolicy from './buytravel';
import Snack from './snack';
import Motorpolicy from './buymotor';
import Accidentpolicy from './buyaccident';
import Homepolicy from './buyhome';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CottageIcon from '@mui/icons-material/Cottage';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { Offline, Online } from "react-detect-offline";
import Network from '../images/network.svg';


interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


function Profile0() {

    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [age, setAge] = React.useState('');
    const [typer, settyper] = React.useState('');
    const [message, setmessage] = React.useState('');
    const [state_, setstate_] = React.useState(false);

    const handleChangeselector = (event) => {
        setAge(event.target.value);
    };
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    const noti = (typer_, message_, state__) => {
        setstate_(true)
        settyper(typer_)
        setmessage(message_)


    }

    return (

        <div>

            <Online>
                <Container maxWidth="lg" >
                    <h2>Buy New Policy</h2>

                    {<Snack typer={typer} message={message} state={state_} />}

                    <Box >
                        <Tabs value={value} onChange={handleChange} variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example" aria-label="basic tabs example">
                            <Tab label={<div><DirectionsCarIcon style={{ fontSize: "40px" }} /> <br /> Motor </div>} {...a11yProps(0)} />
                            <Tab label={<div><FlightTakeoffIcon style={{ fontSize: "40px" }} /> <br /> Travel </div>} {...a11yProps(1)} />
                            <Tab label={<div><CottageIcon style={{ fontSize: "40px" }} /> <br /> Homeowner </div>} {...a11yProps(2)} />
                            <Tab label={<div><MedicalServicesIcon style={{ fontSize: "40px" }} /> <br />Personal Accident </div>} {...a11yProps(4)} />
                        </Tabs>
                    </Box>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction} >


                            <Motorpolicy noti={noti} />

                        </TabPanel>



                        <TabPanel value={value} index={1} dir={theme.direction} >


                            <Travelpolicy noti={noti} />


                        </TabPanel>




                        <TabPanel value={value} index={2} dir={theme.direction} >


                            <Homepolicy noti={noti} />


                        </TabPanel>


                        <TabPanel value={value} index={3} dir={theme.direction} >


                            <Accidentpolicy noti={noti} />

                        </TabPanel>


                    </SwipeableViews>

                </Container>
            </Online>
            <Offline>
                <div>
                    <img src={Network} className="nonetwork" />
                    <p align="center" >no internet connection, connect internet and reload</p>
                    <button onClick={() => window.location.reload()} style={{ backgroundColor: "white", border: "none", color: "blue", cursor: "pointer" }}> Reload</button>
                </div>
            </Offline>


        </div>
    );
}

export default Profile0;
