import * as React from 'react';
import './App.css';
import Admin from './admin/menu1';

import Agent from './agents/menu1';
import Login from './landingpage/login';
import Register from './registeragent/index';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from './redux/accident';
import { update_home } from './redux/home';
import { update_agent } from './redux/agent';
import { update_admin } from './redux/admin';

//import Menu from './menu';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function App() {

     const dispatch = useDispatch()

    const loaders = () => {
        if (localStorage.getItem("user") === null) {

            return (
                <Login/>
            )

        } else {

            if (JSON.parse(localStorage.getItem("user")).status === "Active") {
                dispatch(update_admin({
                        data: JSON.parse(localStorage.getItem("user"))
                    }))
                return (
                    <Admin/>
                    )
            }  if (JSON.parse(localStorage.getItem("user")).status === "Active") {
                dispatch(update_agent({
                        data: JSON.parse(localStorage.getItem("user"))
                    }))
                return (
                    <Admin/>
                    )
            }
            

        }
    }
    return (
        <div className="App" >
            <Router>

                {loaders()}

            </Router>
 
    </div>
  );
}

export default App;
