import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars(props) {

  const [opensnack, setOpensnack] = React.useState(props.state);

  const typer = props.typer
  const message = props.message
 console.log(props.state)

  const handleClicksnack = () => {
    setOpensnack(true);
  };

  
  
  const handleClosesnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpensnack(false);
   
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
     
      <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
        <Alert onClose={handleClosesnack} severity={typer} sx={{ width: '100%' }}>
         {message}
        </Alert>
      </Snackbar>
    
    </Stack>
  );
}
