import * as React from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';

import FormControlLabel from '@mui/material/FormControlLabel';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import axios from "axios"
import swal from 'sweetalert';

import Badge from '@mui/material/Badge';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Person from '../images/Ellipse77.png';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';


import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { AvatarGroup } from '@mui/material';
import { Offline, Online } from "react-detect-offline";
import Network from '../images/network.svg';



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function Profile() {

    const theme = useTheme();
    const [age, setAge] = React.useState('');

    const [buyagent, setbuyagent] = React.useState("none")
    const [policytype, setpolicytype] = React.useState("none")
    const [idtype, setidtype] = React.useState("none")
    const [gender, setgender] = React.useState("none")
    const [account, setaccount] = React.useState("none")
    const [loading, setloading] = React.useState(false)
    const [message, setmessage] = React.useState("")
    const [stated, setstated] = React.useState("")
   
    const [opensnack, setOpensnack] = React.useState(false);

  
    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };

 

 
   
    const handleChangeaccount = (event) => {
        setaccount(event.target.value);
    };



     const submitting = (e) => {
        setloading(true)
        e.preventDefault()

        const email = document.getElementById("email").value
        

        const queryParams = new URLSearchParams(window.location.search);
        const idmain = queryParams.get('id');
    
        const buyingpolicy = async () => {
            await axios.post("https://server.ayconsultgh.com/api/auth/resetpassword/",  {
             
            email:email,
            account:account

            }).then(res => {

                if (res.status === 200) {
                    setloading(!true)
                    swal("", "Password reset link has been sent to your email "+email, "success");

                } else {
                    setloading(!true)
                   swal("", email+" is not registered ", "error");
                }



            })

        }

        buyingpolicy()

    }

  

    return (
        <div>
        <Online>
        <Container>


            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <h2 align="center" style={{marginTop:"20%"}}><span style={{ color: "#062333", padding: 10, borderRadius: "40px" }} >Reset Password</span></h2>
            <form onSubmit={submitting}>
              

                        <div align="center" style={{ padding: 10, borderRadius: "10px", borderColor: "#062333", borderWidth: "0.05px" }}>

                        
                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Email </b>
                                <TextField

                                    id="email"
                                    style={{ width: "29ch" }}
                                    type="email"
                                    placeholder="Enter Email"
                                    size="small"

                                /> </FormControl>
                                <br/>

                                <FormControl align="left" style={{ margin: 10 }}>
                                <b>Account Type </b>

                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={account}
                                    size="small"
                                    style={{ width: "28ch" }}
                                    onChange={handleChangeaccount}
                                >
                                    <MenuItem value="none">
                                        <em>Choose Account Type</em>
                                    </MenuItem>
                                    <MenuItem value="Administrator">Administrator</MenuItem>
                                    <MenuItem value="Agent">Agent</MenuItem>
                                    
                                </Select>
                            </FormControl>



                            <div align="center">
                                {!loading ?
                                    <input type="submit" value="SUBMIT" style={{ padding: 13.5, cursor: "pointer", borderRadius: 5, outline: "none", border: "none", backgroundColor: "#062333", color: "white", width: "35ch", marginTop: "10px", marginBottom: "40px" }} />
                                    :
                                    <Button size="large" disabled startIcon={<CircularProgress style={{ color: "#062333", width: "20px", height: "20px" }} />} style={{ backgroundColor: "lightgrey", color: "#062333", width: "35ch", marginTop: "10px", marginBottom: "8px" }} >Loading...</Button>
                                }

                                <br/>
                                <span>Already have an account? <Link to="/" >Signin</Link></span>
                            </div>


                        </div>
                        <br />
                        <br />
                        <br />
                 

            </form>

        </Container>
</Online>


<Offline>
    <div>
<img src={Network} className="nonetwork" />
<p align="center" >no internet connection, connect internet and reload</p>
<button onClick={()=>window.location.reload()} style={{backgroundColor:"white", border:"none", color:"blue", cursor:"pointer"}}> Reload</button>
    </div>
</Offline>

        </div>
    );
}

export default Profile;
