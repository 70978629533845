import React, { useEffect, useRef } from 'react';
import Table1 from "./portaltable1"
import Table2 from "./portaltable2"
import Table3 from "./portaltable3"
import Table4 from "./portaltable4"
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';

import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Travelpolicy from './buytravel';
import Motorpolicy from './buymotor';
import Accidentpolicy from './buyaccident';
import Homepolicy from './buyhome';
import Pie1 from './charts/pie1';
import Pie2 from './charts/pie2';
import Bar from './charts/bar';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CircularProgress from '@mui/material/CircularProgress';

import GroupsIcon from '@mui/icons-material/Groups';
import EngineeringIcon from '@mui/icons-material/Engineering';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { Offline, Online } from "react-detect-offline";
import Network from '../images/network.svg';


import axios from 'axios';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_motor } from '../redux/motor';
import { update_travel } from '../redux/travel';
import { update_agent } from '../redux/agent';
import { update_admin } from '../redux/admin';
import { update_bookings } from '../redux/bookings';
import { update_rooms } from '../redux/rooms';
import { update_chat } from '../redux/chat';


interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function Profile() {

    const dispatch = useDispatch()
    const months=["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const [openpage, setOpenpage] = React.useState(true);

const dt = new Date()
const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
const day = dt.getUTCDate();
const month = String(dt.getMonth() + 1).padStart(2, '0');
const year = dt.getFullYear();
const date = year + "-" + month + "-" + day;
const last2 = new Date().getFullYear().toString().slice(-2)

const bookings = useSelector((state) => state.bookings)
const rooms = useSelector((state) => state.rooms)
var booking= bookings



    const [chooseyear, setchooseyear] = React.useState(year);
    const [choosemonth, setchoosemonth] = React.useState(months[parseInt(month)-1]);
    const [chooseday, setchooseday] = React.useState("none");

    const getchats = async() => {
        await axios.post("https://cromer.sisrevolution.com/api/chat/getchats/", {

        }).then(res => {
            if (res.status === 200) {
                dispatch(update_chat({
                    data: res.data
                })) 
        
            }
        })
    }

    const getbookings = async () => {
        await axios.post("https://cromer.sisrevolution.com/api/bookings/getbookings_select/",
            {
                year:chooseyear,
                month:choosemonth,
                day:chooseday

            }).then(res => {
                if (res.status === 200) {
                    console.log(res)
                    dispatch(update_bookings({
                        data: res.data
                    }))
                }
            })
    } 
    const getrooms = async () => {
        await axios.post("https://cromer.sisrevolution.com/api/rooms/getrooms/",
            {

            }).then(res => {
                if (res.status === 200) {
                    console.log(res)
                    dispatch(update_rooms({
                        data: res.data
                    }))
                }
            })
    }
    const getagentdata = async () => {
         
        await axios.post("https://server.ayconsultgh.com/api/agent/getagentdata/",
            {
            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_agent({
                        data: res.data
                    }))
                }
            })
    }
    const getadmindata = async () => {
         
        await axios.post("https://server.ayconsultgh.com/api/admin/getadmindata/",
            {
            }).then(res => {
                if (res.status === 200) {
                  
                }
            })
    }
    const getmotordata = async () => {
         
        await axios.post("https://server.ayconsultgh.com/api/motordata/getmotorpolicy/",
            {
                 userID:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).IDnumber:"",
                type:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).type:"",
                institution:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).institution:"",
                year:chooseyear,
                month:choosemonth,
                day:chooseday
            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_motor({
                        data: res.data
                    }))
                }
            })
    }
    const gettraveldata = async () => {
         
        await axios.post("https://server.ayconsultgh.com/api/traveldata/gettravelpolicy/",
            {
                userID:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).IDnumber:"",
                type:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).type:"",
                institution:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).institution:"",
                year:chooseyear,
                month:choosemonth,
                day:chooseday

            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_travel({
                        data: res.data
                    }))
                }
            })
    }
    const gethomedata = async () => {
         
        await axios.post("https://server.ayconsultgh.com/api/homedata/gethomepolicy/",
            {
                userID:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).IDnumber:"",
                type:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).type:"",
                institution:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).institution:"",
                year:chooseyear,
                month:choosemonth,
                day:chooseday

            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_home({
                        data: res.data
                    }))
                }
            })
    }  
    const getaccidentdata = async () => {
        await axios.post("https://server.ayconsultgh.com/api/accidentdata/getaccidentpolicy/",
            {

                userID:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).IDnumber:"",
                type:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).type:"",
                institution:localStorage.getItem("user")!==null?JSON.parse(localStorage.getItem("user")).institution:"",
                year:chooseyear,
                month:choosemonth,
                day:chooseday

            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_accident({
                        data: res.data
                    }))
                    setOpenpage(!true)
                }
            })
    }


    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [age, setAge] = React.useState('');
    const [pageloader, setpageloader] = React.useState(true);
  
    const agents = useSelector((state) => state.agent)
    const motor  = useSelector((state) => state.motor ).filter(bug=>bug.status!=="withdrawn")
    const travel = useSelector((state) => state.travel).filter(bug=>bug.status!=="withdrawn")
    const home = useSelector((state) => state.home).filter(bug=>bug.status!=="withdrawn")
    const accident = useSelector((state) => state.accident).filter(bug=>bug.status!=="withdrawn")

console.log(motor)

    useEffect(() => {
 
        getbookings()
        getrooms()
        getchats()

  },[choosemonth, chooseyear, chooseday])


  const findquest = () =>{
    var quests=[]
    for(var i in booking){
        
        if(quests.filter(bug=>bug.email===booking[i].email).length===0){
            quests.push(booking[i])
        }

    }

    return quests

  }
  const converter=(data)=>{

    const dt = new Date(data+"")    
  const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
  const day = dt.getUTCDate();
  const month = String(dt.getMonth() + 1).padStart(2, '0');
  const year = dt.getFullYear();
  const date = year + "-" + month + "-" + day;
  
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  
  return months[parseInt((date.split("-"))[1])-1] +" "+(date.split("-"))[2]+", "+(date.split("-"))[0] 
  
  
       }

  const graphlist=()=>{

    var date_=[]
    var numofbooking=[]

    for(var i in booking){
        
        if(date_.filter(bug=>converter(bug)===converter(booking[i].date)).length===0){
            date_.push(converter(booking[i].date))
            numofbooking.push(booking.filter(bug=>bug.date===booking[i].date).length)
        }

    }
        return {date_, numofbooking}
  }
  
    const sumcalculator = (data) => {
    var sum = 0
        for(var i in data ){
            if(data[i].charge !== undefined){
            sum += parseInt(data[i].charge)
            }
        }
return sum
    }

  
    const commissioncalculator = (data) => {
    var sum = 0
        for(var i in data ){
            if(data[i].agentcommission !== undefined){
                if(data[i].agentID !=="Direct"){
            sum += parseInt(data[i].agentcommission)
        }else{
            sum += parseInt(data[i].aycommission)

        }
            }
        }
return sum
    }


    const countrooms=()=>{
var results=0
        for (var i in rooms){
            results += parseInt(rooms[i].rooms)
        }
return results
    }


    const countamount=()=>{
var results=0
        for (var i in booking){
            results += parseInt(booking[i].room[0].price)
        }
return results
    }


  
    const sumcalculator_commission = (data) => {
    var sum = 0
        for(var i in data ){
            if(data[i].aycommission !== undefined){
            sum += parseInt(data[i].aycommission)
            }
        }
return sum
    }

  
  const agentproduction=()=>{
    

      var data_main= [["Agent", "Commission"]]
          var sum_ = commissioncalculator(motor.filter(bug=>bug.agentID==="Direct"))+commissioncalculator(travel.filter(bug=>bug.agentID==="Direct"))+commissioncalculator(home.filter(bug=>bug.agentID==="Direct"))+commissioncalculator(accident.filter(bug=>bug.agentID==="Direct"))
          var list_ = ["Head Office" , sum_]
          data_main.push(list_)

        var sum_1 = commissioncalculator(motor.filter(bug=>bug.agentID==="Indirect"))+commissioncalculator(travel.filter(bug=>bug.agentID==="Indirect"))+commissioncalculator(home.filter(bug=>bug.agentID==="Indirect"))+commissioncalculator(accident.filter(bug=>bug.agentID==="Indirect"))
          var list_1 = ["Kumasi Agent" , sum_1]
          data_main.push(list_1)

      for(var i in agents){

      
        
          var sum = commissioncalculator(motor.filter(bug=>bug.agentID===agents[i].IDnumber))+commissioncalculator(travel.filter(bug=>bug.agentID===agents[i].IDnumber))+commissioncalculator(home.filter(bug=>bug.agentID===agents[i].IDnumber))+commissioncalculator(accident.filter(bug=>bug.agentID===agents[i].IDnumber))
          var list = [agents[i].name , sum]
          data_main.push(list)

      }
      return data_main
      
  }

   
  const agentpremium=()=>{

    
      var data_main= [["Agent", "Production"]]
          var sum_ = sumcalculator(motor.filter(bug=>bug.agentID==="Direct"))+sumcalculator(travel.filter(bug=>bug.agentID==="Direct"))+sumcalculator(home.filter(bug=>bug.agentID==="Direct"))+sumcalculator(accident.filter(bug=>bug.agentID==="Direct"))
          var list_ = ["Head Office" , sum_]
          data_main.push(list_)

        var sum_1 = sumcalculator(motor.filter(bug=>bug.agentID==="Indirect"))+sumcalculator(travel.filter(bug=>bug.agentID==="Indirect"))+sumcalculator(home.filter(bug=>bug.agentID==="Indirect"))+sumcalculator(accident.filter(bug=>bug.agentID==="Indirect"))
          var list_1 = ["Kumasi Agent" , sum_1]
          data_main.push(list_1)

      for(var i in agents){

        
        
          var sum = sumcalculator(motor.filter(bug=>bug.agentID===agents[i].IDnumber))+sumcalculator(travel.filter(bug=>bug.agentID===agents[i].IDnumber))+sumcalculator(home.filter(bug=>bug.agentID===agents[i].IDnumber))+sumcalculator(accident.filter(bug=>bug.agentID===agents[i].IDnumber))
          var list = [agents[i].name , sum]
          data_main.push(list)

      }
      return data_main
      
  }

  
 
  


    const handleChangeselector = (event) => {
        setAge(event.target.value);
    };
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };


    
    const handleChangeyear = (event) => {
        setOpenpage(true)
        setchooseyear(event.target.value);
    };
    const handleChangeday = (event) => {
        setOpenpage(true)
        setchooseday(event.target.value);
    };
    const handleChangemonth = (event) => {
        setOpenpage(true)
        setchoosemonth(event.target.value);
    };
    
  const yearcalc=()=>{
var data = []
         for(var i=0; i<12; i++){
                data=[ ...data, year-i]       
              }
    return data
    }
    yearcalc()
  
    return (

        <div>

        <Container maxWidth="lg" >
          <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <h2 align="center"><span  style={{color:"#062333", padding:10,  borderRadius:"40px"}} >DASHBOARD</span></h2>
         
          <div style={{border:"solid", borderWidth:"0.1px", padding:2, backgroundColor:"lightgray", borderColor:"lightgray", borderRadius:"5px"}}>

      
           <FormControl align="left" style={{margin:0}}> 

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={chooseyear}
          size="small"
          style={{width:"12ch"}}
          onChange={handleChangeyear}
        >
          <MenuItem value="none">
            <em>Sort by Year</em>
          </MenuItem>
          {yearcalc().map((list,index)=>
                    <MenuItem value={list}>{list}</MenuItem>
          )
             
          }

          
        </Select>
       </FormControl>

<FormControl align="left" style={{margin:0}}> 

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={choosemonth}
          size="small"
          style={{width:"12ch"}}
          onChange={handleChangemonth}
        >
                             
        <MenuItem value="none">All Months</MenuItem>

          {months.map((list,index)=>
                    <MenuItem value={list}>{list}</MenuItem>
          )
             
          }

          
        </Select>
       </FormControl>

    <FormControl align="left" style={{margin:0}}> 

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={chooseday}
          size="small"
          style={{width:"12ch"}}
          onChange={handleChangeday}
        >
         <MenuItem value="none">All Days</MenuItem>
         <MenuItem value="1">1</MenuItem>
         <MenuItem value="2">2</MenuItem>
         <MenuItem value="3">3</MenuItem>
         <MenuItem value="4">4</MenuItem>
         <MenuItem value="5">5</MenuItem>
         <MenuItem value="6">6</MenuItem>
         <MenuItem value="7">7</MenuItem>
         <MenuItem value="8">8</MenuItem>
         <MenuItem value="9">9</MenuItem>
         <MenuItem value="10">10</MenuItem>
         <MenuItem value="11">11</MenuItem>
         <MenuItem value="12">12</MenuItem>
         <MenuItem value="13">13</MenuItem>
         <MenuItem value="14">14</MenuItem>
         <MenuItem value="15">15</MenuItem>
         <MenuItem value="16">16</MenuItem>
         <MenuItem value="17">17</MenuItem>
         <MenuItem value="18">18</MenuItem>
         <MenuItem value="19">19</MenuItem>
         <MenuItem value="20">20</MenuItem>
         <MenuItem value="21">21</MenuItem>
         <MenuItem value="22">22</MenuItem>
         <MenuItem value="23">23</MenuItem>
         <MenuItem value="24">24</MenuItem>
         <MenuItem value="25">25</MenuItem>
         <MenuItem value="26">26</MenuItem>
         <MenuItem value="27">27</MenuItem>
         <MenuItem value="28">28</MenuItem>
         <MenuItem value="29">29</MenuItem>
         <MenuItem value="30">30</MenuItem>
         <MenuItem value="31">31</MenuItem>
        </Select>
       
              
       </FormControl>

        </div>

        <br/>
         <Grid container spacing={3}>
            <Grid item xs={6} lg={3}>
                <div style={{border:"solid", borderWidth:"0.1px", paddingTop:12, backgroundColor:"lightgray", borderColor:"lightgray", borderRadius:"10px"}}>
                <Grid container spacing={2}>
                <Grid xs={6} item><small style={{color:"#062333"}}> </small></Grid>
                <Grid xs={6} item><GroupsIcon style={{fontSize:"35px", color: "#ffa015", backgroundColor:"white",borderRadius:"50%", padding:"3px"}} /></Grid>
                </Grid>
                <h2 align="center" style={{marginTop:"-1px",color:"##062333"}}>{countrooms()}</h2>
                    <p align="center">  <small style={{color:"#062333"}}>Available Rooms</small>  </p>

                </div>
            </Grid> 
            
            <Grid item xs={6} lg={3}>
                <div style={{border:"solid", borderWidth:"0.1px", paddingTop:12, backgroundColor:"lightgray", borderColor:"lightgray", borderRadius:"10px"}}>
                <Grid container spacing={2}>
                <Grid xs={6} item></Grid>
                <Grid xs={6} item><CreditScoreIcon style={{fontSize:"35px", color: "#ffa015", backgroundColor:"white",borderRadius:"50%", padding:"3px"}} /></Grid>
                </Grid>
                <h2 align="center" style={{marginTop:"-1px",color:"##062333"}}>{booking.length}</h2>
                <p align="center">  <small style={{color:"#062333"}}>Number Of Bookings </small>  </p>

                </div>
            </Grid>

            <Grid item xs={6} lg={3}>
                <div style={{border:"solid", borderWidth:"0.1px", paddingTop:12, backgroundColor:"lightgray", borderColor:"lightgray", borderRadius:"10px"}}>
                <Grid container spacing={2}>
                <Grid xs={6} item><small style={{color:"#062333"}}>  </small></Grid>
                <Grid xs={6} item><AccountBalanceWalletIcon style={{fontSize:"35px", color: "#ffa015", backgroundColor:"white",borderRadius:"50%", padding:"3px"}} /></Grid>
                </Grid>
                <h2 align="center" style={{marginTop:"-1px",color:"##062333"}}>$ {countamount() }</h2>
                <p align="center">  <small style={{color:"#062333"}}>Amount Made </small>  </p>

                </div>
            </Grid>

            <Grid item xs={6} lg={3}>
            <div style={{border:"solid", borderWidth:"0.1px", paddingTop:12, backgroundColor:"lightgray", borderColor:"lightgray", borderRadius:"10px"}}>
                <Grid container spacing={2}>
                <Grid xs={6} item><small style={{color:"#062333"}}> </small></Grid>
                <Grid xs={6} item><GroupsIcon style={{fontSize:"35px", color: "#ffa015", backgroundColor:"white",borderRadius:"50%", padding:"3px"}} /></Grid>
                </Grid>
                <h2 align="center" style={{marginTop:"-1px",color:"##062333"}}>{findquest().length}</h2>
                    <p align="center">  <small style={{color:"#062333"}}>Number Of Guests</small>  </p>

                </div>
            </Grid>

         </Grid>
         
         <br/>
          <Grid container spacing={3}>
            
            <Grid item xs={12} lg={12}>
                <div style={{border:"solid", borderWidth:"0.1px", padding:8, borderColor:"lightgray", borderRadius:"10px"}}>
                

                
                <Bar data={graphlist()}/> 
                
                
                
                </div>
            </Grid>

           

         </Grid>
          
          
         <br/>
        
        </Container>

        </div>
    );
}

export default Profile;
