import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';

import axios from "axios"

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function Profile() {

    const theme = useTheme();
    const [age, setAge] = React.useState('');
    const [loading, setloading] = React.useState(false)
    const [message, setmessage] = React.useState("")
    const [stated, setstated] = React.useState("")
    const [currency, setcurrency] = React.useState("none")
    const [traveltype, settraveltype] = React.useState("none")
    const [travelcategory, settravelcategory] = React.useState("none")
    const [country, setcountry] = React.useState("none")
    const [citizenship, setcitizenship] = React.useState("none")
    const [descountry, setdescountry] = React.useState("none")
    const [opensnack, setOpensnack] = React.useState(false);
    const [havedepandant, sethavedepandant] = React.useState(false);
    const [travelwithspouse, settravelwithspouse] = React.useState(false);
    const [destination, setdestination] = React.useState([]);
    const [beneficiarydata, setbeneficiarydata] = React.useState([]);


  const handleChangecurrency = (event) => {
        setcurrency(event.target.value);
    }; 
   
  const handleChangecitizenship = (event) => {
        setcitizenship(event.target.value);
    };
  const handleChangecountry = (event) => {
        setcountry(event.target.value);
    };  
    const handleChangedescountry = (event) => {
        setdescountry(event.target.value);
    };
 const handleChangetraveltype = (event) => {
        settraveltype(event.target.value);
    };
    const handleChangetravelcategory = (event) => {
        settravelcategory(event.target.value);
    };

    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };


    const handleChangeselector = (event) => {
        setAge(event.target.value);
    };


    const submiting = ()=>{

               
    const buyingpolicy= async () => {

      const name_ = document.getElementById("name").value
      const contact_ = document.getElementById("contact").value
      const email_ = document.getElementById("email").value
      const dob_ = document.getElementById("dob").value
      const occupation_ = document.getElementById("occupation").value
      const address_ = document.getElementById("address").value
      const departuredate_ = document.getElementById("departuredate").value
      const returndate_ = document.getElementById("returndate").value
      const days_ = document.getElementById("days").value
      const passportnum_ = document.getElementById("passportnum").value
      
      const spousename_ = ""   
      const spousepassport_ = ""
      const spousedob_ = ""  
      const spousecontact_ = ""           
      
      const currency_ = currency
      const traveltype_ = traveltype
      const travelcategory_ = travelcategory
      const country_ = country
      const beneficiary_ = beneficiarydata

      if(travelwithspouse){
      spousename_ = document.getElementById("spousename").value
      spousepassport_ = document.getElementById("spousepassport").value
      spousedob_ = document.getElementById("spousedob").value
      spousecontact_ = document.getElementById("spousecontact").value
      
      }



        await axios.post("https://server.ayconsultgh.com/api/policy/buyhome/",
            {



            }).then(res => {

                if (res.status === 200) {

                        setloading(!true)
                        setmessage("Homeowner Policy added successfully")
                        setstated("success")

                }else{
                      setloading(!true)
                        setmessage("Error adding Homeowner Policy")
                        setstated("error")
                }
               
                

            })

    }

    buyingpolicy()



    }

const adddestination=()=>{
  
   const desaddress = document.getElementById("desaddress").value
   const descontact = document.getElementById("descontact").value
var des = {country:descountry, address:desaddress, contact:descontact}
setdestination([...destination,des])
}


const deletedestination=(list)=>{
  var des= destination.filter(bug=>bug!==list)
  setdestination([...des])

}
const addbeneficiary=()=>{
  
   const name = document.getElementById("beneficiaryname").value
   const relationship = document.getElementById("beneficiaryrelationship").value
   const contact = document.getElementById("beneficiarycontact").value
var ben = {name:name, relationship:relationship, contact:contact}
setbeneficiarydata([...beneficiarydata,ben])
}


const deletebeneficiary=(list)=>{
  var ben= beneficiarydata.filter(bug=>bug!==list)
  setbeneficiarydata([...ben])

}

const travelwithdependant=()=>{
  sethavedepandant(!havedepandant)
  
}
const gowithspouse=()=>{
  console.log("go withs")
  settravelwithspouse(!travelwithspouse)
  
}



    return (

<div>

 <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar> 

     <form align="left">

   <Divider style={{marginBottom:"22px", backgroundColor:"#062333"}}>
            <Chip label="PERSONAL DETAILS" style={{ backgroundColor: "white", color: "#062333" }}/>
      </Divider>

<FormControl align="left" style={{margin:10}}> 
<b>Name <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="name"
          style={{width:"28ch"}}

          placeholder="Enter Full Name"
          size="small"

        /> </FormControl>
        
        <FormControl align="left" style={{margin:10}}> 
<b>Phone <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="contact"
          style={{width:"28ch"}}
          type="tel"
          placeholder="Enter Contact"
          size="small"

        /> </FormControl>  
        <FormControl align="left" style={{margin:10}}> 
<b>Email Address </b>
 <TextField
          required
          id="email"
          style={{width:"28ch"}}
          type="email"
          placeholder="Enter Email"
          size="small"

        /> </FormControl>  
        <FormControl align="left" style={{margin:10}}> 
<b>Date of Birth </b>
 <TextField
          required
          id="dob"
          style={{width:"28ch"}}
          type="date"
          placeholder="Hello World"
          size="small"

        /> </FormControl>
        


<br/>

  
        <FormControl align="left" style={{margin:10}}> 
<b>Occupation <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="occupation"
          style={{width:"28ch"}}
          type="text"
          placeholder="Enter Occupation"
          size="small"

        /> </FormControl>
        
       

        <FormControl align="left" style={{margin:10}}> 
<b>Address <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="address"
          style={{width:"28ch"}}
          type="address"
          placeholder="Enter Address"
          size="small"

        /> </FormControl>
        
        <br/>
        <br/>
        <br/>
          <Divider style={{marginBottom:"22px" , backgroundColor:"#062333"}}>
        <Chip label="POLICY DETAILS" style={{backgroundColor:"white", color:"#062333"}}/>
      </Divider>
       
         <FormControl align="left" style={{margin:10}}> 
        <b>Currency <span style={{color:"red"}}> *</span></b>

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={currency}
          size="small"
          style={{width:"28ch"}}
          onChange={handleChangecurrency}
        >
          <MenuItem value="none">
            <em>Choose Currency</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
       </FormControl>
 <FormControl align="left" style={{margin:10}}> 
        <b>Travel Type <span style={{color:"red"}}> *</span></b>

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={traveltype}
          size="small"
          style={{width:"28ch"}}
          onChange={handleChangetraveltype}
        >
          <MenuItem value="none">
            <em>Choose Travel Type</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
       </FormControl>       
        <FormControl align="left" style={{margin:10}}> 
        <b>Travel Category <span style={{color:"red"}}> *</span></b>

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={travelcategory}
          size="small"
          style={{width:"28ch"}}
          onChange={handleChangetravelcategory}
        >
          <MenuItem value="none">
            <em>Choose Travel Category</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
       </FormControl>



        <FormControl align="left" style={{margin:10}}> 
<b>Departure Date <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="departuredate"
          style={{width:"28ch"}}
          type="date"
          placeholder="Hello World"
          size="small"

        /> </FormControl>


        <FormControl align="left" style={{margin:10}}> 
<b>Return Date <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="returndate"
          style={{width:"28ch"}}
          type="date"
          placeholder="Hello World"
          size="small"

        /> </FormControl>  
        
        <FormControl align="left" style={{margin:10}}> 
<b>Number of days <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="days"
          style={{width:"28ch"}}
          type="number"
          placeholder="Number of days"
          size="small"

        /> </FormControl>
        
        <FormControl align="left" style={{margin:10}}> 
<b>Passport Number <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="passportnum"
          style={{width:"28ch"}}
          type="number"
          placeholder="Number of days"
          size="small"

        /> </FormControl> 
        
           <FormControl align="left" style={{margin:10}}> 
        <b>Issuing Country <span style={{color:"red"}}> *</span></b>

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={country}
          size="small"
          style={{width:"28ch"}}
          onChange={handleChangecountry}
        >
          <MenuItem value="none">
            <em>Choose Country</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
       </FormControl>

           <FormControl align="left" style={{margin:10}}> 
        <b>Citizenship <span style={{color:"red"}}> *</span></b>

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={citizenship}
          size="small"
          style={{width:"28ch"}}
          onChange={handleChangecitizenship}
        >
          <MenuItem value="none">
            <em>Choose Citizenship</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
       </FormControl>

<br/>
        <br/> 
        <br/>
          <Divider style={{marginBottom:"22px", backgroundColor:"#062333"}}>
        <Chip label="DESTINATION(S)" style={{backgroundColor:"white", color:"#062333"}}/>
      </Divider>
<br/>
   <FormControl align="left" style={{margin:10}}> 
        <b>Country <span style={{color:"red"}}> *</span></b>

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={descountry}
          size="small"
          style={{width:"28ch"}}
          onChange={handleChangedescountry}
        >
          <MenuItem value="none">
            <em>Choose Destination</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
       </FormControl>

        <FormControl align="left" style={{margin:10}}> 
<b>Address <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="desaddress"
          style={{width:"28ch"}}

          placeholder="Enter destination address"
          size="small"

        /> </FormControl>
        
           <FormControl align="left" style={{margin:10}}> 
<b>Phone Number <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="descontact"
          style={{width:"28ch"}}

          placeholder="Enter Phone Number"
          size="small"

        /> </FormControl>

        
        <Button style={{marginTop:"33px", padding:"9px", backgroundColor:"#062333", color:"white"}} onClick={()=>adddestination()} size="small">Add</Button>
        
        {destination.length!==0?<Container  style={{backgroundColor:"lightgray", marginTop:"20px"}}>
<Grid  container spacing={2}>
  <Grid item xs={3}><b>Country</b></Grid>
  <Grid item xs={3}><b>Address</b></Grid>
  <Grid item xs={3}><b>Phone Number</b></Grid>
  
</Grid>
{destination.map((list,index)=>
<Grid key={index} container spacing={2} style={{paddingTop:10}}>
  <Grid item xs={3}>{list.country}</Grid>
  <Grid item xs={3}>{list.address}</Grid>
  <Grid item xs={3}>{list.contact}</Grid>
  <Grid item xs={3}><IconButton onClick={()=>deletedestination(list)} style={{marginTop:"-4px",   }} size="small"><DeleteIcon/></IconButton>
</Grid>
</Grid>
)
}


        </Container>:""}

        <br/> 
        <br/> 
        <br/>
          <Divider style={{marginBottom:"22px", backgroundColor:"#062333"}}>
        <Chip label="SPOUSE DETAILS" style={{backgroundColor:"white", color:"#062333"}}/>
      </Divider>

<FormControlLabel control={<Checkbox onChange={()=> gowithspouse()} />} label="Will you be traveling with your spouse? " /> 
{travelwithspouse === true?
  <div>
   <FormControl align="left" style={{margin:10}}> 
<b>Spouse Name <span style={{color:"red"}}> *</span></b>
 <TextField
          
          id="spousename"
          style={{width:"28ch"}}
          type="text"
          placeholder="Enter spouse name"
          size="small"

        /> </FormControl>
         

            <FormControl align="left" style={{margin:10}}> 
<b>Passport Number <span style={{color:"red"}}> *</span></b>
 <TextField
          
          id="spousepassport"
          style={{width:"28ch"}}
          type="text"
          placeholder="Enter Passport Number"
          size="small"

        /> </FormControl>

           <FormControl align="left" style={{margin:10}}> 
<b>Date Of Birth <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="spousedob"
          style={{width:"28ch"}}
          type="date"
          placeholder="date "
          size="small"

        /> </FormControl>

           <FormControl align="left" style={{margin:10}}> 
<b>Contact   <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="spousecontact"
          style={{width:"28ch"}}
          type="tel"
          placeholder="Enter destination address"
          size="small"

        /> </FormControl>
</div>:""}
           
       
        <br/> 
        <br/>
          <Divider style={{marginBottom:"22px", backgroundColor:"#062333"}}>
        <Chip label="BENEFICIARY DETAILS" style={{backgroundColor:"white", color:"#062333"}}/>
      </Divider>


<FormControlLabel control={<Checkbox onChange={(e)=> travelwithdependant()} />} label="Will you be traveling with anyone below the age 18? " /> 
<br/>

 {havedepandant?
   <div>

      <FormControl align="left" style={{margin:10}}> 
<b>Beneficiary   <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="beneficiaryname"
          style={{width:"28ch"}}
          type="text"
          placeholder="Enter beneficiary name"
          size="small"

        /> </FormControl>


      <FormControl align="left" style={{margin:10}}> 
<b>Beneficiary Relationship <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="beneficiaryrelationship"
          style={{width:"28ch"}}
          type="text"
          placeholder="Enter beneficiary relationship"
          size="small"

        /> </FormControl>  


      <FormControl align="left" style={{margin:10}}> 
<b>Beneficiary Contact <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="beneficiarycontact"
          style={{width:"28ch"}}
          type="tel"
          placeholder="Enter beneficiary contact"
          size="small"

        /> </FormControl>
 
        <Button style={{marginTop:"33px", padding:"9px", backgroundColor:"#062333",color:"white" }} onClick={()=>addbeneficiary()} size="small">Add</Button>
        
        {beneficiarydata.length!==0?<Container  style={{backgroundColor:"lightgray", marginTop:"20px"}}>
<Grid  container spacing={2}>
  <Grid item xs={3}><b>Beneficiary</b></Grid>
  <Grid item xs={3}><b>Relationship</b></Grid>
  <Grid item xs={3}><b>Phone Number</b></Grid>
  
</Grid>
{beneficiarydata.map((list,index)=>
<Grid key={index} container spacing={2} style={{paddingTop:10}}>
  <Grid item xs={3}>{list.name}</Grid>
  <Grid item xs={3}>{list.relationship}</Grid>
  <Grid item xs={3}>{list.contact}</Grid>
  <Grid item xs={3}><IconButton onClick={()=>deletebeneficiary(list)} style={{marginTop:"-4px",   }} size="small"><DeleteIcon/></IconButton>
</Grid>
</Grid>
)
}


        </Container>:""}



  </div>:""}

<br/>
<br/>



  <div align="center">
          
          <input type="submit" value="SUBMIT" style={{padding:13.5, cursor:"pointer", borderRadius:5, outline:"none", border:"none", backgroundColor:"#062333", color:"white", width:"35ch", marginTop:"10px", marginBottom:"40px"}}/>
          
          <Button size="large" disabled startIcon={<CircularProgress style={{color:"#062333", width:"20px", height:"20px"}} />}  style={{ backgroundColor:"lightgrey", color:"#062333", width:"35ch", marginTop:"10px", marginBottom:"8px"}} >Loading...</Button>
          
          </div>
<br/>
<br/>
 </form>

 </div>

    );
}

export default Profile;