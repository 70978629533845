import React, {useEffect} from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import LoadingButton from '@mui/lab/LoadingButton';
import swal from 'sweetalert';


import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';


import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';

import axios from "axios"

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_motor } from '../redux/motor';
import { update_travel } from '../redux/travel';
import { update_settings } from '../redux/settings';



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function Profile4(props) {
  const Agent = useSelector((state) => state.agent)
    const dispatch = useDispatch()
 
    const getsettingsdata = async () => {
        await axios.post("https://server.ayconsultgh.com/api/settings/getsettings/",
            {

            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_settings({
                        data: res.data
                    }))
                }
            })
    }

    const theme = useTheme();
    const [age, setAge] = React.useState('');
    const [pager, setpager] = React.useState(true);
    const [loading, setloading] = React.useState(false)
    const [message, setmessage] = React.useState("")
    const [stated, setstated] = React.useState("")
    const [currency, setcurrency] = React.useState("none")
    const [traveltype, settraveltype] = React.useState("none")
    const [travelcategory, settravelcategory] = React.useState("none")
    const [country, setcountry] = React.useState("none")
    const [citizenship, setcitizenship] = React.useState("none")
    const [descountry, setdescountry] = React.useState("none")
    const [transact, settransact] = React.useState("no")
    const [opensnack, setOpensnack] = React.useState(false);
    const [havedepandant, sethavedepandant] = React.useState(false);
    const [travelwithspouse, settravelwithspouse] = React.useState(false);
    const [destination, setdestination] = React.useState([]);
    const [beneficiarydata, setbeneficiarydata] = React.useState([]);
    const [premium, setpremium] = React.useState(0);
    const [agenttype, setagenttype] = React.useState( JSON.parse(localStorage.getItem("user")).type === "Agent"? "":"Direct" );
   const settings = useSelector((state) => state.settings)

  useEffect(() => {
 
        getsettingsdata()
      
  },[pager])

  const handleChangecurrency = (event) => {
        setcurrency(event.target.value);
    }; 
   
  const handleChangecitizenship = (event) => {
        setcitizenship(event.target.value);
    };
  const handleChangecountry = (event) => {
        setcountry(event.target.value);
    };  
    const handleChangedescountry = (event) => {
        setdescountry(event.target.value);
    };
 const handleChangetraveltype = (event) => {
        settraveltype(event.target.value);
    };
    const handleChangetravelcategory = (event) => {
        settravelcategory(event.target.value);
    };

    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };


    const handleChangeselector = (event) => {
        setAge(event.target.value);
    };


    const yessubmit = (e)=>{
       e.preventDefault()
      setloading(true)
               
    const buyingpolicy= async () => {
     

      const name_ = document.getElementById("name").value
      const contact_ = document.getElementById("contact").value
      const email_ = document.getElementById("email").value
      const dob_ = document.getElementById("dob").value
      const occupation_ = document.getElementById("occupation").value
      const address_ = document.getElementById("address").value
      const departuredate_ = document.getElementById("departuredate").value
      const returndate_ = document.getElementById("returndate").value
      const days_ = document.getElementById("days").value
      const passportnum_ = document.getElementById("passportnum").value
      const charge = document.getElementById("charge").value
      
      var spousename_ = ""   
      var spousepassport_ = ""
      var spousedob_ = ""  
      var spousecontact_ = ""           
      
      var currency_ = currency
      var traveltype_ = traveltype
      var travelcategory_ = travelcategory
      var country_ = country
      var beneficiary_ = beneficiarydata

      if(travelwithspouse){
      spousename_ = document.getElementById("spousename").value
      spousepassport_ = document.getElementById("spousepassport").value
      spousedob_ = document.getElementById("spousedob").value
      spousecontact_ = document.getElementById("spousecontact").value
      
      }

      var agentname_="" 
      var agentcontact_=""
      var agentIDnumber_=""
      var agentemail_=""
      var agentcommission_=""
      var aycommission_=""
      var institution_=""

      if(agenttype===""){
        agentname_=Agent.name
        agentcontact_=Agent.contact
        agentIDnumber_=Agent.IDnumber
        agentemail_=Agent.email
        institution_=Agent.institution
        agentcommission_=parseFloat(charge)*parseFloat(settings.length!==0?settings[0].agentcommission:0) -(parseFloat(charge)*parseFloat(settings.length!==0?settings[0].agentcommission:0) * parseFloat(settings.length!==0?settings[0].tax:0))
        aycommission_=parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission:0) - (parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission:0)*parseFloat(settings.length!==0?settings[0].tax:0))
              

      }else if(agenttype==="Direct"){
        agentname_=JSON.parse(localStorage.getItem("user")).name
        agentcontact_=JSON.parse(localStorage.getItem("user")).contact
        agentIDnumber_="Direct"
        institution_="Direct"
        agentemail_=JSON.parse(localStorage.getItem("user")).email
        agentcommission_= 0
        aycommission_= parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission_direct:0) - (parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission_direct:0)*parseFloat(settings.length!==0?settings[0].tax:0))
            
      }else if(agenttype==="Indirect"){
        agentname_= document.getElementById("agentname").value
        agentcontact_= document.getElementById("agentcontact").value
        agentIDnumber_= document.getElementById("agentcontact").value
        agentemail_= document.getElementById("agentemail").value
        institution_="Indirect"
        agentcommission_=parseFloat(charge)*parseFloat(settings.length!==0?settings[0].agentcommission:0) -(parseFloat(charge)*parseFloat(settings.length!==0?settings[0].agentcommission:0) * parseFloat(settings.length!==0?settings[0].tax:0))
        aycommission_=parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission:0) - (parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission:0)*parseFloat(settings.length!==0?settings[0].tax:0))
            

      }



        await axios.post("https://server.ayconsultgh.com/api/traveldata/registertravelpolicy/",
            {

              name:name_,
              contact:contact_,
              email:email_,
              dob:dob_,
              occupation:occupation_,
              address:address_,
              charge:charge,
              traveltype:traveltype_,
              travelcategory:travelcategory_,
              currency:currency_,
              departure:departuredate_,
              return:returndate_,
              days:days_,
              passport:passportnum_,
              issuingcountry:country_,
              citizenship:citizenship,
              destination:destination,
              commissionpayment:"none",
              withspouse:travelwithspouse,
              spousedata:{name:spousename_,contact:spousecontact_, dob:spousedob_, passport:spousepassport_},
              withdepandant:havedepandant,
              dependantdata:beneficiarydata,
              agentname:agentname_, 
              agentcontact:agentcontact_,
              agentID:agentIDnumber_,
              agentemail:agentemail_,
              agentcommission:agentcommission_,
              aycommission:aycommission_,
              institution:institution_,

            }).then(res => {

                if (res.status === 200) {

                        setloading(!true)
                      swal("", "Travel Policy Added", "success");

                        document.getElementById("ll").reset()
                        handleClosedelete()
                }else{
                      setloading(!true)
                     swal("", " Error Adding Travel Policy ", "error");

                }
               
                

            })

    }

  
    if((document.getElementById("transactionID").value).length>10 && (document.getElementById("transactionID").value).length<13){
     buyingpolicy()

    }else{
         setloading(!true)
         settransact(false)

    }
    }

    const changepremium=(e)=>{
      setpremium(e.targte.value)
    }

const adddestination=()=>{
  
   const desaddress = document.getElementById("desaddress").value
   const descontact = document.getElementById("descontact").value
var des = {country:descountry, address:desaddress, contact:descontact}
setdestination([...destination,des])
}

const deletedestination=(list)=>{
  var des= destination.filter(bug=>bug!==list)
  setdestination([...des])

}

const addbeneficiary=()=>{
  
   const name = document.getElementById("beneficiaryname").value
   const relationship = document.getElementById("beneficiaryrelationship").value
   const contact = document.getElementById("beneficiarycontact").value
var ben = {name:name, relationship:relationship, contact:contact}
setbeneficiarydata([...beneficiarydata,ben])
}

const deletebeneficiary=(list)=>{
  var ben= beneficiarydata.filter(bug=>bug!==list)
  setbeneficiarydata([...ben])

}
 const handleChangeagenttype = (event) => {
        setagenttype(event.target.value);
    };
const travelwithdependant=()=>{
  sethavedepandant(!havedepandant)
  
}
const gowithspouse=()=>{
  settravelwithspouse(!travelwithspouse)
  
}

 const [opendelete, setOpendelete] = React.useState(!true);

     

    const handleClickOpendelete = () => {
        setOpendelete(true);
    };
    const handleClosedelete = () => {
        setOpendelete(false);
    };

   const submiting=(e)=>{
       e.preventDefault()
      handleClickOpendelete()
    }


    return (

<div>

    <Dialog maxWidth="md" onClose={handleClosedelete} aria-labelledby="customized-dialog-title" open={opendelete}>
<Button autoFocus onClick={handleClosedelete} style={{float:"right"}} color="primary">
                        close
                    </Button>
<form onSubmit={yessubmit}  id="ll">
                <DialogContent dividers>
                <h2 align="center">Make Payment</h2>

<p><b>Payment Instructions</b></p>
<p>Please send your Premium to the merchant number provided and provide the transaction ID the forms below.</p>
<h2>Premium : GHC {premium}</h2>
<h2>Send to : 024 0511421</h2>
                   <FormControl align="left" style={{ margin: 10 }}>
              <b>Transaction ID <span style={{ color: "red" }}> *</span></b>
              <TextField
                required
                id="transactionID"
                style={{ width: "28ch" }}
                type="text"
                placeholder="Transaction ID"
                size="small"
                helperText={transact===false?<span style={{color:"red"}}>Incorrect Transaction ID</span>:""}


              /> </FormControl>

                </DialogContent>
                <DialogActions>
                  {!loading?
              <input type="submit" value="BUY POLICY" style={{ padding: 13.5, cursor: "pointer", borderRadius: 5, outline: "none", border: "none", backgroundColor: "#062333", color: "white", width: "35ch", marginTop: "10px", marginBottom: "4px" }} />
:
              <Button size="large" disabled startIcon={<CircularProgress style={{ color: "#062333", width: "20px", height: "20px" }} />} style={{ backgroundColor: "lightgrey", color: "#062333", width: "35ch", marginTop: "10px", marginBottom: "8px" }} >Loading...</Button>
} 
                </DialogActions>
                </form>
            </Dialog>



        <form align="left" onSubmit={submiting} id="ll">


          {JSON.parse(localStorage.getItem("user")).type==="Admin"?
          <div>
          <div  align="center" style={{margin:10}}>
     <FormControl>
 <FormLabel id="demo-row-radio-buttons-group-label">Choose Agent Type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby = "demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                id = "guardianedit"
                                value={agenttype}
                                onChange={handleChangeagenttype}
                            >
                                <FormControlLabel value="Direct" control={<Radio />} label="Head Office" />
                                <FormControlLabel value="Indirect" control={<Radio />} label="Kumasi Agent" />
                                
                            </RadioGroup>
                        </FormControl>
</div>
{agenttype==="Indirect"?

<div>
  
            <FormControl align="left" style={{ margin: 10 }}>
              <b>Agent Name <span style={{ color: "red" }}> *</span></b>
              <TextField
                
                id="agentname"
                style={{ width: "28ch" }}
                type="text"
                placeholder="Enter Full Name"
                size="small"

              /> </FormControl>

            <FormControl align="left" style={{ margin: 10 }}>
              <b>Agent Contact <span style={{ color: "red" }}> *</span></b>
              <TextField
                
                id="agentcontact"
                style={{ width: "28ch" }}
                type="tel"
                placeholder="Enter Contact Number"
                size="small"

              /> </FormControl>

            <FormControl align="left" style={{ margin: 10 }}>
              <b>Agent Email <span style={{ color: "red" }}> *</span></b>
              <TextField
                
                id="agentemail"
                style={{ width: "28ch" }}
                type="tel"
                placeholder="Enter Agent Email"
                size="small"
              /> </FormControl>

</div>
:""
}</div>:""
          }


          <Divider style={{ marginBottom: "22px", backgroundColor: "#062333" }}>
            <Chip label="PERSONAL DETAILS" style={{ backgroundColor: "white", color: "#062333" }} />
          </Divider>

          <FormControl align="left" style={{ margin: 10 }}>
            <b>Name <span style={{ color: "red" }}> *</span></b>
            <TextField
              required
              id="name"
              style={{ width: "28ch" }}
              inputProps={{maxLength:11}}
              placeholder="Enter Full Name"
              size="small"

            /> </FormControl>

          <FormControl align="left" style={{ margin: 10 }}>
            <b>Phone <span style={{ color: "red" }}> *</span></b>
            <TextField
              required
              id="contact"
              style={{ width: "28ch" }}
              type="tel"
              placeholder="Enter Contact"
              size="small"

            /> </FormControl>
          <FormControl align="left" style={{ margin: 10 }}>
            <b>Email Address </b>
            <TextField
              required
              id="email"
              style={{ width: "28ch" }}
              type="email"
              placeholder="Enter Email"
              size="small"

            /> </FormControl>
          <FormControl align="left" style={{ margin: 10 }}>
            <b>Date of Birth </b>
            <TextField
              required
              id="dob"
              style={{ width: "28ch" }}
              type="date"
              placeholder="Hello World"
              size="small"

            /> </FormControl>



          <br />


          <FormControl align="left" style={{ margin: 10 }}>
            <b>Occupation <span style={{ color: "red" }}> *</span></b>
            <TextField
              required
              id="occupation"
              style={{ width: "28ch" }}
              type="text"
              placeholder="Enter Occupation"
              size="small"

            /> </FormControl>



          <FormControl align="left" style={{ margin: 10 }}>
            <b>Address <span style={{ color: "red" }}> *</span></b>
            <TextField
              required
              id="address"
              style={{ width: "28ch" }}
              type="address"
              placeholder="Enter Address"
              size="small"

            /> </FormControl>

          <br />
          <br />
          <br />
          <Divider style={{ marginBottom: "22px", backgroundColor: "#062333" }}>
            <Chip label="POLICY DETAILS" style={{ backgroundColor: "white", color: "#062333" }} />
          </Divider>

          <FormControl align="left" style={{ margin: 10 }}>
            <b>Currency <span style={{ color: "red" }}> *</span></b>

            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={currency}
              size="small"
              style={{ width: "28ch" }}
              onChange={handleChangecurrency}
            >
              <MenuItem value="none">
                <em>Choose Currency</em>
              </MenuItem>
              <MenuItem value="Ghana Cedis">Ghana Cedis</MenuItem>
            </Select>
          </FormControl>
          <FormControl align="left" style={{ margin: 10 }}>
            <b>Travel Type <span style={{ color: "red" }}> *</span></b>

            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={traveltype}
              size="small"
              style={{ width: "28ch" }}
              onChange={handleChangetraveltype}
            >
              <MenuItem value="none">
                <em>Choose Travel Type</em>
              </MenuItem>
              <MenuItem value="Single Trip">Single Trip</MenuItem>
              <MenuItem value="Multiple Trip">Multiple Trip</MenuItem>
              
            </Select>
          </FormControl>
          <FormControl align="left" style={{ margin: 10 }}>
            <b>Travel Category <span style={{ color: "red" }}> *</span></b>

            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={travelcategory}
              size="small"
              style={{ width: "28ch" }}
              onChange={handleChangetravelcategory}
            >
              <MenuItem value="none">
                <em>Choose Travel Category</em>
              </MenuItem>
              <MenuItem value="World-Economy">World-Economy</MenuItem>
              <MenuItem value="Europe/Schenghen">Europe/Schenghen</MenuItem>
              <MenuItem value="Student">Student</MenuItem>
              <MenuItem value="World - Pearl">World - Pearl</MenuItem>
              
            </Select>
          </FormControl>



          <FormControl align="left" style={{ margin: 10 }}>
            <b>Departure Date <span style={{ color: "red" }}> *</span></b>
            <TextField
              required
              id="departuredate"
              style={{ width: "28ch" }}
              type="date"
              placeholder="Hello World"
              size="small"

            /> </FormControl>


          <FormControl align="left" style={{ margin: 10 }}>
            <b>Return Date <span style={{ color: "red" }}> *</span></b>
            <TextField
              required
              id="returndate"
              style={{ width: "28ch" }}
              type="date"
              placeholder="Hello World"
              size="small"

            /> </FormControl>

          <FormControl align="left" style={{ margin: 10 }}>
            <b>Number of days <span style={{ color: "red" }}> *</span></b>
            <TextField
              required
              id="days"
              style={{ width: "28ch" }}
              type="number"
              placeholder="Number of days"
              size="small"

            /> </FormControl>

          <FormControl align="left" style={{ margin: 10 }}>
            <b>Passport Number <span style={{ color: "red" }}> *</span></b>
            <TextField
              required
              id="passportnum"
              style={{ width: "28ch" }}
              type="text"
              placeholder="Passport Number"
              size="small"

            /> </FormControl>

          <FormControl align="left" style={{ margin: 10 }}>
            <b>Issuing Country <span style={{ color: "red" }}> *</span></b>

            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={country}
              size="small"
              style={{ width: "28ch" }}
              onChange={handleChangecountry}
            >
              <MenuItem value="none">
                <em>Choose Country</em>
              </MenuItem>
               {Countries.map((name, index)=>
                    <MenuItem value={name.name}>{name.name}</MenuItem>

              )

              }>
            </Select>
          </FormControl>

          <FormControl align="left" style={{ margin: 10 }}>
            <b>Citizenship <span style={{ color: "red" }}> *</span></b>

            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={citizenship}
              size="small"
              style={{ width: "28ch" }}
              onChange={handleChangecitizenship}
            >
              <MenuItem value="none">
                <em>Choose Citizenship</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
               {Countries.map((name, index)=>
                    <MenuItem value={name.name}>{name.name}</MenuItem>

              )

              }
            </Select>
          </FormControl>

          <br />
          <br />
          <br />
          <Divider style={{ marginBottom: "22px", backgroundColor: "#062333" }}>
            <Chip label="DESTINATION(S)" style={{ backgroundColor: "white", color: "#062333" }} />
          </Divider>
          <br />
          <FormControl align="left" style={{ margin: 10 }}>
            <b>Country <span style={{ color: "red" }}> *</span></b>

            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={descountry}
              size="small"
              style={{ width: "28ch" }}
              onChange={handleChangedescountry}
            >
              <MenuItem value="none">
                <em>Choose Destination</em>
              </MenuItem>
              {Countries.map((name, index)=>
                    <MenuItem value={name.name}>{name.name}</MenuItem>

              )

              }
            </Select>
          </FormControl>

          <FormControl align="left" style={{ margin: 10 }}>
            <b>Address <span style={{ color: "red" }}> *</span></b>
            <TextField
              required
              id="desaddress"
              style={{ width: "28ch" }}

              placeholder="Enter destination address"
              size="small"

            /> </FormControl>

          <FormControl align="left" style={{ margin: 10 }}>
            <b>Phone Number <span style={{ color: "red" }}> *</span></b>
            <TextField
              required
              id="descontact"
              style={{ width: "28ch" }}

              placeholder="Enter Phone Number"
              size="small"

            /> </FormControl>


          <Button style={{ marginTop: "33px", padding: "9px", backgroundColor: "#062333", color: "white" }} onClick={() => adddestination()} size="small">Add</Button>

          {destination.length !== 0 ? <Container style={{ backgroundColor: "lightgray", marginTop: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={3}><b>Country</b></Grid>
              <Grid item xs={3}><b>Address</b></Grid>
              <Grid item xs={3}><b>Phone Number</b></Grid>

            </Grid>
            {destination.map((list, index) =>
              <Grid key={index} container spacing={2} style={{ paddingTop: 10 }}>
                <Grid item xs={3}>{list.country}</Grid>
                <Grid item xs={3}>{list.address}</Grid>
                <Grid item xs={3}>{list.contact}</Grid>
                <Grid item xs={3}><IconButton onClick={() => deletedestination(list)} style={{ marginTop: "-4px", }} size="small"><DeleteIcon /></IconButton>
                </Grid>
              </Grid>
            )
            }


          </Container> : ""}

          <br />
          <br />
          <br />
          <Divider style={{ marginBottom: "22px", backgroundColor: "#062333" }}>
            <Chip label="SPOUSE DETAILS" style={{ backgroundColor: "white", color: "#062333" }} />
          </Divider>

          <FormControlLabel control={<Checkbox onChange={() => gowithspouse()} />} label="Will you be traveling with your spouse? " />
          {travelwithspouse === true ?
            <div>
              <FormControl align="left" style={{ margin: 10 }}>
                <b>Spouse Name <span style={{ color: "red" }}> *</span></b>
                <TextField

                  id="spousename"
                  style={{ width: "28ch" }}
                  type="text"
                  placeholder="Enter spouse name"
                  size="small"

                /> </FormControl>


              <FormControl align="left" style={{ margin: 10 }}>
                <b>Passport Number <span style={{ color: "red" }}> *</span></b>
                <TextField

                  id="spousepassport"
                  style={{ width: "28ch" }}
                  type="text"
                  placeholder="Enter Passport Number"
                  size="small"

                /> </FormControl>

              <FormControl align="left" style={{ margin: 10 }}>
                <b>Date Of Birth <span style={{ color: "red" }}> *</span></b>
                <TextField
                  required
                  id="spousedob"
                  style={{ width: "28ch" }}
                  type="date"
                  placeholder="date "
                  size="small"

                /> </FormControl>

              <FormControl align="left" style={{ margin: 10 }}>
                <b>Contact   <span style={{ color: "red" }}> *</span></b>
                <TextField
                  required
                  id="spousecontact"
                  style={{ width: "28ch" }}
                  type="tel"
                  placeholder="Enter destination address"
                  size="small"

                /> </FormControl>
            </div> : ""}


          <br />
          <br />
          <Divider style={{ marginBottom: "22px", backgroundColor: "#062333" }}>
            <Chip label="BENEFICIARY DETAILS" style={{ backgroundColor: "white", color: "#062333" }} />
          </Divider>


          <FormControlLabel control={<Checkbox onChange={(e) => travelwithdependant()} />} label="Will you be traveling with anyone below the age 18? " />
          <br />

          {havedepandant ?
            <div>

              <FormControl align="left" style={{ margin: 10 }}>
                <b>Beneficiary   <span style={{ color: "red" }}> *</span></b>
                <TextField
                  required
                  id="beneficiaryname"
                  style={{ width: "28ch" }}
                  type="text"
                  placeholder="Enter beneficiary name"
                  size="small"

                /> </FormControl>


              <FormControl align="left" style={{ margin: 10 }}>
                <b>Beneficiary Relationship <span style={{ color: "red" }}> *</span></b>
                <TextField
                  required
                  id="beneficiaryrelationship"
                  style={{ width: "28ch" }}
                  type="text"
                  placeholder="Enter beneficiary relationship"
                  size="small"

                /> </FormControl>


              <FormControl align="left" style={{ margin: 10 }}>
                <b>Beneficiary Contact <span style={{ color: "red" }}> *</span></b>
                <TextField
                  required
                  id="beneficiarycontact"
                  style={{ width: "28ch" }}
                  type="tel"
                  placeholder="Enter beneficiary contact"
                  size="small"

                /> </FormControl>

              <Button style={{ marginTop: "33px", padding: "9px", backgroundColor: "#062333", color: "white" }} onClick={() => addbeneficiary()} size="small">Add</Button>

              {beneficiarydata.length !== 0 ? <Container style={{ backgroundColor: "lightgray", marginTop: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={3}><b>Beneficiary</b></Grid>
                  <Grid item xs={3}><b>Relationship</b></Grid>
                  <Grid item xs={3}><b>Phone Number</b></Grid>

                </Grid>
                {beneficiarydata.map((list, index) =>
                  <Grid key={index} container spacing={2} style={{ paddingTop: 10 }}>
                    <Grid item xs={3}>{list.name}</Grid>
                    <Grid item xs={3}>{list.relationship}</Grid>
                    <Grid item xs={3}>{list.contact}</Grid>
                    <Grid item xs={3}><IconButton onClick={() => deletebeneficiary(list)} style={{ marginTop: "-4px", }} size="small"><DeleteIcon /></IconButton>
                    </Grid>
                  </Grid>
                )
                }


              </Container> : ""}



            </div> : ""}

          <br />
          <FormControl align="left" style={{ margin: 10 }}>
                <b>Premium <span style={{ color: "red" }}> *</span></b>
                <TextField
                  required
                  id="charge"
                  defaultValue={premium}
                  onChange={changepremium}
                  style={{ width: "28ch" }}
                  type="text"
                  placeholder="Enter Amount"
                  size="small"

                /> </FormControl>
          <br />



          <div align="center">

        {!loading?
              <input type="submit" value="SUBMIT" style={{ padding: 13.5, cursor: "pointer", borderRadius: 5, outline: "none", border: "none", backgroundColor: "#062333", color: "white", width: "35ch", marginTop: "10px", marginBottom: "40px" }} />
:
              <Button size="large" disabled startIcon={<CircularProgress style={{ color: "#062333", width: "20px", height: "20px" }} />} style={{ backgroundColor: "lightgrey", color: "#062333", width: "35ch", marginTop: "10px", marginBottom: "8px" }} >Loading...</Button>
}
          </div>
          <br />
          <br />
        </form>

 </div>

    );
}

export default Profile4;

const Countries = [ 
  {name: 'Afghanistan', code: 'AF'}, 
  {name: 'Åland Islands', code: 'AX'}, 
  {name: 'Albania', code: 'AL'}, 
  {name: 'Algeria', code: 'DZ'}, 
  {name: 'American Samoa', code: 'AS'}, 
  {name: 'AndorrA', code: 'AD'}, 
  {name: 'Angola', code: 'AO'}, 
  {name: 'Anguilla', code: 'AI'}, 
  {name: 'Antarctica', code: 'AQ'}, 
  {name: 'Antigua and Barbuda', code: 'AG'}, 
  {name: 'Argentina', code: 'AR'}, 
  {name: 'Armenia', code: 'AM'}, 
  {name: 'Aruba', code: 'AW'}, 
  {name: 'Australia', code: 'AU'}, 
  {name: 'Austria', code: 'AT'}, 
  {name: 'Azerbaijan', code: 'AZ'}, 
  {name: 'Bahamas', code: 'BS'}, 
  {name: 'Bahrain', code: 'BH'}, 
  {name: 'Bangladesh', code: 'BD'}, 
  {name: 'Barbados', code: 'BB'}, 
  {name: 'Belarus', code: 'BY'}, 
  {name: 'Belgium', code: 'BE'}, 
  {name: 'Belize', code: 'BZ'}, 
  {name: 'Benin', code: 'BJ'}, 
  {name: 'Bermuda', code: 'BM'}, 
  {name: 'Bhutan', code: 'BT'}, 
  {name: 'Bolivia', code: 'BO'}, 
  {name: 'Bosnia and Herzegovina', code: 'BA'}, 
  {name: 'Botswana', code: 'BW'}, 
  {name: 'Bouvet Island', code: 'BV'}, 
  {name: 'Brazil', code: 'BR'}, 
  {name: 'British Indian Ocean Territory', code: 'IO'}, 
  {name: 'Brunei Darussalam', code: 'BN'}, 
  {name: 'Bulgaria', code: 'BG'}, 
  {name: 'Burkina Faso', code: 'BF'}, 
  {name: 'Burundi', code: 'BI'}, 
  {name: 'Cambodia', code: 'KH'}, 
  {name: 'Cameroon', code: 'CM'}, 
  {name: 'Canada', code: 'CA'}, 
  {name: 'Cape Verde', code: 'CV'}, 
  {name: 'Cayman Islands', code: 'KY'}, 
  {name: 'Central African Republic', code: 'CF'}, 
  {name: 'Chad', code: 'TD'}, 
  {name: 'Chile', code: 'CL'}, 
  {name: 'China', code: 'CN'}, 
  {name: 'Christmas Island', code: 'CX'}, 
  {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
  {name: 'Colombia', code: 'CO'}, 
  {name: 'Comoros', code: 'KM'}, 
  {name: 'Congo', code: 'CG'}, 
  {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
  {name: 'Cook Islands', code: 'CK'}, 
  {name: 'Costa Rica', code: 'CR'}, 
  {name: 'Cote D\'Ivoire', code: 'CI'}, 
  {name: 'Croatia', code: 'HR'}, 
  {name: 'Cuba', code: 'CU'}, 
  {name: 'Cyprus', code: 'CY'}, 
  {name: 'Czech Republic', code: 'CZ'}, 
  {name: 'Denmark', code: 'DK'}, 
  {name: 'Djibouti', code: 'DJ'}, 
  {name: 'Dominica', code: 'DM'}, 
  {name: 'Dominican Republic', code: 'DO'}, 
  {name: 'Ecuador', code: 'EC'}, 
  {name: 'Egypt', code: 'EG'}, 
  {name: 'El Salvador', code: 'SV'}, 
  {name: 'Equatorial Guinea', code: 'GQ'}, 
  {name: 'Eritrea', code: 'ER'}, 
  {name: 'Estonia', code: 'EE'}, 
  {name: 'Ethiopia', code: 'ET'}, 
  {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
  {name: 'Faroe Islands', code: 'FO'}, 
  {name: 'Fiji', code: 'FJ'}, 
  {name: 'Finland', code: 'FI'}, 
  {name: 'France', code: 'FR'}, 
  {name: 'French Guiana', code: 'GF'}, 
  {name: 'French Polynesia', code: 'PF'}, 
  {name: 'French Southern Territories', code: 'TF'}, 
  {name: 'Gabon', code: 'GA'}, 
  {name: 'Gambia', code: 'GM'}, 
  {name: 'Georgia', code: 'GE'}, 
  {name: 'Germany', code: 'DE'}, 
  {name: 'Ghana', code: 'GH'}, 
  {name: 'Gibraltar', code: 'GI'}, 
  {name: 'Greece', code: 'GR'}, 
  {name: 'Greenland', code: 'GL'}, 
  {name: 'Grenada', code: 'GD'}, 
  {name: 'Guadeloupe', code: 'GP'}, 
  {name: 'Guam', code: 'GU'}, 
  {name: 'Guatemala', code: 'GT'}, 
  {name: 'Guernsey', code: 'GG'}, 
  {name: 'Guinea', code: 'GN'}, 
  {name: 'Guinea-Bissau', code: 'GW'}, 
  {name: 'Guyana', code: 'GY'}, 
  {name: 'Haiti', code: 'HT'}, 
  {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
  {name: 'Holy See (Vatican City State)', code: 'VA'}, 
  {name: 'Honduras', code: 'HN'}, 
  {name: 'Hong Kong', code: 'HK'}, 
  {name: 'Hungary', code: 'HU'}, 
  {name: 'Iceland', code: 'IS'}, 
  {name: 'India', code: 'IN'}, 
  {name: 'Indonesia', code: 'ID'}, 
  {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
  {name: 'Iraq', code: 'IQ'}, 
  {name: 'Ireland', code: 'IE'}, 
  {name: 'Isle of Man', code: 'IM'}, 
  {name: 'Israel', code: 'IL'}, 
  {name: 'Italy', code: 'IT'}, 
  {name: 'Jamaica', code: 'JM'}, 
  {name: 'Japan', code: 'JP'}, 
  {name: 'Jersey', code: 'JE'}, 
  {name: 'Jordan', code: 'JO'}, 
  {name: 'Kazakhstan', code: 'KZ'}, 
  {name: 'Kenya', code: 'KE'}, 
  {name: 'Kiribati', code: 'KI'}, 
  {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
  {name: 'Korea, Republic of', code: 'KR'}, 
  {name: 'Kuwait', code: 'KW'}, 
  {name: 'Kyrgyzstan', code: 'KG'}, 
  {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
  {name: 'Latvia', code: 'LV'}, 
  {name: 'Lebanon', code: 'LB'}, 
  {name: 'Lesotho', code: 'LS'}, 
  {name: 'Liberia', code: 'LR'}, 
  {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
  {name: 'Liechtenstein', code: 'LI'}, 
  {name: 'Lithuania', code: 'LT'}, 
  {name: 'Luxembourg', code: 'LU'}, 
  {name: 'Macao', code: 'MO'}, 
  {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
  {name: 'Madagascar', code: 'MG'}, 
  {name: 'Malawi', code: 'MW'}, 
  {name: 'Malaysia', code: 'MY'}, 
  {name: 'Maldives', code: 'MV'}, 
  {name: 'Mali', code: 'ML'}, 
  {name: 'Malta', code: 'MT'}, 
  {name: 'Marshall Islands', code: 'MH'}, 
  {name: 'Martinique', code: 'MQ'}, 
  {name: 'Mauritania', code: 'MR'}, 
  {name: 'Mauritius', code: 'MU'}, 
  {name: 'Mayotte', code: 'YT'}, 
  {name: 'Mexico', code: 'MX'}, 
  {name: 'Micronesia, Federated States of', code: 'FM'}, 
  {name: 'Moldova, Republic of', code: 'MD'}, 
  {name: 'Monaco', code: 'MC'}, 
  {name: 'Mongolia', code: 'MN'}, 
  {name: 'Montserrat', code: 'MS'}, 
  {name: 'Morocco', code: 'MA'}, 
  {name: 'Mozambique', code: 'MZ'}, 
  {name: 'Myanmar', code: 'MM'}, 
  {name: 'Namibia', code: 'NA'}, 
  {name: 'Nauru', code: 'NR'}, 
  {name: 'Nepal', code: 'NP'}, 
  {name: 'Netherlands', code: 'NL'}, 
  {name: 'Netherlands Antilles', code: 'AN'}, 
  {name: 'New Caledonia', code: 'NC'}, 
  {name: 'New Zealand', code: 'NZ'}, 
  {name: 'Nicaragua', code: 'NI'}, 
  {name: 'Niger', code: 'NE'}, 
  {name: 'Nigeria', code: 'NG'}, 
  {name: 'Niue', code: 'NU'}, 
  {name: 'Norfolk Island', code: 'NF'}, 
  {name: 'Northern Mariana Islands', code: 'MP'}, 
  {name: 'Norway', code: 'NO'}, 
  {name: 'Oman', code: 'OM'}, 
  {name: 'Pakistan', code: 'PK'}, 
  {name: 'Palau', code: 'PW'}, 
  {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
  {name: 'Panama', code: 'PA'}, 
  {name: 'Papua New Guinea', code: 'PG'}, 
  {name: 'Paraguay', code: 'PY'}, 
  {name: 'Peru', code: 'PE'}, 
  {name: 'Philippines', code: 'PH'}, 
  {name: 'Pitcairn', code: 'PN'}, 
  {name: 'Poland', code: 'PL'}, 
  {name: 'Portugal', code: 'PT'}, 
  {name: 'Puerto Rico', code: 'PR'}, 
  {name: 'Qatar', code: 'QA'}, 
  {name: 'Reunion', code: 'RE'}, 
  {name: 'Romania', code: 'RO'}, 
  {name: 'Russian Federation', code: 'RU'}, 
  {name: 'RWANDA', code: 'RW'}, 
  {name: 'Saint Helena', code: 'SH'}, 
  {name: 'Saint Kitts and Nevis', code: 'KN'}, 
  {name: 'Saint Lucia', code: 'LC'}, 
  {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
  {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
  {name: 'Samoa', code: 'WS'}, 
  {name: 'San Marino', code: 'SM'}, 
  {name: 'Sao Tome and Principe', code: 'ST'}, 
  {name: 'Saudi Arabia', code: 'SA'}, 
  {name: 'Senegal', code: 'SN'}, 
  {name: 'Serbia and Montenegro', code: 'CS'}, 
  {name: 'Seychelles', code: 'SC'}, 
  {name: 'Sierra Leone', code: 'SL'}, 
  {name: 'Singapore', code: 'SG'}, 
  {name: 'Slovakia', code: 'SK'}, 
  {name: 'Slovenia', code: 'SI'}, 
  {name: 'Solomon Islands', code: 'SB'}, 
  {name: 'Somalia', code: 'SO'}, 
  {name: 'South Africa', code: 'ZA'}, 
  {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
  {name: 'Spain', code: 'ES'}, 
  {name: 'Sri Lanka', code: 'LK'}, 
  {name: 'Sudan', code: 'SD'}, 
  {name: 'Suriname', code: 'SR'}, 
  {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
  {name: 'Swaziland', code: 'SZ'}, 
  {name: 'Sweden', code: 'SE'}, 
  {name: 'Switzerland', code: 'CH'}, 
  {name: 'Syrian Arab Republic', code: 'SY'}, 
  {name: 'Taiwan, Province of China', code: 'TW'}, 
  {name: 'Tajikistan', code: 'TJ'}, 
  {name: 'Tanzania, United Republic of', code: 'TZ'}, 
  {name: 'Thailand', code: 'TH'}, 
  {name: 'Timor-Leste', code: 'TL'}, 
  {name: 'Togo', code: 'TG'}, 
  {name: 'Tokelau', code: 'TK'}, 
  {name: 'Tonga', code: 'TO'}, 
  {name: 'Trinidad and Tobago', code: 'TT'}, 
  {name: 'Tunisia', code: 'TN'}, 
  {name: 'Turkey', code: 'TR'}, 
  {name: 'Turkmenistan', code: 'TM'}, 
  {name: 'Turks and Caicos Islands', code: 'TC'}, 
  {name: 'Tuvalu', code: 'TV'}, 
  {name: 'Uganda', code: 'UG'}, 
  {name: 'Ukraine', code: 'UA'}, 
  {name: 'United Arab Emirates', code: 'AE'}, 
  {name: 'United Kingdom', code: 'GB'}, 
  {name: 'United States', code: 'US'}, 
  {name: 'United States Minor Outlying Islands', code: 'UM'}, 
  {name: 'Uruguay', code: 'UY'}, 
  {name: 'Uzbekistan', code: 'UZ'}, 
  {name: 'Vanuatu', code: 'VU'}, 
  {name: 'Venezuela', code: 'VE'}, 
  {name: 'Viet Nam', code: 'VN'}, 
  {name: 'Virgin Islands, British', code: 'VG'}, 
  {name: 'Virgin Islands, U.S.', code: 'VI'}, 
  {name: 'Wallis and Futuna', code: 'WF'}, 
  {name: 'Western Sahara', code: 'EH'}, 
  {name: 'Yemen', code: 'YE'}, 
  {name: 'Zambia', code: 'ZM'}, 
  {name: 'Zimbabwe', code: 'ZW'} 
]