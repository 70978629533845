import * as React from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';

import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import axios from "axios"

import Badge from '@mui/material/Badge';
import ListItemButton from '@mui/material/ListItemButton';
import PersonAddAlt1Icon from '@mui/icons-material/Person';

import Search from '@mui/icons-material/Search';
import MailIcon from '@mui/icons-material/Mail';
import Person from '../images/Ellipse77.png';
import AddBusinessIcon from '@mui/icons-material/Person';
import Close from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Image from '../images/imager.jpg';
// import Avatar from '@mui/material/Avatar';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide'

import swal from 'sweetalert';
import Box from '@mui/material/Box';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { AvatarGroup } from '@mui/material';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_agent } from '../redux/agent';
import { update_bookings } from '../redux/bookings';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction = "up"
    ref = { ref } {...props }
    />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation = { 6 }
    ref = { ref }
    variant = "filled" {...props }
    />;
});



function Profile() {


    const dt = new Date()
    const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    const day = dt.getUTCDate();
    const month = String(dt.getMonth() + 1).padStart(2, '0');
    const year = dt.getFullYear();
    const date = year + "-" + month + "-" + day;

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]



    const Mydata = useSelector((state) => state.admin)
    const booking_ = useSelector((state) => state.bookings)
    const dispatch = useDispatch()
    const [loader, setloader] = React.useState(true)
    var bookings = booking_
    const getrooms = async() => {
        await axios.post("https://cromer.sisrevolution.com/api/bookings/getbookings/", {

        }).then(res => {
            if (res.status === 200) {
                console.log(res)
                dispatch(update_bookings({
                    data: res.data
                }))
            }
        })
    }
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const [open_checkin, setOpen_checkin] = React.useState(false);
    const [open_checkout, setOpen_checkout] = React.useState(false);
    const [open_email, setOpen_email] = React.useState(false);
    const [open_cancel, setOpen_cancel] = React.useState(false);
    const [open_confirm, setOpen_confirm] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
        setOpen_checkout(false);
        setOpen_checkin(false);
        setOpen_email(false);
        setOpen_cancel(false);
        setOpen_confirm(false);

    };

    React.useEffect(() => {
        getrooms()
    }, [loader])

    const [help, sethelp] = React.useState("")
    const [selectedcountry, setselectedcountry] = React.useState("none")
    const [selectedID, setselectedID] = React.useState("none")
    const [roomlist, setroomlist] = React.useState([])
    const [selected, setselected] = React.useState([])
    const [gender, setgender] = React.useState(Mydata.gender)
    const [marital, setmarital] = React.useState(Mydata.marritalstatus)
    const [loading, setloading] = React.useState(false)
    const [message, setmessage] = React.useState("")
    const [stated, setstated] = React.useState("")
    const [searching, setsearching] = React.useState("")
    const [person, setperson] = React.useState(Mydata.image !== undefined || Mydata.image !== null ? "https://cromeradmin.3ammissionministry.com/server/routes/uploads/" + Mydata.image : person)

    const [opensnack, setOpensnack] = React.useState(false);

    const handleClickOpen_checkin = () => {
        setOpen_checkin(true);
    };

    const handleClickOpen_checkout = () => {
        setOpen_checkout(true);
    };

    const handleClickOpen_email = () => {
        setOpen_email(true);
    };
    const handleClickOpen_cancel = () => {
        setOpen_cancel(true);
    };

    const handleClickOpen_confirm = () => {
        setOpen_confirm(true);
    };


    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };


    const changeselect = (e) => {
        setselected(e)
    }

    const converter = (data) => {

        const dt = new Date(data + "")
        const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
        const day = dt.getUTCDate();
        const month = String(dt.getMonth() + 1).padStart(2, '0');
        const year = dt.getFullYear();
        const date = year + "-" + month + "-" + day;

        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

        return months[parseInt((date.split("-"))[1])-1] + " " + (date.split("-"))[2] + ", " + (date.split("-"))[0]


    }

    const searcher = (e) => {

        setsearching(e.target.value)

    }

    if (searching !== "") {

        var data = booking_.filter(bug => (bug.name).toUpperCase().includes(searching.toUpperCase()) || bug.bookingID.includes(searching) || bug.contact.includes(searching))
        bookings = data
    } else {
        bookings = booking_
    }

    const addapartment = (e) => {
        console.log("adder")
        e.preventDefault()
        const file = document.getElementById("fileimage").files[0]
        var name = document.getElementById("apartmentname").value
        var rooms = document.getElementById("rooms").value
        var price = document.getElementById("price").value
        var discount = document.getElementById("discount").value
        var max = document.getElementById("max").value

        const fd = new FormData()
        if (file) {

            fd.append("file", file)
            fd.append("image", fd.append("image", new Date() + "" + name + ".jpg"))
        }

        fd.append("category", name)
        fd.append("rooms", rooms)
        fd.append("price", price)
        fd.append("doscount", discount)
        fd.append("max", max)
        fd.append("specifications", roomlist)
        console.log(fd)
        const apartment = async() => {
            await axios.post("https://cromer.sisrevolution.com/api/rooms/addapartment/", fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            }).then(res => {

                if (res.status === 200) {
                    dispatch(update_bookings({
                            data: res.data
                        }))
                        // setloading(!true)

                    // swal("", " AY Direct Commission Updated ", "success");


                } else {
                    // setloading(!true)
                    // swal("", "Error Updating AY Direct Commission", "error");

                }

            })

        }
        apartment()


    }

    const checkin = (e) => {
        e.preventDefault()
        setloading(true)
        var IDnumber = document.getElementById("IDnumber").value
        var roomnumber = document.getElementById("roomnumber").value

        const apartment = async() => {
            await axios.post("https://cromer.sisrevolution.com/api/bookings/checkin/", {
                name: selected.name,
                category: selected.room[0].category,
                price: selected.room[0].price,
                roomid: selected.room[0]._id,
                _id: selected._id,
                IDtype: selectedID,
                IDnumber: IDnumber,
                nationality: selectedcountry,
                roomnumber: roomnumber,
                status: "Checked-In",
                checkin: { date: date, time: time }


            }).then(res => {

                if (res.status === 200) {
                    setselected(res.data.filter(bug => bug._id === selected._id)[0])

                    dispatch(update_bookings({
                        data: res.data
                    }))
                    setloading(!true)
                    handleClose()
                    swal("", "ChecK-In Successfull", "success");


                } else {
                    setloading(!true)
                    swal("", "Check-In Error", "error");

                }

            })

        }
        apartment()


    }

    const checkout = (e) => {
        e.preventDefault()

        setloading(true)


        const apartment = async() => {
            await axios.post("https://cromer.sisrevolution.com/api/bookings/checkout/", {
                name: selected.name,
                category: selected.room[0].category,
                price: selected.room[0].price,
                roomid: selected.room[0]._id,
                _id: selected._id,
                status: "Checked-Out",
                checkout: { date: date, time: time }

            }).then(res => {

                if (res.status === 200) {
                    setselected(res.data.filter(bug => bug._id === selected._id)[0])
                    dispatch(update_bookings({
                        data: res.data
                    }))
                    setloading(!true)
                    handleClose()

                    swal("", " Check-Out Successfull", "success");


                } else {
                    setloading(!true)
                    swal("", "Check-Out Error", "error");

                }

            })

        }
        apartment()


    }


    const confirm = (e) => {
        e.preventDefault()

        setloading(true)

        const apartment = async() => {
            await axios.post("https://cromer.sisrevolution.com/api/bookings/paybooking/", {
                name: selected.name,
                category: selected.room[0].category,
                price: selected.room[0].price,
                roomid: selected.room[0]._id,
                _id: selected._id,
                payment: {amount:document.getElementById("amountpaid").value, date: date, time: time }

            }).then(res => {

                if (res.status === 200) {
                    setselected(res.data.filter(bug => bug._id === selected._id)[0])
                    dispatch(update_bookings({
                        data: res.data
                    }))
                    setloading(!true)
                    handleClose()

                    swal("", "Payment confirmed", "success");


                } else {
                    setloading(!true)
                    swal("", "Error confirming payment", "error");

                }

            })

        }
        apartment()


    }


    const cancel = (e) => {
        e.preventDefault()

        setloading(true)


        const apartment = async() => {
            await axios.post("https://cromer.sisrevolution.com/api/bookings/cancel/", {
                name: selected.name,
                category: selected.room[0].category,
                price: selected.room[0].price,
                roomid: selected.room[0]._id,
                _id: selected._id,
                status: "Cancelled",
                checkout: { date: date, time: time }

            }).then(res => {

                if (res.status === 200) {
                    setselected(res.data.filter(bug => bug._id === selected._id)[0])
                    dispatch(update_bookings({
                        data: res.data
                    }))
                    setloading(!true)
                    handleClose()

                    swal("", "Cancelled Successfull", "success");


                } else {
                    setloading(!true)
                    swal("", "Cancelling Error", "error");

                }

            })

        }
        apartment()


    }

    const sendemail = (e) => {
        e.preventDefault()

        setloading(true)


        const apartment = async() => {
            await axios.post("https://cromer.sisrevolution.com/api/bookings/sendemail/", {
                name: selected.name,
                email: selected.email,
                subject: document.getElementById("subject").value,
                message: document.getElementById("message").value,
                _id: selected._id,

            }).then(res => {

                if (res.status === 200) {
                    
                    setloading(!true)
                    handleClose()

                    swal("", "Cancelled Successfull", "success");


                } else {
                    setloading(!true)
                    swal("", "Cancelling Error", "error");

                }

            })

        }
        apartment()


    }

    const closenow = (e) => {

        const deleteapartment = async() => {
            await axios.post("https://cromer.sisrevolution.com/api/rooms/deleteroom/", {
                _id: e

            }).then(res => {

                if (res.status === 200) {
                    dispatch(update_bookings({
                        data: res.data
                    }))
                    setselected([])
                        // setloading(!true)

                    // swal("", " AY Direct Commission Updated ", "success");


                } else {
                    // setloading(!true)
                    // swal("", "Error Updating AY Direct Commission", "error");

                }

            })

        }
        deleteapartment()

    }

    const addroom = () => {
        var data = [...roomlist]
        var feature = document.getElementById("feature").value
        data.push(feature)
        setroomlist(data)
    }

    const removeroom = (e) => {
        var data = roomlist.filter(bug => bug !== e)
        setroomlist(data)
    }


    const changer = (selector, changer_) => {
        selector(changer_.target.value)
    }

    return ( <Container >

        <Snackbar open = { opensnack }
        autoHideDuration = { 6000 }
        onClose = { handleClosesnack } >
        <Alert onClose = { handleClosesnack }
        severity = { stated }
        sx = {
            { width: '100%' } } > { message } </Alert> 
            </Snackbar>

        <form>
        <Dialog open = { open }
        TransitionComponent = { Transition }
        keepMounted onClose = { handleClose }
        aria-describedby = "alert-dialog-slide-description" >
        <DialogTitle > { "Add New Apartment" } </DialogTitle> 
        <DialogContent >
        <DialogContentText id = "alert-dialog-slide-description" >

        <input type = "file"
        id = "fileimage"
        style = {
            { margin: "10px" } }
        /> <br/>
        <TextField size = "small"
        type = "text"
        id = "apartmentname"
        label = "Apartment Name"
        style = {
            { margin: "10px" } }
        /> <TextField size = "small"
        type = "number"
        id = "rooms"
        label = "Number Of Rooms "
        style = {
            { margin: "10px" } }
        /> <TextField size = "small"
        type = "number"
        id = "price"
        label = "Price"
        style = {
            { margin: "10px" } }
        /> <TextField size = "small"
        type = "number"
        id = "discount"
        label = "Discount"
        style = {
            { margin: "10px" } }
        /> <TextField size = "small"
        type = "number"
        id = "max"
        label = "Maximum Number of persons"
        style = {
            { margin: "10px" } }
        /> <h3 align = "center" > Room Features </h3> 
        <Grid container spacing = { 2 } >
        <Grid item xs = { 8 } >
        <TextField type = "text"
        id = "feature"
        label = "Room Feature "
        size = "small"
        style = {
            { margin: "10px", width: "100%" } }
        />   </Grid> <Grid item xs = { 4 } >
        <Button variant = "outlined"
        onClick = {
            () => addroom() }
        style = {
            { margin: "10px" } } > Add Feature </Button> </Grid> </Grid> <Divider/>

        { console.log(roomlist) }

        {
            roomlist.map((list, index) =>
                <Grid key = { index }
                container spacing = { 2 } >
                <Grid item xs = { 8 } >
                <p> { list } </p>   
                </Grid> <Grid item xs = { 4 } >
                <IconButton onClick = {
                    () => removeroom(list) }
                style = {
                    { marginTop: "7px" } } > <Close/> </IconButton> 
                    </Grid> </Grid>
            )
        }

        </DialogContentText> </DialogContent> <DialogActions >
        <Button onClick = { addapartment } > Add Apartment </Button> 
        <Button onClick = { handleClose } > Agree </Button> </DialogActions> 
        </Dialog> </form>



        <Dialog open = { open_checkin }
        TransitionComponent = { Transition }
        keepMounted onClose = { handleClose }
        aria-describedby = "alert-dialog-slide-description" >
        <DialogTitle > { "CHECK-IN" } </DialogTitle> <DialogContent >
        <DialogContentText id = "alert-dialog-slide-description" >

        <Select size = "small"
        value = { selectedID }
        id = "IDtype"
        onChange = {
            (e) => changer(setselectedID, e) }
        style = {
            { margin: "10px", width: "40%" } } >
        <MenuItem value = "none" > ID Type </MenuItem> 
        <MenuItem value = "Passport" > Passport </MenuItem> <MenuItem value = "Ghana Card" > Ghana Card </MenuItem> </Select> <TextField size = "small"
        type = "text"
        id = "IDnumber"
        label = "ID Number"
        style = {
            { margin: "10px" } }
        /> <Select size = "small"
        value = { selectedcountry }
        id = "nationality"
        onChange = {
            (e) => changer(setselectedcountry, e) }
        style = {
            { margin: "10px", width: "40%" } } >
        <MenuItem value = "none" > Nationality </MenuItem> {
            country.map((list, index) =>

                <MenuItem value = { list.name } > { list.name } </MenuItem>
            )
        } </Select> <TextField size = "small"
        type = "number"
        id = "roomnumber"
        label = "Room Number "
        style = {
            { margin: "10px" } }
        />


        </DialogContentText> </DialogContent> <DialogActions > {
            loading ? < Button disabled style = {
                { backgroundColor: "gray" } } > Loading... </Button> :
                <Button onClick = { checkin } > Check-In </Button>

        } <Button onClick = { handleClose } > Close </Button> </DialogActions> </Dialog>


        <Dialog open = { open_checkout }
        TransitionComponent = { Transition }
        keepMounted fullWidth maxWidth = "sm"
        onClose = { handleClose }
        aria-describedby = "alert-dialog-slide-description" >
        <DialogTitle > { "CHECK-OUT" } </DialogTitle> <DialogContent >
        <DialogContentText id = "alert-dialog-slide-description" >

        <p> { selected.name } is checking out </p> <p> <b> Date: </b> {months[parseInt((date.split("-"))[1])+1] +" "+(date.split("-"))[2]+", "+(date.split("-"))[0] }</p >
        <p> <b> Time: </b> {time}</p>


        </DialogContentText> </DialogContent> <DialogActions > {
            loading ? < Button disabled style = {
                { backgroundColor: "gray" } } > Loading... </Button> :
                <Button onClick = { checkout } > Check-Out </Button>

        } <Button onClick = { handleClose } > Close </Button> </DialogActions> </Dialog>


        <Dialog open = { open_email }
        TransitionComponent = { Transition }
        keepMounted onClose = { handleClose }
        aria-describedby = "alert-dialog-slide-description" >
        <DialogTitle > { "SEND EMAIL" } </DialogTitle> <DialogContent >
        <DialogContentText id = "alert-dialog-slide-description" >


        <TextField size = "small"
        type = "text"
        id = "subject"
        label = "Subject"
        style = {
            { margin: "10px", width: "95%" } }
        /> <textarea rows = { 8 }
        size = "small"
        type = "text"
        id = "message"
        placeholder = "Enter Message"
        style = {
            { margin: "10px", outline: "none", width: "90%", border: "solid", borderWidth: "0.1px", borderRadius: "5px", borderColor: "lightgray", padding: "10px", fontSize: "16px", fontFamily: "sans-serif" } } > </textarea>


        </DialogContentText> </DialogContent> <DialogActions > {
            loading ? < Button disabled style = {
                { backgroundColor: "gray" } } > Loading... </Button> :
                <Button onClick = { sendemail } > Send Email </Button>

        } <Button onClick = { handleClose } > Close </Button> </DialogActions> </Dialog>



<Dialog open = { open_confirm }
        TransitionComponent = { Transition }
        keepMounted onClose = { handleClose }
        aria-describedby = "alert-dialog-slide-description" >
        <DialogTitle > { "CONFIRM PAYMENT" } </DialogTitle>
        <form onSubmit={confirm }> <DialogContent >
        <DialogContentText id = "alert-dialog-slide-description" >


        <TextField size = "small"
        required
        type = "number"
        id = "amountpaid"
        label = "Amount paid"
        style = {
            { margin: "10px", width: "95%" } }
        /> 
     

        </DialogContentText> </DialogContent> <DialogActions > {
            loading ? < Button disabled style = {
                { backgroundColor: "gray" } } > Loading... </Button> :
                <Button type="submit" > Confirm Payment </Button>

        } <Button onClick = { handleClose } > Close </Button> </DialogActions> 
        
        </form></Dialog>


        <Dialog open = { open_cancel }
        TransitionComponent = { Transition }
        keepMounted onClose = { handleClose }
        aria-describedby = "alert-dialog-slide-description" >
        <DialogTitle > { "CANCEL BOOKING" } </DialogTitle> <DialogContent >
        <DialogContentText id = "alert-dialog-slide-description" >

        <p textAlign = "center" > Are you sure you want to cancel this booking ? </p>

        </DialogContentText> </DialogContent> <DialogActions > {
            loading ? < Button disabled style = {
                { backgroundColor: "gray" } } > Loading... </Button> :
                <Button onClick = { cancel } > Yes </Button>

        } <Button onClick = { handleClose } > Close </Button> </DialogActions> 
        </Dialog>



        <h2 align = "center" > < span style = {
            { color: "#062333", padding: 10, borderRadius: "40px" } } > BOOKINGS </span></h2>

        <Grid container spacing = { 4 } >
        <Grid item xs = { 12 }
        lg = { 4 }
        align = "center" >
        <span style = {
            { position: "absolute", marginTop: "9px", marginLeft: "15px" } } > < Search style = {
            { color: "grey" } }
        /></span > < input onChange = { searcher }
        type = "search"
        placeholder = "Search By Name, Booking ID, Room Type"
        style = {
            { outline: "none", fontSize: "17px", padding: 10, paddingLeft: "40px", width: "100%", borderTopRightRadius: "10px", borderTopLeftRadius: "10px", border: "solid", borderColor: "lightgray", borderWidth: "0.05px", borderBottom: "none" } }
        /> <div className = 'scroller1'
        style = {
            { height: "80vh", padding: 10, borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px", border: "solid", borderColor: "lightgray", borderWidth: "0.05px" } } > {
            bookings.map((list, index) =>
                <div>
                <List style = {
                    { padding: 0, margin: 0 } } >

                <ListItemButton onClick = {
                    () => changeselect(list) }
                secondaryAction = { <IconButton edge = "end"
                    aria-label = "delete" >
                    <DeleteIcon/>
                    </IconButton>
                } >
                <ListItemAvatar >
                <Avatar >
                <AddBusinessIcon/>
                </Avatar> </ListItemAvatar> <ListItemText primary = { list.name }
                secondary = { "BookingID: " + list.bookingID }
                /> </ListItemButton>

                </List> <Divider/>
                </div> 
            )
        }

        </div> </Grid>

        <Grid item xs = { 12 }
        lg = { 8 } >

        <div className = 'scroller1'
        style = {
            { height: "80vh", padding: 10, borderRadius: "10px", border: "solid", borderColor: "lightgray", borderWidth: "0.05px" } } >

        {
            selected.length !== 0 ?

            <Container >
            <h3 textAlign = "center" > Booking Details </h3> 
            <div style = {
                { teaxAlign: "center" } } >
            <Button style = {
                { marginLeft: 10 } }
            variant = "outlined"
            startIcon = { < AddBusinessIcon /> }
            onClick = { handleClickOpen_checkin } > CHECK-IN </Button> 
            <Button style = {
                { marginLeft: 10 } }
            variant = "outlined"
            startIcon = { < AddBusinessIcon /> }
            onClick = { handleClickOpen_checkout } > CHECK-OUT </Button> 

<Button style = {
                { marginLeft: 10 } }
            variant = "outlined"
            color = "success"
            startIcon = { < MailIcon /> }
            onClick = { handleClickOpen_confirm } > Confirm Payment </Button> 

            <Button style = {
                { marginLeft: 10 } }
            variant = "outlined"
            color = "warning"
            startIcon = { < MailIcon /> }
            onClick = { handleClickOpen_email } > E-MAIL </Button> 
            <Button style = {
                { marginLeft: 10, marginTop:10 } }
            variant = "outlined"
            color = "error"
            startIcon = { < Close /> }
            onClick = { handleClickOpen_cancel } > CANCEL </Button>

            </div>       
            <div style = {
                { textAlign: "left" } } > < br/>
            <Divider />
            <b> Status : </b>{selected.status}<br/>
                <b> Date: </b>{selected.checkin.length!==0 && converter(selected.checkin[0].date)} <
                Divider />
                <h3> Person Details </h3> <ListItemText
            primary = { selected.name }
            secondary = "Name" />
            <Divider />

            <ListItemText
            primary = { selected.bookingID }
            secondary = "Booking ID" />
            <Divider />
{console.log(selected)}
            <ListItemText
            primary = { selected.email }
            secondary = "Email" />
            <Divider />

            <ListItemText
            primary = { selected.contact }
            secondary = "Contact" />
            <Divider />


            <ListItemText
            primary = { selected.address }
            secondary = "Address" />

            <h3> Room Details </h3> {
                selected.room.map((list, index) =>
                    <div>
                    <ListItemText primary = { list.category }
                    secondary = "Room Type" />

                    <Divider />
                    <ListItemText primary = { selected.roomnumber }
                    secondary = "Room Number" />

                    <Divider />

                    <ListItemText primary = { "$ " + list.price }
                    secondary = "Charge" />

                    <Divider />

                    <ListItemText primary = { converter(selected.startdate) }
                    secondary = "Start Date" />
                    <Divider />
                    <ListItemText primary = { converter(selected.enddate) }
                    secondary = "End Date" />

                    <Divider />

                    </div>
                )
            }

            {
                /* <ul>
                                    {selected.features.length!==0?
                                selected.features.map((lister, index)=>
                                <li>{lister}</li>
                                ):""    
                                }
                                </ul> */
            }

            </div>

            </Container>

            : ""

        }


        </div> </Grid>
        </Grid> <br/>
        <br />
        <br />
        <br />


        </Container>
    );
}

export default Profile;



const country = [
    { name: 'Afghanistan', code: 'AF' },
    { name: 'Åland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Algeria', code: 'DZ' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'AndorrA', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Anguilla', code: 'AI' },
    { name: 'Antarctica', code: 'AQ' },
    { name: 'Antigua and Barbuda', code: 'AG' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Aruba', code: 'AW' },
    { name: 'Australia', code: 'AU' },
    { name: 'Austria', code: 'AT' },
    { name: 'Azerbaijan', code: 'AZ' },
    { name: 'Bahamas', code: 'BS' },
    { name: 'Bahrain', code: 'BH' },
    { name: 'Bangladesh', code: 'BD' },
    { name: 'Barbados', code: 'BB' },
    { name: 'Belarus', code: 'BY' },
    { name: 'Belgium', code: 'BE' },
    { name: 'Belize', code: 'BZ' },
    { name: 'Benin', code: 'BJ' },
    { name: 'Bermuda', code: 'BM' },
    { name: 'Bhutan', code: 'BT' },
    { name: 'Bolivia', code: 'BO' },
    { name: 'Bosnia and Herzegovina', code: 'BA' },
    { name: 'Botswana', code: 'BW' },
    { name: 'Bouvet Island', code: 'BV' },
    { name: 'Brazil', code: 'BR' },
    { name: 'British Indian Ocean Territory', code: 'IO' },
    { name: 'Brunei Darussalam', code: 'BN' },
    { name: 'Bulgaria', code: 'BG' },
    { name: 'Burkina Faso', code: 'BF' },
    { name: 'Burundi', code: 'BI' },
    { name: 'Cambodia', code: 'KH' },
    { name: 'Cameroon', code: 'CM' },
    { name: 'Canada', code: 'CA' },
    { name: 'Cape Verde', code: 'CV' },
    { name: 'Cayman Islands', code: 'KY' },
    { name: 'Central African Republic', code: 'CF' },
    { name: 'Chad', code: 'TD' },
    { name: 'Chile', code: 'CL' },
    { name: 'China', code: 'CN' },
    { name: 'Christmas Island', code: 'CX' },
    { name: 'Cocos (Keeling) Islands', code: 'CC' },
    { name: 'Colombia', code: 'CO' },
    { name: 'Comoros', code: 'KM' },
    { name: 'Congo', code: 'CG' },
    { name: 'Congo, The Democratic Republic of the', code: 'CD' },
    { name: 'Cook Islands', code: 'CK' },
    { name: 'Costa Rica', code: 'CR' },
    { name: 'Cote D\'Ivoire', code: 'CI' },
    { name: 'Croatia', code: 'HR' },
    { name: 'Cuba', code: 'CU' },
    { name: 'Cyprus', code: 'CY' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Djibouti', code: 'DJ' },
    { name: 'Dominica', code: 'DM' },
    { name: 'Dominican Republic', code: 'DO' },
    { name: 'Ecuador', code: 'EC' },
    { name: 'Egypt', code: 'EG' },
    { name: 'El Salvador', code: 'SV' },
    { name: 'Equatorial Guinea', code: 'GQ' },
    { name: 'Eritrea', code: 'ER' },
    { name: 'Estonia', code: 'EE' },
    { name: 'Ethiopia', code: 'ET' },
    { name: 'Falkland Islands (Malvinas)', code: 'FK' },
    { name: 'Faroe Islands', code: 'FO' },
    { name: 'Fiji', code: 'FJ' },
    { name: 'Finland', code: 'FI' },
    { name: 'France', code: 'FR' },
    { name: 'French Guiana', code: 'GF' },
    { name: 'French Polynesia', code: 'PF' },
    { name: 'French Southern Territories', code: 'TF' },
    { name: 'Gabon', code: 'GA' },
    { name: 'Gambia', code: 'GM' },
    { name: 'Georgia', code: 'GE' },
    { name: 'Germany', code: 'DE' },
    { name: 'Ghana', code: 'GH' },
    { name: 'Gibraltar', code: 'GI' },
    { name: 'Greece', code: 'GR' },
    { name: 'Greenland', code: 'GL' },
    { name: 'Grenada', code: 'GD' },
    { name: 'Guadeloupe', code: 'GP' },
    { name: 'Guam', code: 'GU' },
    { name: 'Guatemala', code: 'GT' },
    { name: 'Guernsey', code: 'GG' },
    { name: 'Guinea', code: 'GN' },
    { name: 'Guinea-Bissau', code: 'GW' },
    { name: 'Guyana', code: 'GY' },
    { name: 'Haiti', code: 'HT' },
    { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
    { name: 'Holy See (Vatican City State)', code: 'VA' },
    { name: 'Honduras', code: 'HN' },
    { name: 'Hong Kong', code: 'HK' },
    { name: 'Hungary', code: 'HU' },
    { name: 'Iceland', code: 'IS' },
    { name: 'India', code: 'IN' },
    { name: 'Indonesia', code: 'ID' },
    { name: 'Iran, Islamic Republic Of', code: 'IR' },
    { name: 'Iraq', code: 'IQ' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Isle of Man', code: 'IM' },
    { name: 'Israel', code: 'IL' },
    { name: 'Italy', code: 'IT' },
    { name: 'Jamaica', code: 'JM' },
    { name: 'Japan', code: 'JP' },
    { name: 'Jersey', code: 'JE' },
    { name: 'Jordan', code: 'JO' },
    { name: 'Kazakhstan', code: 'KZ' },
    { name: 'Kenya', code: 'KE' },
    { name: 'Kiribati', code: 'KI' },
    { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
    { name: 'Korea, Republic of', code: 'KR' },
    { name: 'Kuwait', code: 'KW' },
    { name: 'Kyrgyzstan', code: 'KG' },
    { name: 'Lao People\'S Democratic Republic', code: 'LA' },
    { name: 'Latvia', code: 'LV' },
    { name: 'Lebanon', code: 'LB' },
    { name: 'Lesotho', code: 'LS' },
    { name: 'Liberia', code: 'LR' },
    { name: 'Libyan Arab Jamahiriya', code: 'LY' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Lithuania', code: 'LT' },
    { name: 'Luxembourg', code: 'LU' },
    { name: 'Macao', code: 'MO' },
    { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
    { name: 'Madagascar', code: 'MG' },
    { name: 'Malawi', code: 'MW' },
    { name: 'Malaysia', code: 'MY' },
    { name: 'Maldives', code: 'MV' },
    { name: 'Mali', code: 'ML' },
    { name: 'Malta', code: 'MT' },
    { name: 'Marshall Islands', code: 'MH' },
    { name: 'Martinique', code: 'MQ' },
    { name: 'Mauritania', code: 'MR' },
    { name: 'Mauritius', code: 'MU' },
    { name: 'Mayotte', code: 'YT' },
    { name: 'Mexico', code: 'MX' },
    { name: 'Micronesia, Federated States of', code: 'FM' },
    { name: 'Moldova, Republic of', code: 'MD' },
    { name: 'Monaco', code: 'MC' },
    { name: 'Mongolia', code: 'MN' },
    { name: 'Montserrat', code: 'MS' },
    { name: 'Morocco', code: 'MA' },
    { name: 'Mozambique', code: 'MZ' },
    { name: 'Myanmar', code: 'MM' },
    { name: 'Namibia', code: 'NA' },
    { name: 'Nauru', code: 'NR' },
    { name: 'Nepal', code: 'NP' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'Netherlands Antilles', code: 'AN' },
    { name: 'New Caledonia', code: 'NC' },
    { name: 'New Zealand', code: 'NZ' },
    { name: 'Nicaragua', code: 'NI' },
    { name: 'Niger', code: 'NE' },
    { name: 'Nigeria', code: 'NG' },
    { name: 'Niue', code: 'NU' },
    { name: 'Norfolk Island', code: 'NF' },
    { name: 'Northern Mariana Islands', code: 'MP' },
    { name: 'Norway', code: 'NO' },
    { name: 'Oman', code: 'OM' },
    { name: 'Pakistan', code: 'PK' },
    { name: 'Palau', code: 'PW' },
    { name: 'Palestinian Territory, Occupied', code: 'PS' },
    { name: 'Panama', code: 'PA' },
    { name: 'Papua New Guinea', code: 'PG' },
    { name: 'Paraguay', code: 'PY' },
    { name: 'Peru', code: 'PE' },
    { name: 'Philippines', code: 'PH' },
    { name: 'Pitcairn', code: 'PN' },
    { name: 'Poland', code: 'PL' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Puerto Rico', code: 'PR' },
    { name: 'Qatar', code: 'QA' },
    { name: 'Reunion', code: 'RE' },
    { name: 'Romania', code: 'RO' },
    { name: 'Russian Federation', code: 'RU' },
    { name: 'RWANDA', code: 'RW' },
    { name: 'Saint Helena', code: 'SH' },
    { name: 'Saint Kitts and Nevis', code: 'KN' },
    { name: 'Saint Lucia', code: 'LC' },
    { name: 'Saint Pierre and Miquelon', code: 'PM' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC' },
    { name: 'Samoa', code: 'WS' },
    { name: 'San Marino', code: 'SM' },
    { name: 'Sao Tome and Principe', code: 'ST' },
    { name: 'Saudi Arabia', code: 'SA' },
    { name: 'Senegal', code: 'SN' },
    { name: 'Serbia and Montenegro', code: 'CS' },
    { name: 'Seychelles', code: 'SC' },
    { name: 'Sierra Leone', code: 'SL' },
    { name: 'Singapore', code: 'SG' },
    { name: 'Slovakia', code: 'SK' },
    { name: 'Slovenia', code: 'SI' },
    { name: 'Solomon Islands', code: 'SB' },
    { name: 'Somalia', code: 'SO' },
    { name: 'South Africa', code: 'ZA' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { name: 'Spain', code: 'ES' },
    { name: 'Sri Lanka', code: 'LK' },
    { name: 'Sudan', code: 'SD' },
    { name: 'Suriname', code: 'SR' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    { name: 'Swaziland', code: 'SZ' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'Syrian Arab Republic', code: 'SY' },
    { name: 'Taiwan, Province of China', code: 'TW' },
    { name: 'Tajikistan', code: 'TJ' },
    { name: 'Tanzania, United Republic of', code: 'TZ' },
    { name: 'Thailand', code: 'TH' },
    { name: 'Timor-Leste', code: 'TL' },
    { name: 'Togo', code: 'TG' },
    { name: 'Tokelau', code: 'TK' },
    { name: 'Tonga', code: 'TO' },
    { name: 'Trinidad and Tobago', code: 'TT' },
    { name: 'Tunisia', code: 'TN' },
    { name: 'Turkey', code: 'TR' },
    { name: 'Turkmenistan', code: 'TM' },
    { name: 'Turks and Caicos Islands', code: 'TC' },
    { name: 'Tuvalu', code: 'TV' },
    { name: 'Uganda', code: 'UG' },
    { name: 'Ukraine', code: 'UA' },
    { name: 'United Arab Emirates', code: 'AE' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'United States', code: 'US' },
    { name: 'United States Minor Outlying Islands', code: 'UM' },
    { name: 'Uruguay', code: 'UY' },
    { name: 'Uzbekistan', code: 'UZ' },
    { name: 'Vanuatu', code: 'VU' },
    { name: 'Venezuela', code: 'VE' },
    { name: 'Viet Nam', code: 'VN' },
    { name: 'Virgin Islands, British', code: 'VG' },
    { name: 'Virgin Islands, U.S.', code: 'VI' },
    { name: 'Wallis and Futuna', code: 'WF' },
    { name: 'Western Sahara', code: 'EH' },
    { name: 'Yemen', code: 'YE' },
    { name: 'Zambia', code: 'ZM' },
    { name: 'Zimbabwe', code: 'ZW' }
]