import React, {useEffect} from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import swal from 'sweetalert';

import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';

import axios from "axios"
import Snack from "./snack"


import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';



import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_motor } from '../redux/motor';
import { update_travel } from '../redux/travel';
import { update_settings } from '../redux/settings';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Profile3(props) {
    const dispatch = useDispatch()
 
    const getsettingsdata = async () => {
        await axios.post("https://server.ayconsultgh.com/api/settings/getsettings/",
            {

            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_settings({
                        data: res.data
                    }))
                }
            })
    }


    const theme = useTheme();
    const [age, setAge] = React.useState('');
    const [pager, setpager] = React.useState(true);
    const [loading, setloading] = React.useState(false)
    const [message, setmessage] = React.useState("")
    const [stated, setstated] = React.useState("")
    const [beneficiarydata, setbeneficiarydata] = React.useState([]);
    const [worknature, setworknature] = React.useState("none");
    const [currency, setcurrency] = React.useState("none");
    const [transact, settransact] = React.useState("no");
    const [premium, setpremium] = React.useState("");
    const [agenttype, setagenttype] = React.useState( JSON.parse(localStorage.getItem("user")).type === "Agent"? "":"Direct" );

    const [opensnack, setOpensnack] = React.useState(false);

  const Agent = useSelector((state) => state.agent)
  const settings = useSelector((state) => state.settings)

  useEffect(() => {
 
        getsettingsdata()
      
  },[pager])

        const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };


    const submitting=(e)=>{
       e.preventDefault()
      handleClickOpendelete()
    }


    const yessubmit=(e)=>{
             e.preventDefault()

      setloading(true)
      const name= document.getElementById("name").value
      const contact= document.getElementById("contact").value
      const email= document.getElementById("email").value
      const occupation= document.getElementById("occupation").value
      const address= document.getElementById("address").value
      const charge= document.getElementById("charge").value
      const startdate= document.getElementById("startdate").value
      const enddate= document.getElementById("enddate").value
      const suminsured= document.getElementById("suminsured").value
      const work_ = worknature
      const currency_ = currency


      var agentname_="" 
      var agentcontact_=""
      var agentIDnumber_=""
      var agentemail_=""
      var agentcommission_=""
      var aycommission_=""
      var institution_=""

      if(agenttype===""){
        agentname_=Agent.name
        agentcontact_=Agent.contact
        agentIDnumber_=Agent.IDnumber
        agentemail_=Agent.email
        institution_=Agent.institution
        agentcommission_=parseFloat(charge)*parseFloat(settings.length!==0?settings[0].agentcommission:0) -(parseFloat(charge)*parseFloat(settings.length!==0?settings[0].agentcommission:0) * parseFloat(settings.length!==0?settings[0].tax:0))
        aycommission_=parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission:0) - (parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission:0)*parseFloat(settings.length!==0?settings[0].tax:0))
              

      }else if(agenttype==="Direct"){
        agentname_=JSON.parse(localStorage.getItem("user")).name
        agentcontact_=JSON.parse(localStorage.getItem("user")).contact
        agentIDnumber_="Direct"
        institution_="Direct"
        agentemail_=JSON.parse(localStorage.getItem("user")).email
        agentcommission_= 0
        aycommission_= parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission_direct:0) - (parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission_direct:0)*parseFloat(settings.length!==0?settings[0].tax:0))
            
      }else if(agenttype==="Indirect"){
        agentname_= document.getElementById("agentname").value
        agentcontact_= document.getElementById("agentcontact").value
        agentIDnumber_= document.getElementById("agentcontact").value
        agentemail_= document.getElementById("agentemail").value
        institution_="Indirect"
        agentcommission_=parseFloat(charge)*parseFloat(settings.length!==0?settings[0].agentcommission:0) -(parseFloat(charge)*parseFloat(settings.length!==0?settings[0].agentcommission:0) * parseFloat(settings.length!==0?settings[0].tax:0))
        aycommission_=parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission:0) - (parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission:0)*parseFloat(settings.length!==0?settings[0].tax:0))
            

      }

      const buyingpolicy = async()=>{
             await axios.post("https://server.ayconsultgh.com/api/accidentdata/registeraccidentpolicy/",
            {

              name:name,
              contact:contact,
              email:email,
              occupation:occupation,
              address:address,
              charge:charge,
              worknature:work_,
              startdate:startdate,
              enddate:enddate,
             
              commissionpayment:"none",
              currency:currency_,
              transactionID:document.getElementById("transactionID").value,
              suminsured:suminsured,
              beneficiary:beneficiarydata,

              agentname:agentname_, 
              agentcontact:agentcontact_,
              agentID:agentIDnumber_,
              agentemail:agentemail_,
              agentcommission:agentcommission_,
              aycommission:aycommission_,
              institution:institution_,


            }).then(res => {

                if (res.status === 200) {

                        setloading(!true)
                       swal("", " Accident Policy Added ", "success");

                      document.getElementById("ll").reset()
                      handleClosedelete()
                }else{
                      setloading(!true)
                     swal("", " Error adding Accident Policy", "error");
                }
               
            })

    }

    if((document.getElementById("transactionID").value).length>10 && (document.getElementById("transactionID").value).length<13){
     buyingpolicy()

    }else{
         setloading(!true)
         settransact(false)

    }

  }



    const handleChangeselector = (event) => {
        setAge(event.target.value);
    };
  const handleChangeworknature = (event) => {
        setworknature(event.target.value);
    };

 const handleChangecurrency = (event) => {
        setcurrency(event.target.value);
    };

 const handleChangeagenttype = (event) => {
        setagenttype(event.target.value);
    };


   const addbeneficiary=()=>{
   
   const name = document.getElementById("beneficiaryname").value
   const relationship = document.getElementById("beneficiaryrelationship").value
   const contact = document.getElementById("beneficiarycontact").value
   var ben = {name:name, relationship:relationship, contact:contact}
      setbeneficiarydata([...beneficiarydata,ben])
    }

const deletebeneficiary=(list)=>{
  var ben= beneficiarydata.filter(bug=>bug!==list)
  setbeneficiarydata([...ben])

}
const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClosesnack}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClosesnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

      const [opendelete, setOpendelete] = React.useState(!true);


    const handleClickOpendelete = () => {
        setOpendelete(true);
    };
    const handleClosedelete = () => {
        setOpendelete(false);
    };

    const changepremium =(e)=>{
      setpremium(e.target.value)
    }
 
    return (
      <div>


      
            <Dialog maxWidth="md" onClose={handleClosedelete} aria-labelledby="customized-dialog-title" open={opendelete}>
<Button autoFocus onClick={handleClosedelete} style={{float:"right"}} color="primary">
                        close
                    </Button>
<form onSubmit={yessubmit}>
                <DialogContent dividers>
                <h2 align="center">Make Payment</h2>

<p><b>Payment Instructions</b></p>
<p>Please send your Premium to the merchant number provided and provide the transaction ID the forms below.</p>
<h2>Premium : GHC {premium}</h2>
<h2>Send to : 024 0511421</h2>
                   <FormControl align="left" style={{ margin: 10 }}>
              <b>Transaction ID <span style={{ color: "red" }}> *</span></b>
              <TextField
                required
                id="transactionID"
                style={{ width: "28ch" }}
                type="text"
                placeholder="Transaction ID"
                size="small"
                inputProps={{ maxLength:12 }}
              helperText={transact===false?<span style={{color:"red"}}>Incorrect Transaction ID</span>:""}

              /> </FormControl>

                </DialogContent>
                <DialogActions>
                  {!loading?
              <input type="submit" value="BUY POLICY" style={{ padding: 13.5, cursor: "pointer", borderRadius: 5, outline: "none", border: "none", backgroundColor: "#062333", color: "white", width: "35ch", marginTop: "10px", marginBottom: "4px" }} />
:
              <Button size="large" disabled startIcon={<CircularProgress style={{ color: "#062333", width: "20px", height: "20px" }} />} style={{ backgroundColor: "lightgrey", color: "#062333", width: "35ch", marginTop: "10px", marginBottom: "8px" }} >Loading...</Button>
} 
                </DialogActions>
                </form>
            </Dialog>



     
    
<div >
          <form align="left" onSubmit={submitting} id='ll'>
          
          
          {JSON.parse(localStorage.getItem("user")).type==="Admin"?
          <div>
          <div  align="center" style={{margin:10}}>
     <FormControl>
 <FormLabel id="demo-row-radio-buttons-group-label">Choose Agent Type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby = "demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                id = "guardianedit"
                                value={agenttype}
                                onChange={handleChangeagenttype}
                            >
                                <FormControlLabel value="Direct" control={<Radio />} label="Head Office" />
                                <FormControlLabel value="Indirect" control={<Radio />} label="Kumasi Agent" />
                                
                            </RadioGroup>
                        </FormControl>
</div>
{agenttype==="Indirect"?

<div>
  
            <FormControl align="left" style={{ margin: 10 }}>
              <b>Agent Name <span style={{ color: "red" }}> *</span></b>
              <TextField
                
                id="agentname"
                style={{ width: "28ch" }}
                type="text"
                placeholder="Enter Full Name"
                size="small"

              /> </FormControl>

            <FormControl align="left" style={{ margin: 10 }}>
              <b>Agent Contact <span style={{ color: "red" }}> *</span></b>
              <TextField
                
                id="agentcontact"
                style={{ width: "28ch" }}
                type="tel"
                placeholder="Enter Contact Number"
                size="small"

              /> </FormControl>

            <FormControl align="left" style={{ margin: 10 }}>
              <b>Agent Email <span style={{ color: "red" }}> *</span></b>
              <TextField
                
                id="agentemail"
                style={{ width: "28ch" }}
                type="tel"
                placeholder="Enter Agent Email"
                size="small"
              /> </FormControl>

</div>
:""
}</div>:""
          }



            <Divider style={{ marginBottom: "22px", backgroundColor: "#062333" }}>
              <Chip label="PERSONAL DETAILS" style={{ backgroundColor: "white", color: "#062333" }} />
            </Divider>

            <FormControl align="left" style={{ margin: 10 }}>
              <b>Name <span style={{ color: "red" }}> *</span></b>
              <TextField
                required
                id="name"
                style={{ width: "28ch" }}
                type="text"
                placeholder="Enter Full Name"
                size="small"

              /> </FormControl>

            <FormControl align="left" style={{ margin: 10 }}>
              <b>Phone <span style={{ color: "red" }}> *</span></b>
              <TextField
                required
                id="contact"
                style={{ width: "28ch" }}
                type="tel"
                placeholder="Enter Contact"
                size="small"

              /> </FormControl>
            <FormControl align="left" style={{ margin: 10 }}>
              <b>Email Address <span style={{ color: "red" }}> *</span></b>
              <TextField
                required
                id="email"
                style={{ width: "28ch" }}
                type="email"
                placeholder="Enter Email"
                size="small"

              /> </FormControl>
            <FormControl align="left" style={{ margin: 10 }}>
              <b>Occupation <span style={{ color: "red" }}> *</span></b>
              <TextField
                required
                id="occupation"
                style={{ width: "28ch" }}
                type="text"
                placeholder="Occupation"
                size="small"

              /> </FormControl>
            <FormControl align="left" style={{ margin: 10 }}>
              <b>Address <span style={{ color: "red" }}> *</span></b>
              <TextField
                required
                id="address"
                style={{ width: "28ch" }}
                type="address"
                placeholder="Address"
                size="small"

              /> </FormControl>

            <FormControl align="left" style={{ margin: 10 }}>
              <b>Nature Of Work <span style={{ color: "red" }}> *</span></b>

              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={worknature}
                size="small"
                style={{ width: "28ch" }}
                onChange={handleChangeworknature}
              >
                <MenuItem value="none">
                  <em>Choose Nature of work</em>
                </MenuItem>
                <MenuItem value="Clerical">Clerical</MenuItem>
                <MenuItem value="Non Clerical">Non Clerical</MenuItem>
                <MenuItem value="Ofshore">Ofshore</MenuItem>
              </Select>
            </FormControl>


            <br />
            <br />
            <br />
            <Divider style={{ marginBottom: "22px", backgroundColor: "#062333" }}>
              <Chip label="POLICY DETAILS" style={{ backgroundColor: "white", color: "#062333" }} />
            </Divider>



            <FormControl align="left" style={{ margin: 10 }}>
              <b>Start Date <span style={{ color: "red" }}> *</span></b>
              <TextField
                required
                id="startdate"
                style={{ width: "28ch" }}
                type="date"
                placeholder="Address"
                size="small"

              /> </FormControl>

            <FormControl align="left" style={{ margin: 10 }}>
              <b>End Date <span style={{ color: "red" }}> *</span></b>
              <TextField
                required
                id="enddate"
                style={{ width: "28ch" }}
                type="date"
                placeholder="Address"
                size="small"

              /> </FormControl>
            <FormControl align="left" style={{ margin: 10 }}>
              <b>Sum Issued <span style={{ color: "red" }}> *</span></b>
              <TextField
                required
                id="suminsured"
                style={{ width: "28ch" }}
                type="number"
                placeholder="Sum Insured"
                size="small"

              /> </FormControl>


            <FormControl align="left" style={{ margin: 10 }}>
              <b>Currency <span style={{ color: "red" }}> *</span></b>

              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={currency}
                size="small"
                style={{ width: "28ch" }}
                onChange={handleChangecurrency}
              >
                <MenuItem value="none">
                  <em>Choose Currency</em>
                </MenuItem>
                <MenuItem value="Ghana Cedis">Ghana Cedis</MenuItem>
              </Select>
            </FormControl>

            <br />
            <br />
            <Divider style={{ marginBottom: "22px", backgroundColor: "#062333" }}>
              <Chip label="BENEFICIARY DETAILS" style={{ backgroundColor: "white", color: "#062333" }} />
            </Divider>

            <div>

              <FormControl align="left" style={{ margin: 10 }}>
                <b>Beneficiary   <span style={{ color: "red" }}> *</span></b>
                <TextField
                  required
                  id="beneficiaryname"
                  style={{ width: "28ch" }}
                  type="text"
                  placeholder="Enter beneficiary name"
                  size="small"

                /> </FormControl>


              <FormControl align="left" style={{ margin: 10 }}>
                <b>Beneficiary Relationship <span style={{ color: "red" }}> *</span></b>
                <TextField
                  required
                  id="beneficiaryrelationship"
                  style={{ width: "28ch" }}
                  type="text"
                  placeholder="Enter beneficiary relationship"
                  size="small"

                /> </FormControl>


              <FormControl align="left" style={{ margin: 10 }}>
                <b>Beneficiary Contact <span style={{ color: "red" }}> *</span></b>
                <TextField
                  required
                  id="beneficiarycontact"
                  style={{ width: "28ch" }}
                  type="tel"
                  placeholder="Enter beneficiary contact"
                  size="small"

                /> </FormControl>

              <Button style={{ marginTop: "33px", padding: "9px", backgroundColor: "lightgray" }} onClick={() => addbeneficiary()} size="small">Add</Button>

              {beneficiarydata.length !== 0 ? <Container style={{ backgroundColor: "lightgray", marginTop: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={3}><b>Beneficiary</b></Grid>
                  <Grid item xs={3}><b>Relationship</b></Grid>
                  <Grid item xs={3}><b>Phone Number</b></Grid>

                </Grid>
                {beneficiarydata.map((list, index) =>
                  <Grid key={index} container spacing={2} style={{ paddingTop: 10 }}>
                    <Grid item xs={3}>{list.name}</Grid>
                    <Grid item xs={3}>{list.relationship}</Grid>
                    <Grid item xs={3}>{list.contact}</Grid>
                    <Grid item xs={3}><IconButton onClick={() => deletebeneficiary(list)} style={{ marginTop: "-4px", }} size="small"><DeleteIcon /></IconButton>
                    </Grid>
                  </Grid>
                )
                }


              </Container> : ""}

<FormControl align="left" style={{ margin: 10 }}>
                <b>Premium <span style={{ color: "red" }}> *</span></b>
                <TextField
                  required
                  id="charge"
                  style={{ width: "28ch" }}
                  type="text"
                  onChange={changepremium}

                  placeholder="Enter Amount"
                  size="small"

                /> </FormControl>


            </div>

            <br />










            <div align="center">
{!loading?
              <input type="submit" value="MAKE PAYMENT" style={{ padding: 13.5, cursor: "pointer", borderRadius: 5, outline: "none", border: "none", backgroundColor: "#062333", color: "white", width: "35ch", marginTop: "10px", marginBottom: "40px" }} />
:
              <Button size="large" disabled startIcon={<CircularProgress style={{ color: "#062333", width: "20px", height: "20px" }} />} style={{ backgroundColor: "lightgrey", color: "#062333", width: "35ch", marginTop: "10px", marginBottom: "8px" }} >Loading...</Button>
}
            </div>
            <br />
            <br />
            <br />
          </form>


</div>
</div>
         
    );
}

export default Profile3;
