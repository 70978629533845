import React, {useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { PDFReader } from 'react-read-pdf';
import swal from 'sweetalert';

import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import Backdrop from '@mui/material/Backdrop';

import FormControlLabel from '@mui/material/FormControlLabel';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import axios from "axios"

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";


import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_motor } from '../redux/motor';
import { update_travel } from '../redux/travel';
import { update_settings } from '../redux/settings';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function Profile1(props) {
const Agent = useSelector((state) => state.agent)
const [openpage, setOpenpage] = React.useState(true);

const dt = new Date()
const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
const day = dt.getUTCDate();
const month = String(dt.getMonth() + 1).padStart(2, '0');
const year = dt.getFullYear();
const date = year + "-" + month + "-" + day;
const last2 = new Date().getFullYear().toString().slice(-2)

  
     const dispatch = useDispatch()
 
    const getsettingsdata = async () => {
        await axios.post("https://server.ayconsultgh.com/api/settings/getsettings/",
            {

            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_settings({
                        data: res.data
                    }))
                                        setOpenpage(!true)

                }
            })
    }

    const theme = useTheme();
    const [age, setAge] = React.useState('');

    const [opensubscribe, setOpensubscribe] = React.useState(false)
    const [buyagent, setbuyagent] = React.useState("none")
    const [pager, setpager] = React.useState(true)
    const [policytype, setpolicytype] = React.useState("none")
    const [currency, setcurrency] = React.useState("none")
    const [vehicleusage, setvehicleusage] = React.useState("none")
    const [branch, setbranch] = React.useState("none")
    const [bodytype, setbodytype] = React.useState("none")
    const [buyextra, setbuyextra] = React.useState("none")
    const [buyback, setbuyback] = React.useState("none")
    const [loading, setloading] = React.useState(false)
    const [message, setmessage] = React.useState("")
    const [stated, setstated] = React.useState("")
    const [premium_, setpremium_] = React.useState(0)
    const [premiumdata, setpremiumdata] = React.useState([])
    const [setters, setsetters] = React.useState("notdone")
    const [caryear, setcaryear] = React.useState(2018)
    const [seating_, setseating_] = React.useState(5)
    const [personemail, setpersonemail] = React.useState("none")
    const [persondob, setpersondob] = React.useState("none")
    const [enddate, setenddate] = React.useState("none")
    const [transact, settransact] = React.useState("no")
    const [personoccupation, setpersonoccupation] = React.useState("none")
    const [persontppdamount, setpersontppdamount] = React.useState("none")
    const [period, setperiod] = React.useState(12)
    const [agenttype, setagenttype] = React.useState( JSON.parse(localStorage.getItem("user")).type === "Agent"? "":"Direct" );
    const [price_, setprice_] = React.useState(0)
    const [radioval, setradioval] = React.useState("12")

    const [opensnack, setOpensnack] = React.useState(false);

    const settings = useSelector((state) => state.settings)

    const changeradio=(e)=>{
        setradioval(e.target.value)
    }

  useEffect(() => {
 
        getsettingsdata()
      
  },[pager])

    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpensnack(false);
    };

    const handleChangeselector = (event) => {
        setAge(event.target.value);
    };

    const handleChangebuyback=(event) => {
        setbuyback(event.target.value);
    };  
    
    const handleChangebuyextra=(event) => {
        setbuyextra(event.target.value);
    };
      const handleChangebodytype=(event) => {
        setbodytype(event.target.value);
    };
    
    const handleChangebranch=(event) => {
        setbranch(event.target.value);
    };
    const handleChangevehicleusage=(event) => {
        setvehicleusage(event.target.value);
    }; 
    const changepremium=(event) => {
        setpremium_(event.target.value);
    };
    const handleChangecurrency=(event) => {
        setcurrency(event.target.value);
    };

    const handleChangepolicytype=(event) => {
        setpolicytype(event.target.value);
    };

    const handleChangebuyagent=(event) => {
        setbuyagent(event.target.value);
    }; 
    
    const handleChangecaryear=(event) => {
        setcaryear(event.target.value);
    };
  const seating=(event) => {
        setseating_(event.target.value);
    };

       const submitforms=(e)=>{
       e.preventDefault()
      handleClickOpendelete()
    }


    const yessubmit = (e) =>{
      setloading(true)
     e.preventDefault()
      const name = document.getElementById("name").value 
      const contact = document.getElementById("contact").value 
      const email = personemail 
      const dob = persondob
      const occupation = personoccupation
      const address = document.getElementById("address").value 
      const agentbuy_ = buyagent
      const policytype_ = policytype
      const currency_ = currency
      const vehicleusage_ = vehicleusage
      const branch_ = branch
      const bodytype_ = bodytype
      const vehiclemake = document.getElementById("vehiclemake").value 
      const model = document.getElementById("model").value 
      const chasis = document.getElementById("chasis").value 
      const cc = document.getElementById("cc").value 
      const sc = document.getElementById("sc").value 
      const registration = document.getElementById("registration").value 
      const color = document.getElementById("color").value 
      const charge = document.getElementById("premium").value 
      const myear = document.getElementById("myear").value 
      const startdate = document.getElementById("startdate").value 
      const enddate = document.getElementById("enddate").value 
      const buyextra_ = buyextra 
      const buyback_ = buyback 
      const extratppd = persontppdamount 
      const registrationyear = document.getElementById("registrationyear").value 

      var agentname_="" 
      var agentcontact_=""
      var agentIDnumber_=""
      var agentemail_=""
      var agentcommission_=""
      var aycommission_=""
      var institution_=""

      if(agenttype===""){
        agentname_=Agent.name
        agentcontact_=Agent.contact
        agentIDnumber_=Agent.IDnumber
        agentemail_=Agent.email
        institution_=Agent.institution
        agentcommission_=parseFloat(charge)*parseFloat(settings.length!==0?settings[0].agentcommission:0) -(parseFloat(charge)*parseFloat(settings.length!==0?settings[0].agentcommission:0) * parseFloat(settings.length!==0?settings[0].tax:0))
        aycommission_=parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission:0) - (parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission:0)*parseFloat(settings.length!==0?settings[0].tax:0))
              
      }else if(agenttype==="Direct"){
        agentname_=JSON.parse(localStorage.getItem("user")).name
        agentcontact_=JSON.parse(localStorage.getItem("user")).contact
        agentIDnumber_="Direct"
        institution_="Direct"
        agentemail_=JSON.parse(localStorage.getItem("user")).email
        agentcommission_= 0
        aycommission_= parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission_direct:0) - (parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission_direct:0)*parseFloat(settings.length!==0?settings[0].tax:0))
            
      }else if(agenttype==="Indirect"){
        agentname_= document.getElementById("agentname").value
        agentcontact_= document.getElementById("agentcontact").value
        agentIDnumber_= "Indirect"
        agentemail_= document.getElementById("agentemail").value
        institution_="Indirect"
        agentcommission_=parseFloat(charge)*parseFloat(settings.length!==0?settings[0].agentcommission:0) -(parseFloat(charge)*parseFloat(settings.length!==0?settings[0].agentcommission:0) * parseFloat(settings.length!==0?settings[0].tax:0))
        aycommission_=parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission:0) - (parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission:0)*parseFloat(settings.length!==0?settings[0].tax:0))
            
      }

      
    const buyingpolicy= async () => {
        await axios.post("https://server.ayconsultgh.com/api/motordata/registermotorpolicy/",
            {

              name:name,
              contact:contact,
              email:email,
              dob:dob,
              occupation:occupation,
              address:address,
              agentbuy:"no",
              policytype:policytype_,
              currency:currency_,
              usage:vehicleusage_,
              branch:"Kumasi",
              make:vehiclemake,
              model:model,
              bodytype:bodytype_,
              chasis:chasis,
              cc:cc,
              seats:sc,
              commissionpayment:"none",
              transactionID:document.getElementById("transactionID").value,
              registration:registration,
              color:color,
              charge:charge,
              year:myear,
              value:price,
              startdate:startdate,
              enddate:enddate,
              TPPD:buyextra_,
              TPPDamount:extratppd,
              backexcess:buyback_, 
              institution:institution_,
              registrationyear:registrationyear,
              agentname:agentname_, 
              agentcontact:agentcontact_,
              agentID:agentIDnumber_,
              agentemail:agentemail_,
              agentcommission:agentcommission_,
              aycommission:aycommission_,

            }).then(res => {

                if (res.status === 200) {

                        setloading(!true)
                        swal("", " Motor Policy Added", "success");
                        
                        document.getElementById("ll").reset()
                        handleClosedelete()

                }else{
                      setloading(!true)
                   swal("", "Error adding Motor Policy", "error");

                       
                }
               
                

            })

    }

      if((document.getElementById("transactionID").value).length>10 && (document.getElementById("transactionID").value).length<13){
     buyingpolicy()

    }else{
         setloading(!true)
         settransact(false)

    }
    }



    

    const yessubscribe = (e) =>{
        setloading(true)
       e.preventDefault()
       const name = document.getElementById("name").value 
       const contact = document.getElementById("contact").value 
       const email = personemail 
       const dob = persondob
       const occupation = personoccupation
       const address = document.getElementById("address").value 
       const agentbuy_ = buyagent
       const policytype_ = policytype
       const currency_ = currency
       const vehicleusage_ = vehicleusage
       const branch_ = branch
        const bodytype_ = bodytype
        const vehiclemake = document.getElementById("vehiclemake").value 
        const model = document.getElementById("model").value 
        const chasis = document.getElementById("chasis").value 
        const cc = document.getElementById("cc").value 
        const sc = document.getElementById("sc").value 
        const registration = document.getElementById("registration").value 
        const color = document.getElementById("color").value 
        const charge = document.getElementById("premium").value 
        const myear = document.getElementById("myear").value 
        const startdate = document.getElementById("startdate").value 
        const enddate = document.getElementById("enddate").value 
        const buyextra_ = buyextra 
        const buyback_ = buyback 
        const extratppd = persontppdamount 
        const registrationyear = document.getElementById("registrationyear").value 
  
        var agentname_=Agent.name
        var agentcontact_=Agent.contact
        var agentIDnumber_="User"
        var agentemail_=Agent.email
        var agentcommission_=0
        var aycommission_= parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission_direct:0) - (parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission_direct:0)*parseFloat(settings.length!==0?settings[0].tax:0))
        var institution_="subscribe"
  
        
  
        
      const buyingpolicy= async () => {
          await axios.post("https://cromer.sisrevolution.com/api/motordata/registermotorpolicy/",
              {
  
                name:name,
                contact:contact,
                email:email,
                dob:dob,
                occupation:occupation,
                address:address,
                agentbuy:"no",
                policytype:policytype_,
                currency:currency_,
                usage:vehicleusage_,
                branch:"Kumasi",
                make:vehiclemake,
                model:model,
                bodytype:bodytype_,
                chasis:chasis,
                cc:cc,
                seats:sc,
                commissionpayment:"none",
                transactionID:document.getElementById("transactionID").value,
                registration:registration,
                color:color,
                charge:charge,
                year:myear,
                value:price,
                startdate:startdate,
                enddate:enddate,
                TPPD:buyextra_,
                TPPDamount:extratppd,
                backexcess:buyback_, 
                institution:institution_,
                registrationyear:registrationyear,
                agentname:agentname_, 
                agentcontact:agentcontact_,
                agentID:agentIDnumber_,
                agentemail:agentemail_,
                agentcommission:agentcommission_,
                aycommission:aycommission_,
              
              }).then(res => {
  
                  if (res.status === 200) {
  
                          setloading(!true)
                          swal("", " Motor Policy Added", "success");
                          
                          document.getElementById("ll").reset()
                          handleClosedelete()
  
                  }else{
                        setloading(!true)
                     swal("", "Error adding Motor Policy", "error");
  
                         
                  }
                 
                  
  
              })
  
      }
  
        if((document.getElementById("transactionID").value).length>10 && (document.getElementById("transactionID").value).length<13){
       buyingpolicy()
  
      }else{
           setloading(!true)
           settransact(false)
  
      }
      }
  
      



      const [opendelete, setOpendelete] = React.useState(!true);

      const handleOpensubscribe = () => {
        setOpensubscribe(true);
    };
    
    const handleClosesubscribe = () => {
        setOpensubscribe(false);
    };

     
 const handleChangeagenttype = (event) => {
        setagenttype(event.target.value);
    };
    const handleClickOpendelete = () => {
        setOpendelete(true);
    };
    const handleClosedelete = () => {
        setOpendelete(false);
    };

const price = (event) => {
    if(event.target.value !==""){
        setprice_(event.target.value);}
        else{ setprice_(0)}
    };

      useEffect(() => {

        if (policytype === "Motor Third Party Only") {
            var data = settings[0].motorpremium
            console.log(vehicleusage)
if(period===12 || period===11 || period===10|| period===9){
            if (vehicleusage === "Private Individual" || vehicleusage === "Private Company"|| vehicleusage === "Private 4X4") {
                if (parseInt(caryear) > parseInt(data[0].threshold)) {


                    if (data[0].data.privatecar.persons === seating_) {
                        setpremium_(parseFloat(data[0].data.privatecar.above.year))
                        setpremiumdata(data[0].data.privatecar.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    } else {
                        setseating_(5)
                        setpremium_(parseFloat(data[0].data.privatecar.above.year))
                        setpremiumdata(data[0].data.privatecar.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }


                } else {

                    if (data[0].data.privatecar.persons === seating_) {
                        setpremium_(parseFloat(data[0].data.privatecar.below.year))
                        setpremiumdata(data[0].data.privatecar.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                }

            }

            if (vehicleusage === "Taxi") {
                if (parseInt(caryear) > parseInt(data[0].threshold)) {


                    if (data[0].data.taxi.persons === seating_) {
                        setpremium_(parseFloat(data[0].data.taxi.above.year)+20)
                        console.log(premium_)
                        setpremiumdata(data[0].data.taxi.above)
                        document.getElementById("premium").value=premium_.toFixed(2)

                    } else {
                        setseating_(5)
                        setpremium_(parseFloat(data[0].data.taxi.above.year))
                        setpremiumdata(data[0].data.taxi.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }


                } else {

                    if (data[0].data.taxi.persons === seating_) {
                        setpremium_(parseFloat(data[0].data.taxi.below.year))
                        setpremiumdata(data[0].data.taxi.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                }

            }


            if (vehicleusage === "Hiring") {
                if (parseInt(caryear) > parseInt(data[0].threshold)) {


                    if (data[0].data.hiring.persons === seating_) {
                        setpremium_(parseFloat(data[0].data.hiring.above.year))
                        setpremiumdata(data[0].data.hiring.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    } else {
                        setseating_(5)
                        setpremium_(parseFloat(data[0].data.hiring.above.year))
                        setpremiumdata(data[0].data.hiring.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }


                } else {

                    if (data[0].data.hiring.persons === seating_) {
                        setpremium_(parseFloat(data[0].data.hiring.below.year))
                        setpremiumdata(data[0].data.hiring.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                }

            }


            if (vehicleusage === "Motor Cycle" || vehicleusage === "Mini Bus" || vehicleusage === "Maxi Bus") {

                if (parseInt(caryear) > parseInt(data[0].threshold)) {
                    console.log(seating_)
                    if (parseInt(seating_) === 2) {
                        setpremium_(parseFloat(data[0].data.motor.above.year))
                        setpremiumdata(data[0].data.motor.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                    else if (parseInt(seating_) === 12) {
                        setpremium_(parseFloat(data[0].data.motor_1.above.year))
                        setpremiumdata(data[0].data.motor_1.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                    else if (parseInt(seating_) === 15) {
                        setpremium_(parseFloat(data[0].data.motor_2.above.year))
                        setpremiumdata(data[0].data.motor_2.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                    else if (parseInt(seating_) === 19) {
                        setpremium_(parseFloat(data[0].data.motor_3.above.year))
                        setpremiumdata(data[0].data.motor_3.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }

                    else if (parseInt(seating_) === 23) {
                        setpremium_(parseFloat(data[0].data.motor_4.above.year))
                        setpremiumdata(data[0].data.motor_4.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                    else if (parseInt(seating_) === 27) {
                        setpremium_(parseFloat(data[0].data.motor_5.above.year))
                        setpremiumdata(data[0].data.motor_5.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }

                    else {
                        setseating_(2)
                        setpremium_(parseFloat(data[0].data.motor.above.year))
                        setpremiumdata(data[0].data.motor.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }



                } else {
                      console.log(seating_)
                    if (parseInt(seating_) === 2) {
                        setpremium_(parseFloat(data[0].data.motor.below.year))
                        setpremiumdata(data[0].data.motor.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                    else if (parseInt(seating_) === 12) {
                        setpremium_(parseFloat(data[0].data.motor_1.below.year))
                        setpremiumdata(data[0].data.motor_1.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                    else if (parseInt(seating_) === 15) {
                        setpremium_(parseFloat(data[0].data.motor_2.below.year))
                        setpremiumdata(data[0].data.motor_2.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                    else if (parseInt(seating_) === 19) {
                        setpremium_(parseFloat(data[0].data.motor_3.below.year))
                        setpremiumdata(data[0].data.motor_3.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }

                    else if (parseInt(seating_) === 23) {
                        setpremium_(parseFloat(data[0].data.motor_4.below.year))
                        setpremiumdata(data[0].data.motor_4.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                    else if (parseInt(seating_) === 27) {
                        setpremium_(parseFloat(data[0].data.motor_5.below.year))
                        setpremiumdata(data[0].data.motor_5.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }

                    else {
                        setseating_(2)
                        setpremium_(parseFloat(data[0].data.motor.below.year))
                        setpremiumdata(data[0].data.motor.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
 

                }

            }



            if (vehicleusage === "Own Goods (CC upto 3000)" || vehicleusage === "Own Goods (CC above 3000)") {
                if (parseInt(caryear) > parseInt(data[0].threshold)) {


                    if (data[0].data.owngoods.persons === seating_) {
                        setpremium_(parseFloat(data[0].data.owngoods.above.year))
                        setpremiumdata(data[0].data.owngoods.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    } else {
                        setseating_(5)
                        setpremium_(parseFloat(data[0].data.owngoods.above.year))
                        setpremiumdata(data[0].data.owngoods.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }


                } else {
                    console.log("own")
                    setpremium_(parseFloat(data[0].data.owngoods.below.year))
                    setpremiumdata(data[0].data.owngoods.below)
                                                    document.getElementById("premium").value=premium_.toFixed(2)


                }

            }


            if (vehicleusage === "General Cartage (CC upto 3000)") {
                setseating_(3)
                if (parseInt(caryear) > parseInt(data[0].threshold)) {

                    if (data[0].data.generalcartagebelow.persons === seating_) {
                        setpremium_(parseFloat(data[0].data.generalcartagebelow.above.year))
                        setpremiumdata(data[0].data.generalcartagebelow.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }

                } else {

                    setpremium_(parseFloat(data[0].data.generalcartagebelow.below.year))
                    setpremiumdata(data[0].data.generalcartagebelow.below)
                                                    document.getElementById("premium").value=premium_.toFixed(2)


                }

            }


            if (vehicleusage === "General Cartage (CC above 3000)") {
                setseating_(3)

                if (parseInt(caryear) > parseInt(data[0].threshold)) {

                    setpremium_(parseFloat(data[0].data.generalcartageabove.above.year))
                    setpremiumdata(data[0].data.generalcartageabove.above)
                                                    document.getElementById("premium").value=premium_.toFixed(2)


                } else {

                    setpremium_(parseFloat(data[0].data.generalcartageabove.below.year))
                    setpremiumdata(data[0].data.generalcartageabove.below)
                                                    document.getElementById("premium").value=premium_.toFixed(2)


                }

            }


            if (vehicleusage === "Art / Tanker") {
                setseating_(3)

                if (parseInt(caryear) > parseInt(data[0].threshold)) {


                    setpremium_(parseFloat(data[0].data.tanker.above.year))
                    setpremiumdata(data[0].data.tanker.above)
                                                    document.getElementById("premium").value=premium_.toFixed(2)



                } else {

                    setpremium_(parseFloat(data[0].data.tanker.below.year))
                    setpremiumdata(data[0].data.tanker.below)
                                                    document.getElementById("premium").value=premium_.toFixed(2)


                }

            }


            if (vehicleusage === "Ambulance / Hearse") {
                setseating_(3)

                if (parseInt(caryear) > parseInt(data[0].threshold)) {


                    setpremium_(parseFloat(data[0].data.ambulance.above.year))
                    setpremiumdata(data[0].data.ambulance.above)
                                                    document.getElementById("premium").value=premium_.toFixed(2)



                } else {

                    setpremium_(parseFloat(data[0].data.ambulance.below.year))
                    setpremiumdata(data[0].data.ambulance.below)
                                                    document.getElementById("premium").value=premium_.toFixed(2)


                }

            }

            if (vehicleusage === "Z.802 On Road") {
                setseating_(3)

                if (parseInt(caryear) > parseInt(data[0].threshold)) {


                    setpremium_(parseFloat(data[0].data.onroad.above.year))
                    setpremiumdata(data[0].data.onroad.above)
                                                    document.getElementById("premium").value=premium_.toFixed(2)



                } else {

                    setpremium_(parseFloat(data[0].data.onroad.below.year))
                    setpremiumdata(data[0].data.onroad.below)
                                                    document.getElementById("premium").value=premium_.toFixed(2)


                }
            }

            if (vehicleusage === "Z.802 On Site") {
                setseating_(3)
                if (parseInt(caryear) > parseInt(data[0].threshold)) {


                    setpremium_(parseFloat(data[0].data.onsite.above.year))
                    setpremiumdata(data[0].data.onsite.above)
                                                    document.getElementById("premium").value=premium_.toFixed(2)



                } else {

                    setpremium_(parseFloat(data[0].data.onsite.below.year))
                    setpremiumdata(data[0].data.onsite.below)
                               document.getElementById("premium").value=premium_.toFixed(2)


                }

            }
        }
        
        else{

           if (vehicleusage === "Private Individual" || vehicleusage === "Private Company"|| vehicleusage === "Private 4X4") {
                if (parseInt(caryear) > parseInt(data[0].threshold)) {


                    if (data[0].data.privatecar.persons === seating_) {
                        setpremium_(parseFloat(data[0].data.privatecar.above["month"+period]))
                        setpremiumdata(data[0].data.privatecar.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    } else {
                        setseating_(5)
                        setpremium_(parseFloat(data[0].data.privatecar.above["month"+period]))
                        setpremiumdata(data[0].data.privatecar.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }


                } else {

                    if (data[0].data.privatecar.persons === seating_) {
                        setpremium_(parseFloat(data[0].data.privatecar.below["month"+period]))
                        setpremiumdata(data[0].data.privatecar.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                }

            }

            if (vehicleusage === "Taxi") {
                if (parseInt(caryear) > parseInt(data[0].threshold)) {


                    if (data[0].data.taxi.persons === seating_) {
                        setpremium_(parseFloat(data[0].data.taxi.above["month"+period])+20)
                        console.log(premium_)
                        setpremiumdata(data[0].data.taxi.above)
                        document.getElementById("premium").value=premium_.toFixed(2)

                    } else {
                        setseating_(5)
                        setpremium_(parseFloat(data[0].data.taxi.above["month"+period]))
                        setpremiumdata(data[0].data.taxi.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }


                } else {

                    if (data[0].data.taxi.persons === seating_) {
                        setpremium_(parseFloat(data[0].data.taxi.below["month"+period]))
                        setpremiumdata(data[0].data.taxi.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                }

            }


            if (vehicleusage === "Hiring") {
                if (parseInt(caryear) > parseInt(data[0].threshold)) {


                    if (data[0].data.hiring.persons === seating_) {
                        setpremium_(parseFloat(data[0].data.hiring.above["month"+period]))
                        setpremiumdata(data[0].data.hiring.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    } else {
                        setseating_(5)
                        setpremium_(parseFloat(data[0].data.hiring.above["month"+period]))
                        setpremiumdata(data[0].data.hiring.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }


                } else {

                    if (data[0].data.hiring.persons === seating_) {
                        setpremium_(parseFloat(data[0].data.hiring.below["month"+period]))
                        setpremiumdata(data[0].data.hiring.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                }

            }


            if (vehicleusage === "Motor Cycle" || vehicleusage === "Mini Bus" || vehicleusage === "Maxi Bus") {

                if (parseInt(caryear) > parseInt(data[0].threshold)) {
                    console.log(seating_)
                    if (parseInt(seating_) === 2) {
                        setpremium_(parseFloat(data[0].data.motor.above["month"+period]))
                        setpremiumdata(data[0].data.motor.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                    else if (parseInt(seating_) === 12) {
                        setpremium_(parseFloat(data[0].data.motor_1.above["month"+period]))
                        setpremiumdata(data[0].data.motor_1.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                    else if (parseInt(seating_) === 15) {
                        setpremium_(parseFloat(data[0].data.motor_2.above["month"+period]))
                        setpremiumdata(data[0].data.motor_2.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                    else if (parseInt(seating_) === 19) {
                        setpremium_(parseFloat(data[0].data.motor_3.above["month"+period]))
                        setpremiumdata(data[0].data.motor_3.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }

                    else if (parseInt(seating_) === 23) {
                        setpremium_(parseFloat(data[0].data.motor_4.above["month"+period]))
                        setpremiumdata(data[0].data.motor_4.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                    else if (parseInt(seating_) === 27) {
                        setpremium_(parseFloat(data[0].data.motor_5.above["month"+period]))
                        setpremiumdata(data[0].data.motor_5.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }

                    else {
                        setseating_(2)
                        setpremium_(parseFloat(data[0].data.motor.above["month"+period]))
                        setpremiumdata(data[0].data.motor.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }



                } else {
                      console.log(seating_)
                    if (parseInt(seating_) === 2) {
                        setpremium_(parseFloat(data[0].data.motor.below["month"+period]))
                        setpremiumdata(data[0].data.motor.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                    else if (parseInt(seating_) === 12) {
                        setpremium_(parseFloat(data[0].data.motor_1.below["month"+period]))
                        setpremiumdata(data[0].data.motor_1.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                    else if (parseInt(seating_) === 15) {
                        setpremium_(parseFloat(data[0].data.motor_2.below["month"+period]))
                        setpremiumdata(data[0].data.motor_2.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                    else if (parseInt(seating_) === 19) {
                        setpremium_(parseFloat(data[0].data.motor_3.below["month"+period]))
                        setpremiumdata(data[0].data.motor_3.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }

                    else if (parseInt(seating_) === 23) {
                        setpremium_(parseFloat(data[0].data.motor_4.below["month"+period]))
                        setpremiumdata(data[0].data.motor_4.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
                    else if (parseInt(seating_) === 27) {
                        setpremium_(parseFloat(data[0].data.motor_5.below["month"+period]))
                        setpremiumdata(data[0].data.motor_5.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }

                    else {
                        setseating_(2)
                        setpremium_(parseFloat(data[0].data.motor.below["month"+period]))
                        setpremiumdata(data[0].data.motor.below)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }
 

                }

            }



            if (vehicleusage === "Own Goods (CC upto 3000)" || vehicleusage === "Own Goods (CC above 3000)") {
                if (parseInt(caryear) > parseInt(data[0].threshold)) {


                    if (data[0].data.owngoods.persons === seating_) {
                        setpremium_(parseFloat(data[0].data.owngoods.above["month"+period]))
                        setpremiumdata(data[0].data.owngoods.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    } else {
                        setseating_(5)
                        setpremium_(parseFloat(data[0].data.owngoods.above["month"+period]))
                        setpremiumdata(data[0].data.owngoods.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }


                } else {
                    console.log("own")
                    setpremium_(parseFloat(data[0].data.owngoods.below["month"+period]))
                    setpremiumdata(data[0].data.owngoods.below)
                                                    document.getElementById("premium").value=premium_.toFixed(2)


                }

            }


            if (vehicleusage === "General Cartage (CC upto 3000)") {
                setseating_(3)
                if (parseInt(caryear) > parseInt(data[0].threshold)) {

                    if (data[0].data.generalcartagebelow.persons === seating_) {
                        setpremium_(parseFloat(data[0].data.generalcartagebelow.above["month"+period]))
                        setpremiumdata(data[0].data.generalcartagebelow.above)
                                                        document.getElementById("premium").value=premium_.toFixed(2)

                    }

                } else {

                    setpremium_(parseFloat(data[0].data.generalcartagebelow.below["month"+period]))
                    setpremiumdata(data[0].data.generalcartagebelow.below)
                                                    document.getElementById("premium").value=premium_.toFixed(2)


                }

            }


            if (vehicleusage === "General Cartage (CC above 3000)") {
                setseating_(3)

                if (parseInt(caryear) > parseInt(data[0].threshold)) {

                    setpremium_(parseFloat(data[0].data.generalcartageabove.above["month"+period]))
                    setpremiumdata(data[0].data.generalcartageabove.above)
                                                    document.getElementById("premium").value=premium_.toFixed(2)


                } else {

                    setpremium_(parseFloat(data[0].data.generalcartageabove.below["month"+period]))
                    setpremiumdata(data[0].data.generalcartageabove.below)
                                                    document.getElementById("premium").value=premium_.toFixed(2)


                }

            }


            if (vehicleusage === "Art / Tanker") {
                setseating_(3)

                if (parseInt(caryear) > parseInt(data[0].threshold)) {


                    setpremium_(parseFloat(data[0].data.tanker.above["month"+period]))
                    setpremiumdata(data[0].data.tanker.above)
                                                    document.getElementById("premium").value=premium_.toFixed(2)



                } else {

                    setpremium_(parseFloat(data[0].data.tanker.below["month"+period]))
                    setpremiumdata(data[0].data.tanker.below)
                                                    document.getElementById("premium").value=premium_.toFixed(2)


                }

            }


            if (vehicleusage === "Ambulance / Hearse") {
                setseating_(3)

                if (parseInt(caryear) > parseInt(data[0].threshold)) {


                    setpremium_(parseFloat(data[0].data.ambulance.above["month"+period]))
                    setpremiumdata(data[0].data.ambulance.above)
                                                    document.getElementById("premium").value=premium_.toFixed(2)



                } else {

                    setpremium_(parseFloat(data[0].data.ambulance.below["month"+period]))
                    setpremiumdata(data[0].data.ambulance.below)
                                                    document.getElementById("premium").value=premium_.toFixed(2)


                }

            }

            if (vehicleusage === "Z.802 On Road") {
                setseating_(3)

                if (parseInt(caryear) > parseInt(data[0].threshold)) {


                    setpremium_(parseFloat(data[0].data.onroad.above["month"+period]))
                    setpremiumdata(data[0].data.onroad.above)
                                                    document.getElementById("premium").value=premium_.toFixed(2)



                } else {

                    setpremium_(parseFloat(data[0].data.onroad.below["month"+period]))
                    setpremiumdata(data[0].data.onroad.below)
                                                    document.getElementById("premium").value=premium_.toFixed(2)


                }
            }

            if (vehicleusage === "Z.802 On Site") {
                setseating_(3)
                if (parseInt(caryear) > parseInt(data[0].threshold)) {


                    setpremium_(parseFloat(data[0].data.onsite.above["month"+period]))
                    setpremiumdata(data[0].data.onsite.above)
                                                    document.getElementById("premium").value=premium_.toFixed(2)



                } else {

                    setpremium_(parseFloat(data[0].data.onsite.below["month"+period]))
                    setpremiumdata(data[0].data.onsite.below)
                               document.getElementById("premium").value=premium_.toFixed(2)


                }

            }

        }

      }

      
    if (policytype === "Motor Comprehensive") {
            var data = settings[0].motorpremium

            if (vehicleusage === "Private Individual" || vehicleusage === "Private Company") {
                if (parseInt(caryear) === parseInt(year)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.05)+parseFloat(data[0].data.privatecar.above.year):((parseFloat(price_)*0.05)+parseFloat(data[0].data.privatecar.above.year))+(((parseFloat(price_)*0.05)+parseFloat(data[0].data.privatecar.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.25))+parseFloat(data[0].data.privatecar.above.year):    ((((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.25))+parseFloat(data[0].data.privatecar.above.year))   +  ((((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.25))+parseFloat(data[0].data.privatecar.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.30))+parseFloat(data[0].data.privatecar.above.year):    ((((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.30))+parseFloat(data[0].data.privatecar.above.year))   +  ((((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.30))+parseFloat(data[0].data.privatecar.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.35))+parseFloat(data[0].data.privatecar.above.year):    ((((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.35))+parseFloat(data[0].data.privatecar.above.year))   +  ((((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.35))+parseFloat(data[0].data.privatecar.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
                else if((parseInt(caryear) === parseInt(year-4))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.45))+parseFloat(data[0].data.privatecar.above.year):    ((((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.45))+parseFloat(data[0].data.privatecar.above.year))   +  ((((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.45))+parseFloat(data[0].data.privatecar.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                } 
                else if((parseInt(caryear) >= parseInt(year-5))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.50))+parseFloat(data[0].data.privatecar.above.year):    ((((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.50))+parseFloat(data[0].data.privatecar.above.year))   +  ((((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.50))+parseFloat(data[0].data.privatecar.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.50))+parseFloat(data[0].data.privatecar.above.year):    ((((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.50))+parseFloat(data[0].data.privatecar.above.year))   +  ((((parseFloat(price_)*0.05)-((parseFloat(price_)*0.05) *0.50))+parseFloat(data[0].data.privatecar.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }

            }

            if (vehicleusage === "Private 4X4" ) {
                if (parseInt(caryear) === parseInt(year)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.06)+parseFloat(data[0].data.privatecar.above.year):((parseFloat(price_)*0.06)+parseFloat(data[0].data.privatecar.above.year))+(((parseFloat(price_)*0.06)+parseFloat(data[0].data.privatecar.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.25))+parseFloat(data[0].data.privatecar.above.year):    ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.25))+parseFloat(data[0].data.privatecar.above.year))   +  ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.25))+parseFloat(data[0].data.privatecar.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.30))+parseFloat(data[0].data.privatecar.above.year):    ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.30))+parseFloat(data[0].data.privatecar.above.year))   +  ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.30))+parseFloat(data[0].data.privatecar.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.35))+parseFloat(data[0].data.privatecar.above.year):    ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.35))+parseFloat(data[0].data.privatecar.above.year))   +  ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.35))+parseFloat(data[0].data.privatecar.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
                else if((parseInt(caryear) === parseInt(year-4))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.45))+parseFloat(data[0].data.privatecar.above.year):    ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.45))+parseFloat(data[0].data.privatecar.above.year))   +  ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.45))+parseFloat(data[0].data.privatecar.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                } 
                else if((parseInt(caryear) >= parseInt(year-5))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.50))+parseFloat(data[0].data.privatecar.above.year):    ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.50))+parseFloat(data[0].data.privatecar.above.year))   +  ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.50))+parseFloat(data[0].data.privatecar.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.50))+parseFloat(data[0].data.privatecar.above.year):    ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.50))+parseFloat(data[0].data.privatecar.above.year))   +  ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.50))+parseFloat(data[0].data.privatecar.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }

            }

          
            if (vehicleusage === "Taxi" ) {
                if (parseInt(caryear) > parseInt(year-1)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.07)+parseFloat(data[0].data.taxi.above.year):((parseFloat(price_)*0.07)+parseFloat(data[0].data.taxi.above.year))+(((parseFloat(price_)*0.07)+parseFloat(data[0].data.taxi.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.taxi.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.taxi.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.taxi.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.20))+parseFloat(data[0].data.taxi.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.20))+parseFloat(data[0].data.taxi.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.30))+parseFloat(data[0].data.taxi.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.taxi.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.taxi.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.35))+parseFloat(data[0].data.taxi.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
                else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.taxi.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.taxi.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.50))+parseFloat(data[0].data.taxi.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }

            }

             
            if (vehicleusage === "Hiring" ) {
                if (parseInt(caryear) > parseInt(year-1)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.07)+parseFloat(data[0].data.hiring.above.year):((parseFloat(price_)*0.07)+parseFloat(data[0].data.hiring.above.year))+(((parseFloat(price_)*0.07)+parseFloat(data[0].data.hiring.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.hiring.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.hiring.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.hiring.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.20))+parseFloat(data[0].data.hiring.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.30))+parseFloat(data[0].data.hiring.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.30))+parseFloat(data[0].data.hiring.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.hiring.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.35))+parseFloat(data[0].data.hiring.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.35))+parseFloat(data[0].data.hiring.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
                else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.hiring.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.50))+parseFloat(data[0].data.hiring.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.50))+parseFloat(data[0].data.hiring.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }

            }
    
            if (vehicleusage === "Motor Cycle" ) {
                if (parseInt(caryear) > parseInt(year-1)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.03)+parseFloat(data[0].data.motor.above.year):((parseFloat(price_)*0.03)+parseFloat(data[0].data.motor.above.year))+(((parseFloat(price_)*0.03)+parseFloat(data[0].data.motor.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.15))+parseFloat(data[0].data.motor.above.year):    ((((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.15))+parseFloat(data[0].data.motor.above.year))   +  ((((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.15))+parseFloat(data[0].data.motor.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.20))+parseFloat(data[0].data.motor.above.year):    ((((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.30))+parseFloat(data[0].data.motor.above.year))   +  ((((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.30))+parseFloat(data[0].data.motor.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.25))+parseFloat(data[0].data.motor.above.year):    ((((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.35))+parseFloat(data[0].data.motor.above.year))   +  ((((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.35))+parseFloat(data[0].data.motor.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
                else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.25))+parseFloat(data[0].data.motor.above.year):    ((((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.50))+parseFloat(data[0].data.motor.above.year))   +  ((((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.50))+parseFloat(data[0].data.motor.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }

            }

          
            if (vehicleusage === "Motor Tricycle" ) {
                if (parseInt(caryear) > parseInt(year-1)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.04)+parseFloat(data[0].data.motor.above.year):((parseFloat(price_)*0.04)+parseFloat(data[0].data.motor.above.year))+(((parseFloat(price_)*0.04)+parseFloat(data[0].data.motor.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.15))+parseFloat(data[0].data.motor.above.year):    ((((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.15))+parseFloat(data[0].data.motor.above.year))   +  ((((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.15))+parseFloat(data[0].data.motor.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.30))+parseFloat(data[0].data.motor.above.year):    ((((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.20))+parseFloat(data[0].data.motor.above.year))   +  ((((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.30))+parseFloat(data[0].data.motor.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.35))+parseFloat(data[0].data.motor.above.year):    ((((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.25))+parseFloat(data[0].data.motor.above.year))   +  ((((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.35))+parseFloat(data[0].data.motor.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
                else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.50))+parseFloat(data[0].data.motor.above.year):    ((((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.25))+parseFloat(data[0].data.motor.above.year))   +  ((((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.50))+parseFloat(data[0].data.motor.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }

            }

          

          
            if (vehicleusage === "Mini Bus" || vehicleusage === "Maxi Bus" ) {

                  if (parseInt(seating_) <13 ) {
                if (parseInt(caryear) > parseInt(year-1)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.07)+parseFloat(data[0].data.motor_1.above.year):((parseFloat(price_)*0.07)+parseFloat(data[0].data.motor_1.above.year))+(((parseFloat(price_)*0.07)+parseFloat(data[0].data.motor_1.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.motor_1.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.motor_1.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.motor_1.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.20))+parseFloat(data[0].data.motor_1.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.30))+parseFloat(data[0].data.motor_1.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.30))+parseFloat(data[0].data.motor_1.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.motor_1.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.35))+parseFloat(data[0].data.motor_1.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.35))+parseFloat(data[0].data.motor_1.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
                else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.motor_1.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.50))+parseFloat(data[0].data.motor_1.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.50))+parseFloat(data[0].data.motor_1.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }

            }
                 else if (parseInt(seating_) === 15) {
                if (parseInt(caryear) > parseInt(year-1)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.07)+parseFloat(data[0].data.motor_2.above.year):((parseFloat(price_)*0.07)+parseFloat(data[0].data.motor_2.above.year))+(((parseFloat(price_)*0.07)+parseFloat(data[0].data.motor_2.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.motor_2.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.motor_2.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.motor_2.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.20))+parseFloat(data[0].data.motor_2.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.30))+parseFloat(data[0].data.motor_2.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.30))+parseFloat(data[0].data.motor_2.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.motor_2.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.35))+parseFloat(data[0].data.motor_2.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.35))+parseFloat(data[0].data.motor_2.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
                else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.motor_2.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.50))+parseFloat(data[0].data.motor_2.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.50))+parseFloat(data[0].data.motor_2.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }

            }
            else if (parseInt(seating_) === 19) {
                if (parseInt(caryear) > parseInt(year-1)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.07)+parseFloat(data[0].data.motor_3.above.year):((parseFloat(price_)*0.07)+parseFloat(data[0].data.motor_3.above.year))+(((parseFloat(price_)*0.07)+parseFloat(data[0].data.motor_3.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.motor_3.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.motor_3.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.motor_3.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.20))+parseFloat(data[0].data.motor_3.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.30))+parseFloat(data[0].data.motor_3.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.30))+parseFloat(data[0].data.motor_3.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.motor_3.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.35))+parseFloat(data[0].data.motor_3.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.35))+parseFloat(data[0].data.motor_3.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
                else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.motor_3.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.50))+parseFloat(data[0].data.motor_3.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.50))+parseFloat(data[0].data.motor_3.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }

            }
            else if (parseInt(seating_) === 23) {
                if (parseInt(caryear) > parseInt(year-1)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.07)+parseFloat(data[0].data.motor_4.above.year):((parseFloat(price_)*0.07)+parseFloat(data[0].data.motor_4.above.year))+(((parseFloat(price_)*0.07)+parseFloat(data[0].data.motor_4.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.motor_4.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.motor_4.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.motor_4.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.20))+parseFloat(data[0].data.motor_4.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.30))+parseFloat(data[0].data.motor_4.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.30))+parseFloat(data[0].data.motor_4.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.motor_4.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.35))+parseFloat(data[0].data.motor_4.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.35))+parseFloat(data[0].data.motor_4.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
                else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.motor_4.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.50))+parseFloat(data[0].data.motor_4.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.50))+parseFloat(data[0].data.motor_4.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }

            }  
            else if (parseInt(seating_) > 23) {
                if (parseInt(caryear) > parseInt(year-1)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.07)+parseFloat(data[0].data.motor_5.above.year):((parseFloat(price_)*0.07)+parseFloat(data[0].data.motor_5.above.year))+(((parseFloat(price_)*0.07)+parseFloat(data[0].data.motor_5.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.motor_5.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.motor_5.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.motor_5.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.20))+parseFloat(data[0].data.motor_5.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.30))+parseFloat(data[0].data.motor_5.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.30))+parseFloat(data[0].data.motor_5.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.motor_5.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.35))+parseFloat(data[0].data.motor_5.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.35))+parseFloat(data[0].data.motor_5.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
                else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.motor_5.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.50))+parseFloat(data[0].data.motor_5.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.50))+parseFloat(data[0].data.motor_5.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }

            }

            }

        

            if (vehicleusage === "Own Goods (CC upto 3000)" || vehicleusage === "Own Goods (CC above 3000)") {
                 
                
                 if (parseInt(caryear) > parseInt(year-1)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.04)+parseFloat(data[0].data.owngoods.above.year):((parseFloat(price_)*0.04)+parseFloat(data[0].data.owngoods.above.year))+(((parseFloat(price_)*0.04)+parseFloat(data[0].data.owngoods.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.15))+parseFloat(data[0].data.owngoods.above.year):    ((((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.15))+parseFloat(data[0].data.owngoods.above.year))   +  ((((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.15))+parseFloat(data[0].data.owngoods.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.20))+parseFloat(data[0].data.owngoods.above.year):    ((((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.30))+parseFloat(data[0].data.owngoods.above.year))   +  ((((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.30))+parseFloat(data[0].data.owngoods.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.25))+parseFloat(data[0].data.owngoods.above.year):    ((((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.35))+parseFloat(data[0].data.owngoods.above.year))   +  ((((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.35))+parseFloat(data[0].data.owngoods.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
                else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.25))+parseFloat(data[0].data.owngoods.above.year):    ((((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.50))+parseFloat(data[0].data.owngoods.above.year))   +  ((((parseFloat(price_)*0.04)-((parseFloat(price_)*0.04) *0.50))+parseFloat(data[0].data.owngoods.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }

                
                
               

            }


            if (vehicleusage === "General Cartage (CC upto 3000)") {
                setseating_(3)
                    if (parseInt(caryear) > parseInt(year-1)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.06)+parseFloat(data[0].data.generalcartagebelow.above.year):((parseFloat(price_)*0.06)+parseFloat(data[0].data.generalcartagebelow.above.year))+(((parseFloat(price_)*0.06)+parseFloat(data[0].data.generalcartagebelow.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.15))+parseFloat(data[0].data.generalcartagebelow.above.year):    ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.15))+parseFloat(data[0].data.generalcartagebelow.above.year))   +  ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.15))+parseFloat(data[0].data.generalcartagebelow.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.20))+parseFloat(data[0].data.generalcartagebelow.above.year):    ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.30))+parseFloat(data[0].data.generalcartagebelow.above.year))   +  ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.30))+parseFloat(data[0].data.generalcartagebelow.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.25))+parseFloat(data[0].data.generalcartagebelow.above.year):    ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.35))+parseFloat(data[0].data.generalcartagebelow.above.year))   +  ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.35))+parseFloat(data[0].data.generalcartagebelow.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
               else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.25))+parseFloat(data[0].data.generalcartagebelow.above.year):    ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.50))+parseFloat(data[0].data.generalcartagebelow.above.year))   +  ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.50))+parseFloat(data[0].data.generalcartagebelow.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }


            }

            if (vehicleusage === "General Cartage (CC above 3000)") {
                setseating_(3)
                   if (parseInt(caryear) > parseInt(year-1)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.06)+parseFloat(data[0].data.generalcartageabove.above.year):((parseFloat(price_)*0.06)+parseFloat(data[0].data.generalcartageabove.above.year))+(((parseFloat(price_)*0.06)+parseFloat(data[0].data.generalcartageabove.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.15))+parseFloat(data[0].data.generalcartageabove.above.year):    ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.15))+parseFloat(data[0].data.generalcartageabove.above.year))   +  ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.15))+parseFloat(data[0].data.generalcartageabove.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.20))+parseFloat(data[0].data.generalcartageabove.above.year):    ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.30))+parseFloat(data[0].data.generalcartageabove.above.year))   +  ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.30))+parseFloat(data[0].data.generalcartageabove.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.25))+parseFloat(data[0].data.generalcartageabove.above.year):    ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.35))+parseFloat(data[0].data.generalcartageabove.above.year))   +  ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.35))+parseFloat(data[0].data.generalcartageabove.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
               else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.25))+parseFloat(data[0].data.generalcartageabove.above.year):    ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.50))+parseFloat(data[0].data.generalcartageabove.above.year))   +  ((((parseFloat(price_)*0.06)-((parseFloat(price_)*0.06) *0.50))+parseFloat(data[0].data.generalcartageabove.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)


            }
        }

            if (vehicleusage === "Art / Tanker") {

                setseating_(3)
                    if (parseInt(caryear) > parseInt(year-1)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.08)+parseFloat(data[0].data.tanker.above.year):((parseFloat(price_)*0.08)+parseFloat(data[0].data.tanker.above.year))+(((parseFloat(price_)*0.08)+parseFloat(data[0].data.tanker.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.08)-((parseFloat(price_)*0.08) *0.15))+parseFloat(data[0].data.tanker.above.year):    ((((parseFloat(price_)*0.08)-((parseFloat(price_)*0.08) *0.15))+parseFloat(data[0].data.tanker.above.year))   +  ((((parseFloat(price_)*0.08)-((parseFloat(price_)*0.08) *0.15))+parseFloat(data[0].data.tanker.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.08)-((parseFloat(price_)*0.08) *0.20))+parseFloat(data[0].data.tanker.above.year):    ((((parseFloat(price_)*0.08)-((parseFloat(price_)*0.08) *0.30))+parseFloat(data[0].data.tanker.above.year))   +  ((((parseFloat(price_)*0.08)-((parseFloat(price_)*0.08) *0.30))+parseFloat(data[0].data.tanker.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.08)-((parseFloat(price_)*0.08) *0.25))+parseFloat(data[0].data.tanker.above.year):    ((((parseFloat(price_)*0.08)-((parseFloat(price_)*0.08) *0.35))+parseFloat(data[0].data.tanker.above.year))   +  ((((parseFloat(price_)*0.08)-((parseFloat(price_)*0.08) *0.35))+parseFloat(data[0].data.tanker.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
               else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.08)-((parseFloat(price_)*0.08) *0.25))+parseFloat(data[0].data.tanker.above.year):    ((((parseFloat(price_)*0.08)-((parseFloat(price_)*0.08) *0.50))+parseFloat(data[0].data.tanker.above.year))   +  ((((parseFloat(price_)*0.08)-((parseFloat(price_)*0.08) *0.50))+parseFloat(data[0].data.tanker.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }

            }




            if (vehicleusage === "Ambulance / Hearse") {
             setseating_(3)
                    if (parseInt(caryear) > parseInt(year-1)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.07)+parseFloat(data[0].data.ambulance.above.year):((parseFloat(price_)*0.07)+parseFloat(data[0].data.ambulance.above.year))+(((parseFloat(price_)*0.07)+parseFloat(data[0].data.ambulance.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.ambulance.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.ambulance.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.15))+parseFloat(data[0].data.ambulance.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.20))+parseFloat(data[0].data.ambulance.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.30))+parseFloat(data[0].data.ambulance.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.30))+parseFloat(data[0].data.ambulance.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.ambulance.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.35))+parseFloat(data[0].data.ambulance.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.35))+parseFloat(data[0].data.ambulance.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
               else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.25))+parseFloat(data[0].data.ambulance.above.year):    ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.50))+parseFloat(data[0].data.ambulance.above.year))   +  ((((parseFloat(price_)*0.07)-((parseFloat(price_)*0.07) *0.50))+parseFloat(data[0].data.ambulance.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }

            }

            if (vehicleusage === "Z.802 On Road") {
                setseating_(3)
                   if (parseInt(caryear) > parseInt(year-1)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.03)+parseFloat(data[0].data.onroad.above.year):((parseFloat(price_)*0.03)+parseFloat(data[0].data.onroad.above.year))+(((parseFloat(price_)*0.03)+parseFloat(data[0].data.onroad.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.15))+parseFloat(data[0].data.onroad.above.year):    ((((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.15))+parseFloat(data[0].data.onroad.above.year))   +  ((((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.15))+parseFloat(data[0].data.onroad.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.20))+parseFloat(data[0].data.onroad.above.year):    ((((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.30))+parseFloat(data[0].data.onroad.above.year))   +  ((((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.30))+parseFloat(data[0].data.onroad.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.25))+parseFloat(data[0].data.onroad.above.year):    ((((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.35))+parseFloat(data[0].data.onroad.above.year))   +  ((((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.35))+parseFloat(data[0].data.onroad.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
               else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.25))+parseFloat(data[0].data.onroad.above.year):    ((((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.50))+parseFloat(data[0].data.onroad.above.year))   +  ((((parseFloat(price_)*0.03)-((parseFloat(price_)*0.03) *0.50))+parseFloat(data[0].data.onroad.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
            }

            if (vehicleusage === "Z.802 On Site") {
                setseating_(3)
                    if (parseInt(caryear) > parseInt(year-1)) {

                        setpremium_(buyback.toLowerCase() !=="yes"?(parseFloat(price_)*0.15)+parseFloat(data[0].data.onsite.above.year):((parseFloat(price_)*0.15)+parseFloat(data[0].data.onsite.above.year))+(((parseFloat(price_)*0.15)+parseFloat(data[0].data.onsite.above.year))*0.1))                 
                        document.getElementById("premium").value=premium_.toFixed(2)

                } else if((parseInt(caryear) === parseInt(year-1))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.15)-((parseFloat(price_)*0.15) *0.15))+parseFloat(data[0].data.onsite.above.year):    ((((parseFloat(price_)*0.15)-((parseFloat(price_)*0.15) *0.15))+parseFloat(data[0].data.onsite.above.year))   +  ((((parseFloat(price_)*0.15)-((parseFloat(price_)*0.15) *0.15))+parseFloat(data[0].data.onsite.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-2))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.15)-((parseFloat(price_)*0.15) *0.20))+parseFloat(data[0].data.onsite.above.year):    ((((parseFloat(price_)*0.15)-((parseFloat(price_)*0.15) *0.30))+parseFloat(data[0].data.onsite.above.year))   +  ((((parseFloat(price_)*0.15)-((parseFloat(price_)*0.15) *0.30))+parseFloat(data[0].data.onsite.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }else if((parseInt(caryear) === parseInt(year-3))) {

                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.15)-((parseFloat(price_)*0.15) *0.25))+parseFloat(data[0].data.onsite.above.year):    ((((parseFloat(price_)*0.15)-((parseFloat(price_)*0.15) *0.35))+parseFloat(data[0].data.onsite.above.year))   +  ((((parseFloat(price_)*0.15)-((parseFloat(price_)*0.15) *0.35))+parseFloat(data[0].data.onsite.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }
               else{
                         setpremium_(buyback.toLowerCase() !=="yes"?((parseFloat(price_)*0.15)-((parseFloat(price_)*0.15) *0.25))+parseFloat(data[0].data.onsite.above.year):    ((((parseFloat(price_)*0.15)-((parseFloat(price_)*0.15) *0.50))+parseFloat(data[0].data.onsite.above.year))   +  ((((parseFloat(price_)*0.15)-((parseFloat(price_)*0.15) *0.50))+parseFloat(data[0].data.onsite.above.year))*0.1)))
                        document.getElementById("premium").value=premium_.toFixed(2)

                }

            }

            if (vehicleusage === "Trade GW1 Class 1") {
                setseating_(3)
                if (parseInt(caryear) > parseInt(data[0].threshold)) {
                 setpremium_(parseFloat(price_)*0.05)
                        document.getElementById("premium").value=premium_.toFixed(2)


                } else {
 setpremium_(parseFloat(price_)*0.05)
                                             document.getElementById("premium").value=premium_.toFixed(2)

                }

            }

            if (vehicleusage === "Trade GW2 Class 2") {
                setseating_(3)
                if (parseInt(caryear) > parseInt(data[0].threshold)) {
                 setpremium_(parseFloat(price_)*0.06)
                        document.getElementById("premium").value=premium_.toFixed(2)


                } else {
 setpremium_(parseFloat(price_)*0.06)
                                             document.getElementById("premium").value=premium_.toFixed(2)


                }

            }

            if (vehicleusage === "Trade GW2 Class 2") {
                setseating_(3)
                if (parseInt(caryear) > parseInt(data[0].threshold)) {
                 setpremium_(parseFloat(price_)*0.07)
                        document.getElementById("premium").value=premium_.toFixed(2)


                } else {
 setpremium_(parseFloat(price_)*0.07)
                                             document.getElementById("premium").value=premium_.toFixed(2)


                }

            }

        }
    


    }, [policytype, vehicleusage, caryear, premium_, seating_, price_,buyback, period])


const changer=(e,data)=>{
data(e.target.value)
}

const changestartdate=(e)=>{
     var val = e.target.value
     setperiod(12)
     var year = parseInt(val.split("-")[0])
     var day = parseInt(val.split("-")[2])
     console.log()
     var done = (year+1)+"-"+(val.split("-")[1])+((day+"").length===1?"-0":"-")+(day+1)
     console.log(done)
     console.log(e.target.value)
     document.getElementById("enddate").value=done
    
}

const getperiod=(e)=>{

  var enddate = document.getElementById("enddate").value
  var startdate = document.getElementById("startdate").value
  var endmonth = (enddate.split("-")[1])
  var endyear = (enddate.split("-")[0])
  var startmonth = (startdate.split("-")[1])

  if(endyear-year===0){
      setperiod(parseInt(endmonth)-parseInt(startmonth))
        console.log(period)
  }else{
          setperiod((12-parseInt(startmonth))+parseInt(endmonth))
        console.log(parseInt(endmonth))
        console.log(period)

  }

}


    return (
<div>
<Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openpage}
      >
        <CircularProgress color="inherit" />
      </Backdrop>



      <Dialog maxWidth="md" onClose={handleClosesubscribe} aria-labelledby="customized-dialog-title" open={opensubscribe}>

<div>
<IconButton autoFocus onClick={handleClosesubscribe} style={{float:"right", margin:5}} color="primary">
                       <CloseIcon/>
                    </IconButton>
</div>
                <h2 align="center" style={{margin:0}}>Motor Policy Subscription</h2>

{/* <h1 align="center" style={{margin:0}}>{policytype}</h1> */}
<h1 align="center" style={{fontSize:50, margin:0, color:"orange"}}><sup style={{fontSize:16}}>GHC </sup>  {(premium_/parseInt(radioval)).toFixed(2)}<sup style={{fontSize:12}}> /Month</sup></h1>
<Container>
<p align="center">Undertake a monthly suscribtion payment plan to pay for your motor insurance policy.</p>

<Divider/>

<div style={{textAlign:"center"}}>
<h3 >Subscription Period</h3>

<FormControl style={{textAlign:"center"}} >
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        style={{textAlign:"center"}}
        onChange={changeradio}
      >
        <FormControlLabel value="3" control={<Radio />} label="3 Months" />
        <FormControlLabel value="6" control={<Radio />} label="6 Months" />
        <FormControlLabel value="12" checked  control={<Radio />} label="12 Months" />
        
      </RadioGroup>
    </FormControl>
    </div>
<Divider/>
<ul>
<li>No bulk payment</li>
<li>Only partial payment monthly</li>
<li>Mothly payment is automated</li>
</ul>
<div align="center">
{!loading?
              <input type="submit" value="SUBSCRIBE" style={{ padding: 13.5, cursor: "pointer", borderRadius: 5, outline: "none", border: "none", backgroundColor: "#062333", color: "white", width: "35ch", marginTop: "10px", marginBottom: "4px" }} />
:
              <Button size="large" disabled startIcon={<CircularProgress style={{ color: "#062333", width: "20px", height: "20px" }} />} style={{ backgroundColor: "lightgrey", color: "#062333", width: "35ch", marginTop: "10px", marginBottom: "8px" }} >Loading...</Button>
} 
</div>
<br/>
<br/>
</Container>




            </Dialog>





    <Dialog maxWidth="md" onClose={handleClosedelete} aria-labelledby="customized-dialog-title" open={opendelete}>
<Button autoFocus onClick={handleClosedelete} style={{float:"right"}} color="primary">
                        close
                    </Button>
<form onSubmit={yessubmit}  id="ll">
                <DialogContent dividers>
                <h2 align="center">Make Payment</h2>

<p><b>Payment Instructions</b></p>
<p>Please send your Premium to the merchant number provided and provide the transaction ID the forms below.</p>
<h2>Premium : GHC {premium_}</h2>
<h2>Send to : 024 0511421</h2>
                   <FormControl align="left" style={{ margin: 10 }}>
              <b>Transaction ID <span style={{ color: "red" }}> *</span></b>
              <TextField
                required
                id="transactionID"
                style={{ width: "28ch" }}
                type="text"
                placeholder="Transaction ID"
                size="small"
                inputProps={{maxLength:12}}
              helperText={transact===false?<span style={{color:"red"}}>Incorrect Transaction ID</span>:""}


              /> </FormControl>

                </DialogContent>
                <DialogActions>
                  {!loading?
              <input type="submit" value="BUY POLICY" style={{ padding: 13.5, cursor: "pointer", borderRadius: 5, outline: "none", border: "none", backgroundColor: "#062333", color: "white", width: "35ch", marginTop: "10px", marginBottom: "4px" }} />
:
              <Button size="large" disabled startIcon={<CircularProgress style={{ color: "#062333", width: "20px", height: "20px" }} />} style={{ backgroundColor: "lightgrey", color: "#062333", width: "35ch", marginTop: "10px", marginBottom: "8px" }} >Loading...</Button>
} 
                </DialogActions>
                </form>
            </Dialog>



  <form align="left" onSubmit={submitforms} id="ll">
   
            {JSON.parse(localStorage.getItem("user")).type==="Admin"?
          <div>
          <div  align="center" style={{margin:10}}>
     <FormControl>
 <FormLabel id="demo-row-radio-buttons-group-label">Choose Agent Type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby = "demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                id = "guardianedit"
                                value={agenttype}
                                onChange={handleChangeagenttype}
                            >
                                <FormControlLabel value="Direct" control={<Radio />} label="Head Office" />
                                <FormControlLabel value="Indirect" control={<Radio />} label="Kumasi Agent" />
                                
                            </RadioGroup>
                        </FormControl>
</div>
{agenttype==="Indirect"?

<div>
  
            <FormControl align="left" style={{ margin: 10 }}>
              <b>Agent Name <span style={{ color: "red" }}> *</span></b>
              <TextField
                
                id="agentname"
                style={{ width: "28ch" }}
                type="text"
                placeholder="Enter Full Name"
                size="small"

              /> </FormControl>

            <FormControl align="left" style={{ margin: 10 }}>
              <b>Agent Contact <span style={{ color: "red" }}> *</span></b>
              <TextField
                
                id="agentcontact"
                style={{ width: "28ch" }}
                type="tel"
                placeholder="Enter Contact Number"
                size="small"

              /> </FormControl>

            <FormControl align="left" style={{ margin: 10 }}>
              <b>Agent Email <span style={{ color: "red" }}> *</span></b>
              <TextField
                
                id="agentemail"
                style={{ width: "28ch" }}
                type="tel"
                placeholder="Enter Agent Email"
                size="small"
              /> </FormControl>

</div>
:""
}</div>:""
          }

   
   <Divider style={{marginBottom:"22px", backgroundColor:"#062333"}}>
        <Chip label="PERSONAL DETAILS" style={{backgroundColor:"white", color:"#062333"}} />
      </Divider>

<FormControl align="left" style={{margin:10}}> 
<b>Name <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="name"
          style={{width:"28ch"}}
          type="text"
          inputMode='name'
          placeholder="Enter Full Name"
          size="small"

        /> </FormControl>
        
        <FormControl align="left" style={{margin:10}}> 
<b>Phone <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="contact"
          style={{width:"28ch"}}
          type="tel"
          inputMode='tel'
          placeholder="Enter Contact"
          size="small"

        /> </FormControl>  
        <FormControl align="left" style={{margin:10}}> 
<b>Email Address </b>
 <TextField
          
          id="email"
          onChange={(e)=>changer(e,setpersonemail)}
          style={{width:"28ch"}}
          type="email"
          inputMode='email'
          placeholder="Enter Email"
          size="small"

        /> </FormControl>  
        <FormControl align="left" style={{margin:10}}> 
<b>Date of Birth </b>
 <TextField
          
          id="dob"
          onChange={(e)=>changer(e.setpersondob)}

          style={{width:"28ch"}}
          type="date"
          inputMode='date'
          placeholder="Hello World"
          size="small"

        /> </FormControl>
        


<br/>

  
        <FormControl align="left" style={{margin:10}}> 
<b>Occupation </b>
 <TextField
          onChange={(e)=>changer(e,setpersonoccupation)}
          id="occupation"
          style={{width:"28ch"}}
          type="text"
          placeholder="Enter Occupation"
          size="small"
          inputMode='occupation'
        /> </FormControl>
        
       

        <FormControl align="left" style={{margin:10}}> 
<b>Address <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="address"
          style={{width:"28ch"}}
          type="address"
          placeholder="Enter Address"
          size="small"

        /> </FormControl>
        
       

      
       

        {/* <FormControl align="left" style={{margin:10}}> 
        <b>Buy for another agent? <span style={{color:"red"}}> *</span></b>

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={buyagent}
          size="small"
          style={{width:"28ch"}}
          onChange={handleChangebuyagent}
        >
          <MenuItem value="none">
            <em>select</em>
          </MenuItem>
          <MenuItem value="yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
          
        </Select>
       </FormControl> */}
        <br/>
        <br/>
        <br/>
          <Divider style={{marginBottom:"22px", backgroundColor:"#062333"}}>
        <Chip label="POLICY DETAILS" style={{backgroundColor:"white", color:"#062333"}} />
      </Divider>
       
         <FormControl align="left" style={{margin:10}}> 
        <b>Policy type <span style={{color:"red"}}> *</span></b>

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={policytype}
          size="small"
          style={{width:"28ch"}}
          onChange={handleChangepolicytype}
        >
          <MenuItem value="none">
            <em>Choose Policy Type</em>
          </MenuItem>
          <MenuItem value="Motor Comprehensive">Motor Comprehensive</MenuItem>
          <MenuItem value="Motor Third Party Only">Motor Third Party Only</MenuItem>
          <MenuItem value="Motor Third Party Fire And Theft">Motor Third Party Fire And Theft</MenuItem>
          
        </Select>
       </FormControl>
 <FormControl align="left" style={{margin:10}}> 
        <b>Currency <span style={{color:"red"}}> *</span></b>

        <Select
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={currency}
          size="small"
          style={{width:"28ch"}}
          onChange={handleChangecurrency}
        >
          <MenuItem value="none">
            <em>Choose Currency</em>
          </MenuItem>
          <MenuItem value="Ghana Cedis">Ghana Cedis</MenuItem>
        </Select>
       </FormControl>       
        <FormControl align="left" style={{margin:10}}> 
        <b>Vehicle Usage <span style={{color:"red"}}> *</span></b>

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={vehicleusage}
          size="small"
          style={{width:"28ch"}}
          onChange={handleChangevehicleusage}
        >
          <MenuItem value="none">
            <em>Choose Vehicle Usage</em>
          </MenuItem>
          <MenuItem value="Private Individual">Private Individual</MenuItem>
          <MenuItem value="Private 4X4">Private 4X4</MenuItem>
          <MenuItem value="Private Company">Private Company</MenuItem>
          <MenuItem value="Own Goods (CC upto 3000)">Own Goods (CC upto 3000)</MenuItem>
          <MenuItem value="General Cartage (CC upto 3000)">General Cartage (CC upto 3000)</MenuItem>
          <MenuItem value="Taxi">Taxi</MenuItem>
          <MenuItem value="Hiring">Hiring</MenuItem>
          <MenuItem value="Motor Cycle">Motor Cycle</MenuItem>
          <MenuItem value="Mini Bus">Mini Bus</MenuItem>
          <MenuItem value="Maxi Bus">Maxi Bus</MenuItem>
          <MenuItem value="Ambulance / Hearse">Ambulance / Hearse</MenuItem>
          <MenuItem value="Z.802 On Site">Z.802 On Site</MenuItem>
          <MenuItem value="Trade GW1 Classs 1">Trade GW1 Classs 1</MenuItem>
          <MenuItem value="Trade GW1 Classs 2">Trade GW1 Classs 2</MenuItem>
          <MenuItem value="Trade GW1 Classs 3">Trade GW1 Classs 3</MenuItem>
          <MenuItem value="General Cartage (CC above 3000)">General Cartage (CC above 3000)</MenuItem>
          <MenuItem value="Own Goods (CC above 3000)">Own Goods (CC above 3000)</MenuItem>
          <MenuItem value="Art / Tanker">Art / Tanker</MenuItem>
          <MenuItem value="Z.802 On Road">Z.802 On Road</MenuItem>

        </Select>
       </FormControl>
        {/* <FormControl align="left" style={{margin:10}}> 
        <b>Branch <span style={{color:"red"}}> *</span></b>

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={branch}
          size="small"
          style={{width:"28ch"}}
          onChange={handleChangebranch}
        >
          <MenuItem value="none">
            <em>Choose Branch</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
       </FormControl> */}


<br/>
        <br/>
        <br/>
          <Divider style={{marginBottom:"22px", backgroundColor:"#062333"}}>
        <Chip label="VEHICLE DETAILS"  style={{backgroundColor:"white", color:"#062333"}}/>
      </Divider>



        <FormControl align="left" style={{margin:10}}> 
<b>Vehicle Make <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="vehiclemake"
          style={{width:"28ch"}}
          type="text"
          placeholder="Vehicle Make"
          size="small"

        /> </FormControl>
        
       
        <FormControl align="left" style={{margin:10}}> 
<b>Vehicle Model <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="model"
          style={{width:"28ch"}}

          placeholder="Vehicle Model"
          size="small"

        /> </FormControl>
        
           <FormControl align="left" style={{margin:10}}> 
        <b>Body Type <span style={{color:"red"}}> *</span></b>

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={bodytype}
          size="small"
          style={{width:"28ch"}}
          onChange={handleChangebodytype}
        >
          <MenuItem value="none">
            <em>Body Type</em>
          </MenuItem>
          <MenuItem value="Tractor">Tractor</MenuItem>
          <MenuItem value="Mini Bus">Mini Bus</MenuItem>
          <MenuItem value="Maxi Bus">Maxi Bus</MenuItem>
          <MenuItem value="Dump Truck">Dump Truck</MenuItem>
          <MenuItem value="4 Wheel Drive">4 Wheel Drive</MenuItem>
          <MenuItem value="Saloon">Saloon</MenuItem>
          <MenuItem value="Jeep">Jeep</MenuItem>
          <MenuItem value="Pick Up">Pick Up</MenuItem>
          <MenuItem value="Caravan">Caravan</MenuItem>
          <MenuItem value="Limousine">Limousine</MenuItem>
          <MenuItem value="Van">Van</MenuItem>
          <MenuItem value="Station Wagon">Station Wagon</MenuItem>
          <MenuItem value="Family Car">Family Car</MenuItem>
          <MenuItem value="Agricultural Vehicle">Agricultural Vehicle</MenuItem>
          <MenuItem value="Gas Tanker">Gas Tanker</MenuItem>
          <MenuItem value="Convertible">Convertible</MenuItem>
          <MenuItem value="Motor Cycle">Motor Cycle</MenuItem>
          <MenuItem value="Ambulance">Ambulance</MenuItem>
          <MenuItem value="Passenger Trailer">Passenger Trailer</MenuItem>
          <MenuItem value="Tipper Truck">Tipper Truck</MenuItem>
          <MenuItem value="Grader">Grader</MenuItem>
          <MenuItem value="Roller">Roller</MenuItem>
          <MenuItem value="Truck">Truck</MenuItem>
          <MenuItem value="Trailer">Trailer</MenuItem>
          <MenuItem value="SUV">SUV</MenuItem>
          <MenuItem value="Cope">Cope</MenuItem>
          <MenuItem value="LiftBack">LiftBack</MenuItem>
          <MenuItem value="Sports Car">Sports Car</MenuItem>
         
        </Select>
       </FormControl>

       
        <FormControl align="left" style={{margin:10}}> 
<b>Chasis Number <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="chasis"
          style={{width:"28ch"}}

          placeholder="Hello World"
          size="small"

        /> </FormControl>
        
        <br/>
       
        <FormControl align="left" style={{margin:10}}> 
<b>Cubic Capacity (CC) <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="cc"
          style={{width:"28ch"}}

          placeholder="Enter Capacity"
          size="small"

        /> </FormControl>
        
              
        <FormControl align="left" style={{margin:10}}> 
<b>Seating Capacity <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="sc"
          style={{width:"28ch"}}
          onChange={seating}
          placeholder="Enter Number"
          size="small"

        /> </FormControl>
        
       
        <FormControl align="left" style={{margin:10}}> 
<b>Vehicle Registration <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="registration"
          style={{width:"28ch"}}

          placeholder="Enter Registration Number"
          size="small"

        /> </FormControl>
        
       
        <FormControl align="left" style={{margin:10}}> 
<b>Colour <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="color"
          style={{width:"28ch"}}

          placeholder="Enter Colour"
          size="small"

        /> </FormControl>
        
       <br/>
          <FormControl align="left" style={{margin:10}}> 
        <b>Year of manufacturing <span style={{color:"red"}}> *</span></b>
       <TextField
          required
          id="myear"
          style={{width:"28ch"}}
          type="number"
          placeholder="Year of manufacturing"
          size="small"

        /> 
       </FormControl>

      {policytype === "Motor Comprehensive"?
                <FormControl align="left" style={{ margin: 10 }}>
                    <b>Vehicle Value <span style={{ color: "red" }}> *</span></b>
                    <TextField
                        
                        id="value"
                        defaultValue={price}
                        onChange={price}
                        type="number"
                        style={{ width: "28ch" }}
                        placeholder="Vehicle Value"
                        size="small"

                    /> </FormControl>
                    :""
}
        
       
        <FormControl align="left" style={{margin:10}}> 
<b>Start Date <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="startdate"
          onChange={changestartdate}
          style={{width:"28ch"}}
          type="date"
          size="small"

        /> </FormControl>
        
       {/* give endyear when start year is given */}
        <FormControl align="left" style={{margin:10}}> 
<b>End Date <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="enddate"
          defaultValue={enddate}
          style={{width:"28ch"}}
          type="date"
          size="small"
          onChange={getperiod}

        /> </FormControl>
        
       <br/>

      
           <FormControl align="left" style={{margin:10}}> 
        <b>Buy Extra TPPD? </b>

        <Select
        required
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={buyextra}
          size="small"
          style={{width:"28ch"}}
          onChange={handleChangebuyextra}
        >
          <MenuItem value="none">
            <em>Select</em>
          </MenuItem>
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
          
        </Select>
       </FormControl>
       
        <FormControl align="left" style={{margin:10}}> 
<b>Extra TPPD Amount</b>
 <TextField
        onChange={(e)=>changer(e,setpersontppdamount)}

          id="extratppd"
          style={{width:"28ch"}}
          placeholder="Enter TPPD Amount"
          size="small"
        /> </FormControl>
        
          <FormControl align="left" style={{margin:10}}> 
        <b>Buy Back Excess? </b>

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={buyback}
          size="small"
          style={{width:"28ch"}}
          onChange={handleChangebuyback}
        >
          <MenuItem value="none">
            <em>Select</em>
          </MenuItem>
         <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
          
        </Select>
       </FormControl>

   <FormControl align="left" style={{margin:10}}> 
        <b>Registration Year <span style={{color:"red"}}> *</span></b>

        <TextField
          required
          id="registrationyear"
          style={{width:"28ch"}}
          type="number"
          defaultValue={caryear}
          onChange={handleChangecaryear}
          placeholder="Registration Year"
          size="small"

        /> 

       </FormControl>
       <br/>
       <FormControl align="left" style={{margin:10}}> 
        <b>Premium<span style={{color:"red"}}> *</span></b>

        <TextField
          required
          id="premium"
          style={{width:"28ch"}}
          type="number"
        //  onChange={changepremium}
          placeholder="Enter Premium"
          size="small"

        /> 

       </FormControl>

          <div align="center">
      {!loading?
              <input type="submit" value="SUBMIT" style={{ padding: 13.5, cursor: "pointer", borderRadius: 5, outline: "none", border: "none", backgroundColor: "#062333", color: "white", width: "35ch", marginTop: "10px", marginBottom: "40px" }} />
:
              <Button size="large" disabled startIcon={<CircularProgress style={{ color: "#062333", width: "20px", height: "20px" }} />} style={{ backgroundColor: "lightgrey", color: "#062333", width: "35ch", marginTop: "10px", marginBottom: "8px" }} >Loading...</Button>
}
{!loading?
               <Button size="small" onClick={handleOpensubscribe}  style={{ padding: 10.5, cursor: "pointer", borderRadius: 5, outline: "none", border: "none", backgroundColor: "orange", color: "white", width: "36ch", marginTop: "-20px", marginBottom: "40px" }} >SUBSCRIBE</Button>

:
              <Button size="large" disabled startIcon={<CircularProgress style={{ color: "#062333", width: "20px", height: "20px" }} />} style={{ backgroundColor: "lightgrey", color: "#062333", width: "35ch", marginTop: "-20px", marginBottom: "8px" }} >Loading...</Button>
}
          </div>



 </form>
</div>
    );
}

export default Profile1;


const cost = [
  {usage:"Private individual", above:"2018", 
  abovedata:{fullyear:"327", month1:"65", month2:"95", month3:"130", month4:"165", month5:"200", month6:"235", month7:"270", month8:"327"},
  belowdata:{fullyear:"235", month1:"55", month2:"80", month3:"105", month4:"130", month5:"155", month6:"181", month7:"206", month8:"240"}

} ,
{usage:"Taxi", above:"2018", 
  abovedata:{fullyear:"438", month1:"76.8", month2:"124", month3:"170.40", month4:"217.20", month5:"264", month6:"311", month7:"358", month8:"343"},
  belowdata:{fullyear:"360", month1:"68.46", month2:"107", month3:"145.39", month4:"284", month5:"222.30", month6:"261", month7:"299", month8:"365"}

}
 ,
{usage:"Hiring", above:"2018", 
  abovedata:{fullyear:"438", month1:"76.8", month2:"124", month3:"170.40", month4:"217.20", month5:"264", month6:"311", month7:"358", month8:"343"},
  belowdata:{fullyear:"360", month1:"68.46", month2:"107", month3:"145.39", month4:"284", month5:"222.30", month6:"261", month7:"299", month8:"365"}

}


]

