import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import Search from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import Close from '@mui/icons-material/Close';
import { visuallyHidden } from '@mui/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import SupplyProduct from '@mui/icons-material/LocalShipping';
import SearchIcon from '@mui/icons-material/Search';
import AddSRV from '@mui/icons-material/PostAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Downloader from './downloader';

import Backdrop from '@mui/material/Backdrop';

import Chip from '@mui/material/Chip';

import Menustyle from './menumotor';
import Changestatus from './changestatus';


import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { Document, Page } from 'react-pdf';
import CircularProgress from '@mui/material/CircularProgress';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_motor } from '../redux/motor';
import { update_travel } from '../redux/travel';

import { JsonToExcel } from 'react-json-to-excel';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';



import swal from 'sweetalert';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function createData(_id, logo,name, contact, email, schoolID, address, yearestablished, studentpopulation, staffpopulation, terms, year, password, date, status) {
    return {
        _id, logo, name, contact, email, schoolID, address, yearestablished, studentpopulation, staffpopulation, terms, year, password,date, status
    };
}

const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [

    {
        id: 'No.',
        numeric: !false,
        disablePadding: !true,
        label: 'NO.',
    },
    {
        id: 'calories',
        numeric: true,
        disablePadding: false,
        label: 'Date',
    },    {
        id: 'calories',
        numeric: true,
        disablePadding: false,
        label: 'School Name',
    },
    {
        id: 'fat',
        numeric: true,
        disablePadding: false,
        label: 'Contact',
    },  {
        id: 'fat',
        numeric: true,
        disablePadding: false,
        label: 'Location',
    }, {
        id: 'email',
        numeric: true,
        disablePadding: false,
        label: 'Email',
    }, 
    
    {
        id: 'carbs',
        numeric: true,
        disablePadding: false,
        label: 'Num. of Students',
    },{
        id: 'carbs',
        numeric: true,
        disablePadding: false,
        label: 'Num. of Staff',
    },{
        id: 'carbs',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    },{
        id: 'carbs',
        numeric: true,
        disablePadding: false,
        label: 'Actions',
    }
  
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <b>{headCell.label}</b>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >

            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                SRV
            </Typography>



        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
      const [openpage, setOpenpage] = React.useState(true);

    const dispatch = useDispatch()
    const inst = useSelector((state) => state.selectedagent)

const getmotordata = async () => {
         
        await axios.post("https://cromer.sisrevolution.com/api/schools/getschools/",
            {
             
            }).then(res => {
                if (res.status === 200) {
                    console.log(res.data)
                    dispatch(update_accident({
                        data: res.data
                    }))
                }
            })
    }
  

    // const getmotordata = async () => {
    //     await axios.post("https://server.ayconsultgh.com/api/motordata/getmotorpolicy/",
    //         {
    //             userID:inst.IDnumber,
    //             type:"Agent",
    //             institution:inst.institution,
    //             year:"none",
    //             month:"none",
    //             day:"none"

                
            

    //         }).then(res => {
    //             if (res.status === 200) {
    //                 dispatch(update_motor({
    //                     data: res.data
    //                 }))

    //                 setOpenpage(!true)
    //             }
    //         })
    // }
   
  const gettraveldata = async () => {
        await axios.post("https://server.ayconsultgh.com/api/traveldata/gettravelpolicy/",
            {
                userID:inst.IDnumber,
                type:"Agent",
                institution:inst.institution,
                year:"none",
                month:"none",
                day:"none"
            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_travel({
                        data: res.data
                    }))
                }
            })
    }
   
     const gethomedata = async () => {
        await axios.post("https://server.ayconsultgh.com/api/homedata/gethomepolicy/",
            {

           userID:inst.IDnumber,
                type:"Agent",
                institution:inst.institution,
                year:"none",
                month:"none",
                day:"none"

            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_home({
                        data: res.data
                    }))
                }
            })
    }
   
  const getaccidentdata = async () => {
        await axios.post("https://server.ayconsultgh.com/api/accidentdata/getaccidentpolicy/",
            {
                userID:inst.IDnumber,
                type:"Agent",
                institution:inst.institution,
                year:"none",
                month:"none",
                day:"none"
            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_accident({
                        data: res.data
                    }))
                }
            })
    }
   






    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl_, setAnchorEl_] = React.useState(null);
    const open = Boolean(anchorEl);
    const open_ = Boolean(anchorEl_);
    const [age, setAge] = React.useState('');
    const [pageloader, setpageloader] = React.useState(true);


    const [openadd, setOpenadd] = React.useState(!true);
    const [openview, setOpenview] = React.useState(!true);
    const [opendocument, setOpendocument] = React.useState(!true);
    const [openclaims, setOpenclaims] = React.useState(!true);
    const [openpaycommission, setOpenpaycommission] = React.useState(!true);
    const [openpolicynumber, setOpenpolicynumber] = React.useState(!true);
    const [openchangestatus, setOpenchangestatus] = React.useState(!true);
    const [opensrv, setOpensrv] = React.useState(!true);
    const [openedit, setOpenedit] = React.useState(!true);
    const [opendelete, setOpendelete] = React.useState(!true);
    const [chosen, setchosen] = React.useState([]);
    const [copyname, setcopyname] = React.useState("copy to clipboard");
    const [Openmessage, setOpenmessage] = React.useState([]);
    const [search, setsearch] = React.useState("");
    const [person, setperson] = React.useState("");

    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");
    const [opensnack, setOpensnack] = React.useState(false);
    const [numPages, setNumPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [loading, setloading] = React.useState(false)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }   
    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };
    
    const motor = useSelector((state) => state.accident)
console.log(motor)
    const data = [motor.map((list, index) => createData(list._id, list.logo, list.schoolname, list.contact,list.email, list.schoolID, list.address, list.yearestablished, list.studentpopulation, list.staffpopulation, list.terms, list.yearestablished, list.primarypassword, list.date, list.status))]
    var rows = data[0]

useEffect(() => {
 
        getmotordata()
        // gettraveldata()
        // getaccidentdata()
        // gethomedata()
   
   
  },[inst])

  
 if (search !== "") {
        console.log(search)
        var listed = rows.filter(bug => bug.name.toLowerCase().includes(search.toLowerCase()) || bug.contact.toLowerCase().includes(search.toLowerCase()) ||  bug.email.toLowerCase().includes(search.toLowerCase()) ||  bug.status.toLowerCase().includes(search.toLowerCase()) )
        rows=listed
    }

    const searcher = (e)=>{
        setsearch(e.target.value)
    }

    const handleClickOpenedit = () => {
        setOpenedit(true);
    };
 
    const handleClickOpendelete = () => {
         setchosen(data[0])
        setOpendelete(true);
    };

    const handleClosedelete = () => {
        setOpendelete(false);
    };
  
    const handleClickOpenview = (data) => {
        setchosen(data)
        setOpenview(true);
    };

    const handleCloseview = () => {
        setOpenview(false);
    };

    const handleClickOpendocument = (data) => {
        setchosen(data)
        setOpendocument(true);
    };

    const handleClosedocument = () => {
        setOpendocument(false);
    };

    const handleClickOpenclaims = (data) => {
        setchosen(data)
        setOpenclaims(true);
    };

    const handleCloseclaims = () => {
        setOpenclaims(false);
    };

    const handleClickOpenchangestatus = (data) => {
        setchosen(data)
        setOpenchangestatus(true);
    };

    const handleClosechangestatus = () => {
        setOpenchangestatus(false);
    };

    const handleClickOpenpolicynumber = (data) => {
        setchosen(data)
        setOpenpolicynumber(true);
    };

    const handleClosepolicynumber = () => {
        setOpenpolicynumber(false);
    };

   const handleClickOpenpaycommission = (data) => {
       setchosen(data)
        setOpenpaycommission(true);
    };

    const handleClosepaycommission = () => {
        setOpenpaycommission(false);
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


const copytext =(text, user)=>{
    navigator.clipboard.writeText(text);
    user("Copied")

}

const submitpolicynumber= (e)=>{
e.preventDefault()
const submitting=async()=>{
          await axios.post("https://server.ayconsultgh.com/api/motordata/changepolicynumber/",
            {
              _id:chosen._id,
              number:document.getElementById("policynumber").value,
                userID:inst._id,
                type:"Agent",
                institution:inst.institution
            }).then(res => {
                if (res.status === 200) {
                  console.log(res.data)
                    dispatch(update_motor({
                        data: res.data
                    }))
                    handleClosepolicynumber()
                    swal("", "Policy Number Changed to " + document.getElementById("policynumber").value, "success");
                    e.target.reset()
                }
            })
    }

   submitting()

}

const policydocument = (e) => {
        setloading(true)
        e.preventDefault()
            var doc = {schedule:document.getElementById("schedule").value, cert:document.getElementById("certificate").value, sticker:document.getElementById("sticker").value}
      
                    
        const buyingpolicy = async () => {
            await axios.post("https://server.ayconsultgh.com/api/motordata/policydocument/", {
                      document:doc,
                        _id:chosen._id,
                        agentemail:chosen.agentemail,
                        name:chosen.name,
                        policytype:"Motor Insurance Policy",
                        policynumber:chosen.policynumber,
                        startdate:chosen.startdate,
                        premium:chosen.premium,
                        suminsured:chosen.suminsured,
                        certificate:document.getElementById("certificate").value,
                        sticker:document.getElementById("sticker").value,
                        schedule:document.getElementById("schedule").value,
                        }).then(res => {

                    if (res.status === 200) {

                        setloading(!true)
                        swal("", "Policy document uploaded", "success");
                        e.target.reset()


                    } else {
                        setloading(!true)
                        setmessage("Error adding Motor Policy")
                        setstated("error")
                    }



                })

        }

        buyingpolicy()

    }
    
const policyclaims = (e) => {
    console.log(chosen)
        setloading(true)
        e.preventDefault()
            var doc = [{amount:document.getElementById("claimamount").value, date:document.getElementById("claimdate").value, others:document.getElementById("otherclaim").value}]
      
                    
        const buyingpolicy = async () => {
            await axios.post("https://server.ayconsultgh.com/api/motordata/policyclaims/", {
                        claims:doc,
                        _id:chosen._id,
                        agentemail:chosen.agentemail,
                        name:chosen.name,
                        policytype:"Motor Insurance Policy",
                        policynumber:chosen.policynumber,
                        startdate:chosen.startdate,
                        premium:chosen.premium,
                        suminsured:chosen.suminsured,
                     
                        }).then(res => {

                    if (res.status === 200) {

                        setloading(!true)
                        swal("", "Policy Claim uploaded", "success");
                        e.target.reset()


                    } else {
                        setloading(!true)
                        setmessage("Error updating policy claim")
                        setstated("error")
                    }

                })

        }

        buyingpolicy()

    }

    const paycommission = (e) => {
        setloading(true)
      
                    
        const buyingpolicy = async () => {
            await axios.post("https://server.ayconsultgh.com/api/motordata/paycommission/", {

                        _id:chosen._id,
                        commissionpayment:"Paid"

                        }).then(res => {

                    if (res.status === 200) {

                        setloading(!true)
                        swal("", "Commission has been paid", "success");
                        // e.target.reset()
                                                handleClosepaycommission()



                    } else {
                        setloading(!true)
                        setmessage("Error paying commission")
                        setstated("error")
                    }



                })

        }

        buyingpolicy()

    }

    const deletepolicy = (e) => {
        setloading(true)
      console.log(chosen)
                    
        const buyingpolicy_delete = async () => {
            await axios.post("https://server.ayconsultgh.com/api/motordata/deletemotorpolicy/", {

                        _id:chosen[0]._id,
                       
                        }).then(res => {

                    if (res.status === 200) {
                     dispatch(update_motor({
                        data: res.data
                    }))
                        setloading(!true)
                        swal("", "Policy Deleted", "success");
                        // e.target.reset()
                                                handleClosedelete()



                    } else {
                        setloading(!true)
                         handleClosedelete()
                        setmessage("Error deleting policy")
                        
                    }



                })

        }

        buyingpolicy_delete()

    }


 const imagehandler = (e) => {
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setperson(e.target.files[0].name)
            }
        }

        reader.readAsDataURL(e.target.files[0])
    }

const changingname=()=>{
        setcopyname("copy to clipboard")
    }

    return (
        <Box sx={{ width: '100%' }}>
  
<Snackbar style={{position:"fixed",bottom:0}} open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>

                <Dialog fullWidth maxWidth="sm" onClose={handleCloseview} aria-labelledby="customized-dialog-title" open={openview}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseview} style={{ color: "grey" }}>
                      <b>Policy Information</b>
                      <IconButton  style={{float:"right"}} onClick={handleCloseview}><Close /></IconButton>
                    </DialogTitle>

                    <DialogContent >
                    <List>
                     <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.transactionID:""} <IconButton><Tooltip  title={copyname} onMouseLeave={changingname} onClick={()=>copytext(chosen.length !==0 ? chosen.transactionID:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Transaction ID" />
      </ListItem>
                      <Divider>
        <Chip label="PERSONAL DETAILS" />
      </Divider>
        <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.name:""} <IconButton><Tooltip  title={copyname} onMouseLeave={changingname} onClick={()=>copytext(chosen.length !==0 ? chosen.name:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Fullname" />
      </ListItem>

  <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.contact:""} <IconButton><Tooltip title={copyname} onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.contact:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Contact" />
      </ListItem>

  <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.email:""} <IconButton><Tooltip title={copyname} onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.email:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Email" />
      </ListItem>

  <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.dob:""} <IconButton><Tooltip title={copyname} onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.dob:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Date Of Birth" />
      </ListItem>

  <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.occupation:""} <IconButton><Tooltip title={copyname} onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.occupation:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Occupation" />
      </ListItem>

  <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.address:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.address:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Address" />
      </ListItem>

  <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.agentbuy:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.agentbuy:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Buy For Agent" />
      </ListItem>




  <Divider>
        <Chip label="POLICY DETAILS" />
      </Divider>



  <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.policytype:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.policytype:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Policy Type" />
      </ListItem>

  <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.currency:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.currency:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Currency" />
      </ListItem>

  <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.usage:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}   onClick={()=>copytext(chosen.length !==0 ? chosen.usage:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Vehicle Usage" />
      </ListItem>

  <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.branch:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}   onClick={()=>copytext(chosen.length !==0 ? chosen.branch:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Branch" />
      </ListItem>


  <Divider>
        <Chip label="VEHICLE DETAILS" />
      </Divider>


  <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.make:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}   onClick={()=>copytext(chosen.length !==0 ? chosen.make:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Vehicle Make" />
      </ListItem>
  <ListItem>
        

        <ListItemText primary={<div> {chosen.length !==0 ? chosen.model:""} <IconButton><Tooltip title={copyname}   onMouseLeave={changingname}   onClick={()=>copytext(chosen.length !==0 ? chosen.model:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Vehicle Model" />
      </ListItem>
  <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.bodytype:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}   onClick={()=>copytext(chosen.length !==0 ? chosen.bodytype:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Body Type" />
      </ListItem>
  <ListItem>
        
       <ListItemText primary={<div> {chosen.length !==0 ? chosen.chasis:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.chasis:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Chasis number" />
      </ListItem>
  <ListItem>
        
       <ListItemText primary={<div> {chosen.length !==0 ? chosen.cc:""} <IconButton><Tooltip title={copyname} onMouseLeave={changingname}    onClick={()=>copytext(chosen.length !==0 ? chosen.cc:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Cubic Capacity (CC)" />
      </ListItem>
  <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.seats:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}   onClick={()=>copytext(chosen.length !==0 ? chosen.seats:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Seating Capacity" />
      </ListItem>
  <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.registration:""} <IconButton><Tooltip title={copyname}   onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.registration:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Vehicle Registration" />
      </ListItem>

 <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.color:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.color:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Color" />
      </ListItem>
 <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.year:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.year:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Year of Manufacturing" />
      </ListItem>
 <ListItem>
        
       <ListItemText primary={<div> {chosen.length !==0 ? chosen.value:""} <IconButton><Tooltip title={copyname} onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.value:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Vehicle Value" />
      </ListItem>
 <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.startdate:""} <IconButton><Tooltip title={copyname} onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.startdate:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Start Date" />
      </ListItem> 
      
      <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.enddate:""} <IconButton><Tooltip title={copyname} onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.enddate:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="End Date" />
      </ListItem>

 
      <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.TPPD:""} <IconButton><Tooltip title={copyname} onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.TPPD:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Buy Extra TPPD" />
      </ListItem>

      <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.TPPDamount:""} <IconButton><Tooltip title={copyname} onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.TPPDamount:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Extra TPPD Amount" />
      </ListItem>


      <ListItem>
        
       <ListItemText primary={<div> {chosen.length !==0 ? chosen.backexcess:""} <IconButton><Tooltip title={copyname} onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.backexcess:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Buy Back Excess" />
      </ListItem>

      <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.registrationyear:""} <IconButton><Tooltip title={copyname} onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.registrationyear:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Registration Year" />
      </ListItem>



      </List>


                   
                    </DialogContent>

                   
                </Dialog>

                <Dialog fullWidth maxWidth="sm" onClose={handleClosedocument} aria-labelledby="customized-dialog-title" open={opendocument}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClosedocument} style={{ color: "grey" }}>
                      <b>Policy Document</b>
                      <IconButton  style={{float:"right"}} onClick={handleClosedocument}><Close /></IconButton>
                    </DialogTitle>

                       <div style={{ width: "100%", textAlign:"center", marginTop:20 }}>
{/* {chosen.length!==0?chosen.policydocument.length!==0? "submited" : "Not Submited" :"Not Submited"} */}

<form onSubmit={policydocument}>
                            
                            
                            <input type='link' id="schedule"  placeholder='Enter Scedule Link'  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"14px", width:250}}/>
                            <br/>
                            <input type='link' id="certificate"  placeholder='Enter Enter Certificate Link'  style={{outline:"none", marginTop:10, border:"solid", borderWidth:"0.1px", borderRadius:"5px", borderColor:"lightgray", padding:"14px", width:250}}/>
                            <br/>
                            <input type='link' id="sticker"  placeholder='Enter Sticker Link'  style={{outline:"none", marginTop:10, border:"solid", borderWidth:"0.1px", borderRadius:"5px", borderColor:"lightgray", padding:"14px", width:250}}/>
                              
                            <br/>

                            {
                            !loading?
        <input type="submit" value="SUBMIT" style={{outline:"none", width:280, border:"none", backgroundColor:"#062333", color:"white", padding:12 , cursor:"pointer", margin:10, borderRadius:5}} />
        :
        <Button size="large" disabled startIcon={<CircularProgress style={{color:"#062333", width:"20px", height:"20px"}} />}  style={{ backgroundColor:"lightgrey", color:"#062333", width:"35ch", marginTop:"10px", marginBottom:"8px"}} >Loading...</Button>

                           }


                           <br/>
                           <br/>
                      

</form>
</div>
{/* <div>
      <Document file="https://www.africau.edu/images/default/sample.pdf" onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
    </div> */}

                    <DialogContent >
                 
                 </DialogContent>
                   
                </Dialog>




                <Dialog fullWidth maxWidth="sm" onClose={handleCloseclaims} aria-labelledby="customized-dialog-title" open={openclaims}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseclaims} style={{ color: "grey" }}>
                      <b>Update Claims</b>
                      <IconButton  style={{float:"right"}} onClick={handleCloseclaims}><Close /></IconButton>
                    </DialogTitle>

                       <div style={{ width: "100%", textAlign:"center", marginTop:20 }}>

<form onSubmit={policyclaims}>
                            
                            
                            <input type='link' required id="claimamount"  placeholder='Enter Amount Claimed'  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"14px", width:250}}/>
                            <br/>
                            <input type='date' required id="claimdate"  placeholder='Enter Date'  style={{outline:"none", marginTop:10, border:"solid", borderWidth:"0.1px", borderRadius:"5px", borderColor:"lightgray", padding:"14px", width:250}}/>
                            <br/>
                            <input type='text' required id="otherclaim"  placeholder='Other Claim Information'  style={{outline:"none", marginTop:10, border:"solid", borderWidth:"0.1px", borderRadius:"5px", borderColor:"lightgray", padding:"14px", width:250}}/>
                              
                            <br/>

                            {
                            !loading?
        <input type="submit" value="SUBMIT" style={{outline:"none", width:280, border:"none", backgroundColor:"#062333", color:"white", padding:12 , cursor:"pointer", margin:10, borderRadius:5}} />
        :
        <Button size="large" disabled startIcon={<CircularProgress style={{color:"#062333", width:"20px", height:"20px"}} />}  style={{ backgroundColor:"lightgrey", color:"#062333", width:"35ch", marginTop:"10px", marginBottom:"8px"}} >Loading...</Button>

                           }


                           <br/>
                           <br/>
                      

</form>
</div>


                    <DialogContent >
                 
                 </DialogContent>
                   
                </Dialog>




                <Dialog  maxWidth="sm" onClose={handleClosedelete} aria-labelledby="customized-dialog-title" open={opendelete}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClosedelete} style={{ color: "grey" }}>
                      <b>Delete Policy</b>
                      <IconButton  style={{float:"right"}} onClick={handleClosedelete}><Close /></IconButton>
                    </DialogTitle>

                    <DialogContent >
<p align="center">Are you sure you want to delete this policy?</p>

<div>


                            {
                            !loading?
  <Button onClick={deletepolicy} style={{  height:"20px"}}>Yes</Button>        :
        <Button size="large" disabled startIcon={<CircularProgress style={{color:"#062333", width:"20px", height:"20px"}} />}  style={{ backgroundColor:"lightgrey", color:"#062333",  marginTop:"10px", marginBottom:"8px"}} >Loading...</Button>

                           }

    

    <Button style={{float:"right"}}>NO</Button>
</div>

                   
                    </DialogContent>

                   
                </Dialog>

                <Dialog fullWidth maxWidth="sm" onClose={handleClosechangestatus} aria-labelledby="customized-dialog-title" open={openchangestatus}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClosechangestatus} style={{ color: "grey" }}>
                      <b>Change Status</b>
                      <IconButton  style={{float:"right"}} onClick={handleClosechangestatus}><Close /></IconButton>
                    </DialogTitle>

                    <DialogContent >

<Changestatus status={chosen.length!==0?chosen.status:"None"} type="motor"  _id={chosen.length!==0?chosen._id:"None"} link="https://server.ayconsultgh.com/api/motordata/changestatus/"/>
                   
                    </DialogContent>

                   
                </Dialog>

                <Dialog fullWidth maxWidth="sm" onClose={handleClosepolicynumber} aria-labelledby="customized-dialog-title" open={openpolicynumber}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClosepolicynumber} style={{ color: "grey" }}>
                      <b>Add Policy Number</b>
                      <IconButton  style={{float:"right"}} onClick={handleClosepolicynumber}><Close /></IconButton>
                    </DialogTitle>

                    <DialogContent align="center" >

                        <input type='text' id="policynumber"  placeholder='Enter Policy Number'  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"14px"}}/>
                        <Button size="large" onClick={submitpolicynumber} style={{backgroundColor:"#062333" ,color:"white", marginLeft:7}}>Submit</Button>
                   <br/>
                   <br/>
                   <br/>
                    </DialogContent>

                   
                </Dialog>

            
                <Dialog fullWidth maxWidth="sm" onClose={handleClosepaycommission} aria-labelledby="customized-dialog-title" open={openpaycommission}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClosepaycommission} style={{ color: "grey" }}>
                      <b>Pay Commission</b>
                      <IconButton  style={{float:"right"}} onClick={handleClosepaycommission}><Close /></IconButton>
                    </DialogTitle>

                    <DialogContent >
<List>
                          <ListItem>
        
        <ListItemText primary={chosen.length!==0?"GHC "+ chosen.agentcommission :"No Commission"} secondary="Amount to be paid" />
      </ListItem>
        <ListItem>
        
        <ListItemText primary={chosen.length!==0?chosen.commissionpayment !== "none"? "Paid":"Not Paid" :"Not Paid"} secondary="Status" />
      </ListItem>

</List>
{chosen.length !==0 ?chosen.commissionpayment==="none"?
<Button onClick={paycommission} style={{backgroundColor:"#062333", color:"white"}}> Pay </Button>
:""
:""
}
                   
                    </DialogContent>

                   
                </Dialog>


            <Paper sx={{ width: '100%', mb: 2 }}>

<Grid container style={{paddingTop:10}} align="center">
                    <Grid lg={3} xs={12} md={2} align="center">

                <JsonToExcel title="Export To Excel"  data={rows} fileName="good-one" btnClassName="custom-classname" />

                      {/* <Button style={{marginTop:"7px", backgroundColor:"lightgray"}} color="primary">Export to excell</Button> */}

                    </Grid>
                    
                    <Grid lg={4} xs={12} md={4}>
                        <SearchIcon style={{position:"absolute", marginTop:"15px", marginLeft:"6px", color:"lightgrey"}}/><input onChange={searcher} type="search" placeholder="Search" style={{padding: 9, outline:"none",  marginTop: 10, width: "100%", border:"solid", borderWidth:"0.2px", borderColor:"lightgrey", paddingLeft:"40px",borderRadius:"5px" }} />
                    </Grid>

                    <Grid lg={5} sm={12} md={5}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />

                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                        >
                                            <TableCell style={{ textAlign: "left" }}>
                                                <span>
                                                    {(index + 1 + (page * rowsPerPage)) + "."}
                                                </span>
                                            </TableCell>

                                            <TableCell align="left">{row.date}</TableCell>                                          
                                            <TableCell align="left">{row.name}</TableCell>
                                            <TableCell align="left">{row.contact}</TableCell>
                                            <TableCell align="left">{row.address}</TableCell>
                                            <TableCell align="left">{row.email}</TableCell>
                                            <TableCell align="left">{row.studentpopulation}</TableCell>
                                            <TableCell align="left">{row.staffpopulation}</TableCell>
                                            <TableCell align="left">{row.status}</TableCell>
                                            
                                            <TableCell align="left">{<Menustyle data={row} view={(sender) => handleClickOpenview(sender)} policydocument={(sender) => handleClickOpendocument(sender)}  policyclaims={(sender) => handleClickOpenclaims(sender)} changestatus={(sender) => handleClickOpenchangestatus(sender)} policynumber={(sender) => handleClickOpenpolicynumber(sender)} paycommission={(sender) => handleClickOpenpaycommission(sender)} addsupply={(sender) => handleClickOpenview(sender)} edit={(sender) => handleClickOpenedit(sender)} delete={(sender) => handleClickOpendelete(sender)} />}</TableCell>

                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Box>
    );
}
