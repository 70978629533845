import { configureStore } from "@reduxjs/toolkit"
import admin from "./admin"
import admin_ from "./admin_"
import accident from "./accident"
import agent from "./agent"
import home from "./home"
import motor from "./motor"
import travel from "./travel"
import settings from "./settings"
import selectedagent from "./selectedagent"
import rooms from "./rooms"
import bookings from "./bookings"
import emails from "./emails"
import chat from "./chat"



export default configureStore({

    reducer: {
        admin: admin,
        admin_: admin_,
        accident: accident,
        agent: agent,
        home: home,
        motor: motor,
        travel: travel,
        settings: settings,
        rooms: rooms,
        bookings: bookings,
        emails: emails,
        chat: chat,
        selectedagent: selectedagent,
    },

})