import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import Search from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import Close from '@mui/icons-material/Close';
import { visuallyHidden } from '@mui/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import SupplyProduct from '@mui/icons-material/LocalShipping';
import SearchIcon from '@mui/icons-material/Search';
import AddSRV from '@mui/icons-material/PostAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import Chip from '@mui/material/Chip';

import Menustyle from './menuadmin';
import Changestatus from './changestatus';


import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { Document, Page } from 'react-pdf';
import CircularProgress from '@mui/material/CircularProgress';

import swal from 'sweetalert';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_admin_ } from '../redux/admin_';
// import { update_travel } from '../redux/travel';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';




const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



function createData(_id, name, contact, email, dob, address, marritalstatus , status, admintype, localID, IDtype, IDnumber, datejoined , gender) {
    return {
            _id, name, contact, email, dob, address, marritalstatus , status, admintype, localID, IDtype, IDnumber, datejoined, gender     };
}


const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [

    {
        id: 'No.',
        numeric: !false,
        disablePadding: !true,
        label: 'NO.',
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'contact',
        numeric: true,
        disablePadding: false,
        label: 'Contact',
    }, {
        id: 'email',
        numeric: true,
        disablePadding: false,
        label: 'Email',
    },  {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    }, 
    
    {
        id: 'admintype',
        numeric: true,
        disablePadding: false,
        label: 'Admin Type',
    },{
        id: 'localid',
        numeric: true,
        disablePadding: false,
        label: 'Local Id',
    }
    ,{
        id: '',
        numeric: true,
        disablePadding: false,
        label: 'Actions',
    }
  
];





function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <b>{headCell.label}</b>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >

            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                SRV
            </Typography>



        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
        const dispatch = useDispatch()
        // const inst = useSelector((state) => state.selectedagent)
           const [pageloader, setpageloader] = React.useState(true);


    const getadmindata = async () => {
        await axios.post("https://server.ayconsultgh.com/api/admin/getadmindata/",
            {
            
            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_admin_({
                        data: res.data
                    }))
                }
            })
    }
   
     useEffect(() => {

        getadmindata()
   
  },[pageloader])


    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl_, setAnchorEl_] = React.useState(null);
    const open = Boolean(anchorEl);
    const open_ = Boolean(anchorEl_);
    const [age, setAge] = React.useState('');


    const [openadd, setOpenadd] = React.useState(!true);
    const [openview, setOpenview] = React.useState(!true);
    const [openpaycommission, setOpenpaycommission] = React.useState(!true);
    const [openpolicynumber, setOpenpolicynumber] = React.useState(!true);
    const [openchangestatus, setOpenchangestatus] = React.useState(!true);
    const [opensrv, setOpensrv] = React.useState(!true);
    const [openedit, setOpenedit] = React.useState(!true);
    const [opendelete, setOpendelete] = React.useState(!true);
    const [chosen, setchosen] = React.useState([]);
    const [copyname, setcopyname] = React.useState("copy to clipboard");
    const [Openmessage, setOpenmessage] = React.useState([]);
    const [search, setsearch] = React.useState("");
    const [opendocument, setOpendocument] = React.useState(!true);
    const [person, setperson] = React.useState("");
    const [idtype, setidtype] = React.useState("")
    const [gender, setgender] = React.useState("")
    const [marital, setmarital] = React.useState("")
    
    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");
    const [opensnack, setOpensnack] = React.useState(false);
    const [numPages, setNumPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [loading, setloading] = React.useState(false)
    const [name, setname] = React.useState("")
    const [contact, setcontact] = React.useState("")
    const [email, setemail] = React.useState("")
    const [dob, setdob] = React.useState("")
    const [status, setstatus] = React.useState("")
    const [admintype, setadmintype] = React.useState("")
    const [localID, setlocalID] = React.useState("")
    const [idnumber, setidnumber] = React.useState("")
    const [address, setaddress] = React.useState("")

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }               
    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };
 const travel = useSelector((state) => state.admin_)

    const data = [travel.map((list, index) => createData(list._id, list.name, list.contact, list.email, list.dob, list.address, list.marritalstatus , list.status, list.admintype, list.localID, list.IDtype, list.IDnumber, list.datejoined, list.gender ))]
    var rows = data[0]


   

  
 if (search !== "") {
        console.log(search)
        var listed = rows.filter(bug => bug.name.toLowerCase().includes(search.toLowerCase()) || bug.contact.toLowerCase().includes(search.toLowerCase()) ||  bug.email.toLowerCase().includes(search.toLowerCase()) )
        rows=listed
    }

    const searcher = (e)=>{
        setsearch(e.target.value)
    }


    const handleClickOpenedit = () => {
        setOpenedit(true);
    };
 
    const handleClickOpendelete = (data) => {
        setchosen(data)
        setOpendelete(true);
    };
    const handleClosedelete = () => {
        setOpendelete(false);
    };

  
    const handleClickOpenview = (data) => {
        setchosen(data)
        setOpenview(true);
    };
    const handleCloseview = () => {
        setOpenview(false);
    };

    const handleClickOpenchangestatus = (data) => {
        setchosen(data)
        setOpenchangestatus(true);
    };
    const handleClosechangestatus = () => {
        setOpenchangestatus(false);
    };
    const handleClickOpenpolicynumber = (data) => {
        setchosen(data)
        setOpenpolicynumber(true);
    };
    const handleClosepolicynumber = () => {
        setOpenpolicynumber(false);
    };

   const handleClickOpenpaycommission = (data) => {
       setchosen(data)
        setOpenpaycommission(true);
    };
    const handleClosepaycommission = () => {
        setOpenpaycommission(false);
    };

     const handleClickOpendocument = (data) => {
        setchosen(data)
        setOpendocument(true);
    };

    const handleClosedocument = () => {
        setOpendocument(false);
    };



    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


        const copytext =(text, user)=>{
    navigator.clipboard.writeText(text);
    user("Copied")

}

      const policydocument = (e) => {
        setloading(true)
        e.preventDefault()

        const file = document.getElementById("fileimage").files[0]
               
        const fd = new FormData()
          if (file) {
                   
                        fd.append("file", file)
                        fd.append("name", chosen.policynumber)
                    }

                    fd.append("_id",chosen._id)
                   
                    
        const buyingpolicy = async () => {
            await axios.post("https://server.ayconsultgh.com/api/motordata/policydocument/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }


                        }).then(res => {

                    if (res.status === 200) {

                        setloading(!true)
                        swal("", "Policy document uploaded", "success");


                    } else {
                        setloading(!true)
                        setmessage("Error adding Motor Policy")
                        setstated("error")
                    }



                })

        }

        buyingpolicy()

    }


 const imagehandler = (e) => {
        const reader = new FileReader()
        reader.onload = () => {
            if (reader.readyState === 2) {
                setperson(e.target.files[0].name)
            }
        }

        reader.readAsDataURL(e.target.files[0])
    }

       const changingname=()=>{
        setcopyname("Copy to clipboard")
    }


    
    const handleChangestatus = (event) => {
        setstatus(event.target.value);
    };

    const handleChangeadmintype = (event) => {
        setadmintype(event.target.value);
    };
    
    const handleChangeidtype = (event) => {
        setidtype(event.target.value);
    };
    const handleChangegender = (event) => {
        setgender(event.target.value);
    };
    const handleChangemarital = (event) => {
        setmarital(event.target.value);
    };

    
    const submitting = (e) => {
        setloading(true)
        e.preventDefault()


        const file = document.getElementById("fileimage").files[0]
        const name = document.getElementById("name").value
        const contact = document.getElementById("contact").value
        const email = document.getElementById("email").value
        const dob = document.getElementById("dob").value
        const address = document.getElementById("address").value
        const localid = document.getElementById("localid").value
        const security = document.getElementById("security").value
        
        const idnumber = document.getElementById("idnumber").value
        const marritalstatus = marital

        const gender_ = gender
        const idtype_ = idtype

        const fd = new FormData()
        if (file) {

            fd.append("file", file)
            fd.append("image", document.getElementById("fileimage").files[0].name)
        }

        fd.append("_id", chosen._id)
        fd.append("name", name)
        fd.append("user", "admin")
        fd.append("contact", contact)
        fd.append("email", email)
        fd.append("dob", dob)
        fd.append("address", address)
        fd.append("status", status===""?chosen.status:status)
        fd.append("marritalstatus", marital===""?chosen.marritalstatus:marital)
        fd.append("gender", gender===""?chosen.gender:gender)
        fd.append("IDtype", idtype===""?chosen.IDtype:idtype)
        fd.append("IDnumber", idnumber)
        fd.append("localID", localid)
        fd.append("admintype", admintype===""?chosen.admintype:admintype)


        const buyingpolicy = async () => {
            await axios.post("https://server.ayconsultgh.com/api/admin/editadmin/", fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            }).then(res => {

                if (res.status === 200) {
                    setloading(!true)
                     dispatch(update_admin_({
                        data: res.data
                    }))
                    swal("", " Admin data updated", "success");

                } else {
                    setloading(!true)
                   swal("", " Error Registering Admin ", "error");
                }



            })

        }

        buyingpolicy()

    }
  
    const yesdelete = (e) => {
        setloading(true)
        const fd = new FormData()

        fd.append("_id", chosen._id)
   

        const buyingpolicy = async () => {
            await axios.post("https://server.ayconsultgh.com/api/admin/deleteadmin/", fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            }).then(res => {

                if (res.status === 200) {
                    dispatch(update_admin_({
                        data: res.data
                    }))
                    setloading(!true)
                    swal("", "Admin Deleted", "success");

                } else {
                    setloading(!true)
                   swal("", " Error Deleting Admin ", "error");
                }



            })

        }

        buyingpolicy()

    }

    const changer= (setter,e)=>{
        setter(e.target.value)
    }



    return (
        <Box sx={{ width: '100%' }}>

<Snackbar style={{position:"sticky", bottom:0}} open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    { message }
                </Alert>
            </Snackbar>

                <Dialog fullWidth maxWidth="sm" onClose={handleCloseview} aria-labelledby="customized-dialog-title" open={openview}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseview} style={{ color: "grey" }}>
                      <b>Agent Information</b>
                      <IconButton  style={{float:"right"}} onClick={handleCloseview}><Close /></IconButton>
                    </DialogTitle>

                    <DialogContent >
                    <List>
                      <Divider>
        <Chip label="PERSONAL DETAILS" />
      </Divider>
        <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.name:""} <IconButton><Tooltip  title={copyname}  onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.name:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Fullname" />
      </ListItem>

  <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.contact:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.contact:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Contact" />
      </ListItem>

  <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.email:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.email:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Email" />
      </ListItem>

   <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.IDtype:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.IDtype:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="ID type" />
      </ListItem>

   <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.IDnumber:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.IDnumber:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="ID Number" />
      </ListItem>

   <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.status:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.status:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Status" />
      </ListItem>

   <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.admintype:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.admintype:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Admin Type" />
      </ListItem>

   <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.localID:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.localID:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Local ID" />
      </ListItem>

  

  <ListItem>
        
        <ListItemText primary={<div> {chosen.length !==0 ? chosen.address:""} <IconButton><Tooltip title={copyname}  onMouseLeave={changingname}  onClick={()=>copytext(chosen.length !==0 ? chosen.address:"" ,setcopyname )} style={{marginLeft:"10px"}}><ContentCopyIcon style={{fontSize:"17px"}}/></Tooltip></IconButton> </div>} secondary="Address" />
      </ListItem>

   


 
      </List>


             </DialogContent>
                   
                </Dialog>

                 <Dialog fullWidth maxWidth="sm" onClose={handleClosedocument} aria-labelledby="customized-dialog-title" open={opendocument}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClosedocument} style={{ color: "grey" }}>
                      <b>Policy Document</b>
                      <IconButton  style={{float:"right"}} onClick={handleClosedocument}><Close /></IconButton>
                    </DialogTitle>

                       <div style={{ width: "100%", textAlign:"center", marginTop:20 }}>


<form onSubmit={policydocument}>
                            <input

                                style={{ display: "none", marginBottom:5 }}
                                id="fileimage"
                                multiple
                                type="file"
                                onChange={(e) => imagehandler(e)}

                                
                            />
                            <p style={{marginBottom:40}}>Upload PDF only</p>
                            <label htmlFor="fileimage">
                             
                             
                        <span size="large" style={{backgroundColor:"lightgrey", color:"#062333", padding:12 , cursor:"pointer", margin:10, borderRadius:5}}>Choose file</span> <span> {person} </span>

                                

                            </label>
                            <br/>
                            <br/>
                            {person!==""?
                            !loading?
                <input type="submit" value="SUBMIT" style={{outline:"none", width:300, border:"none", backgroundColor:"#062333", color:"white", padding:12 , cursor:"pointer", margin:10, borderRadius:5}} />
          :
          <Button size="large" disabled startIcon={<CircularProgress style={{color:"#062333", width:"20px", height:"20px"}} />}  style={{ backgroundColor:"lightgrey", color:"#062333", width:"35ch", marginTop:"10px", marginBottom:"8px"}} >Loading...</Button>
         
                           
                            :""}
                           <br/>
                           <br/>
                      

</form>
</div>
{/* <div>
      <Document file="https://www.africau.edu/images/default/sample.pdf" onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
    </div> */}

                    <DialogContent >
                 
                 </DialogContent>
                   
                </Dialog>


                <Dialog  maxWidth="sm" onClose={handleClosedelete} aria-labelledby="customized-dialog-title" open={opendelete}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClosedelete} style={{ color: "grey" }}>
                      <b>Delete Admin</b>
                      <IconButton  style={{float:"right"}} onClick={handleClosedelete}><Close /></IconButton>
                    </DialogTitle>
                    <DialogContent >
<p align="center">Are you sure you want to delete this admin?</p>

<div>
    <Button onClick={yesdelete}>Yes</Button>
    <Button style={{float:"right"}}>NO</Button>
</div>

                   
                    </DialogContent>

                   
                </Dialog>

                <Dialog fullWidth maxWidth="sm" onClose={handleClosechangestatus} aria-labelledby="customized-dialog-title" open={openchangestatus}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClosechangestatus} style={{ color: "grey" }}>
                      <b>Change Status</b>
                      <IconButton  style={{float:"right"}} onClick={handleClosechangestatus}><Close /></IconButton>
                    </DialogTitle>

                    <DialogContent >

{console.log(chosen)}
<Changestatus status={chosen.length!==0?chosen.status:"None"} type="accident"  _id={chosen.length!==0?chosen._id:"None"} link="https://server.ayconsultgh.com/api/accidentdata/changestatus/"/>
                   
                    </DialogContent>

                   
                </Dialog>

                <Dialog fullWidth maxWidth="lg" onClose={handleClosepolicynumber} aria-labelledby="customized-dialog-title" open={openpolicynumber}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClosepolicynumber} style={{ color: "grey" }}>
                      <b>Update Admin </b>
                      <IconButton  style={{float:"right"}} onClick={handleClosepolicynumber}><Close /></IconButton>
                    </DialogTitle>

                    <DialogContent align="center" >

                    
                      <form onSubmit={submitting}>
                <Grid container spacing={4}>

                    <Grid item xs={12} lg={12} align="center">
                        <div style={{ padding: 10, borderRadius: "10px", border: "solid", borderColor: "#062333", borderWidth: "0.05px" }}>
                            <Avatar size="large" src={person} style={{ width: "13ch", height: "13ch", border: "solid", borderWidth: "0.1px" }} />
                            <p style={{ color: "#062333" }}><b>Person Name Here</b></p>
                            <div style={{ width: "100%", textAlign: "center", marginTop: 20 }}>

                                <input

                                    style={{ display: "none", marginBottom: 5 }}
                                    id="fileimage"
                                    multiple
                                    type="file"
                                    onChange={(e) => imagehandler(e)}
                                />
                                <label htmlFor="fileimage">


                                    <span size="large" style={{ backgroundColor: "#062333", color: "white", padding: 12, cursor: "pointer", margin: 10, borderRadius: 5 }}>Upload New Image</span>

                                </label>

                                <br />
                                <br />
                            </div>

                        </div>
                    </Grid>



                    <Grid item xs={12} lg={12}>


                        <div align="center" style={{ padding: 10, borderRadius: "10px", borderColor: "#062333", borderWidth: "0.05px" }}>

                            <Divider style={{ marginBottom: "18px", backgroundColor: "#062333" }}>
                                <Chip label="PERSONAL DETAILS" style={{ backgroundColor: "white", color: "#062333" }} />
                            </Divider>

                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Name </b>
                                <TextField
                                    required
                                    id="name"
                                    
                                    defaultValue={chosen.name}
                                    onChange={(e)=>changer(setname,e)}
                                    style={{ width: "28ch" }}
                                    type="text"
                                    inputMode='name'
                                    placeholder="Enter Full Name"
                                    size="small"

                                /> </FormControl>
                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Contact </b>
                                <TextField
                                    required
                                    id="contact"
                                     defaultValue={chosen.contact}
                                     onChange={(e)=>changer(setcontact,e)}

                                    style={{ width: "28ch" }}
                                    type="tel"
                                    inputMode='tel'
                                    placeholder="Enter Contact"
                                    size="small"

                                /> </FormControl>
                          
                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Email </b>
                                <TextField

                                    id="email"
                                     defaultValue={chosen.email}
                                    onChange={(e)=>changer(setemail,e)}

                                    style={{ width: "28ch" }}
                                    type="email"
                                    placeholder="Enter Email"
                                    size="small"

                                /> </FormControl>
  <br/>
                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Date of Birth </b>
                                <TextField

                                    id="dob"
                                     defaultValue={chosen.dob}
                                     onChange={(e)=>changer(setdob,e)}

                                    style={{ width: "28ch" }}
                                    type="date"
                                    placeholder="Enter "
                                    size="small"

                                /> </FormControl>

                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Marital Status </b>

                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={marital===""?chosen.marritalstatus:marital}
                                    size="small"
                                    style={{ width: "28ch" }}
                                    onChange={handleChangemarital}
                                >
                                    <MenuItem value="none">
                                        <em>Marital status</em>
                                    </MenuItem>
                                    <MenuItem value="Single">Single</MenuItem>
                                    <MenuItem value="Married">Married</MenuItem>
                                    <MenuItem value="Divorced">Divorced</MenuItem>
                                    <MenuItem value="Complicated">COmplicated</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Gender</b>

                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={gender===""?chosen.gender:gender}
                                    size="small"
                                    style={{ width: "28ch" }}
                                    onChange={handleChangegender}
                                >
                                    <MenuItem value="none">
                                        <em>Choose Gender</em>
                                    </MenuItem>
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>

                                </Select>
                            </FormControl>
    <br/>
                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Admin Type</b>

                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={admintype===""?chosen.admintype:admintype}
                                    size="small"
                                    style={{ width: "28ch" }}
                                    onChange={handleChangeadmintype}
                                >
                                    <MenuItem value="none">
                                        <em>Choose Admin Type</em>
                                    </MenuItem>
                                    <MenuItem value="Primary">Primary</MenuItem>
                                    <MenuItem value="Secondary">Secondary</MenuItem>

                                </Select>
                            </FormControl>

                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>ID Type</b>

                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={idtype===""?chosen.IDtype:idtype}
                                    size="small"
                                    style={{ width: "28ch" }}
                                    onChange={handleChangeidtype}
                                >
                                    <MenuItem value="none">
                                        <em>Choose ID Type</em>
                                    </MenuItem>
                                    <MenuItem value="Ghana Card">Ghana Card</MenuItem>
                                    <MenuItem value="Passport">Passport</MenuItem>
                                    <MenuItem value="Voter ID">Voter ID</MenuItem>

                                </Select>
                            </FormControl>

                          
                            
                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>ID Number </b>
                                <TextField

                                    id="idnumber"
                                     defaultValue={chosen.IDnumber}
                                     onChange={(e)=>changer(setidnumber,e)}

                                    style={{ width: "28ch" }}
                                    type="address"
                                    placeholder="Enter ID number"
                                    size="small"

                                /> </FormControl>
                                  <br />

                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Local ID </b>
                                <TextField

                                    id="localid"
                                     defaultValue={chosen.localID}
                                     onChange={(e)=>changer(setlocalID,e)}

                                    style={{ width: "28ch" }}
                                    type="address"
                                    placeholder="Enter Local ID"
                                    size="small"

                                /> </FormControl> 
                                
                                <FormControl align="left" style={{ margin: 10 }}>
                                <b>Address </b>
                                <TextField

                                    id="address"
                                     defaultValue={chosen.address}
                                     onChange={(e)=>changer(setaddress,e)}

                                    style={{ width: "28ch" }}
                                    type="address"
                                    placeholder="Enter Address"
                                    size="small"

                                /> </FormControl>


                            <FormControl align="left" style={{ margin: 10 }}>
                                <b>Admin Status</b>

                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={status===""?chosen.status:status}
                                    size="small"
                                    style={{ width: "28ch" }}
                                    onChange={handleChangeidtype}
                                >
                                    <MenuItem value="none">
                                        <em>Choose Status</em>
                                    </MenuItem>
                                    <MenuItem value="Active">Active</MenuItem>
                                    <MenuItem value="Suspended">Suspend</MenuItem>

                                </Select>
                            </FormControl>

                            <br />
                            <br />

                            <div align="center">
                                {!loading ?
                                    <input type="submit" value="SUBMIT" style={{ padding: 13.5, cursor: "pointer", borderRadius: 5, outline: "none", border: "none", backgroundColor: "#062333", color: "white", width: "35ch", marginTop: "10px", marginBottom: "40px" }} />
                                    :
                                    <Button size="large" disabled startIcon={<CircularProgress style={{ color: "#062333", width: "20px", height: "20px" }} />} style={{ backgroundColor: "lightgrey", color: "#062333", width: "35ch", marginTop: "10px", marginBottom: "8px" }} >Loading...</Button>
                                }
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                    </Grid>

                </Grid>

            </form>



                    </DialogContent>

                   
                </Dialog>


                <Dialog fullWidth maxWidth="sm" onClose={handleClosepaycommission} aria-labelledby="customized-dialog-title" open={openpaycommission}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClosepaycommission} style={{ color: "grey" }}>
                      <b>Pay Commission</b>
                      <IconButton  style={{float:"right"}} onClick={handleClosepaycommission}><Close /></IconButton>
                    </DialogTitle>

                    <DialogContent >
<List>
                          <ListItem>
        
        <ListItemText primary="GHC 200" secondary="Amount to be paid" />
      </ListItem>
        <ListItem>
        
        <ListItemText primary="Paid" secondary="Status" />
      </ListItem>

</List>

<Button> Pay </Button>
                   
                    </DialogContent>

                   
                </Dialog>


            


            <Paper sx={{ width: '100%', mb: 2 }}>

<Grid container style={{paddingTop:10}} align="center">
                    <Grid lg={3} xs={12} md={2} align="center">

                      <Link to="/registeradmin"><Button style={{marginTop:"7px", backgroundColor:"#062333" , color:"white"}} color="primary">Register New AY Admin</Button></Link>

                    </Grid>
                    

                    <Grid lg={4} xs={12} md={4}>
                        <SearchIcon style={{position:"absolute", marginTop:"15px", marginLeft:"6px", color:"lightgrey"}}/><input onChange={searcher} type="search" placeholder="Search" style={{padding: 9, outline:"none",  marginTop: 10, width: "100%", border:"solid", borderWidth:"0.2px", borderColor:"lightgrey", paddingLeft:"40px",borderRadius:"5px" }} />
                    </Grid>

                    <Grid lg={5} sm={12} md={5}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />

                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover

                                        >
                                            <TableCell style={{ textAlign: "left" }}>
                                                <span>
                                                    {(index + 1 + (page * rowsPerPage)) + "."}
                                                </span>
                                            </TableCell>

                                            <TableCell align="left">{row.name}</TableCell>                                          
                                            <TableCell align="left">{row.contact}</TableCell>
                                            <TableCell align="left">{row.email}</TableCell>
                                            <TableCell align="left">{row.status}</TableCell>
                                            <TableCell align="left">{row.admintype}</TableCell>
                                            <TableCell align="left">{row.localID}</TableCell>
                                            
                                            <TableCell align="left">{<Menustyle data={row} view={(sender) => handleClickOpenview(sender)} policydocument={(sender) => handleClickOpendocument(sender)} changestatus={(sender) => handleClickOpenchangestatus(sender)} policynumber={(sender) => handleClickOpenpolicynumber(sender)} paycommission={(sender) => handleClickOpenpaycommission(sender)} addsupply={(sender) => handleClickOpenview(sender)} edit={(sender) => handleClickOpenedit(sender)} delete={(sender) => handleClickOpendelete(sender)} />}</TableCell>

                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Box>
    );
}
