import  React ,{useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';

import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import axios from "axios"

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';


import Close from '@mui/icons-material/Close';
import Person from '../images/Ellipse77.png';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Ayadmin from './ayadmin_';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_agent } from '../redux/agent';
import { update_settings } from '../redux/settings';

import swal from 'sweetalert';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function Profile4() {
    const dispatch = useDispatch()
    
    const getsettingsdata = async () => {
        await axios.post("https://server.ayconsultgh.com/api/settings/getsettings/",
            {

            }).then(res => {
                if (res.status === 200) {
                    console.log(res)
                    dispatch(update_settings({
                        data: res.data
                    }))
                }
            })
    }

const settings = useSelector((state) => state.settings)

const [loading, setloading] = React.useState(!true)
const [pager, setpager] = React.useState(true)
const [opendelete, setOpendelete] = React.useState(!true)
const [opentax, setOpentax] = React.useState(!true)
const [openaycommission_direct, setOpenaycommission_direct] = React.useState(!true)
const [openagentcommission, setOpenagentcommission] = React.useState(!true)
const [openaycommission, setOpenaycommission] = React.useState(!true)
const [openmonthly, setOpenmonthly] = React.useState(!true)
const [openmotor, setOpenmotor] = React.useState(!true)

useEffect(() => {
 
        getsettingsdata()
   
   
  },[pager])


const handleClickOpendelete = () => {
        setOpendelete(true);
    };

    const handleClosedelete = () => {
        setOpendelete(false);
    };
  
const handleClickOpenaycommission = () => {
        setOpenaycommission(true);
    };

    const handleCloseaycommission = () => {
        setOpenaycommission(false);
    };
    
const handleClickOpenmonthly = () => {
        setOpenmonthly(true);
    };

    const handleClosemonthly = () => {
        setOpenmonthly(false);
    };
  
const handleClickOpenagentcommission = () => {
        setOpenagentcommission(true);
    };

    const handleCloseagentcommission = () => {
        setOpenagentcommission(false);
    };
  
const handleClickOpentax = () => {
        setOpentax(true);
    };

    const handleClosetax = () => {
        setOpentax(false);
    };
  const handleClickOpenaycommission_direct = () => {
        setOpenaycommission_direct(true);
    };

    const handleCloseaycommission_direct = () => {
        setOpenaycommission_direct(false);
    };
  
    const handleClickOpenmotor = () => {
        setOpenmotor(true);
    };

    const handleClosemotor = () => {
        setOpenmotor(false);
    };
  
    
    const submitting = (e) => {
        setloading(true)
        // e.preventDefault()
console.log(document.getElementById("aboveprivatefullyear").value)
        var data={
            privatecar:{persons:5,
            above:{year:document.getElementById("aboveprivatefullyear").value, month1:document.getElementById("aboveprivate1").value, month2:document.getElementById("aboveprivate2").value, month3:document.getElementById("aboveprivate3").value, month4:document.getElementById("aboveprivate4").value, month5:document.getElementById("aboveprivate5").value, month6:document.getElementById("aboveprivate6").value, month7:document.getElementById("aboveprivate7").value, month8:document.getElementById("aboveprivate8").value }, 
            below:{year:document.getElementById("belowprivatefullyear").value, month1:document.getElementById("belowprivate1").value, month2:document.getElementById("belowprivate2").value, month3:document.getElementById("belowprivate3").value, month4:document.getElementById("belowprivate4").value, month5:document.getElementById("belowprivate5").value, month6:document.getElementById("belowprivate6").value, month7:document.getElementById("belowprivate7").value, month8:document.getElementById("belowprivate8").value }
        },   
        taxi:{persons:5,
            above:{year:document.getElementById("abovetaxifullyear").value, month1:document.getElementById("abovetaxi1").value, month2:document.getElementById("abovetaxi2").value, month3:document.getElementById("abovetaxi3").value, month4:document.getElementById("abovetaxi4").value, month5:document.getElementById("abovetaxi5").value, month6:document.getElementById("abovetaxi6").value, month7:document.getElementById("abovetaxi7").value, month8:document.getElementById("abovetaxi8").value }, 
            below:{year:document.getElementById("belowtaxifullyear").value, month1:document.getElementById("belowtaxi1").value, month2:document.getElementById("belowtaxi2").value, month3:document.getElementById("belowtaxi3").value, month4:document.getElementById("belowtaxi4").value, month5:document.getElementById("belowtaxi5").value, month6:document.getElementById("belowtaxi6").value, month7:document.getElementById("belowtaxi7").value, month8:document.getElementById("belowtaxi8").value }
        },
        hiring:{persons:5,
            above:{year:document.getElementById("abovehiringfullyear").value, month1:document.getElementById("abovehiring1").value, month2:document.getElementById("abovehiring2").value, month3:document.getElementById("abovehiring3").value, month4:document.getElementById("abovehiring4").value, month5:document.getElementById("abovehiring5").value, month6:document.getElementById("abovehiring6").value, month7:document.getElementById("abovehiring7").value, month8:document.getElementById("abovehiring8").value }, 
            below:{year:document.getElementById("belowhiringfullyear").value, month1:document.getElementById("belowhiring1").value, month2:document.getElementById("belowhiring2").value, month3:document.getElementById("belowhiring3").value, month4:document.getElementById("belowhiring4").value, month5:document.getElementById("belowhiring5").value, month6:document.getElementById("belowhiring6").value, month7:document.getElementById("belowhiring7").value, month8:document.getElementById("belowhiring8").value }
        }, 
        motor:{persons:2,
            above:{year:document.getElementById("abovemotorcyclefullyear").value, month1:document.getElementById("abovemotorcycle1").value, month2:document.getElementById("abovemotorcycle2").value, month3:document.getElementById("abovemotorcycle3").value, month4:document.getElementById("abovemotorcycle4").value, month5:document.getElementById("abovemotorcycle5").value, month6:document.getElementById("abovemotorcycle6").value, month7:document.getElementById("abovemotorcycle7").value, month8:document.getElementById("abovemotorcycle8").value }, 
            below:{year:document.getElementById("belowmotorcyclefullyear").value, month1:document.getElementById("belowmotorcycle1").value, month2:document.getElementById("belowmotorcycle2").value, month3:document.getElementById("belowmotorcycle3").value, month4:document.getElementById("belowmotorcycle4").value, month5:document.getElementById("belowmotorcycle5").value, month6:document.getElementById("belowmotorcycle6").value, month7:document.getElementById("belowmotorcycle7").value, month8:document.getElementById("belowmotorcycle8").value }
        }, 
        
        motor_1:{persons:12,
            above:{year:document.getElementById("abovemotorcyclefullyear_1").value, month1:document.getElementById("abovemotorcycle1_1").value, month2:document.getElementById("abovemotorcycle2_1").value, month3:document.getElementById("abovemotorcycle3_1").value, month4:document.getElementById("abovemotorcycle4_1").value, month5:document.getElementById("abovemotorcycle5_1").value, month6:document.getElementById("abovemotorcycle6_1").value, month7:document.getElementById("abovemotorcycle7_1").value, month8:document.getElementById("abovemotorcycle8_1").value }, 
            below:{year:document.getElementById("belowmotorcyclefullyear_1").value, month1:document.getElementById("belowmotorcycle1_1").value, month2:document.getElementById("belowmotorcycle2_1").value, month3:document.getElementById("belowmotorcycle3_1").value, month4:document.getElementById("belowmotorcycle4_1").value, month5:document.getElementById("belowmotorcycle5_1").value, month6:document.getElementById("belowmotorcycle6_1").value, month7:document.getElementById("belowmotorcycle7_1").value, month8:document.getElementById("belowmotorcycle8_1").value }
        },  
        motor_2:{persons:15,
            above:{year:document.getElementById("abovemotorcyclefullyear_2").value, month1:document.getElementById("abovemotorcycle1").value, month2:document.getElementById("abovemotorcycle2").value, month3:document.getElementById("abovemotorcycle3").value, month4:document.getElementById("abovemotorcycle4").value, month5:document.getElementById("abovemotorcycle5").value, month6:document.getElementById("abovemotorcycle6").value, month7:document.getElementById("abovemotorcycle7").value, month8:document.getElementById("abovemotorcycle8").value }, 
            below:{year:document.getElementById("belowmotorcyclefullyear_2").value, month1:document.getElementById("belowmotorcycle1").value, month2:document.getElementById("belowmotorcycle2").value, month3:document.getElementById("belowmotorcycle3").value, month4:document.getElementById("belowmotorcycle4").value, month5:document.getElementById("belowmotorcycle5").value, month6:document.getElementById("belowmotorcycle6").value, month7:document.getElementById("belowmotorcycle7").value, month8:document.getElementById("belowmotorcycle8").value }
        },
        motor_3:{persons:19,
            above:{year:document.getElementById("abovemotorcyclefullyear_3").value, month1:document.getElementById("abovemotorcycle1").value, month2:document.getElementById("abovemotorcycle2").value, month3:document.getElementById("abovemotorcycle3").value, month4:document.getElementById("abovemotorcycle4").value, month5:document.getElementById("abovemotorcycle5").value, month6:document.getElementById("abovemotorcycle6").value, month7:document.getElementById("abovemotorcycle7").value, month8:document.getElementById("abovemotorcycle8").value }, 
            below:{year:document.getElementById("belowmotorcyclefullyear_3").value, month1:document.getElementById("belowmotorcycle1").value, month2:document.getElementById("belowmotorcycle2").value, month3:document.getElementById("belowmotorcycle3").value, month4:document.getElementById("belowmotorcycle4").value, month5:document.getElementById("belowmotorcycle5").value, month6:document.getElementById("belowmotorcycle6").value, month7:document.getElementById("belowmotorcycle7").value, month8:document.getElementById("belowmotorcycle8").value }
        },   
        motor_4:{persons:23,
            above:{year:document.getElementById("abovemotorcyclefullyear_4").value, month1:document.getElementById("abovemotorcycle1").value, month2:document.getElementById("abovemotorcycle2").value, month3:document.getElementById("abovemotorcycle3").value, month4:document.getElementById("abovemotorcycle4").value, month5:document.getElementById("abovemotorcycle5").value, month6:document.getElementById("abovemotorcycle6").value, month7:document.getElementById("abovemotorcycle7").value, month8:document.getElementById("abovemotorcycle8").value }, 
            below:{year:document.getElementById("belowmotorcyclefullyear_4").value, month1:document.getElementById("belowmotorcycle1").value, month2:document.getElementById("belowmotorcycle2").value, month3:document.getElementById("belowmotorcycle3").value, month4:document.getElementById("belowmotorcycle4").value, month5:document.getElementById("belowmotorcycle5").value, month6:document.getElementById("belowmotorcycle6").value, month7:document.getElementById("belowmotorcycle7").value, month8:document.getElementById("belowmotorcycle8").value }
        },  
        motor_5:{persons:27,
            above:{year:document.getElementById("abovemotorcyclefullyear_5").value, month1:document.getElementById("abovemotorcycle1").value, month2:document.getElementById("abovemotorcycle2").value, month3:document.getElementById("abovemotorcycle3").value, month4:document.getElementById("abovemotorcycle4").value, month5:document.getElementById("abovemotorcycle5").value, month6:document.getElementById("abovemotorcycle6").value, month7:document.getElementById("abovemotorcycle7").value, month8:document.getElementById("abovemotorcycle8").value }, 
            below:{year:document.getElementById("belowmotorcyclefullyear_5").value, month1:document.getElementById("belowmotorcycle1").value, month2:document.getElementById("belowmotorcycle2").value, month3:document.getElementById("belowmotorcycle3").value, month4:document.getElementById("belowmotorcycle4").value, month5:document.getElementById("belowmotorcycle5").value, month6:document.getElementById("belowmotorcycle6").value, month7:document.getElementById("belowmotorcycle7").value, month8:document.getElementById("belowmotorcycle8").value }
        },

    owngoods:{persons:3,
            above:{year:document.getElementById("aboveowngoodsfullyear").value, month1:document.getElementById("aboveowngoods1").value, month2:document.getElementById("aboveowngoods2").value, month3:document.getElementById("aboveowngoods3").value, month4:document.getElementById("aboveowngoods4").value, month5:document.getElementById("aboveowngoods5").value, month6:document.getElementById("aboveowngoods6").value, month7:document.getElementById("aboveowngoods7").value, month8:document.getElementById("aboveowngoods8").value }, 
            below:{year:document.getElementById("belowowngoodsfullyear").value, month1:document.getElementById("belowowngoods1").value, month2:document.getElementById("belowowngoods2").value, month3:document.getElementById("belowowngoods3").value, month4:document.getElementById("belowowngoods4").value, month5:document.getElementById("belowowngoods5").value, month6:document.getElementById("belowowngoods6").value, month7:document.getElementById("belowowngoods7").value, month8:document.getElementById("belowowngoods8").value }
        },
    generalcartagebelow:{persons:3,
            above:{year:document.getElementById("abovegeneralfullyear").value, month1:document.getElementById("abovegeneral1").value, month2:document.getElementById("abovegeneral2").value, month3:document.getElementById("abovegeneral3").value, month4:document.getElementById("abovegeneral4").value, month5:document.getElementById("abovegeneral5").value, month6:document.getElementById("abovegeneral6").value, month7:document.getElementById("abovegeneral7").value, month8:document.getElementById("abovegeneral8").value }, 
            below:{year:document.getElementById("belowgeneralfullyear").value, month1:document.getElementById("belowgeneral1").value, month2:document.getElementById("belowgeneral2").value, month3:document.getElementById("belowgeneral3").value, month4:document.getElementById("belowgeneral4").value, month5:document.getElementById("belowgeneral5").value, month6:document.getElementById("belowgeneral6").value, month7:document.getElementById("belowgeneral7").value, month8:document.getElementById("belowgeneral8").value }
        },
     generalcartageabove:{persons:3,
            above:{year:document.getElementById("abovegeneralabfullyear").value, month1:document.getElementById("abovegeneralab1").value, month2:document.getElementById("abovegeneralab2").value, month3:document.getElementById("abovegeneralab3").value, month4:document.getElementById("abovegeneralab4").value, month5:document.getElementById("abovegeneralab5").value, month6:document.getElementById("abovegeneralab6").value, month7:document.getElementById("abovegeneralab7").value, month8:document.getElementById("abovegeneralab8").value }, 
            below:{year:document.getElementById("belowgeneralabfullyear").value, month1:document.getElementById("belowgeneralab1").value, month2:document.getElementById("belowgeneralab2").value, month3:document.getElementById("belowgeneralab3").value, month4:document.getElementById("belowgeneralab4").value, month5:document.getElementById("belowgeneralab5").value, month6:document.getElementById("belowgeneralab6").value, month7:document.getElementById("belowgeneralab7").value, month8:document.getElementById("belowgeneralab8").value }
        },  
        tanker:{persons:3,
            above:{year:document.getElementById("abovetankerfullyear").value, month1:document.getElementById("abovetanker1").value, month2:document.getElementById("abovetanker2").value, month3:document.getElementById("abovetanker3").value, month4:document.getElementById("abovetanker4").value, month5:document.getElementById("abovetanker5").value, month6:document.getElementById("abovetanker6").value, month7:document.getElementById("abovetanker7").value, month8:document.getElementById("abovetanker8").value }, 
            below:{year:document.getElementById("belowtankerfullyear").value, month1:document.getElementById("belowtanker1").value, month2:document.getElementById("belowtanker2").value, month3:document.getElementById("belowtanker3").value, month4:document.getElementById("belowtanker4").value, month5:document.getElementById("belowtanker5").value, month6:document.getElementById("belowtanker6").value, month7:document.getElementById("belowtanker7").value, month8:document.getElementById("belowtanker8").value }
        }, 
        ambulance:{persons:3,
            above:{year:document.getElementById("aboveambulancefullyear").value, month1:document.getElementById("aboveambulance1").value, month2:document.getElementById("aboveambulance2").value, month3:document.getElementById("aboveambulance3").value, month4:document.getElementById("aboveambulance4").value, month5:document.getElementById("aboveambulance5").value, month6:document.getElementById("aboveambulance6").value, month7:document.getElementById("aboveambulance7").value, month8:document.getElementById("aboveambulance8").value }, 
            below:{year:document.getElementById("belowambulancefullyear").value, month1:document.getElementById("belowambulance1").value, month2:document.getElementById("belowambulance2").value, month3:document.getElementById("belowambulance3").value, month4:document.getElementById("belowambulance4").value, month5:document.getElementById("belowambulance5").value, month6:document.getElementById("belowambulance6").value, month7:document.getElementById("belowambulance7").value, month8:document.getElementById("belowambulance8").value }
        },   
        onroad:{persons:3,
            above:{year:document.getElementById("aboveroadfullyear").value, month1:document.getElementById("aboveroad1").value, month2:document.getElementById("aboveroad2").value, month3:document.getElementById("aboveroad3").value, month4:document.getElementById("aboveroad4").value, month5:document.getElementById("aboveroad5").value, month6:document.getElementById("aboveroad6").value, month7:document.getElementById("aboveroad7").value, month8:document.getElementById("aboveroad8").value }, 
            below:{year:document.getElementById("belowroadfullyear").value, month1:document.getElementById("belowroad1").value, month2:document.getElementById("belowroad2").value, month3:document.getElementById("belowroad3").value, month4:document.getElementById("belowroad4").value, month5:document.getElementById("belowroad5").value, month6:document.getElementById("belowroad6").value, month7:document.getElementById("belowroad7").value, month8:document.getElementById("belowroad8").value }
        },
     onsite:{persons:3,
            above:{year:document.getElementById("abovesitefullyear").value, month1:document.getElementById("abovesite1").value, month2:document.getElementById("abovesite2").value, month3:document.getElementById("abovesite3").value, month4:document.getElementById("abovesite4").value, month5:document.getElementById("abovesite5").value, month6:document.getElementById("abovesite6").value, month7:document.getElementById("abovesite7").value, month8:document.getElementById("abovesite8").value }, 
            below:{year:document.getElementById("belowsitefullyear").value, month1:document.getElementById("belowsite1").value, month2:document.getElementById("belowsite2").value, month3:document.getElementById("belowsite3").value, month4:document.getElementById("belowsite4").value, month5:document.getElementById("belowsite5").value, month6:document.getElementById("belowsite6").value, month7:document.getElementById("belowsite7").value, month8:document.getElementById("belowsite8").value }
        },
    
    }
       
       
  
                  
                  
                  
        const buyingpolicy = async () => {
            await axios.post("https://server.ayconsultgh.com/api/settings/registersettings/", {

                    
                        "motorpremium":{threshold:document.getElementById("thresholdyear").value, data:data}

                        }).then(res => {

                    if (res.status === 200) {
                        // e.target.reset()
                        setloading(!true)

                        swal("", " Settings Updated ", "success");


                    } else {
                        setloading(!true)
                        swal("", "Error Updating Settings", "error");

                    }

                })

        }

        buyingpolicy()

    }

    const changetaxrate=(e)=>{

           const fd = new FormData()
        
                    fd.append("tax",document.getElementById("taxrate").value)
                  
                  
        const buyingpolicy = async () => {
            await axios.post("https://server.ayconsultgh.com/api/settings/registersettings/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }

                        }).then(res => {

                    if (res.status === 200) {
                        dispatch(update_settings({
                        data: res.data
                    }))
                        setloading(!true)

                        swal("", " Tax Rate Updated ", "success");


                    } else {
                        setloading(!true)
                        swal("", "Error Updating Tax Rate", "error");

                    }

                })

        }

        buyingpolicy()

    }

    const changeagentrate=()=>{


            const fd = new FormData()
        
                    fd.append("agentcommission",document.getElementById("agentrate").value)
                  
                  
        const buyingpolicy = async () => {
            await axios.post("https://server.ayconsultgh.com/api/settings/registersettings/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }

                        }).then(res => {

                    if (res.status === 200) {
                       dispatch(update_settings({
                        data: res.data
                    }))
                        setloading(!true)

                        swal("", " Agent Commission Updated ", "success");


                    } else {
                        setloading(!true)
                        swal("", "Error Updating Agent Commission", "error");

                    }

                })

        }

        buyingpolicy()

    }  
    
    const changemonthlytrate=()=>{


            const fd = new FormData()
        
                    fd.append("monthlycommission",document.getElementById("monthlytrate").value)
                  
                  
        const buyingpolicy = async () => {
            await axios.post("https://server.ayconsultgh.com/api/settings/registersettings/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }

                        }).then(res => {

                    if (res.status === 200) {
                       dispatch(update_settings({
                        data: res.data
                    }))
                        setloading(!true)

                        swal("", " Monthly Target Achievement Rate Updated ", "success");


                    } else {
                        setloading(!true)
                        swal("", "Error Updating Tax Rate", "error");

                    }

                })

        }

        buyingpolicy()

    }

    const changeayrate=()=>{

           const fd = new FormData()
        
                    fd.append("AYcommission",document.getElementById("ayrate").value)
                  
                  
        const buyingpolicy = async () => {
            await axios.post("https://server.ayconsultgh.com/api/settings/registersettings/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }

                        }).then(res => {

                    if (res.status === 200) {
                       dispatch(update_settings({
                        data: res.data
                    }))
                        setloading(!true)

                        swal("", " AY Commission Rate Updated ", "success");


                    } else {
                        setloading(!true)
                        swal("", "Error Updating AY Commission Rate", "error");

                    }

                })

        }

        buyingpolicy()

    }
    const changeayrate_direct=()=>{

           const fd = new FormData()
        
                    fd.append("AYcommission_direct",document.getElementById("ayrate_direct").value)
                  
                  
        const buyingpolicy = async () => {
            await axios.post("https://server.ayconsultgh.com/api/settings/registersettings/", fd, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }

                        }).then(res => {

                    if (res.status === 200) {
                       dispatch(update_settings({
                        data: res.data
                    }))
                        setloading(!true)

                        swal("", " AY Direct Commission Updated ", "success");


                    } else {
                        setloading(!true)
                        swal("", "Error Updating AY Direct Commission", "error");

                    }

                })

        }

        buyingpolicy()

    }


    return (
        <div >

         <Dialog fullWidth maxWidth="sm" onClose={handleClosetax} aria-labelledby="customized-dialog-title" open={opentax}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClosetax} style={{ color: "grey" }}>
                      <b>Tax</b>
                      <IconButton  style={{float:"right"}} onClick={handleClosetax}><Close /></IconButton>
                    </DialogTitle>

                    <DialogContent align="center" >
                    <p>Input Rates in decimal format</p>
                    <b>NB:</b> 10% = 0.10, 20% = 0.20                         

                    <br/>
                    <br/>
                    <br/>
                    <input type='text' id="taxrate"  placeholder='Enter Tax Rate'  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"14px"}}/>

<br/>
<div align="center">
    <Button onClick={changetaxrate}>submit</Button>
</div>

                    </DialogContent>

                   
                </Dialog>

         <Dialog fullWidth maxWidth="sm" onClose={handleCloseaycommission} aria-labelledby="customized-dialog-title" open={openaycommission}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseaycommission} style={{ color: "grey" }}>
                      <b>AY Commission</b>
                      <IconButton  style={{float:"right"}} onClick={handleCloseaycommission}><Close /></IconButton>
                    </DialogTitle>

                    <DialogContent align="center" >
                    <p>Input Rates in decimal format</p>
                    <b>NB:</b> 10% = 0.10, 20% = 0.20                         

                    <br/>
                    <br/>
                    <br/>
                    <input type='text' id="ayrate"  placeholder='Enter AY Commission Rate'  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"14px"}}/>
<br/>
<div align="center">
    <Button onClick={changeayrate} >submit</Button>
</div>

                   
                    </DialogContent>

                   
                </Dialog>

         <Dialog fullWidth maxWidth="sm" onClose={handleCloseaycommission_direct} aria-labelledby="customized-dialog-title" open={openaycommission_direct}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseaycommission_direct} style={{ color: "grey" }}>
                      <b>DIrect AY Commission</b>
                      <IconButton  style={{float:"right"}} onClick={handleCloseaycommission_direct}><Close /></IconButton>
                    </DialogTitle>

                    <DialogContent align="center" >
                    <p>Input Rates in decimal format</p>
                    <b>NB:</b> 10% = 0.10, 20% = 0.20                         

                    <br/>
                    <br/>
                    <br/>
                    <input type='text' id="ayrate_direct"  placeholder='Enter Direct AY Commission Rate'  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"14px"}}/>
<br/>
<div align="center">
    <Button onClick={changeayrate_direct} >submit</Button>
</div>

                   
                    </DialogContent>

                   
                </Dialog>

         <Dialog  maxWidth="sm" onClose={handleCloseagentcommission} aria-labelledby="customized-dialog-title" open={openagentcommission}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseagentcommission} style={{ color: "grey" }}>
                      <b>Agent Commission</b>
                      <IconButton  style={{float:"right"}} onClick={handleCloseagentcommission}><Close /></IconButton>
                    </DialogTitle>
                    <DialogContent align="center" >
                    <p>Input Rates in decimal format</p>
                    <b>NB:</b> 10% = 0.10, 20% = 0.20
                    <br/>
                    <br/>
                    <br/>
                    <input type='text' id="agentrate"  placeholder='Enter Agent Commission Rate'  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"14px"}}/>
<br/>
<div align="center">
    <Button onClick={changeagentrate} >Submit</Button>
</div>

                   
                    </DialogContent>

                   
                </Dialog>

         <Dialog  maxWidth="sm" onClose={handleClosemonthly} aria-labelledby="customized-dialog-title" open={openmonthly}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClosemonthly} style={{ color: "grey" }}>
                      <b>Monthly Target Achievement Commission</b>
                      <IconButton  style={{float:"right"}} onClick={handleClosemonthly}><Close /></IconButton>
                    </DialogTitle>
                    <DialogContent align="center" >
                    <p>Input Rates in decimal format</p>
                    <b>NB:</b> 10% = 0.10, 20% = 0.20
                    <br/>
                    <br/>
                    <br/>
                    <input type='text' id="monthlytrate"  placeholder='Enter Rate'  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"14px"}}/>
<br/>
<div align="center">
    <Button onClick={changemonthlytrate} >Submit</Button>
</div>

                   
                    </DialogContent>

                   
                </Dialog>


         <Dialog fullWidth maxWidth="lg" onClose={handleClosemotor} aria-labelledby="customized-dialog-title" open={openmotor}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClosemotor} style={{ color: "grey" }}>
                      <b>Motor Policy Premium</b>
                      <IconButton  style={{float:"right"}} onClick={handleClosemotor}><Close /></IconButton>
                    </DialogTitle>

                    <DialogContent >

                    <input type='text' id="thresholdyear" defaultValue={settings.length!==0? settings[0].motorpremium[0].threshold :2018}  placeholder='Enter Threshold Year'  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"14px"}}/>

 <TableContainer >
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell><b>Threshold</b></TableCell>
                        <TableCell align="center"><b>Full Year</b></TableCell>
                        <TableCell align="center"><b>1 Month</b></TableCell>
                        <TableCell align="center"><b>2 Months</b></TableCell>
                        <TableCell align="center"><b>3 Months</b></TableCell>
                        <TableCell align="center"><b>4 Months</b></TableCell>
                        <TableCell align="center"><b>5 Months</b></TableCell>
                        <TableCell align="center"><b>6 Months</b></TableCell>
                        <TableCell align="center"><b>7 Months</b></TableCell>
                        <TableCell align="center"><b>8 Months</b></TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                  
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center' scope="row" colSpan={9}>
                             Private Car
                            </TableCell>
                           

                        </TableRow>


                    <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Above Threshold
                            </TableCell>
                            <TableCell align="center">
                                

                                <input type='number' id="aboveprivatefullyear"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.above.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="aboveprivate1" defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.above.month1 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="aboveprivate2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.above.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="aboveprivate3"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.above.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="aboveprivate4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.above.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="aboveprivate5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.above.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="aboveprivate6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.above.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="aboveprivate7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.above.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="aboveprivate8"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.above.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Below Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="belowprivatefullyear" defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.below.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="belowprivate1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.below.month1 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowprivate2"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.below.month2 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowprivate3"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.below.month3 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowprivate4"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.below.month4 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowprivate5"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.below.month5 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowprivate6"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.below.month6 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowprivate7"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.below.month7 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowprivate8"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.privatecar.below.month8 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  




                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center' scope="row" colSpan={9}>
                             Taxi
                            </TableCell>
                           

                        </TableRow>

                    <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Above Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="abovetaxifullyear"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.above.year :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="abovetaxi1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.above.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovetaxi2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.above.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovetaxi3"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.above.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovetaxi4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.above.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovetaxi5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.above.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovetaxi6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.above.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovetaxi7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.above.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovetaxi8"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.above.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Below Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="belowtaxifullyear" defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.below.year :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="belowtaxi1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.below.month1 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowtaxi2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.below.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowtaxi3"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.below.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowtaxi4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.below.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowtaxi5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.below.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowtaxi6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.below.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowtaxi7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.below.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowtaxi8"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.taxi.below.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  


                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center' scope="row" colSpan={9}>
                             Hiring 
                            </TableCell>
                           

                        </TableRow>

                    <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Above Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="abovehiringfullyear" defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.above.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="abovehiring1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.above.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovehiring2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.above.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovehiring3"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.above.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovehiring4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.above.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovehiring5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.above.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovehiring6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.above.month6 :0} style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovehiring7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.above.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovehiring8"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.above.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Below Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="belowhiringfullyear"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.below.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="belowhiring1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.below.month1 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowhiring2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.below.month2 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowhiring3" defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.below.month3 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowhiring4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.below.month4 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowhiring5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.below.month5 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowhiring6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.below.month6 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowhiring7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.below.month7 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowhiring8"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.hiring.below.month8 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  



                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center' scope="row" colSpan={9}>
                             Motor Cycle (2 Persons)
                            </TableCell>
                           

                        </TableRow>

                    <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Above Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="abovemotorcyclefullyear"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.above.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="abovemotorcycle1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.above.month1 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle2"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.above.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle3"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.above.month3 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle4"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.above.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle5"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.above.month5 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle6"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.above.month6 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle7"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.above.month7 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle8"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.above.month8 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Below Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="belowmotorcyclefullyear"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.below.year :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="belowmotorcycle1"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.below.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle2"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.below.month2 :0} style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.below.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle4"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.below.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle5"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.below.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle6"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.below.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle7"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.below.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle8"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor.below.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  



                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center' scope="row" colSpan={9}>
                             Motor (12 Persons)
                            </TableCell>
                           

                        </TableRow>

                    <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Above Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="abovemotorcyclefullyear_1"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.above.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="abovemotorcycle1_1"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.above.month1 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle2_1"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.above.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle3_1"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.above.month3 :0} style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle4_1"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.above.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle5_1"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.above.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle6_1"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.above.month6 :0} style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle7_1"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.above.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle8_1"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.above.month8 :0} style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Below Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="belowmotorcyclefullyear_1"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.below.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="belowmotorcycle1_1"     defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.below.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle2_1"     defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.below.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle3_1"     defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.below.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle4_1"     defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.below.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle5_1"     defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.below.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle6_1"     defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.below.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle7_1"     defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.below.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle8_1"     defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_1.below.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  










                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center' scope="row" colSpan={9}>
                             Motor (15 Persons)
                            </TableCell>
                           

                        </TableRow>

                    <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Above Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="abovemotorcyclefullyear_2"     defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.below.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="abovemotorcycle1_2"     defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.above.month1 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle2_2"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.above.month2 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle3_2"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.above.month3 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle4_2"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.above.month4 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle5_2"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.above.month5 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle6_2"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.above.month6 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle7_2"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.above.month7 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle8_2"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.above.month8 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Below Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="belowmotorcyclefullyear_2"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.below.year :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="belowmotorcycle1_2"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.below.month1 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle2_2"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.below.month2 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle3_2"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.below.month3 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle4_2"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.below.month4 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle5_2"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.below.month5 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle6_2"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.below.month6 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle7_2"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.below.month7 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle8_2"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_2.below.month8 :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  











                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center' scope="row" colSpan={9}>
                             Motor  (19 Persons)
                            </TableCell>
                           

                        </TableRow>

                    <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Above Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="abovemotorcyclefullyear_3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.above.year :0}    style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="abovemotorcycle1_3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.above.month1 :0}     style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle2_3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.above.month2 :0}     style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle3_3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.above.month3 :0}     style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle4_3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.above.month4 :0}     style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle5_3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.above.month5 :0}     style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle6_3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.above.month6 :0}     style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle7_3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.above.month7 :0}     style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle8_3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.above.month8 :0}     style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Below Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="belowmotorcyclefullyear_3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.below.year :0}     style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="belowmotorcycle1_3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.below.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle2_3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.below.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle3_3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.below.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle4_3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.below.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle5_3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.below.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle6_3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.below.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle7_3"     defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.below.month7 :0} style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle8_3"    defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_3.below.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  











                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center' scope="row" colSpan={9}>
                             Motor (23 Persons)
                            </TableCell>
                           

                        </TableRow>

                    <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Above Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="abovemotorcyclefullyear_4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.above.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="abovemotorcycle1_4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.above.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle2_4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.above.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle3_4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.above.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle4_4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.above.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle5_4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.above.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle6_4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.above.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle7_4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.above.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle8_4"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.above.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Below Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="belowmotorcyclefullyear_4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.below.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="belowmotorcycle1_4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.below.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle2_4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.below.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle3_4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.below.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle4_4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.below.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle5_4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.below.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle6_4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.below.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle7_4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.below.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle8_4"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_4.below.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  











                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center' scope="row" colSpan={9}>
                             Motor (27 Persons)
                            </TableCell>
                           

                        </TableRow>

                    <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Above Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="abovemotorcyclefullyear_5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.above.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="abovemotorcycle1_5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.above.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle2_5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.above.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle3_5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.above.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle4_5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.above.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle5_5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.above.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle6_5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.above.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle7_5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.above.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovemotorcycle8_5"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.above.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Below Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="belowmotorcyclefullyear_5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.below.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="belowmotorcycle1_5"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.below.month1 :0} style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle2_5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.below.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle3_5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.below.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle4_5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.below.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle5_5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.below.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle6_5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.below.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle7_5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.below.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowmotorcycle8_5"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.motor_5.below.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>


                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center' scope="row" colSpan={9}>
                             Own Goods
                            </TableCell>
                           

                        </TableRow>

                    <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Above Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="aboveowngoodsfullyear"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.above.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="aboveowngoods1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.above.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="aboveowngoods2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.above.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="aboveowngoods3"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.above.month3 :0} style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="aboveowngoods4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.above.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="aboveowngoods5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.above.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="aboveowngoods6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.above.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="aboveowngoods7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.above.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="aboveowngoods8"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.above.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Below Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="belowowngoodsfullyear"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.below.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="belowowngoods1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.below.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowowngoods2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.below.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowowngoods3"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.below.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowowngoods4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.below.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowowngoods5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.below.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowowngoods6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.below.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowowngoods7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.below.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowowngoods8"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.owngoods.below.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  







                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center' scope="row" colSpan={9}>
                             General Cartage up to 3000 CC
                            </TableCell>
                           

                        </TableRow>

                    <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Above Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="abovegeneralfullyear" defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.above.year :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="abovegeneral1" defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.above.month1 :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovegeneral2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.above.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovegeneral3"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.above.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovegeneral4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.above.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovegeneral5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.above.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovegeneral6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.above.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovegeneral7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.above.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovegeneral8"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.above.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Below Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="belowgeneralfullyear"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.year :0} style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="belowgeneral1"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.month1 :0} style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowgeneral2"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.month2 :0} style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowgeneral3"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.month3 :0} style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowgeneral4"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.month4 :0} style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowgeneral5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowgeneral6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowgeneral7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowgeneral8"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  




                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center' scope="row" colSpan={9}>
                             General Cartage Above 3000 CC
                            </TableCell>
                           

                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Above Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="abovegeneralabfullyear"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartageabove.above.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="abovegeneralab1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartageabove.above.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovegeneralab2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartageabove.above.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovegeneralab3"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartageabove.above.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovegeneralab4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartageabove.above.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovegeneralab5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartageabove.above.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovegeneralab6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartageabove.above.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovegeneralab7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartageabove.above.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovegeneralab8"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartageabove.above.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Below Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="belowgeneralabfullyear"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="belowgeneralab1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowgeneralab2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowgeneralab3"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowgeneralab4"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.month4 :0} style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowgeneralab5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowgeneralab6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowgeneralab7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowgeneralab8"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.generalcartagebelow.below.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  





                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center' scope="row" colSpan={9}>
                             Articulator / Tanker
                            </TableCell>
                           

                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Above Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="abovetankerfullyear"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.above.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="abovetanker1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.above.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovetanker2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.above.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovetanker3"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.above.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovetanker4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.above.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovetanker5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.above.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovetanker6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.above.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovetanker7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.above.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovetanker8"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.above.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Below Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="belowtankerfullyear"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.below.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="belowtanker1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.below.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowtanker2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.below.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowtanker3"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.below.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowtanker4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.below.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowtanker5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.below.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowtanker6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.below.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowtanker7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.below.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowtanker8"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.tanker.below.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  






                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center' scope="row" colSpan={9}>
                             Ambulance
                            </TableCell>
                           

                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Above Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="aboveambulancefullyear" defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.above.year :0}   style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="aboveambulance1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.above.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="aboveambulance2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.above.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="aboveambulance3"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.above.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="aboveambulance4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.above.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="aboveambulance5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.above.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="aboveambulance6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.above.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="aboveambulance7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.above.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="aboveambulance8"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.above.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Below Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="belowambulancefullyear"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.below.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="belowambulance1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.below.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowambulance2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.below.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowambulance3"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.below.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowambulance4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.below.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowambulance5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.below.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowambulance6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.below.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowambulance7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.below.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowambulance8"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.ambulance.below.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  






                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center' scope="row" colSpan={9}>
                             Special Type : On Road
                            </TableCell>
                           

                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Above Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="aboveroadfullyear"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.above.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="aboveroad1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.above.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="aboveroad2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.above.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="aboveroad3"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.above.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="aboveroad4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.above.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="aboveroad5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.above.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="aboveroad6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.above.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="aboveroad7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.above.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="aboveroad8"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.above.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Below Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="belowroadfullyear"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.below.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="belowroad1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.below.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowroad2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.below.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowroad3"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.below.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowroad4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.below.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowroad5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.below.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowroad6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.below.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowroad7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.below.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowroad8"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onroad.below.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  








                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center' scope="row" colSpan={9}>
                             Special Type : On Site
                            </TableCell>
                           

                        </TableRow>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Above Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="abovesitefullyear"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.above.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="abovesite1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.above.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovesite2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.above.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovesite3"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.above.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovesite4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.above.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="abovesite5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.above.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovesite6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.above.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovesite7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.above.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="abovesite8"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.above.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                             Below Threshold
                            </TableCell>
                            <TableCell align="center">
                                
                                <input type='number' id="belowsitefullyear"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.below.year :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>
                            <TableCell align="center">
                               
                                <input type='number' id="belowsite1"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.below.month1 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowsite2"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.below.month2 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                                
                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowsite3"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.below.month3 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowsite4"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.below.month4 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>


                            </TableCell>
                            <TableCell align="center">

                                <input type='number' id="belowsite5"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.below.month5 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowsite6"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.below.month6 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowsite7"  defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.below.month7 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell> 
                            <TableCell align="center">

                                <input type='number' id="belowsite8"   defaultValue={settings.length!==0? settings[0].motorpremium[0].data.onsite.below.month8 :0}  style={{outline:"none", border:"solid", borderWidth:"0.1px", borderRadius:"5px",marginTop:1, borderColor:"lightgray", padding:"10px", margin:"10px" , width:"80%"}}/>

                            </TableCell>

                        </TableRow>
                  




                </TableBody>
            </Table>
            </TableContainer>

<div align="center"> <Button onClick={submitting} >Submit</Button> </div>
                   
                    </DialogContent>

                   
                </Dialog>

<h2 align="center" >SETTINGS</h2>
<br/>
            <Container maxWidth="md">

            <div align="left">

                <Grid container  style={{margin:10}} >

                    <Grid xs={4} lg={4}> <b>TAX</b> </Grid>
                    <Grid xs={4} lg={4}> <b>{settings.length!==0?parseFloat(settings[0].tax) * 100 +"%":"0%"}</b> </Grid>
                    <Grid xs={4} lg={4}> <Button style={{backgroundColor:"#062333" , color:"white"}} onClick={handleClickOpentax}> Edit </Button> </Grid>

                </Grid>
                <Divider/>

<Grid container  style={{margin:10}} >

                    <Grid xs={4} lg={4}> <b>AY Commission</b> </Grid>
                    <Grid xs={4} lg={4}> <b>{settings.length!==0?parseFloat(settings[0].AYcommission) * 100 +"%":"0%"}</b> </Grid>
                    <Grid xs={4} lg={4}> <Button style={{backgroundColor:"#062333" , color:"white"}} onClick={handleClickOpenaycommission}> Edit </Button> </Grid>

                </Grid>
<Grid container  style={{margin:10}} >

                    <Grid xs={4} lg={4}> <b>Direct AY Commission</b> </Grid>
                    <Grid xs={4} lg={4}> <b>{settings.length!==0?parseFloat(settings[0].AYcommission_direct) * 100 +"%":"0%"}</b> </Grid>
                    <Grid xs={4} lg={4}> <Button style={{backgroundColor:"#062333" , color:"white"}} onClick={handleClickOpenaycommission_direct}> Edit </Button> </Grid>

                </Grid>
                <Divider/>
<Grid container  style={{margin:10}} >

                    <Grid xs={4} lg={4}> <b>Agent Commission</b> </Grid>
                    <Grid xs={4} lg={4}> <b> {settings.length!==0?parseFloat(settings[0].agentcommission) * 100 +"%":"0%"} </b> </Grid>
                    <Grid xs={4} lg={4}> <Button style={{backgroundColor:"#062333" , color:"white"}} onClick={handleClickOpenagentcommission} > Edit </Button> </Grid>

                </Grid>              

                 <Divider/>
<Grid container  style={{margin:10}} >

                    <Grid xs={4} lg={4}> <b>Monthly Target Achievement Commission</b> </Grid>
                    <Grid xs={4} lg={4}> <b> {settings.length!==0?parseFloat(settings[0].monthlycommission) * 100 +"%":"0%"} </b> </Grid>
                    <Grid xs={4} lg={4}> <Button style={{backgroundColor:"#062333" , color:"white"}} onClick={handleClickOpenmonthly} > Edit </Button> </Grid>

                </Grid>
                <Divider/>

<div>

        <p align="center"><b>Motor Tariff</b>  <Button onClick={handleClickOpenmotor} style={{float:"right", marginTop:"-8px", backgroundColor:"#062333" , color:"white"}}> Edit </Button></p>

</div>

                <Divider/>

            </div>

            </Container>
 {JSON.parse(localStorage.getItem("user")).admintype!=="Secondary"?
        <div>
<h2>{("AY Administrators").toUpperCase()} </h2>
            <Container maxWidth="lg">
         <Ayadmin/>
            </Container>
            </div>  
:
""
 }

        </div>
    );
}

export default Profile4;
