import * as React from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';

import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import MailIcon from '@mui/icons-material/Mail';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import axios from "axios"

import Badge from '@mui/material/Badge';
import ListItemButton from '@mui/material/ListItemButton';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import Search from '@mui/icons-material/Search';
import Person from '../images/Ellipse77.png';
import AddBusinessIcon from '@mui/icons-material/Person';
import Close from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Image from '../images/imager.jpg';
// import Avatar from '@mui/material/Avatar';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide'

import swal from 'sweetalert';
import Box from '@mui/material/Box';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { AvatarGroup } from '@mui/material';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_agent } from '../redux/agent';
import { update_bookings } from '../redux/bookings';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction = "up"
    ref = { ref } {...props }
    />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation = { 6 }
    ref = { ref }
    variant = "filled" {...props }
    />;
});

function Profile() {
    const Mydata = useSelector((state) => state.admin)
    const booking = useSelector((state) => state.bookings)
    const dispatch = useDispatch()
    const [loader, setloader] = React.useState(true)
    var bookings = [...booking]
    const getrooms = async() => {
        await axios.post("https://cromer.sisrevolution.com/api/bookings/getbookings/", {

        }).then(res => {
            if (res.status === 200) {
                console.log(res)
                dispatch(update_bookings({
                    data: res.data
                }))
            }
        })
    }
    const theme = useTheme();

    const [open_email, setOpen_email] = React.useState(false);

    const handleClickOpen_email = () => {
        setOpen_email(true);
    };

    const handleClose = () => {
        setOpen_email(false);
    };

    React.useEffect(() => {
        getrooms()
    }, [loader])

    const [help, sethelp] = React.useState("")
    const [roomlist, setroomlist] = React.useState([])
    const [selected, setselected] = React.useState([])
    const [gender, setgender] = React.useState(Mydata.gender)
    const [marital, setmarital] = React.useState(Mydata.marritalstatus)
    const [loading, setloading] = React.useState(false)
    const [message, setmessage] = React.useState("")
    const [stated, setstated] = React.useState("")
    const [searching, setsearching] = React.useState("")
    const [person, setperson] = React.useState(Mydata.image !== undefined || Mydata.image !== null ? "https://cromeradmin.3ammissionministry.com/server/routes/uploads/" + Mydata.image : person)

    const [opensnack, setOpensnack] = React.useState(false);

    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };


    const searcher = (e) => {

        setsearching(e.target.value)

    }

    if (searching !== "") {

        var data = booking.filter(bug => (bug.name).toUpperCase().includes(searching.toUpperCase()) || bug.contact.includes(searching))
        bookings = data
    } else {
        bookings = booking
    }


    const changeselect = (e) => {
        setselected(e)
    }




    const findquest = () => {
        var quests = []
        for (var i in bookings) {

            if (quests.filter(bug => bug.email === bookings[i].email).length === 0) {
                quests.push(bookings[i])
            }

        }

        return quests

    }



    const sender = () => {
        setloading(true)
        setloading(true)
        const subject = document.getElementById("subject").value
        const message = document.getElementById("message").value

        const apartment = async() => {
            await axios.post("https://cromer.sisrevolution.com/api/bookings/sendemail/", {

                name: selected.name,
                subject: subject,
                message: message,
                email: selected.email

            }).then(res => {

                if (res.status === 200) {
                    setloading(!true)

                    // setloading(!true)

                    swal("", " Newsletters sent ", "success");

                } else {
                    setloading(!true)
                    swal("", "Error sending news letter", "error");

                }

            })

        }
        apartment()


    }


    const addapartment = (e) => {
        console.log("adder")

        const file = document.getElementById("fileimage").files[0]
        var name = document.getElementById("apartmentname").value
        var rooms = document.getElementById("rooms").value
        var price = document.getElementById("price").value
        var discount = document.getElementById("discount").value
        var max = document.getElementById("max").value

        const fd = new FormData()
        if (file) {

            fd.append("file", file)
            fd.append("image", fd.append("image", new Date() + "" + name + ".jpg"))
        }

        fd.append("category", name)
        fd.append("rooms", rooms)
        fd.append("price", price)
        fd.append("doscount", discount)
        fd.append("max", max)
        fd.append("specifications", roomlist)
        console.log(fd)
        const apartment = async() => {
            await axios.post("https://cromer.sisrevolution.com/api/rooms/addapartment/", fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            }).then(res => {

                if (res.status === 200) {
                    dispatch(update_bookings({
                            data: res.data
                        }))
                        // setloading(!true)

                    // swal("", " AY Direct Commission Updated ", "success");


                } else {
                    // setloading(!true)
                    // swal("", "Error Updating AY Direct Commission", "error");

                }

            })

        }
        apartment()


    }

    const closenow = (e) => {

        const deleteapartment = async() => {
            await axios.post("https://cromer.sisrevolution.com/api/rooms/deleteroom/", {
                _id: e

            }).then(res => {

                if (res.status === 200) {
                    dispatch(update_bookings({
                        data: res.data
                    }))
                    setselected([])
                        // setloading(!true)

                    // swal("", " AY Direct Commission Updated ", "success");


                } else {
                    // setloading(!true)
                    // swal("", "Error Updating AY Direct Commission", "error");

                }

            })

        }
        deleteapartment()

    }

    const addroom = () => {
        var data = [...roomlist]
        var feature = document.getElementById("feature").value
        data.push(feature)
        setroomlist(data)
    }

    const removeroom = (e) => {
        var data = roomlist.filter(bug => bug !== e)
        setroomlist(data)
    }

    return ( <Container>

        <Snackbar open = { opensnack }
        autoHideDuration = { 6000 }
        onClose = { handleClosesnack } >
        <Alert onClose = { handleClosesnack }
        severity = { stated }
        sx = {
            { width: '100%' } } > { message } </Alert> 
            </Snackbar>

        <Dialog open = { open_email }
        TransitionComponent = { Transition }
        keepMounted onClose = { handleClose }
        aria-describedby = "alert-dialog-slide-description" >
        <DialogTitle > { "SEND EMAIL" } </DialogTitle> <DialogContent>
        <DialogContentText id = "alert-dialog-slide-description" >


        <TextField size = "small"
        type = "text"
        id = "subject"
        label = "Subject"
        style = {
            { margin: "10px", width: "95%" } }
        /> 
        <textarea rows = { 8 }
        size = "small"
        type = "text"
        id = "message"
        placeholder = "Enter Message"
        style = {
            { margin: "10px", outline: "none", width: "90%", border: "solid", borderWidth: "0.1px", borderRadius: "5px", borderColor: "lightgray", padding: "10px", fontSize: "16px", fontFamily: "sans-serif" } } > </textarea>


        </DialogContentText> 
        </DialogContent> 
        <DialogActions >
        <Button onClick = { sender } > Send Email </Button> 
        <Button onClick = { handleClose } > Close </Button> 
        </DialogActions> 
        </Dialog>



        <h2 align = "center" > < span style = {
            { color: "#062333", padding: 10, borderRadius: "40px" } } > GUESTS </span>
            </h2>

        <Grid container spacing = { 4 } >

        <Grid item xs = { 12 }
        lg = { 4 }
        align = "center" >
        <span style = {
            { position: "absolute", marginTop: "9px", marginLeft: "10px" } } > < Search style = {
            { color: "grey" } }
        /></span> <input type = "search"
        onChange = { searcher }
        placeholder = "Search By Name, Booking ID, Room Type"
        style = {
            { outline: "none", fontSize: "17px", padding: 10, paddingLeft: "40px", width: "100%", borderTopRightRadius: "10px", borderTopLeftRadius: "10px", border: "solid", borderColor: "#062333", borderWidth: "0.05px", borderBottom: "none" } }
        /> 
        <div className = 'scroller1'
        style = {
            { height: "80vh", padding: 10, borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px", border: "solid", borderColor: "#062333", borderWidth: "0.05px" } } > {
            findquest().map((list, index) =>
                <div>
                <List style = {
                    { padding: 0, margin: 0 } } >

                <ListItemButton onClick = {
                    () => changeselect(list) }
                secondaryAction = { <
                    IconButton edge = "end"
                    aria-label = "delete" >
                    <DeleteIcon/>
                    </IconButton>
                } >
                <ListItemAvatar>
                <Avatar>
                <AddBusinessIcon/>
                </Avatar> </ListItemAvatar> 
                <ListItemText primary = { list.name }
                secondary = { "Contact: " + list.contact }
                /> 
                </ListItemButton>

                </List> 
                <Divider/>
                </div> 
            )
        }

        </div> 
        </Grid>

        <Grid item xs = { 12 }
        lg = { 8 } >

        <div className = 'scroller1'
        style = {
            { height: "80vh", padding: 10, borderRadius: "10px", border: "solid", borderColor: "#062333", borderWidth: "0.05px" } } >

        {
            selected.length !== 0 ?

            <Container>
            <h3 textAlign = "center" > QUEST Details </h3> 
            <div style = {
                { teaxAlign: "center" } } >
            <Button style = {
                { marginLeft: 10 } }
            variant = "outlined"
            color = "warning"
            startIcon = { <MailIcon/> }
            onClick = { handleClickOpen_email } > E - MAIL </Button>

            </div>  <div style = {
                { textAlign: "left" } } >
            <h3> Person Details </h3> 
            <ListItemText
            primary = { selected.name }
            secondary = "Name" 
            />
            <Divider/>

            <ListItemText
            primary = { selected.email }
            secondary = "Email" 
            />
            <Divider/>

            <ListItemText
            primary = { selected.contact }
            secondary = "Contact" 
            />
            <Divider/>

            <ListItemText
            primary = { selected.address }
            secondary = "Address" 
            />



            {
                /* <ul>
                                    {selected.features.length!==0?
                                selected.features.map((lister, index)=>
                                <li>{lister}</li>
                                ):""    
                                }
                                </ul> */
            }

            </div>

            </Container>

            :
                ""

        }


        </div> </Grid>

        </Grid> <br/>
        <br/>
        <br/>
        <br/>


        </Container>
    );
}

export default Profile;