import * as React from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';

import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import axios from "axios"

import Badge from '@mui/material/Badge';
import ListItemButton from '@mui/material/ListItemButton';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import Search from '@mui/icons-material/Search';
import Person from '../images/Ellipse77.png';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import Close from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Image from '../images/imager.jpg';
// import Avatar from '@mui/material/Avatar';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide'

import swal from 'sweetalert';
import Box from '@mui/material/Box';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { AvatarGroup } from '@mui/material';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_agent } from '../redux/agent';
import { update_bookings } from '../redux/bookings';
import { update_emails } from '../redux/emails';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Profile() {
    const Mydata = useSelector((state) => state.admin)
    const booking = useSelector((state) => state.emails)
    const quests = useSelector((state) => state.bookings)
    const dispatch = useDispatch()
    const [loader, setloader] = React.useState(true)

    var bookings=[...booking]
    const getrooms = async () => {
        await axios.post("https://cromer.sisrevolution.com/api/news/getnews/",
            {

            }).then(res => {
                if (res.status === 200) {
                    console.log(res)
                    dispatch(update_emails({
                        data: res.data
                    }))
                }
            })
    }

    const getquests = async () => {
      await axios.post("https://cromer.sisrevolution.com/api/bookings/getbookings/",
          {

          }).then(res => {
              if (res.status === 200) {
                  console.log(res)
                  dispatch(update_bookings({
                      data: res.data
                  }))
              }
          })
  }

    const theme = useTheme();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    React.useEffect(()=>{
        getrooms()
        getquests()
    },[loader])
    
    const [help, sethelp] = React.useState("")
    const [roomlist, setroomlist] = React.useState([])
    const [selected, setselected] = React.useState([])
    const [gender, setgender] = React.useState(Mydata.gender)
    const [marital, setmarital] = React.useState(Mydata.marritalstatus)
    const [loading, setloading] = React.useState(false)
    const [message, setmessage] = React.useState("")
    const [stated, setstated] = React.useState("")
    const [person, setperson] = React.useState(Mydata.image!==undefined || Mydata.image!==null? "https://cromeradmin.3ammissionministry.com/server/routes/uploads/"+Mydata.image:person)
    
    const [opensnack, setOpensnack] = React.useState(false);

    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };

    const sender=()=>{

      setloading(true)
      const subject = document.getElementById("subject").value
      const message = document.getElementById("message").value

      const apartment = async () => {
        await axios.post("https://cromer.sisrevolution.com/api/news/bulk/", {
                     
                    subject:subject,
                    message:message,
                    emails:emaillist()

                    }).then(res => {

                if (res.status === 200) {
                  setloading(!true)
                //    dispatch(update_bookings({
                //     data: res.data
                // }))
                    // setloading(!true)

                    swal("", " Newsletters sent ", "success");

                } else {
                    setloading(!true)
                    swal("", "Error sending news letter", "error");

                }

            })

    }
apartment()


    }

const changeselect=(e)=>{
    setselected(e)
}
 
const findquest = () =>{
 
  for(var i in quests){
      
      if(bookings.filter(bug=>bug.email===quests[i].email).length===0){
          bookings.push(quests[i])
      }

  }

  return bookings

}

const emaillist=()=>{
var lister=[]

for(var i in findquest()){
      
      lister.push(findquest()[i].email)
  
}

return lister



}

 
const addapartment=(e)=>{
    console.log("adder")
    e.preventDefault()
    const file = document.getElementById("fileimage").files[0]
    var name = document.getElementById("apartmentname").value
    var rooms = document.getElementById("rooms").value
    var price = document.getElementById("price").value
    var discount = document.getElementById("discount").value
    var max = document.getElementById("max").value

    const fd = new FormData()
    if (file) {
             
                  fd.append("file", file)
                  fd.append("image", fd.append("image",new Date() + "" + name+ ".jpg"))
              }

              fd.append("category",name)
              fd.append("rooms",rooms)
              fd.append("price",price)
              fd.append("doscount",discount)
              fd.append("max",max)
              fd.append("specifications",roomlist)
console.log(fd)
              const apartment = async () => {
                await axios.post("https://cromer.sisrevolution.com/api/rooms/addapartment/", fd, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
    
                            }).then(res => {
    
                        if (res.status === 200) {
                           dispatch(update_bookings({
                            data: res.data
                        }))
                            // setloading(!true)
    
                            // swal("", " AY Direct Commission Updated ", "success");
    
    
                        } else {
                            // setloading(!true)
                            // swal("", "Error Updating AY Direct Commission", "error");
    
                        }
    
                    })
    
            }
apartment()

         
}


const closenow=(e)=>{

  const deleteapartment = async () => {
    await axios.post("https://cromer.sisrevolution.com/api/rooms/deleteroom/", {
                   _id:e

                }).then(res => {

            if (res.status === 200) {
               dispatch(update_bookings({
                data: res.data
            }))
            setselected([])
                // setloading(!true)

                // swal("", " AY Direct Commission Updated ", "success");


            } else {
                // setloading(!true)
                // swal("", "Error Updating AY Direct Commission", "error");

            }

        })

}
deleteapartment()

}

const addroom=()=>{
    var data=[...roomlist]
    var feature = document.getElementById("feature").value
    data.push(feature)
    setroomlist(data)
}

const removeroom=(e)=>{
    var data=roomlist.filter(bug=>bug!==e)
    setroomlist(data)
}

 return (
        <Container>
          
            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            
            <form >
            <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Add New Apartment"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          
<input type="file" id="fileimage" style={{margin:"10px"}}  />
<br/>
       <TextField size="small" type="text" id="apartmentname" label="Apartment Name"  style={{margin:"10px"}}/>
       <TextField size="small" type="number" id="rooms" label="Number Of Rooms "  style={{margin:"10px"}}/>
       <TextField size="small" type="number" id="price" label="Price"  style={{margin:"10px"}} />
       <TextField size="small" type="number" id="discount" label="Discount"  style={{margin:"10px"}} />
       <TextField size="small" type="number" id="max" label="Maximum Number of persons"  style={{margin:"10px"}} />
<h3 align="center">Room Features</h3>
<Grid container spacing={2}>
    <Grid item xs={8}>
    <TextField type="text" id="feature" label="Room Feature " size="small"  style={{margin:"10px", width:"100%"}}/>  
    </Grid>
    <Grid item xs={4}>
    <Button variant="outlined" onClick={()=>addroom()} style={{margin:"10px"}}>Add Feature</Button>
    </Grid>
</Grid>
<Divider/>

{console.log(roomlist)}

{roomlist.map((list,index)=>
<Grid key={index} container spacing={2}>
    <Grid item xs={8}>
    <p>{list}</p>  
    </Grid>
    <Grid item xs={4}>
    <IconButton onClick={()=>removeroom(list)} style={{marginTop:"7px"}}><Close/></IconButton>
    </Grid>
</Grid>
)
}
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={addapartment}>Add Apartment</Button>
          <Button onClick={handleClose}>Agree</Button>
        </DialogActions>
      </Dialog>
      </form>

                  <h2 align="center"><span  style={{color:"#062333", padding:10,  borderRadius:"40px"}} >Send Newsletter</span></h2>
                  <h3>{bookings.length} Registered Mails</h3>

            <Grid container spacing={4}>

                <Grid item xs={12} lg={4} align="center" >
                  <span style={{position:"absolute", marginTop:"9px", marginLeft:"10px"}}><Search style={{color:"grey"}}/></span><input type="search" placeholder="Search By Name, Booking ID, Room Type" style={{outline:"none", fontSize:"17px", padding: 10, paddingLeft:"40px",width:"100%", borderTopRightRadius: "10px",borderTopLeftRadius: "10px", border: "solid", borderColor: "#062333",  borderWidth: "0.05px", borderBottom:"none" }}/>
                    <div className='scroller1' style={{ height:"80vh", padding: 10,  borderBottomRightRadius: "10px",borderBottomLeftRadius: "10px",  border: "solid", borderColor: "#062333",  borderWidth: "0.05px" }}>

{findquest().map((list,index)=>
                     <div>
                    <List style={{padding:0, margin:0}} >
              
                <ListItemButton
                onClick={()=>changeselect(list)}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <AddBusinessIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={list.email}
                  />
                </ListItemButton>
            
            </List>
                  <Divider/>
            </div> 
            )}

                    </div>
                </Grid>

                <Grid item xs={12} lg={8}>

                <div className='scroller1' style={{ height:"80vh", padding: 10, borderRadius: "10px", border: "solid", borderColor: "#062333",  borderWidth: "0.05px" }}>


                  <Container>

                  <TextField size="small" type="text" id="subject" label="Subject"  style={{margin:"10px", width:"100%"}}/>
<br/>
                  <textarea rows={10}  type="text" id="message" placeholder="Enter message"  style={{margin:"10px", width:"97%", outline:"none", borderColor:"lightgray", borderRadius:"8px", fontStyle:"unset",fontSize:"16px", color:"gray", padding:"10px", fontFamily:"sans-serif" }}> </textarea> 
{
  loading?
<button disabled style={{width:"100%", padding:"10px", marginLeft:"11px", border:"none", borderRadius:"5px", backgroundColor:"lightgray",  cursor:"pointer"}}>Loading...</button>
:
<button onClick={sender} style={{width:"100%", padding:"10px", marginLeft:"11px", border:"none", borderRadius:"5px", backgroundColor:"black", color:"goldenrod", cursor:"pointer"}}>SEND MESSAGE</button>
}
                  </Container>
                  
  
                      
                   
                      </div>
                </Grid>

            </Grid>
<br/>
<br/>
<br/>
<br/>


        </Container>
    );
}

export default Profile;
