import React, {useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';

import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import axios from "axios"

import Badge from '@mui/material/Badge';
import ListItemButton from '@mui/material/ListItemButton';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import Search from '@mui/icons-material/Search';
import MailIcon from '@mui/icons-material/Mail';
import PersonIcon from '@mui/icons-material/Person';

import Person from '../images/Ellipse77.png';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import Close from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Image from '../images/imager.jpg';
// import Avatar from '@mui/material/Avatar';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide'

import swal from 'sweetalert';
import Box from '@mui/material/Box';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { AvatarGroup } from '@mui/material';

import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css"

import{MainContainer, ChatContainer, MessageList, Message,MessageFooter, MessageInput, ConversationHeader} from "@chatscope/chat-ui-kit-react"
import { format, render, cancel, register } from 'timeago.js';

import io from "socket.io-client";



import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_agent } from '../redux/agent';
import { update_chat } from '../redux/chat';

import { update_bookings } from '../redux/bookings';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction = "up"
    ref = { ref } {...props }
    />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation = { 6 }
    ref = { ref }
    variant = "filled" {...props }
    />;
});

const socket = io.connect("https://server2.cromerapartments.com")


function Profile() {

    console.log(socket)

    const [room, setroom] = React.useState("cromer")
    const [selected, setselected] = React.useState([])

    const dt = new Date()
    const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    const day = dt.getUTCDate();
    const month = String(dt.getMonth() + 1).padStart(2, '0');
    const year = dt.getFullYear();
    const date = year + "-" + month + "-" + day;
    const chats  = useSelector((state) => state.chat )
    const [message, setmessage] = React.useState("")
    const [loader, setloader] = React.useState(true)

    const getrooms = async() => {
        await axios.post("https://cromer.sisrevolution.com/api/chat/getchats/", {

        }).then(res => {
            if (res.status === 200) {
                dispatch(update_chat({
                    data: res.data
                }))
        
            }
        })
    }
    
    const getrooms_ = async() => {
        await axios.post("https://cromer.sisrevolution.com/api/chat/getchats/", {

        }).then(res => {
            if (res.status === 200) {
           
             dispatch(update_chat({
                data: res.data
            })) 
            }
        })
    }


    const theme = useTheme();

    React.useEffect(() => {
        getrooms()
        getrooms_()

    }, [loader])

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    console.log(selected.length!==0 ? selected.email :"")



    useEffect(() => {
        socket.emit("join_room", "cromer")
        
        socket.on("receive_usermessage", (data) => {
            getrooms()
    if(selected.length!==0 && data.email===selected.email ){        
    var select={...selected}
    var chats_= [...select.chat]
    chats_.push(data.chat)
    select.chat=chats_
  setselected(select)
    
    // var index = chats.findIndex(bug=>bug.email===data.email)
    // var all = [...chats]
    // all[index]=select

   
    }
    else if(selected.length===0 && data!==undefined && chats.length!==0 ) {
      
        var data_=chats.filter(bug=>bug.email===data.email)[0]
        var index = chats.findIndex(bug=>bug.email===data.email)
        console.log(data_)
        var select={...data_}
        var chats_= [...select.chat]
        chats_.push(data.chat)
        select.chat=chats_
        var all_ =[...chats]
        all_[index]=select
        console.log(all_)
        dispatch(update_chat({
                data: all_
            }))

    }
           
        })
    },[socket, selected])

    const Mydata = useSelector((state) => state.admin)
    const chatamount= useSelector((state) => state.chat)

    const booking_ = useSelector((state) => state.bookings)
    const dispatch = useDispatch()
    var bookings = booking_

    const [help, sethelp] = React.useState("")
    const [selectedcountry, setselectedcountry] = React.useState("none")
    const [selectedID, setselectedID] = React.useState("none")
    const [roomlist, setroomlist] = React.useState([])
    const [gender, setgender] = React.useState(Mydata.gender)
    const [marital, setmarital] = React.useState(Mydata.marritalstatus)
    const [loading, setloading] = React.useState(false)
    const [stated, setstated] = React.useState("")
    const [searching, setsearching] = React.useState("")
    const [person, setperson] = React.useState(Mydata.image !== undefined || Mydata.image !== null ? "https://cromeradmin.3ammissionministry.com/server/routes/uploads/" + Mydata.image : person)

    const [opensnack, setOpensnack] = React.useState(false);

    
    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };

  
    
    

    const changeselect = (e) => {
        var main = {...e}
        var selectcahts=[...e.chat]
        var newchats=[]
        var counter=0
        for(var i in selectcahts){
            if(selectcahts[i].seen && selectcahts[i].seen.length===0){
                console.log(selectcahts[i])
                var getter = {...selectcahts[i]}
                var seener = [...selectcahts[i].seen,{status:"yes", date:date, time:time}]
                getter.seen=seener
                newchats.push(getter)
            }else{
                newchats.push(selectcahts[i])
            }
        }
        main.chat=newchats
        setselected(main)

      

        const seenchat = async () => {
            await axios.post("https://cromer.sisrevolution.com/api/chat/seenchat/", {
                           email:main.email,
                           chat: main.chat , 
                         
          
                        }).then(res => {
          
                    if (res.status === 200) {
          
                    //   console.log(res.data)
          
          
                       dispatch(update_chat({
                        data: res.data
                    }))
                    // setselected([])
                    //     // setloading(!true)
          
                    //     // swal("", " AY Direct Commission Updated ", "success");
          
          
                    } else {
                        // setloading(!true)
                        // swal("", "Error Updating AY Direct Commission", "error");
          
                    }
          
                })
          
          }
          
          seenchat()


    }

    const converter = (data) => {

        const dt = new Date(data + "")
        const time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
        const day = dt.getUTCDate();
        const month = String(dt.getMonth() + 1).padStart(2, '0');
        const year = dt.getFullYear();
        const date = year + "-" + month + "-" + day;

        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

        return months[parseInt((date.split("-"))[1])-1] + " " + (date.split("-"))[2] + ", " + (date.split("-"))[0]


    }

    const searcher = (e) => {

        setsearching(e.target.value)

    }

    if (searching !== "") {

        var data = booking_.filter(bug => (bug.name).toUpperCase().includes(searching.toUpperCase()) || bug.bookingID.includes(searching) || bug.contact.includes(searching))
        bookings = data
    } else {
        bookings = booking_
    }

    const addchat = async (email_,newMessage_) => {
        await axios.post("https://cromer.sisrevolution.com/api/chat/addchat/", {
                       email:email_,
                       chat:{sender:"cromer",message:newMessage_, reply:[], seen:[], date:date, direction:"outgoing", sentTime:new Date()},
                       date:date,
                       time:time
      
                    }).then(res => {
      
                if (res.status === 200) {
      
                  console.log(res.data)
      
      
                //    dispatch(update_bookings({
                //     data: res.data
                // }))
                // setselected([])
                //     // setloading(!true)
      
                //     // swal("", " AY Direct Commission Updated ", "success");
      
      
                } else {
                    // setloading(!true)
                    // swal("", "Error Updating AY Direct Commission", "error");
      
                }
      
            })
      
      }

const sendreply=(e)=>{
    socket.emit("send_message_touser", { email:selected.email,chat:{message:message, sender:"cromer", reply:[], seen:[], date:date, time:time}})

    var select={...selected}
    var chats= [...select.chat]
    chats.push(
        {message:message, sender:"cromer", reply:[], seen:[], date:date, time:time, direction:"outgoing", sentTime:new Date().getTime()}
        )
    select.chat=chats
    console.log(select)
   
    setselected({...select})

    addchat(selected.email,message)

}

 
    return ( <Container>

        <Snackbar open = { opensnack }
        autoHideDuration = { 6000 }
        onClose = { handleClosesnack } >
        <Alert onClose = { handleClosesnack }
        severity = { stated }
        sx = {{width: '100%'}}> {message} </Alert> 
            </Snackbar>

        <h2 align = "center" > < span style = {
            { color: "#062333", padding: 10, borderRadius: "40px" } } > Live Chat </span></h2>

        <Grid container spacing = { 4 } >
        <Grid item xs = { 12 }
        lg = { 4 }
        align = "center" >
        <span style = {
            { position: "absolute", marginTop: "9px", marginLeft: "15px" } } > < Search style = {
            { color: "grey" } }
        /></span > < input onChange = { searcher }
        type = "search"
        placeholder = "Search By Name, Booking ID, Room Type"
        style = {
            { outline: "none", fontSize: "17px", padding: 10, paddingLeft: "40px", width: "100%", borderTopRightRadius: "10px", borderTopLeftRadius: "10px", border: "solid", borderColor: "lightgray", borderWidth: "0.05px", borderBottom: "none" } }
        /> 
        
        
        <div className = 'scroller1'
        style = {
            { height: "440px", padding: 10, borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px", border: "solid", borderColor: "lightgray", borderWidth: "0.05px" } } > 
            
           

            {
            chats.map((list, index) =>
            list.email!=="false"?
                <div>
                <List style = {
                    { padding: 0, margin: 0, backgroundColor: selected.length!==0 && selected.email===list.email? "lightgray":"" } } >

                <ListItemButton onClick = {
                    () => changeselect({...list}) }
                secondaryAction = { <IconButton edge = "end"
                    aria-label = "delete" >
                    <DeleteIcon/>
                    </IconButton>
                } >
                <ListItemAvatar>
                <Badge badgeContent={list.chat.filter(bug=> bug.seen && bug.seen.length===0 ).length} color="error">
                <Avatar >
                <PersonIcon/>
                </Avatar> 
                </Badge>
                </ListItemAvatar> <ListItemText primary = { list.email }
                secondary = "Name"
                /> </ListItemButton>

                </List> <Divider/>
                </div> :""
            )
        }

        </div> </Grid>

        <Grid item xs = { 12 }
        lg = { 8 } >

      
<div style={{position:"relative",  height:"500px"}}>
{console.log(Message)}
<MainContainer style={{borderRadius: "10px"}}>
    <ChatContainer>
    <ConversationHeader>
            <Avatar src={PersonIcon} name="Eliot" />
            <ConversationHeader.Content>
              <span style={{
      color: "#ec1212",
      alignSelf: "flex-center"
    }}>{selected.length!==0?selected.email:"Select Chat"}</span>
            </ConversationHeader.Content>
        </ConversationHeader>


    <MessageList>
        {
            selected.length!==0 && selected.chat.map((list, index)=>
           
            <>
            <Message  model={list}>
            <Message.Footer sentTime={format(list.sentTime)} />
             </Message>        
            </>
        
            )
        }
       
    </MessageList>
        <MessageInput onChange={(value)=>setmessage(value)} onSend={sendreply} style={{textAlign:"left"}} placeholder="Type message here" />
    </ChatContainer>
</MainContainer> 



{/* <div style={{
  fontFamily: "Segoe UI"
}}>
            <TypingIndicator content="Patrik is typing" />
        </div> */}



        </div> 
        
        </Grid>
        </Grid> <br/>
        <br />
        <br />
        <br />


        </Container>
    );
}

export default Profile;



















