import React, {useEffect} from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import swal from 'sweetalert';


import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';




import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';

import Checkbox from '@mui/material/Checkbox';

import axios from "axios"

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';


import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_motor } from '../redux/motor';
import { update_travel } from '../redux/travel';
import { update_settings } from '../redux/settings';


import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function Profile3(props) {
    const Agent = useSelector((state) => state.agent)

     const dispatch = useDispatch()
 
    const getsettingsdata = async () => {
        await axios.post("https://server.ayconsultgh.com/api/settings/getsettings/",
            {

            }).then(res => {
                if (res.status === 200) {
                    dispatch(update_settings({
                        data: res.data
                    }))
                }
            })
    }

    const theme = useTheme();
    const [age, setAge] = React.useState('');
    const [extinguisher, setextinguisher] = React.useState('none');
    const [currency, setcurrency] = React.useState('none');

    const [loading, setloading] = React.useState(false)
    const [pager, setpager] = React.useState(true)
    const [message, setmessage] = React.useState("")
    const [stated, setstated] = React.useState("")

    const [checkbuilding, setcheckbuilding] = React.useState(false)
    const [checkcontent, setcheckcontent] = React.useState(false)
    const [checkliability, setcheckliability] = React.useState(false)
    const [checkrent, setcheckrent] = React.useState(false)
    const [checkaccident, setcheckaccident] = React.useState(false)
    const [suminsured, setsuminsured] = React.useState(0)
    const [premium, setpremium] = React.useState("")
    const [agenttype, setagenttype] = React.useState( JSON.parse(localStorage.getItem("user")).type === "Agent"? "":"Direct" );
    const [opensnack, setOpensnack] = React.useState(false);
    const [transact, settransact] = React.useState("no");
      
    const settings = useSelector((state) => state.settings)

  useEffect(() => {
 
        getsettingsdata()
      
  },[pager])


  
    const handleClicksnack = () => {
        setOpensnack(true);
    };

       const submitform=(e)=>{
       e.preventDefault()
      handleClickOpendelete()
    }


    const  yessubmit= (e)=>{
      e.preventDefault()
      setloading(true)
console.log("putting")
      const name= document.getElementById("name").value
      const contact = document.getElementById("contact").value
      const email = document.getElementById("email").value
      const occupation = document.getElementById("occupation").value
      const address = document.getElementById("address").value
      const charge = document.getElementById("charge").value
      const effectivedate = document.getElementById("effectivedate").value
      const expirydate = document.getElementById("expirydate").value
      const currency_ = currency
      var building = 0
      var content = 0
      var liability = 0
      var rent = 0
      var accident = 0

      var agentname_="" 
      var agentcontact_=""
      var agentIDnumber_=""
      var agentemail_=""
      var agentcommission_=""
      var aycommission_=""
      var institution_=""

      if(agenttype===""){
        agentname_=Agent.name
        agentcontact_=Agent.contact
        agentIDnumber_=Agent.IDnumber
        agentemail_=Agent.email
        institution_=Agent.institution
        agentcommission_=parseFloat(charge)*parseFloat(settings.length!==0?settings[0].agentcommission:0) -(parseFloat(charge)*parseFloat(settings.length!==0?settings[0].agentcommission:0) * parseFloat(settings.length!==0?settings[0].tax:0))
        aycommission_=parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission:0) - (parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission:0)*parseFloat(settings.length!==0?settings[0].tax:0))
              

      }else if(agenttype==="Direct"){
        agentname_=JSON.parse(localStorage.getItem("user")).name
        agentcontact_=JSON.parse(localStorage.getItem("user")).contact
        agentIDnumber_="Direct"
        institution_="Direct"
        agentemail_=JSON.parse(localStorage.getItem("user")).email
        agentcommission_= 0
        aycommission_= parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission_direct:0) - (parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission_direct:0)*parseFloat(settings.length!==0?settings[0].tax:0))
            
      }else if(agenttype==="Indirect"){
        agentname_= document.getElementById("agentname").value
        agentcontact_= document.getElementById("agentcontact").value
        agentIDnumber_= document.getElementById("agentcontact").value
        agentemail_= document.getElementById("agentemail").value
        institution_="Indirect"
        agentcommission_=parseFloat(charge)*parseFloat(settings.length!==0?settings[0].agentcommission:0) -(parseFloat(charge)*parseFloat(settings.length!==0?settings[0].agentcommission:0) * parseFloat(settings.length!==0?settings[0].tax:0))
        aycommission_=parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission:0) - (parseFloat(charge)*parseFloat(settings.length!==0?settings[0].AYcommission:0)*parseFloat(settings.length!==0?settings[0].tax:0))
            

      }

      if(checkbuilding === true){
        building = document.getElementById("building").value 
      }
      if(checkcontent === true){
        content = document.getElementById("content").value 
      } 
      if(checkliability === true){
        liability = document.getElementById("liability").value 
      }  
      if(checkrent === true){
        rent = document.getElementById("rent").value 
      }  
      if(checkaccident === true){
        accident = document.getElementById("accident").value 
      }

      const suminsured_ = suminsured
      const exinguisher_ = extinguisher

           
    const buyingpolicy= async () => {
        await axios.post("https://server.ayconsultgh.com/api/homedata/registerhomepolicy/",
            {
              name:name,
              contact:contact,
              email:email,
              occupation:occupation,
              address:address,
              charge:charge,
              startdate:effectivedate,
              expirydate:expirydate,
              currency:currency_,  
              commissionpayment:"none",
              transactionID:document.getElementById("transactionID").value,
              covers:{building:building, content:content, liability:liability, rent:rent, accident:accident},
              suminsured:suminsured_,
              withextinguisher:exinguisher_,
           
              agentname:agentname_, 
              agentcontact:agentcontact_,
              agentID:agentIDnumber_,
              agentemail:agentemail_,
              agentcommission:agentcommission_,
              aycommission:aycommission_,
              institution:institution_,

            }).then(res => {

                if (res.status === 200) {

                        setloading(!true)
                        
                      swal("", "Homeowner Policy Added", "success");

                        e.target.reset()
                        handleClosedelete()
                        document.getElementById("ll").reset()

                }else{
                      setloading(!true)
                      swal("", " Error Adding Homeowner Policy ", "error");

                      
                }
               
                

            })

    }

       if((document.getElementById("transactionID").value).length>10 && (document.getElementById("transactionID").value).length<13){
     buyingpolicy()

    }else{
         setloading(!true)
         settransact(false)

    }

    }





    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpensnack(false);
    };

    const handleChangecurrency = (event) => {
        setcurrency(event.target.value);
    }; 
     const handleChangeextinguisher = (event) => {
        setextinguisher(event.target.value);
    }; 
   
    const handlecheck=(setdata, maindata)=>{
  
        setdata(!maindata)

    }

    const sumchanger= ()=>{
      var building = 0
      var content = 0
      var liability = 0
      var rent = 0
      var accident = 0

      if(checkbuilding === true){
        building = document.getElementById("building").value 
      }
      if(checkcontent === true){
        content = document.getElementById("content").value 
      } 
      if(checkliability === true){
        liability = document.getElementById("liability").value 
      }  
      if(checkrent === true){
        rent = document.getElementById("rent").value 
      }  
      if(checkaccident === true){
        accident = document.getElementById("accident").value 
      }

      setsuminsured(parseFloat(building)+parseFloat(content)+parseFloat(liability)+parseFloat(rent)+parseFloat(accident))
       
  
      
    }


     const [opendelete, setOpendelete] = React.useState(!true);

     
 const handleChangeagenttype = (event) => {
        setagenttype(event.target.value);
    };

    const handleClickOpendelete = () => {
        setOpendelete(true);
    };
    const handleClosedelete = () => {
        setOpendelete(false);
    };

 const changepremium=(e)=>{
  setpremium(e.target.value)
 }

    return (

      <div>
               
            <Dialog maxWidth="md" onClose={handleClosedelete} aria-labelledby="customized-dialog-title" open={opendelete}>
<Button autoFocus onClick={handleClosedelete} style={{float:"right"}} color="primary">
                        close
                    </Button>
<form onSubmit={yessubmit}  id="ll">
                <DialogContent dividers>
                <h2 align="center">Make Payment</h2>

<p><b>Payment Instructions</b></p>
<p>Please send your Premium to the merchant number provided and provide the transaction ID the forms below.</p>
<h2>Premium : GHC {premium}</h2>
<h2>Send to : 024 0511421</h2>
                   <FormControl align="left" style={{ margin: 10 }}>
              <b>Transaction ID <span style={{ color: "red" }}> *</span></b>
              <TextField
                required
                id="transactionID"
                style={{ width: "28ch" }}
                type="text"
              helperText={transact===false?<span style={{color:"red"}}>Incorrect Transaction ID</span>:""}
                size="small"

              /> </FormControl>

                </DialogContent>
                <DialogActions>
                  {!loading?
              <input type="submit" value="BUY POLICY" style={{ padding: 13.5, cursor: "pointer", borderRadius: 5, outline: "none", border: "none", backgroundColor: "#062333", color: "white", width: "35ch", marginTop: "10px", marginBottom: "4px" }} />
:
              <Button size="large" disabled startIcon={<CircularProgress style={{ color: "#062333", width: "20px", height: "20px" }} />} style={{ backgroundColor: "lightgrey", color: "#062333", width: "35ch", marginTop: "10px", marginBottom: "8px" }} >Loading...</Button>
} 
                </DialogActions>
                </form>
            </Dialog>



  <form align="left" onSubmit={submitform}>

          {JSON.parse(localStorage.getItem("user")).type==="Admin"?
          <div>
          <div  align="center" style={{margin:10}}>
     <FormControl>
 <FormLabel id="demo-row-radio-buttons-group-label">Choose Agent Type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby = "demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                id = "guardianedit"
                                value={agenttype}
                                onChange={handleChangeagenttype}
                            >
                                <FormControlLabel value="Direct" control={<Radio />} label="Head Office" />
                                <FormControlLabel value="Indirect" control={<Radio />} label="Kumasi Agent" />
                                
                            </RadioGroup>
                        </FormControl>
</div>
{agenttype==="Indirect"?

<div>
  
            <FormControl align="left" style={{ margin: 10 }}>
              <b>Agent Name <span style={{ color: "red" }}> *</span></b>
              <TextField
                
                id="agentname"
                style={{ width: "28ch" }}
                type="text"
                placeholder="Enter Full Name"
                size="small"

              /> </FormControl>

            <FormControl align="left" style={{ margin: 10 }}>
              <b>Agent Contact <span style={{ color: "red" }}> *</span></b>
              <TextField
                
                id="agentcontact"
                style={{ width: "28ch" }}
                type="tel"
                placeholder="Enter Contact Number"
                size="small"

              /> </FormControl>

            <FormControl align="left" style={{ margin: 10 }}>
              <b>Agent Email <span style={{ color: "red" }}> *</span></b>
              <TextField
                
                id="agentemail"
                style={{ width: "28ch" }}
                type="tel"
                placeholder="Enter Agent Email"
                size="small"
              /> </FormControl>

</div>
:""
}</div>:""
          }




   <Divider style={{marginBottom:"22px", backgroundColor:"#062333"}}>
        <Chip label="PERSONAL DETAILS" style={{backgroundColor:"white", color:"#062333"}} />
      </Divider>

<FormControl align="left" style={{margin:10}}> 
<b>Name <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="name"
          style={{width:"28ch"}}
          type="text"
          placeholder="Enter Full Name"
          size="small"

        /> </FormControl>
        
        <FormControl align="left" style={{margin:10}}> 
<b>Phone <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="contact"
          style={{width:"28ch"}}
          type="tel"
          inputMode='tel'
          placeholder="Enter Contact"
          size="small"
          inputProps={{maxLength:11}}


        /> </FormControl>  
        <FormControl align="left" style={{margin:10}}> 
<b>Email Address <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="email"
          style={{width:"28ch"}}
          type="email"
          inputMode='email'
          placeholder="Enter Email"
          size="small"

        /> </FormControl>  
        <FormControl align="left" style={{margin:10}}> 
<b>Occupation <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="occupation"
          style={{width:"28ch"}}
          type="text"
          placeholder="Occupation"
          size="small"

        /> </FormControl>
        <FormControl align="left" style={{margin:10}}> 
<b>Address <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="address"
          style={{width:"28ch"}}
          type="address"
          placeholder="Address"
          size="small"

        /> </FormControl>
        



        <br/>
        <br/>
        <br/>
          <Divider style={{marginBottom:"22px", backgroundColor:"#062333"}}>
        <Chip label="POLICY DETAILS" style={{backgroundColor:"white", color:"#062333"}} />
      </Divider>


     
        <FormControl align="left" style={{margin:10}}> 
<b>Effective Date <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="effectivedate"
          style={{width:"28ch"}}
          type="date"
          placeholder="Address"
          size="small"

        /> </FormControl>
        
        <FormControl align="left" style={{margin:10}}> 
<b>Expiry Date <span style={{color:"red"}}> *</span></b>
 <TextField
          required
          id="expirydate"
          style={{width:"28ch"}}
          type="date"
          placeholder="Address"
          size="small"

        /> </FormControl>
        
       
         <FormControl align="left" style={{margin:10}}> 
        <b>Currency <span style={{color:"red"}}> *</span></b>

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={currency}
          size="small"
          style={{width:"28ch"}}
          onChange={handleChangecurrency}
        >
          <MenuItem value="none">
            <em>Choose Currency</em>
          </MenuItem>
          <MenuItem value="Ghana Cedis">Ghana Cedis</MenuItem>
        </Select>
       </FormControl>

       <br/>

       <p align="center"> <b>Select Cover(s) <span style={{color:"red"}}> *</span></b></p>

<Grid container spacing={2}>

<Grid item lg={3} xs={12}> 
<FormControlLabel control={<Checkbox onChange={(e)=> handlecheck(setcheckbuilding, checkbuilding)} />} label="Building" /> <TextField
          required
          id="building"
          style={{width:"12ch"}}
          type="number"
          onChange={()=>sumchanger()}
           defaultValue={0}
          disabled={!checkbuilding}
          placeholder="Cover"
          size="small"
        />
        </Grid>

<Grid item lg={3} xs={12}> <FormControlLabel control={<Checkbox  onChange={(e)=> handlecheck(setcheckcontent, checkcontent)} />} label="Content"/> <TextField
        
          id="content"
          style={{width:"12ch"}}
          type="number"
           onChange={()=>sumchanger()}
           defaultValue={0}
          disabled={!checkcontent}
          placeholder="Cover"
          size="small"

        /></Grid>
        
        
        
         <Grid item lg={3} xs={12}> <FormControlLabel control={<Checkbox onChange={(e)=> handlecheck(setcheckliability, checkliability)}  />} label="Public Liability"/> <TextField
         
          id="liability"
          style={{width:"12ch"}}
          type="number"
           onChange={()=>sumchanger()}
          placeholder="Cover"
          size="small"
          defaultValue={0}
          disabled={!checkliability}

        /></Grid>
         <Grid item lg={3} xs={12}> <FormControlLabel control={<Checkbox  onChange={(e)=> handlecheck(setcheckrent, checkrent)} />} label="Rent"/> <TextField
         
          id="rent"
          style={{width:"12ch"}}
          type="number"
           onChange={()=>sumchanger()}
           defaultValue={0}
          disabled={!checkrent}
          placeholder="Cover"
          size="small"

        /></Grid>
        <Grid item lg={4} xs={12}> <FormControlLabel control={<Checkbox onChange={(e)=> handlecheck(setcheckaccident, checkaccident)} />} label="Personal Accident"/> <TextField
         
          id="accident"
          style={{width:"12ch"}}
          type="number"
           onChange={()=>sumchanger()}
           defaultValue={0}
          disabled={!checkaccident}
          placeholder="Cover"
          size="small"

        /></Grid> 



</Grid>
     



       <br/>



        <FormControl align="left" style={{margin:10}}> 
<b>Total Sum Insured </b>
 <TextField
          required
          id="suminsured"
          style={{width:"28ch"}}
          type="number"
          value={suminsured}
          placeholder="Enter total sum insured"
          size="small"

        /> </FormControl>
           <FormControl align="left" style={{margin:10}}> 
        <b>Do you have fire extinguisher? <span style={{color:"red"}}> *</span></b>

        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={extinguisher}
          size="small"
          style={{width:"28ch"}}
          onChange={handleChangeextinguisher}
        >
          <MenuItem value="none">
            <em>Choose option</em>
          </MenuItem>
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
       </FormControl>
      
        <br/> 
        <FormControl align="left" style={{ margin: 10 }}>
                <b>Premium <span style={{ color: "red" }}> *</span></b>
                <TextField
                  required
                  id="charge"
                  style={{ width: "28ch" }}
                  type="text"
                onChange={changepremium}
                  placeholder="Enter Amount"
                  size="small"

                /> </FormControl>
        <br/>
        



          <div align="center">
          
         {!loading?
              <input type="submit" value="SUBMIT" style={{ padding: 13.5, cursor: "pointer", borderRadius: 5, outline: "none", border: "none", backgroundColor: "#062333", color: "white", width: "35ch", marginTop: "10px", marginBottom: "40px" }} />
:
              <Button size="large" disabled startIcon={<CircularProgress style={{ color: "#062333", width: "20px", height: "20px" }} />} style={{ backgroundColor: "lightgrey", color: "#062333", width: "35ch", marginTop: "10px", marginBottom: "8px" }} >Loading...</Button>
}
          </div>
<br/>
<br/>
 </form>
 
 </div>

    );
}

export default Profile3;
