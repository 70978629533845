import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import Motorimage from '../images/Waiting.gif'
import Travelimage from '../images/Smalltown.gif'
import Homeimage from '../images/SecurityOn.gif'

function Example(props) {
    var items = [
        {
            name: <img src={Motorimage} width="50%" marginTop="-50px"/>,
            description: " "
        },  {
            name: <img src={Travelimage} width="50%" marginTop="-50px"/>,
            description: " "
        },  {
            name: <img src={Homeimage} width="50%" marginTop="-50px"/>,
            description: " "
        }
       
    ]

    return (
        <Carousel swipe={true} animation="slide" className="slider">
            {
                items.map((item, i) => <Item key={i} item={item} />)
            }
        </Carousel>
    )
}

function Item(props) {
    return (
        <div style={{height:"70vh"}}>
            <h2>{props.item.name}</h2>
            <p>{props.item.description}</p>
        </div>
    )
}

export default Example