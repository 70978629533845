import React from 'react';
import Grid from '@mui/material/Grid';
import '../index.css'
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import AccountCircle from '@mui/icons-material/AccountCircle';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Slider from './slider';
//import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import Logo2 from '../images/photo_2022-06-02_02-37-50.jpg';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { Offline, Online } from "react-detect-offline";
import Network from '../images/network.svg';



import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { update_accident } from '../redux/accident';
import { update_home } from '../redux/home';
import { update_motor } from '../redux/motor';
import { update_travel } from '../redux/travel';



import Logo from '../images/logo.png';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams
} from "react-router-dom";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function BurcyHome() {
    const dispatch = useDispatch()

    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });
    const [opensnack, setOpensnack] = React.useState(false);
    const [loading, setloading] = React.useState(false)
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const [message, setmessage] = React.useState("");
    const [stated, setstated] = React.useState("");
    const handleClicksnack = () => {
        setOpensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpensnack(false);
    };


    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const login = (e) => {
        e.preventDefault()
        const password = document.getElementById("password").value
        const username = document.getElementById("username").value

        const doer = async () => {
            setloading(true)

            await axios.post("https://cromer.sisrevolution.com/api/staff/login/",
                {
                    email: username,
                    password: password,
                }).then(res => {

                    if (res.status === 200) {

                        localStorage.setItem("user", JSON.stringify(res.data))
                        window.location.reload()


                    } else if(res.status === 201){
                        setloading(!true)
                         handleClicksnack()
                        setmessage("You have been suspended")
                        setstated("warning")
                    } else {
                    console.log(res)
                        setloading(!true)
                        handleClicksnack()
                        setmessage("Login Error, please check your login credentials")
                        setstated("error")
                    }

                })

        }

       doer()


    }



    return (

       <div>
       
       
        <Container maxWidth="lg" >
            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClosesnack}>
                <Alert onClose={handleClosesnack} severity={stated} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
                

            <br/>
<div className="slogan" style={{width:"100%", backgroundColor:"#031428", paddingTop:10, paddingBottom:10, borderRadius:30}}>
<img src={Logo2} width="30%" />
            <h3  style={{color:"white", marginTop:"-8px"}}>
               CROMER APARTMENTS
             </h3>
             <p style={{marginTop:"-15px", color:"white" , fontSize:"16px"}}>A Home Away From Home</p>
             </div>
           <p className='kkk'></p>
                <h1 style={{ marginTop:26 ,color:"#062333" }}> SignIn </h1>
                <br/>
<form onSubmit={login}>
                <FormControl variant="outlined">

                    <Input
                    required
                        id="username"
                        placeholder="Email"
                        type="email"
                        className="credentials"
                        variant="standard"
                        startAdornment={
                            <InputAdornment position="start">
                                <PersonOutlineIcon style={{ marginRight: 4, color:"#062333" }} />   |

                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">

                            </InputAdornment>
                        }

                    />
                </FormControl>
                <br />
                <br />
                <br />
                <FormControl variant="standard">

                    <Input
                    required
                        id="password"
                        placeholder="Password"
                        type="password"
                        className="credentials"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                         
                        startAdornment={
                            <InputAdornment position="start">
                                <VpnKeyOutlinedIcon style={{ marginRight: 4, color:"#031428" }} />   |     <Divider orientation="vertical" />

                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>

                <Grid container style={{ textAlign: "left" }}>

                <Grid lg={6} xs={12} md={6}>

                    </Grid>
                <Grid lg={6} xs={12} md={6} style={{ textAlign:"center" }}>
                    <br />
                    {/* <Link to="/forgottenpassword" style={{ fontSize: 15, marginTop: 40, marginBottom: 40,  }}>
                            forgotten password?
                        </Link> */}
                    </Grid>

                </Grid>



            <br />

            {loading === false ?
                <input type="submit" value="SUBMIT" style={{backgroundColor:"#062333", color:"white", padding:"15px", borderRadius:"5px", border:"none", outline:"none"}}  size="large" />
                
                :
                <Button variant="contained" disabled={loading} size="large">

                    <CircularProgress size={20} />  Loading...

                </Button>
            }
               
                <br />
              </form>
<br/>
              {/* <Link to="/signup"> Register New AY Administrator </Link> */}
            </Container>
           
           
           {/* <Offline>
    <div>
<img src={Network} className="nonetwork" />
<p align="center" >no internet connection, connect internet and reload</p>
<button onClick={()=>window.location.reload()} style={{backgroundColor:"white", border:"none", color:"blue", cursor:"pointer"}}> Reload</button>
    </div>
</Offline> */}

</div>
    );
}
